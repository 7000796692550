import { Field } from "formik";
import React from "react";

const FormikCheckbox = ({ name, label, consistentLabel }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <div className="mb-5">
          {consistentLabel && (
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {consistentLabel}
              <span className="text-blue-500"> *</span>
            </label>
          )}
          <label
            className="flex items-center mb-2 text-gray-600 font-medium text-sm cursor-pointer"
            onClick={() => form.setFieldValue(name, !field.value)}
          >
            {/* Custom checkbox */}
            <div
              className={`w-5 h-5 flex items-center justify-center border-2 rounded-sm w-[25px] ${
                field.value
                  ? "bg-blue-600 border-blue-600"
                  : "bg-white border-gray-300"
              }`}
              // Toggle Formik's state
            >
              {field.value && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
            </div>
            <span className="ml-3">{label}</span>
          </label>
        </div>
      )}
    </Field>
  );
};

export default FormikCheckbox;
