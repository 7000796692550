import React, { useState } from "react";
import { Button } from "antd";
import { toast } from "react-toastify";
import { CloseOutlined } from "@ant-design/icons";
import { FiCheck } from "react-icons/fi"; // Example icon, replace if needed
import GettingStarted from "./GettingStartedTikTok";
import AffiliateManagerModal from "./AffiliateManagerModal";
import VerifyingAffiliateManagerModal from "./VerifyingModal";
import AuthenticateTTSAppModal from "./AuthenticateTTSAppModal";
import { userConfirmsAliasAdded } from "../store/shops";
import { useAppDispatch, useAppSelector } from "../hooks";
import Confetti from "./svgs/Confetti";

interface Shop {
  shop_id: string | number;
  account_email: string;
  onboarding_status: string;
  onboarding_link: string;
}

const AuthenticateTikTokShop = ({
  onboarding_status,
  shop,
  refreshShops,
}: {
  shop: Shop;
  refreshShops: () => void;
  onboarding_status: string;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalContactVisible, setIsContactModalVisible] = useState(false);
  const [isModalVisibleTTS, setIsModalVisibleTTS] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("Not verified");
  const shops = useAppSelector((state) => state.shops);
  const shopAffiliated = shops.shopAffiliatedWithReacher;
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleConfirm = () => {
    setButtonDisabled(true);
    setVerificationStatus("Verifying");
    dispatch(userConfirmsAliasAdded({ shop_id: Number(shop.shop_id) }))
      .then(() => {
        setIsModalVisible(false);
        handleOpenModalContact();
        setButtonDisabled(false);
        setVerificationStatus("Verified");
        refreshShops();
      })
      .catch((error) => {
        setButtonDisabled(false);
        console.error("Error confirming alias:", error);
        setVerificationStatus("Verification failed");
      });
  };

  const handleOpenModalContact = () => {
    setIsContactModalVisible(true);
  };

  const handleCloseModalContact = () => {
    setIsContactModalVisible(false);
    refreshShops();
  };
  const handleContact = () => {
    console.log("Contact Reacher button clicked");
    setIsContactModalVisible(false);
  };

  const handleOpenModalTTS = () => {
    setIsModalVisibleTTS(true);
    handleAuthenticate();
  };

  const handleCloseModalTTS = () => {
    setIsModalVisibleTTS(false);
  };

  const redirectToAffiliateShop = (region) => {
    let url = "";

    switch (region) {
      case "US":
        url = `${process.env.REACT_APP_US_AFFILIATE_SHOP}&state=${shops.selectedStoreId}`;
        break;
      case "UK":
        url = `${process.env.REACT_APP_UK_AFFILIATE_SHOP}&state=${shops.selectedStoreId}`;
        break;
      default:
        url = "https://default-url.com";
    }

    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Affiliate shop URL is not defined for this region.");
    }
  };

  const handleAuthenticate = () => {
    if (shops.selectedStoreRegion === "US") {
      redirectToAffiliateShop(shops.selectedStoreRegion);
    } else {
      toast.error("TTS App is not available in your region");
    }
  };



  return (
    <div>
      <AffiliateManagerModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onConfirm={handleConfirm}
        account_email={(shop as Shop)?.account_email}
        disabled={buttonDisabled}
        onboarding_status={onboarding_status === "added_to_affiliate_portal" ?
          "progress" :
          onboarding_status === "onboard_complete" || onboarding_status === "container_created"
            ? "complete"
            : "pending"}     
          />
      <VerifyingAffiliateManagerModal
        visible={isModalContactVisible}
        account_email={(shop as Shop)?.account_email}
        onCancel={handleCloseModalContact}
        onContact={handleContact}
        onboarding_status={onboarding_status === "added_to_affiliate_portal" ?
          "progress" :
          onboarding_status === "onboard_complete" || onboarding_status === "container_created"
            ? "complete"
            : "pending"}
      />
      {/* <AuthenticateTTSAppModal
        visible={isModalVisibleTTS}
        onCancel={handleCloseModalTTS}
        onAuthenticate={handleAuthenticate}
      /> */}
      {!shopAffiliated && (
        <div
          className="flex items-center justify-between p-4 rounded-lg shadow-md_01 my-10"
          style={{ background: "linear-gradient(4deg, #4B73FF, #7CF7FF)" }}
        >
          <div className="flex items-center space-x-4">
            <Confetti />
            <span className="text-white text-sm md:text-base font-medium">
              We're excited to announce that we are now an official TikTok Shop
              App!
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              type="primary"
              className="bg-white text-black rounded-md text-lg hover:bg-gray-100 h-[40px] w-[150px]"
              onClick={handleAuthenticate}
            >
              Authenticate
            </Button>
            <Button
              type="text"
              icon={<CloseOutlined className="text-white" />}
              className="hover:bg-blue-400"
            />
          </div>
        </div>
      )}

      <GettingStarted
        handleOpenModal={handleOpenModal}
        handleOpenModalTTS={handleAuthenticate}
        onboarding_status={onboarding_status}
      />
    </div>
  );
};

export default AuthenticateTikTokShop;
