import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Spin, Button, Space, message, Empty, Tooltip } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  DownloadOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../hooks";
import { deleteVideoGroup, getVideoGroups } from "../store/videoGroups";
import CustomTable from "../../common/table";
import SearchComponent from "../../common/search";
import CustomModal from "../../common/modal";
import * as XLSX from "xlsx";
import IconButton from "../../common/button";

export default function ContentFlywheels() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shops = useAppSelector((state) => state.shops);
  const videoGroups = useAppSelector((state) => state.videoGroups);
  const shopGroups = videoGroups.videoGroups[shops.selectedStoreId] || {};
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);

  // Fetch video groups when the selected store changes
  useEffect(() => {
    if (shops.selectedStoreId) {
      dispatch(getVideoGroups({ shop_id: shops.selectedStoreId }));
    }
  }, [shops.selectedStoreId]);

  // Process data to calculate cumulative GMV and units sold
  useEffect(() => {
    const aggregatedGroups = Object.values(shopGroups).map((group) => {
      const totalGMV = group.videos.reduce(
        (acc, video) => acc + (video.gmv_amount || 0),
        0
      );
      const totalUnitsSold = group.videos.reduce(
        (acc, video) => acc + (video.units_sold || 0),
        0
      );
      const gmvCurrency = group.videos[0]?.gmv_currency || "USD"; // Default to USD
      return {
        ...group,
        totalGMV,
        totalUnitsSold,
        gmvCurrency,
      };
    });

    // Filter based on the search query
    const filtered = searchQuery
      ? aggregatedGroups.filter((group) =>
          group.group_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : aggregatedGroups;

    setFilteredGroups(filtered);
  }, [shopGroups, searchQuery]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  // Action handlers
  const handleEdit = (group) => {
    navigate(`/content-flywheels/edit?id=${group.id}`);
  };

  const handleDelete = (group) => {
    setGroupToDelete(group);
    setDeleteModalVisible(true);
  };

  const confirmDelete = () => {
    dispatch(
      deleteVideoGroup({
        group_id: groupToDelete.id,
        shop_id: shops.selectedStoreId,
      })
    );
    setDeleteModalVisible(false);
    setGroupToDelete(null);

    // Add logic to call API or update the store to delete the group
  };

  const cancelDelete = () => {
    setDeleteModalVisible(false);
    setGroupToDelete(null);
  };

  const columns = [
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Creator Inspiration Links",
      dataIndex: "uuid",
      key: "uuid",
      render: (uuid) => (
        <div className="flex items-center space-x-2">
          {/* Copy Icon */}
          <Tooltip title="Copy Link">
            <CopyOutlined
              style={{ cursor: "pointer", fontSize: "16px" }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/content-flywheeling/creator/${uuid}`
                );
                // Optional: Notify the user about the successful copy
                console.log(
                  "UUID copied to clipboard:",
                  `${window.location.origin}/content-flywheeling/creator/${uuid}`
                );
              }}
            />
          </Tooltip>

          {/* Link */}
          <Link
            target="__blank"
            to={`${window.location.origin}/content-flywheeling/creator/${uuid}`}
          >
            View Creator Insights
          </Link>
        </div>
      ),
    },
    {
      title: "Total GMV",
      dataIndex: "totalGMV",
      key: "totalGMV",
      render: (gmv, record) =>
        gmv ? `${record.gmvCurrency} ${gmv.toFixed(2)}` : "-",
    },
    {
      title: "Total Units Sold",
      dataIndex: "totalUnitsSold",
      key: "totalUnitsSold",
      render: (units) => (units ? units : "-"),
    },
    {
      title: "Number of Videos",
      dataIndex: "videos",
      key: "videos",
      render: (videos) => videos.length,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{
              backgroundColor: "#1890ff",
              borderColor: "#1890ff",
              color: "#fff",
            }}
          >
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record)}
            danger
          >
            Delete
          </Button>
          <Button
            icon={<DownloadOutlined />}
            onClick={() => handleDownloadGroup(record, "xlsx")} // XLSX download
            style={{
              backgroundColor: "#4CAF50",
              borderColor: "#4CAF50",
              color: "#fff",
            }}
          >
            XLSX
          </Button>
          <Button
            icon={<DownloadOutlined />}
            onClick={() => handleDownloadGroup(record, "csv")} // CSV download
            style={{
              backgroundColor: "#2196F3",
              borderColor: "#2196F3",
              color: "#fff",
            }}
          >
            CSV
          </Button>
        </Space>
      ),
    },
  ];

  const labels = {
    group_name: "Group Name",
    totalGMV: "Total GMV",
    totalUnitsSold: "Total Units Sold",
    videos: "Number of Videos",
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  };

  const handleDownloadGroup = (group, format) => {
    // Flatten the group videos for better readability
    const exportData = group.videos.map((video) => ({
      "Group Name": group.group_name,
      Description: group.description,
      "Video Title": video.title || "N/A",
      Username: video.username || "N/A",
      "GMV Amount": video.gmv_amount || 0,
      Currency: video.gmv_currency || "N/A",
      "Units Sold": video.units_sold || 0,
      "SKU Orders": video.sku_orders || 0,
      Views: video.views || 0,
      "Click-Through Rate": video.click_through_rate || 0,
      Products: video.products.map((p) => p.name || "N/A").join(", "),
      "Created At": video.created_at || "N/A",
    }));

    if (format === "xlsx") {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.book_append_sheet(workbook, worksheet, group.group_name);
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });
      downloadBlob(blob, `${group.group_name}.xlsx`);
    } else if (format === "csv") {
      const csvContent = XLSX.utils.sheet_to_csv(
        XLSX.utils.json_to_sheet(exportData)
      );
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      downloadBlob(blob, `${group.group_name}.csv`);
    }
  };

  const handleDownloadAll = (data, format) => {
    // Flatten the data to include group and video details
    const exportData = data.flatMap((group) =>
      group.videos.map((video) => ({
        "Group Name": group.group_name,
        Description: group.description,
        "Video Title": video.title || "N/A",
        Username: video.username || "N/A",
        "GMV Amount": video.gmv_amount || 0,
        Currency: video.gmv_currency || "N/A",
        "Units Sold": video.units_sold || 0,
        "SKU Orders": video.sku_orders || 0,
        Views: video.views || 0,
        "Click-Through Rate": video.click_through_rate || 0,
        Products: video.products.map((p) => p.name || "N/A").join(", "),
        "Created At": video.created_at || "N/A",
      }))
    );

    if (format === "xlsx") {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "All Groups");
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });
      downloadBlob(blob, "AllGroupsData.xlsx");
    } else if (format === "csv") {
      const csvContent = XLSX.utils.sheet_to_csv(
        XLSX.utils.json_to_sheet(exportData)
      );
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      downloadBlob(blob, "AllGroupsData.csv");
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <h2 className="text-xl font-semibold mb-4">Flywheels</h2>
        <IconButton
          label="Create a flywheel"
          onClick={() => {
            navigate("/content-flywheels/create");
          }}
          icon={<PlusOutlined />}
          buttonClass="bg-black text-white hover:bg-gray-800 px-4 py-2 rounded-md"
          textClass="text-sm font-medium"
        />
      </div>
      <div className="mb-4">
        <SearchComponent
          search={searchQuery}
          setSearch={setSearchQuery}
          onSearch={handleSearch}
          placeholder="Search by group name"
          inputStyle={{ width: 372, height: 48 }}
        />
      </div>
      {filteredGroups.length > 0 && (
        <Space className="flex justify-end">
          <>
            <Button
              onClick={() => handleDownloadAll(filteredGroups, "xlsx")}
              icon={<DownloadOutlined />}
              style={{
                backgroundColor: "#4CAF50",
                borderColor: "#4CAF50",
                color: "#fff",
              }}
            >
              XLSX
            </Button>
            <Button
              onClick={() => handleDownloadAll(filteredGroups, "csv")}
              icon={<DownloadOutlined />}
              style={{
                backgroundColor: "#2196F3",
                borderColor: "#2196F3",
                color: "#fff",
              }}
            >
              CSV
            </Button>
          </>
        </Space>
      )}

      {Object.keys(shopGroups).length ? (
        <CustomTable
          columns={columns}
          data={filteredGroups}
          loading={false}
          labels={labels}
        />
      ) : typeof videoGroups.videoGroups[shops.selectedStoreId] ===
        "undefined" ? (
        <div className="flex items-center justify-center h-[50vh]">
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex h-[80vh] items-center justify-center">
          <Empty />
        </div>
      )}

      {/* Delete Modal */}
      <CustomModal
        visible={deleteModalVisible}
        title={`Delete Group "${groupToDelete?.group_name}"?`}
        content={
          <p>
            Are you sure you want to delete the group{" "}
            <strong>{groupToDelete?.group_name}</strong>? This action cannot be
            undone.
          </p>
        }
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
        confirmText="Delete"
        confirmButtonType="primary"
        showModalFooter
        className="delete-modal"
      />
    </div>
  );
}
