import React from "react";
import { Field, Form } from "formik";
import { Tooltip, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomImageUploader from "./CustomImageUploader";
import MessageEntryTextField from "./MessageEntryTextField";

interface MessageImageFormProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
  onPrev: () => void;
  isSubmitting: boolean;
}

const MessageImageForm: React.FC<MessageImageFormProps> = ({
  setFieldValue,
  values,
}) => {

  return (
    <Form className="mt-6 mb-2 w-full">
      <div >
        <div className="mb-6">
          <MessageEntryTextField/>
        </div>

        <div className="">
          <CustomImageUploader
            value={values.image}
            onChange={(file) => setFieldValue("image", file)}
            label="Image"
            previousImageName={values.image_name}
            error={values.imageError}
          />
        </div>

          {!values.image && (
            <div className="mt-2 text-sm text-gray-400">
              Warning: No new image selected. Saving now will not affect the image
              to be sent.
            </div>
          )}
        </div>
    </Form>
);
};

export default MessageImageForm;
