// import { Spin } from "antd";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { TableProps } from "antd";
import { Button, Input, Modal, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { FiAlertOctagon } from "react-icons/fi";
import { LuLoader } from "react-icons/lu";
import { toast } from "react-toastify";
import Skeletons from "../../../pages/components/skeletons/Skeleton";
import { handleError } from "../../../pages/helpers";
import { archiveShop } from "../../../pages/store/shops";
import { useAppDispatch, useAppSelector } from "../../hooks";
import UpdateShopNameModal from "./UpdateShopNameModal";
import { plans } from "../../../utils/plans";

interface ShopTableProps {
  shop_id: number;
  shop_name: string;
  region: "US" | "UK" | "Global";
  status: "active" | "inactive" | "pro" | "basic";
  subscription_id: string | null;
  unit_amount: number | null;
  price: string | null;
  plan: string | null;
}

// interface DataType {
//   key: string;
//   shopID: number;
//   shopName: string;
//   region: string;
//   links: string[];
// }

const ShopTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const [currShop, setCurrShop] = useState({ shop_id: null, linked: false });
  const [open, setOpen] = useState(false);

  const handleOpen = (shop) => {
    setCurrShop(shop);
    setOpen(true);
  };

  const handleCloseDelete = () => {
    if (!isArchiving) {
      setCurrShop({ shop_id: null, linked: false });
      setOpen(false);
    }
  };
  const deleteFun = async () => {
    try {
      if (currShop?.shop_id) {
        try {
          const resp = await dispatch(
            archiveShop({ shop_id: currShop.shop_id })
          );
          console.log(resp);
          if (resp?.error) {
            toast.error("Server Error");
          } else toast.success("Shop Deleted Successfully.");
        } catch (error) {
          toast.error("Server Error");
        }

        setCurrShop({ shop_id: null, linked: false });
        setOpen(false);
      }
    } catch (error) {
      handleError(error);
      setCurrShop({ shop_id: null, linked: false });
      setOpen(false);
    }
  };
  const columns: TableProps<ShopTableProps>["columns"] = [
    {
      title: "Shop ID",
      dataIndex: "shop_id",
      key: "shop_id",
      render: (_, { shop_id }) => (
        <>
          <div className="font-bold">{shop_id}</div>
        </>
      ),
    },
    {
      title: "Shop Name",
      dataIndex: "shop_name",
      key: "shop_name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Region",
      dataIndex: "shop_region",
      key: "region",
    },
    {
      title: "Linked Subscription",
      key: "price",
      dataIndex: "price",
      render: (_, { price }) => (
        <>
          {plans[price || "default"]?.name !== "None" && (
            <Tag bordered={true}>{plans[price || "default"]?.name}</Tag>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <>
          <div>
            <Space>
              {/* <CloudUploadOutlined
                onClick={() => console.log("Cloud Clicked")}
              /> */}

              <button
                onClick={() =>
                  handleOpen({
                    shop_id: record.shop_id,
                    linked: record.subscription_id ? true : false,
                  })
                }
              >
                <DeleteOutlined />
              </button>
              <UpdateShopNameModal
                oldShopName={record.shop_name}
                shopId={record.shop_id}
              />
            </Space>
          </div>
        </>
      ),
    },
  ];

  const [filterInput, setFilterInput] = useState("");
  const { shops: allShops, isArchiving, isFetching } = useAppSelector(
    (state: any) => state.shops
  );
  const auth = useAppSelector((state: any) => state.auth);
  const shops = (allShops[auth?.meInfo?.customer_id] || [])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(shops.length / 10));
  const [filteredShops, setFilteredShops] = useState<any>(shops);


  const handleChange = (event: any) => {
    if (event.target.value) {
      setFilterInput(event.target.value);
      setCurrentPage(1);
      let currShops = [...shops];
      currShops = currShops.filter((i) =>
        i?.shop_name?.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setTotalPages(Math.ceil(currShops.length / 10));
      filterShops(currShops, 1);
    } else {
      setFilterInput("");
      setFilteredShops(shops);
      setCurrentPage(1);
      setTotalPages(Math.ceil(shops.length / 10));
      filterShops(shops, 1);
    }
  };

  const filterShops = (currShops: any[], currPage: any) => {
    const indexOfLastSubscription = currPage * 10;
    const indexOfFirstSubscription = indexOfLastSubscription - 10;
    setFilteredShops(
      currShops.slice(indexOfFirstSubscription, indexOfLastSubscription)
    );
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      filterShops(shops, currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if(shops?.length) {
      console.log('insideerrrrrrr')
      setCurrentPage(1);
      setTotalPages(Math.ceil(shops.length / 10));
      filterShops(shops, 1);  
    }
  }, [shops]);

  const prevPage = () => {
    if (currentPage > 1) {
      filterShops(shops, currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Modal
        open={open}
        centered
        maskClosable={!isArchiving}
        className="top-14 transition-none"
        footer={null}
        title={
          <p className="font-semibold bg-transparent text-start font-sans text-lg leading-6">
            Delete Shop
          </p>
        }
        onCancel={handleCloseDelete}
      >
        <div className="flex flex-col">
          <p className="mt-1 text-sm text-slate-600">
            Are you sure you want to delete this shop? This action cannot be
            undone.
          </p>

          {currShop.linked ? (
            <div className="flex flex-row rounded-lg p-4 bg-amber-50 gap-x-3 mt-3">
              <div>
                <FiAlertOctagon className="text-xl mt-1 text-amber-600" />
              </div>
              <p className="text-sm text-gray-900 ">
                Please note that the shop has an active subscription. If you
                want to delete this shop cancel your subscription first by
                heading over to subscriptions tab.
              </p>
            </div>
          ) : (
            <div className="flex flex-row justify-between mt-3 gap-x-3">
              <button
                onClick={handleCloseDelete}
                className="p-2 border border-slate-200 font-semibold w-full rounded-lg"
              >
                I changed my mind!
              </button>
              <button
                disabled={isArchiving}
                onClick={deleteFun}
                className={`p-2 space-x-3 flex flex-row justify-center items-center border border-slate-200 font-semibold w-full rounded-lg bg-red-600 text-white ${
                  isArchiving && "cursor-not-allowed"
                }`}
              >
                <p>Delete Shop</p>
                {isArchiving && <LuLoader className="animate-spin" />}
              </button>
            </div>
          )}
        </div>
      </Modal>
      {isFetching ? (
        <div className="mt-5">
          <Skeletons />
        </div>
      ) : (
        <>
          <Table<ShopTableProps>
            rowKey={(e) => e.shop_id}
            columns={columns}
            dataSource={filteredShops}
            pagination={false}
            title={() => {
              return (
                <>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <p className="font-semibold text-xl align-baseline">
                        Manage Shops
                      </p>
                      <Tag className="ml-2">{`${shops.length} Shops`}</Tag>
                    </div>

                    <div className="w-1/4">
                      <Input
                        placeholder="Search Shops..."
                        prefix={
                          <SearchOutlined
                            style={{ color: "#D1D5DB", widows: 20, height: 20 }}
                          />
                        }
                        className="w-full"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </>
              );
            }}
          />

          <div className="flex items-center justify-between bg-white p-4 rounded-b-md">
            <p>{`Page ${totalPages ? currentPage : 0} of ${totalPages}`}</p>
            <div className="flex items-center gap-2">
              <Button
                className="font-medium"
                disabled={currentPage === 1}
                onClick={prevPage}
              >
                Previous
              </Button>
              <Button
                disabled={currentPage >= totalPages}
                className="font-medium"
                onClick={nextPage}
              >
                Next
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ShopTable;
