import React, { useEffect, useState } from "react";
import { Tooltip, Switch, Badge, Spin } from "antd";
import { TikTokOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomTable from "../../common/table";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getContentManager } from "../store/contentManagerSlice";
import ColumnSelector from "../my-creators/columnSelector";
import { AiOutlineClose } from "react-icons/ai";
import { CiFilter } from "react-icons/ci";
import { getVideoConfig } from "../store/videoGroups";
import { transformConfigData } from "./helper";
import CustomFilters from "../customFilter/customFilters";
import FilterFooter from "../customFilter/customFilterFooter";
import { convertToURLSearchParams, getSelectedFilters } from "../groups/helper";
import CustomModal from "../../common/modal";

const MyContentTable = ({ selectedVideos, setSelectedVideos }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const paramsObject = Object.fromEntries(searchParams.entries());
  const { id } = paramsObject;
  const [openfiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const contentManagerSlice = useAppSelector(
    (state) => state.contentManagerSlice
  );
  const videoGroups = useAppSelector((state) => state.videoGroups);
  const shops = useAppSelector((state) => state.shops);
  let key = `${shops.selectedStoreId}`;
  if (Object.keys(paramsObject).length > 0) {
    key = `${shops.selectedStoreId}-${location.search}`;
  }
  console.log("key1312321", key);
  const contentManagerVideos = contentManagerSlice.contentManager?.[key] || [];
  console.log(
    "contentManagerVideos2131232",
    contentManagerSlice.contentManager
  );
  const selectedVideoGroup = videoGroups.selectedVideoGroup;
  const filterLoading = videoGroups.filterLoading;
  const videoFilter =
    Object.values(videoGroups.filters?.[shops.selectedStoreId] || {}).length > 0
      ? transformConfigData(videoGroups.filters?.[shops.selectedStoreId])
      : {};
  console.log("videoFilter123321312", videoGroups.filters);
  const handleAddRemove = (video) => {
    setSelectedVideos((prev) =>
      prev.some((v) => v.video_id === video.video_id)
        ? prev.filter((v) => v.video_id !== video.video_id)
        : [...prev, video]
    );
  };

  useEffect(() => {
    if ((contentManagerVideos || [])?.length === 0 && shops.selectedStoreId) {
      if (Object.keys(paramsObject).length) {
        // setFilters(updateFilters(transformApiData(creators.filters), paramsObject));
        let dataObject: any = {};
        Object.keys(paramsObject).forEach((key) => {
          dataObject[key] = (paramsObject[key] || "").split(",");
        });
        // getShopCreators(dataObject);
        dispatch(
          getContentManager({
            shop_id: shops.selectedStoreId,
            filters: dataObject,
            search: location.search,
          })
        );
      }
    }
  }, [location.search, shops.selectedStoreId]);

  useEffect(() => {
    if (
      (contentManagerSlice?.[key] || []).length === 0 &&
      shops.selectedStoreId &&
      Object.keys(paramsObject).length === 0
    ) {
      dispatch(
        getContentManager({
          shop_id: shops.selectedStoreId,
        })
      );
    }
    if (
      Object.values(videoFilter || {}).length === 0 &&
      shops.selectedStoreId
    ) {
      dispatch(
        getVideoConfig({
          shop_id: shops.selectedStoreId,
        })
      );
    }
  }, [key, shops.selectedStoreId]);

  useEffect(() => {
    if (selectedVideoGroup?.[id]) {
      setSelectedVideos(selectedVideoGroup?.[id].videos);
    }
  }, [selectedVideoGroup?.[id]]);

  useEffect(() => {
    if (
      Object.values(videoFilter || {}).length > 0 &&
      Object.values(filters || {}).length === 0
    ) {
      setFilters(videoFilter);
    }
  }, [videoFilter]);

  const filtersItems = [
    {
      name: "Clear All",
      handleClick: () => {
        handleResetFilters();
      },
      icon: <AiOutlineClose color={"#1E1E1E"} />,
      buttonClass:
        "flex items-center gap-2 text-gray-700 hover:text-black bg-transparent",
      textClass: "text-black",
    },
    {
      name: "Video Filters",
      handleClick: () => {
        setOpenFiltersDrawer(true);
      },
      icon: <CiFilter color={"#1E1E1E"} />,
      badge: Object.keys(paramsObject).filter((el) => el !== "id").length,
      buttonClass: "bg-gray-200 border-solid border-1 border-black",
      textClass: "text-black",
    },
  ];

  const columns = [
    showVideo
      ? {
          title: "Video",
          dataIndex: "video_id",
          key: "video",
          render: (video_id) => (
            <div
              style={{
                position: "relative",
                width: "300px", // Fixed width for better visibility
                height: "533px", // Fixed height to match 9:16 aspect ratio
                overflow: "hidden",
              }}
            >
              <iframe
                src={`https://www.tiktok.com/embed/${video_id}?autoplay=0`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
                scrolling="no"
                allowFullScreen
              />
            </div>
          ),
        }
      : {},
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      render: (title) => (
        <Tooltip title={title}>
          <h4
            className="font-bold text-md truncate"
            style={{
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: 200,
              cursor: "pointer",
            }}
          >
            {(title || "").trim() || '-'}
          </h4>
        </Tooltip>
      ),
    },
    {
      title: "Creator",
      dataIndex: "username",
      key: "creator",
      render: (username) => <span className="text-gray-600">{username}</span>,
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      render: (products) => {
        return products
          .filter((el) => el.id !== "0")
          .map((product) => (
            <div className="ml-4" style={{ maxWidth: "200px" }}>
              <Tooltip title={product.name}>
                <div
                  style={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    cursor: "pointer",
                  }}
                >
                  {product.name || "-"}
                </div>
              </Tooltip>
              <div style={{ color: "#888" }}>ID: {product.id || "-"}</div>
            </div>
          ));
      },
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
      render: (views) => <span className="text-gray-600">{views}</span>,
    },
    {
      title: "GMV",
      dataIndex: "gmv",
      key: "gmv",
      render: (_, record) => (
        <span className="text-gray-600">
          {record.gmv_amount} {record.gmv_currency}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, video) => (
        <div className="flex items-center space-x-2 justify-between">
          <a
            href={`https://www.tiktok.com/@${video.username}/video/${video.video_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tooltip title="View on TikTok">
              <TikTokOutlined style={{ fontSize: "24px", color: "#000" }} />
            </Tooltip>
          </a>
          <button
            onClick={() => handleAddRemove(video)}
            className={`${
              selectedVideos.some((v) => v.video_id === video.video_id)
                ? "bg-red-500 hover:bg-red-600"
                : "bg-green-500 hover:bg-green-600"
            } text-white px-2 py-1 rounded`}
          >
            {selectedVideos.some((v) => v.video_id === video.video_id)
              ? "Remove"
              : "Add"}
          </button>
        </div>
      ),
    },
  ];
  const handleResetFilters = () => {
    const resetFilters = Object.keys(filters).reduce(
      (acc, key) => {
        const filter = filters[key as keyof typeof filters];
        acc[key as keyof typeof filters] = {
          ...filter,
          options: filter.options?.map((option) => ({
            ...option,
            selected: false,
          })),
          value: "",
          selected: false,
        };
        return acc;
      },
      {} as typeof filters
    );

    setFilters(resetFilters);
    navigate(`/content-flywheels/create`, { replace: true });
  };

  return (
    <div style={{ background: "white", padding: "20px" }}>
      <header className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <h2 className="font-bold text-2xl mr-4">
            My Content ({contentManagerVideos.length})
          </h2>
          <Switch
            checked={showVideo}
            onChange={(checked) => setShowVideo(checked)}
            checkedChildren="Videos On"
            unCheckedChildren="Videos Off"
          />
        </div>
        <div className="flex space-x-4 items-center">
          <div className="flex mt-4">
            {filtersItems.map((filter, index) => (
              <div
                className="mr-4 hover:bg-black-500 hover:border-black-500 hover:text-white transition duration-300"
                key={`filter-${filter.name}`}
              >
                <Badge count={filter.badge || 0}>
                  <ColumnSelector
                    columns={[]}
                    onUpdate={() => {}}
                    showButton={
                      filter.name === "Clear All"
                        ? Object.keys(paramsObject).filter((el) => el !== "id")
                            .length > 0
                        : true
                    }
                    restProps={{
                      label: filter.name,
                      onClick: filter.handleClick,
                      icon: filter.icon,
                      buttonClass: filter.buttonClass,
                      textClass: filter.textClass,
                    }}
                  />
                </Badge>
              </div>
            ))}
          </div>
        </div>
      </header>
      <CustomModal
        visible={openfiltersDrawer}
        onCancel={() => {
          setOpenFiltersDrawer(false);
        }}
        title={
          <div>
            <div className="mb-2">More Filters</div>
            <div className="border-b-2 border-gray-300 mb-5" />
          </div>
        }
        showModalFooter={false}
        width={700}
        content={
          <>
            <div className="overflow-y-auto max-h-[60vh] min-h-[40vh]">
              {filterLoading ? (
                <Spin />
              ) : (
                <CustomFilters filters={filters} setFilters={setFilters} />
              )}
            </div>
            <FilterFooter
              onClearAll={() => {
                handleResetFilters();
                setOpenFiltersDrawer(false);
              }}
              onApplyFilters={() => {
                const selectedFilters = getSelectedFilters(filters);
                const urlParams = convertToURLSearchParams(selectedFilters);

                // Example of how to get the string representation for a URL
                navigate(`/content-flywheels/create?${urlParams.toString()}`, {
                  replace: true,
                });
                setOpenFiltersDrawer(false);
              }}
            />
          </>
        }
      />
      <CustomTable
        data={contentManagerVideos.map((video) => ({
          ...video,
          key: video.video_id,
        }))}
        loading={
          typeof contentManagerSlice.contentManager?.[key] === "undefined"
        }
        columns={columns}
        rowSelection={{
          selectedRowKeys: selectedVideos.map((video) => video.video_id),
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedVideos(selectedRows);
          },
        }}
        showDownloadOptions={false}
      />
    </div>
  );
};

export default MyContentTable;
