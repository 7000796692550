import React, { useEffect, useState, useMemo } from "react";
import { Divider, Tag } from "antd";
import MessageModal from "./Modal";
import { ArrowLeft, ArrowRight } from "untitledui-js-base";

const ReusableTable = React.memo(({
  cols = [],
  rows = [],
  pageSize = 10,
  modalContent,
  TableTitleComponent,
}) => {
  const [currMessage, setCurrMessage] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currPage, setCurrPage] = useState(1);

  // Calculate the total number of pages
  const pageNum = useMemo(() => Math.ceil(rows.length / pageSize), [rows, pageSize]);

  // Memoize the filtered data for the current page
  const filteredData = useMemo(() => {
    const startIndex = (currPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return rows.slice(startIndex, endIndex);
  }, [currPage, rows, pageSize]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrMessage("");
  };

  // Helper function to generate pagination numbers with ellipsis
  const renderPagination = useMemo(() => {
    const paginationItems = [];
    const visiblePages = 3; // Number of pages visible around current page
    
    // Helper to add a pagination number
    const addPageItem = (i) => (
      <div
        key={i}
        onClick={() => setCurrPage(i)}
        className={`w-10 h-10 hover:bg-secondary-hover ${
          currPage === i && "bg-secondary-hover"
        } grid place-items-center rounded-md cursor-pointer`}
      >
        {i}
      </div>
    );
  
    // Add 'Previous Page' Arrow
    if (currPage > 1) {
      paginationItems.push(
        <div
          key="prev"
          onClick={() => setCurrPage(currPage - 1)}
          className="w-10 h-10 grid place-items-center rounded-md cursor-pointer"
        >
          {"<"}
        </div>
      );
    }
  
    // Add first page
    paginationItems.push(addPageItem(1));
  
    // Ellipsis before middle pages
    if (currPage > visiblePages + 1) {
      paginationItems.push(
        <div key="start-ellipsis" className="w-10 h-10 grid place-items-center">
          ...
        </div>
      );
    }
  
    // Middle pages
    const start = Math.max(2, currPage - 1);
    const end = Math.min(pageNum - 1, currPage + 1);
    for (let i = start; i <= end; i++) {
      paginationItems.push(addPageItem(i));
    }
  
    // Ellipsis after middle pages
    if (currPage < pageNum - visiblePages) {
      paginationItems.push(
        <div key="end-ellipsis" className="w-10 h-10 grid place-items-center">
          ...
        </div>
      );
    }
  
    // Add last page
    if (pageNum > 1) {
      paginationItems.push(addPageItem(pageNum));
    }
  
    // Add 'Next Page' Arrow
    if (currPage < pageNum) {
      paginationItems.push(
        <div
          key="next"
          onClick={() => setCurrPage(currPage + 1)}
          className="w-10 h-10 grid place-items-center rounded-md cursor-pointer"
        >
          {">"}
        </div>
      );
    }
  
    return paginationItems;
  }, [currPage, pageNum]);

  return (
    <div className="mt-8 border border-secondary shadow-xs rounded-xl">
      {/* Render the custom TableTitle */}
      {TableTitleComponent && <TableTitleComponent />}
      <Divider className="border-t border-b-0 border-secondary m-0" />
      {isModalVisible && currMessage && (
        <MessageModal
          isVisible={isModalVisible}
          onClose={handleCancel}
          title="Message"
          message={modalContent ? modalContent(currMessage) : currMessage}
        />
      )}

      {/* Horizontal Scroll Wrapper */}
      <div className="overflow-x-auto w-full">
        <table className="w-full min-w-max">
          <thead>
            <tr className="text-xs text-tertiary-default bg-secondary-default">
              {cols.map((heading, key) => (
                <th key={key} className="px-3xl font-medium text-start py-lg">
                  {heading.name}
                </th>
              ))}
            </tr>
            <tr>
              <th colSpan={cols.length}>
                <Divider className="border-t border-b-0 border-t-secondary m-0 p-0" />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, key) => (
              <React.Fragment key={key}>
                <tr className={`${key === 0 && "bg-secondary-default"} text-sm`}>
                  {cols.map((col, colKey) => (
                    <td
                      key={colKey}
                      className="px-3xl hover:bg-secondary-hover cursor-pointer py-lg"
                    >
                      {col.render ? col.render(row, setCurrMessage, showModal) : row[col.name.toLowerCase().replace(/\s/g, "")]}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td colSpan={cols.length}>
                    <Divider className="border-t border-b-0 border-t-secondary m-0 p-0" />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="pt-3.5 pb-4 px-6">
        <div className="h-9 flex flex-row justify-between">
          <button
            disabled={currPage === 1}
            onClick={() => setCurrPage(currPage - 1)}
            className={`h-9 rounded-md text-sm text-fg-secondary-default font-semibold bg-primary-default ${
              currPage !== 1 && "hover:bg-primary-hover"
            }  border border-secondary shadow-xs py-md px-lg flex flex-row items-center space-x-xs justify-center`}
          >
            <ArrowLeft size="20" />
            <div>Previous</div>
          </button>

          {/* Pagination Number Display */}
          <div className="h-10 text-secondary-hover font-medium text-sm flex flex-row justify-between items-center">
            {renderPagination}
          </div>

          <button
            disabled={currPage === pageNum}
            onClick={() => setCurrPage(currPage + 1)}
            className={`h-9 rounded-md text-sm text-fg-secondary-default font-semibold bg-primary-default ${
              currPage !== pageNum && "hover:bg-primary-hover"
            } border border-secondary shadow-xs py-md px-lg flex flex-row items-center space-x-xs`}
          >
            <div>Next</div>
            <ArrowRight size="20" />
          </button>
        </div>
      </div>
    </div>
  );
});

export default ReusableTable;
