import React, {memo, useEffect, useState} from "react";
// @ts-ignore
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkAction} from "../helpers/index";
import {useAppDispatch, useAppSelector} from "../hooks/index";
import {Api} from "../constants/api";
import clientService from "../helpers/client";
// @ts-ignore
import {Link, useParams, useSearchParams} from "react-router-dom";
import LoadingScreenV2 from "../../pages/components/LoadingV2";
// @ts-ignore
import {useInView} from "react-intersection-observer";
// @ts-ignore
import {Button, Tooltip} from "antd";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {remove} from "@jridgewell/set-array";
import AddNewEntryForm from "./AddNew";

interface Product {
  shop_id: number; // Foreign key to Shop
  video_id: string|null; // Unique identifier for the video
  title?: string | null; // Optional title of the video
  username?: string | null; // Optional username associated with the video
  products?: { id: string; name: string }[] | null; // Array of products with id and product_name
  video_url: string; // Video URL
  spark_code?: string | null; // Optional spark code
}

const SparkCodeForm = () => {
  const [searchParams] = useSearchParams();
  const preview = searchParams.get("preview") === "true";
  const dummy = searchParams.get("dummy") === "true";

  const [newEntry, setNewEntry] = useState<Product>({

    shop_id: 0, // Default foreign key value
    video_id: null, // Default to an empty string
    title: null, // Optional field defaulting to null
    username: null, // Optional field defaulting to null
    products: [{"id": "", "name": ""}], // Default to null (no products associated initially)
    video_url: "", // Default to an empty string
    spark_code: null, // Optional field defaulting to null
  });

  const getFormData = createAsyncThunk(
    "sparkCodeForm:getSparkCodeData",
    async (payload: any) => {
      return clientService.post(Api.sparkCodeForm.getSparkCodeData, payload);
    }
  );
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState<Product[]>([]);
  const [shopName, setShopName] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const {id} = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true); // Loading stat
  const [loadingMessage, setLoadingMessage] = useState(''); // Loading stat
  const [submitPayload, setSubmitPayload] = useState({});
  const handleAddEntry = (newEntry) => {
    console.log('New Entry:', newEntry);
  };
  useEffect(() => {
    setLoading(true);
    setLoadingMessage("Please wait, data is being fetched...");
    dispatch(getFormData({id, dummy}))
      .then((response: any) => {
        console.log("Form Data Loaded", response.payload);
        const data = response.payload.data;
        setSubmitPayload(data)
        setShopName(data.shop_name);
        setCreatorName(data.creator_name);
        setProducts(data.products || []);
        const allDisabled = (data.products || []).length > 0 && (data.products || []).every(product => product?.disabled === true);

        if (allDisabled && !preview &&!dummy) {
          setIsSuccess(true)
        }
      })
      .catch((error) => console.error("Failed to load form data", error))
      .finally(() => setLoading(false));

  }, [dispatch]);
  const initial_values = {
    "products": products,
    "shopName": shopName,
    "creatorName": creatorName,
    "submitPayload": submitPayload
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSubmit = (values) => {
    const products = values.products;
    const allEmpty = products.every((product) => !product.spark_code);
    console.log("Submitting Spark Codes:", products);
    if (allEmpty) {

      setIsModalOpen(true);
    } else {
      console.log("Submitting Spark Codes:", products);


      // Construct payload
      const payload = {
        metadata: submitPayload,
        form_uuid: id,
        shop_name: shopName,
        creator_name: creatorName,
        products: products.map(({products, spark_code, video_url, video_id}) => ({
          product_name: products[0]?.name ?? "",
          product_id: products[0]?.id ?? "",
          spark_code,
          video_url: video_url || `https://www.tiktok.com/@${submitPayload.creator_name}/video/${video_id}`, // Derive URL if it's missing

          video_id,
        })),
      };
      setLoading(true);
      setLoadingMessage("Please wait, while form is being submitted...");
      // API call to submit form data
      clientService
        .post(Api.sparkCodeForm.submitFormData, payload) // Replace `submitFormData` with the correct endpoint
        .then((response) => {
          console.log("Form submitted successfully:", response.data);
          setIsSuccess(true); // Show success page
        })
        .catch((error) => {
          alert("Failed to submit form data. Please try again.");
        }).finally(() => setLoading(false));
    }

  }
  const [isFormOpen, setIsFormOpen] = useState(false);
  const ShopCreatorCards: React.FC<{ shopName: string; creatorName: string }> = React.memo(({
                                                                                              shopName,
                                                                                              creatorName,
                                                                                            }) => {
    return (
      <div className="flex justify-center items-center gap-6">
        {/* Shop Name Card */}
        <div
          className="flex flex-col items-center justify-center bg-gray-50 border rounded-lg p-4 shadow-md w-48">
          <div className="text-blue-500 text-3xl mb-2">
            {/* Icon for Shop */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              className="h-8 w-8"
            >
              <path
                d="M20 6h-4V4a4 4 0 0 0-8 0v2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zM8 4a2 2 0 0 1 4 0v2H8zm12 16H4V8h4v2a1 1 0 0 0 2 0V8h4v2a1 1 0 0 0 2 0V8h4z"/>
            </svg>
          </div>
          <h3 className="text-gray-600 text-sm">Shop Name</h3>
          <p className="font-bold text-lg text-gray-600">{shopName}</p>
        </div>

        {/* Creator Name Card */}
        <div
          className="flex flex-col items-center justify-center bg-gray-50 border rounded-lg p-4 shadow-md w-48">
          <div className="text-blue-500 text-3xl mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              className="h-8 w-8"
            >
              <path
                d="M12 2a7 7 0 1 0 7 7 7 7 0 0 0-7-7zm0 12a5 5 0 1 1 5-5 5 5 0 0 1-5 5zM12 14c-5.33 0-8 2.67-8 8a1 1 0 0 0 2 0c0-3.77 2.69-6 6-6s6 2.23 6 6a1 1 0 0 0 2 0c0-5.33-2.67-8-8-8z"/>
            </svg>
          </div>
          <h3 className="text-gray-600 text-sm">Creator Name</h3>
          <p className="font-bold text-lg text-gray-600">{creatorName}</p>
        </div>
      </div>
    );
  });
  if (isSuccess) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
        {/* Success Icon */}
        <div className="bg-green-100 rounded-full p-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-green-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>

        {/* Success Message */}
        <h3 className="text-2xl font-bold text-gray-800 mt-4">
          Spark Codes Submission Successful
        </h3>
        <p className="text-gray-600 mt-2">
          Your spark code submission form is submitted successfully.
        </p>
      </div>
    );
  }

  if (loading) {
    return <LoadingScreenV2 message={loadingMessage}/>;
  }
  const ProductCard = ({product}) => (
    <div className="p-2 border rounded-md bg-gray-50 hover:bg-gray-100 transition">
      <Tooltip title={product.name}>
        <div
          className="font-semibold text-sm text-gray-600 truncate"
          aria-label={`Product name: ${product.name}`}
        >
          {product.name || "-"}
        </div>
      </Tooltip>
    </div>
  );
  const VideoCard = React.memo(({video, showVideo}) => {
      const [ref, inView] = useInView({triggerOnce: true});


      const products = React.useMemo(
        () => video.products.filter((el) => el.id !== "0"),
        [video.products]
      );

      return (
        <div
          ref={ref}
          className={`flex w-full flex-col items-center`}
          style={{
            background: "#fff",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {showVideo && inView && (
            <iframe
              id={`tiktok-video-${video.video_id}`}
              src={video.video_id ?`https://www.tiktok.com/embed/${video.video_id}?autoplay=0 `: `${video.video_url}?autoplay=0`}
              width="300"
              height="500"
              style={{
                border: "none",
                overflow: "hidden",
              }}
              scrolling="no"
            />
          )}

          <div className="mt-4 w-full bg-white shadow-sm p-2">
            {(video.id) &&(
              <div>
                <Link
                  to={`https://www.tiktok.com/@${video.username}/video/${video.video_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-800 font-medium hover:underline"
                  aria-label="View on TikTok"
                >
                  View on TikTok
                </Link>
                <Tooltip title={video.title}>
                  <h4
                    className="font-semibold text-sm text-gray-600 truncate mt-2"
                    aria-label="Video title"
                  >
                    {video.title || "-"}
                  </h4>
                </Tooltip>
              </div>
            )}

            {products.length > 0 && (
              <div className="mt-4">
                <div className="space-y-2">
                  {products.map((product) => (
                    <ProductCard key={product.id} product={product}/>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  );

  const Products = React.memo(({product, index, handleDeleteEntry}) => (
    console.log('Products:', products[index].title),
      <div className="border relative flex flex-col justify-between rounded-lg shadow-md bg-white">
        <div className="relative">
          <div className="p-2">
            <VideoCard video={product} key={product.video_id} showVideo={true}/>
          </div>
          <button
            onClick={() => handleDeleteEntry(index)}
            type="button"
            className="absolute top-2 right-2 bg-gray-100 text-gray-500 p-1 rounded-full hover:bg-gray-200 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="ml-4 mr-4">
          <div className="mt-2">
            <label className="block text-sm font-medium text-gray-600">Spark Code</label>
            <div className="pt-2 pb-2">
              <Field
                as="textarea"
                name={`products[${index}].title`}
                rows={2}
                className="w-full p-2 border rounded"
              />
              <ErrorMessage
                name={`products[${index}].spark_code`}
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
          </div>
        </div>
      </div>
  ));


  return (
    <Formik initialValues={initial_values} onSubmit={handleSubmit}>
      {({values, setFieldValue}) => (

        <Form>
          <div>
            <div className="p-4 max-w-5xl mx-auto">

              <h1 className="text-2xl mt-2 font-bold text-gray-600 text-center">Spark Code Submission Form</h1>
              <div className="mt-5">
                <ShopCreatorCards shopName={values.shopName} creatorName={values.creatorName}/>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                {values.products.map((product, index) => (

                  <div className="border relative flex flex-col justify-between rounded-lg shadow-md bg-white">
                    <div className="relative">
                      <div className="p-2">
                        <VideoCard video={product} key={product.video_id} showVideo={true}/>
                      </div>
                      <Button
                        onClick={()=>{

                          const updatedProducts = values.products.filter((_, i) => i !== index); // Remove the item by index
                          setFieldValue("products", updatedProducts); // Update Formik's products state

                        }}
                        type="button"
                        className="absolute top-2 right-2 bg-gray-100 text-gray-500 p-1 rounded-full hover:bg-gray-200 hover:text-gray-700"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </Button>
                    </div>
                    <div className="ml-4 mr-4">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-600">Spark Code</label>
                        <div className="pt-2 pb-2">
                          <Field
                            as="textarea"
                            name={`products[${index}].spark_code`}
                            rows={2}
                            className="w-full p-2 border rounded"
                            disabled={values.products[index].disabled == true || preview || dummy}
                          />
                          <ErrorMessage
                            name={`products[${index}].spark_code`}
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  type={"button"}
                  onClick={() => setIsFormOpen(true)}
                  className="border-dashed border-2 border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center text-gray-500 hover:bg-gray-100"
                >
                  <span className="text-2xl font-bold">+</span>
                  <span>Add New Entry</span>
                </button>
                {/* Add New Entry Form */}
                <AddNewEntryForm
                  isFormOpen={isFormOpen}
                  setIsFormOpen={setIsFormOpen}

                  addNewProduct={(newProduct) => {
                    setFieldValue("products", [...values.products, newProduct]);
                  }}
                />

              </div>
              <button
                type={"submit"}
                disabled={preview || dummy}
                className="mt-6 p-2 bg-blue-500 text-white px-6 font-bold py-2 rounded hover:bg-blue-600 block mx-auto"
              >
                Submit Spark Codes
              </button>
            </div>

          </div>

          {isModalOpen && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
                {/* Close Button */}
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Warning Icon */}
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-yellow-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.29 3.86a2.42 2.42 0 013.42 0l7.42 7.42a2.42 2.42 0 010 3.42l-7.42 7.42a2.42 2.42 0 01-3.42 0l-7.42-7.42a2.42 2.42 0 010-3.42l7.42-7.42z"
                      />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v4m0 4h.01"/>
                    </svg>
                  </div>
                  <div className="ml-5">
                    <h3 className="text-lg font-bold text-gray-700">Spark Code Required</h3>
                    <p className="text-sm text-gray-600 mt-2">
                      At least one spark code required to submit this form.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      )}

    </Formik>
  );
}
export default SparkCodeForm;