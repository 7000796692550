import React from "react";
// @ts-ignore
import { Formik, FormikValues } from "formik";
// @ts-ignore
import * as Yup from "yup";

import MessageAddV2 from "./MessageAddV2";

interface AddNewAutomationStepOneTwoProps {
  handleLoginSubmit: any;
  onPrev: () => void;
  initialValues: FormikValues;
  group: any;
}

const validationSchema = Yup.object().shape({
  // message_entry: Yup.string().required("Message Entry is required"),

  message_entry: Yup.string()
    .test(
      "no-amazon",
      'The message cannot contain the word "amazon".',
      (value: any) => !/amazon/i.test(value || "")
    )
    .required("The message entry is required"),
});

const AddNewAutomationStepOneTwoV2: React.FC<
  AddNewAutomationStepOneTwoProps
> = ({ handleLoginSubmit, onPrev, initialValues }) => {
  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      validationSchema={validationSchema}
      onSubmit={(values: any, { setSubmitting }: any) => {
        handleLoginSubmit(values, setSubmitting);
      }}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <MessageAddV2
          onPrev={onPrev}
          values={values}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
        />
      )}
    </Formik>
  );
};

export default AddNewAutomationStepOneTwoV2;
