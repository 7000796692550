import React, { useEffect } from 'react';

type CustomModalProps = {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  message: string;
};

const CustomModal: React.FC<CustomModalProps> = ({ isVisible, onClose, title, message }) => {
  // Disable background scroll when modal is open
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden'; // Disable scroll
    } else {
      document.body.style.overflow = 'auto'; // Re-enable scroll when modal is closed
    }

    return () => {
      document.body.style.overflow = 'auto'; // Cleanup on component unmount
    };
  }, [isVisible]);

  if (!isVisible) return null; // If the modal is not visible, render nothing

  // Prevent click events on modal content from closing the modal
  const handleModalContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose} // Clicking the overlay runs the onClose function
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 scale-100 w-[506px]"
        onClick={handleModalContentClick} // Prevent modal click from propagating to the overlay
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{title}</h2>
          <button
            className="text-gray-600 hover:text-gray-800 text-2xl"
            onClick={onClose}
          >
            &times; {/* Close icon */}
          </button>
        </div>
        <p className="text-gray-700">{message}</p>
      </div>
    </div>
  );
};

export default CustomModal;
