import React, { useEffect, useState } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { Select, Button, Spin, Empty, Image, Tooltip } from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { PlusOutlined } from "@ant-design/icons";
import CreatorPreview from "./CreatorPreview";
import { SchedulingSettingsButton } from "./FilterToggle";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../pages/hooks";
import { getCurrencySign, getStatusColor } from "../../my-creators/helper";
import { getGroups } from "../../../pages/store/creators";
import FormikMultipleSelectField from "../../components/formikForms/FormikMultipleSelectField";
import ReusableTable from "~/pages/dashboard-new/components/TableComponent";
import CRMPreview from "./CRMPreview";

interface CRMGroupLinking {
  onPrev: () => void;
  onNext: (values: any) => void;
  crmGroups: { label: string; value: string }[]; // List of CRM Groups to populate the dropdown
  filteredCreators: any[]; // Data for the Creator Preview
  initialValues: any[]; // Data for the Creator Preview
}

const CRMGroupLinking: React.FC<CRMGroupLinking> = ({
  onPrev,
  onNext,
  initialValues,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shops = useAppSelector((state) => state.shops);
  const creators = useAppSelector((state) => state.creators);
  let key = `${shops.selectedStoreId}`;
  const total = 0;

  useEffect(() => {
    if (
      shops.selectedStoreId &&
      (creators?.groups?.[key] || [])?.length === 0
    ) {
      dispatch(
        getGroups({
          shop_id: shops.selectedStoreId,
        })
      );
    }
  }, []);
  console.log("creators12321312312", creators.groups);
  const groupOptions = ((creators.groups || {})?.[key] || []).map(
    (group: any) => ({
      value: group.group_id, // Use `group_id` as the value
      label: `${group.group_name} #${group.group_id}`, // Use `group_name` as the label
    })
  );
  console.log("groupOptions123123", groupOptions);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: any, { setSubmitting }: FormikHelpers<any>) => {
        onNext(values);
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue, isSubmitting, validateField }) => (
        <Form>
          <div className="p-6 bg-white rounded-lg shadow-sm">
            {/* Title and Description */}
            <div className="flex justify-between ">
              <div className="flex flex-col">
                <h2 className="text-lg font-semibold text-gray-900">
                  Select CRM Group
                </h2>
                <p className="text-sm text-gray-500">
                  Select a CRM group for creator outreach or create a new group
                  by clicking "Create a Group".
                </p>
              </div>

              <div className="flex">
                <div className="mr-2">
                  <SchedulingSettingsButton
                    setOpenModal={setOpenModal}
                    openModal={openModal}
                    weeklyAutomationScheduleProps={{
                      values,
                      setFieldValue,
                      validateField,
                    }}
                  />
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  iconPosition="start"
                  icon={<PlusOutlined />}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300"
                  onClick={() => {
                    navigate("/groups/create");
                  }}
                >
                  Create a group
                </Button>
              </div>
            </div>

            {/* CRM Group Selector */}
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                CRM Group Name <span className="text-blue-500">*</span>
              </label>
            </div>
            <div className="flex">
              <FormikMultipleSelectField
                isMultiple={false}
                name="crm_group_id"
                options={groupOptions}
                placeHolder={"Group name ID# 235743"}
                selectClassName={
                  " border text-gray-700 font-medium text-sm  rounded-xl"
                }
              />
            </div>

            <CRMPreview values={values} />
            {/* Navigation Buttons */}
            <div className ="flex justify-between items-center mt-10">
              <button
                type="button"
                onClick={onPrev}
                className="bg-gray-100 flex items-center hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded-md focus:outline-none"
              >
                <ArrowLeftIcon className="w-4 h-4 mr-2" />
                Previous
              </button>
              <Button
                type="primary"
                htmlType="submit"
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300"
                loading={isSubmitting}
              >
                Continue
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CRMGroupLinking;
