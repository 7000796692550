import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./analytics.css";
import { ApexOptions } from "apexcharts";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "../../../pages/store/theme";

type DateNumberMap = {
  [key: string]: number;
};

type LineGraphProps = {
  data: DateNumberMap;
};

function formatData(data: any, format: "days" | "weeks" | "months") {
  const result: any = [];
  const dateFormat = { year: "numeric", month: "short", day: "numeric" };
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateEntries = Object.entries(data).map(([dateStr, value]) => ({
    date: new Date(dateStr.split("/").reverse().join("-")),
    value,
  }));

  if (format === "days") {
    dateEntries.forEach(({ date, value }) => {
      const dayName = date.toLocaleDateString("en-US", { weekday: "short" });
      result.push({ [dayName]: value });
    });
  } else if (format === "weeks") {
    let weekNumber = 1;
    let currentWeek: any = [];
    dateEntries.forEach(({ date, value }) => {
      currentWeek.push(value);
      if (date.getDay() === 0 || dateEntries[dateEntries.length - 1] === date) {
        result.push({
          [`${weekNumber * 7 - 6}-${weekNumber * 7}`]: currentWeek,
        });
        weekNumber++;
        currentWeek = [];
      }
    });
  } else if (format === "months") {
    const months = {};
    dateEntries.forEach(({ date, value }) => {
      const monthName = monthNames[date.getMonth()];
      if (!months[monthName]) months[monthName] = [];
      months[monthName].push(value);
    });
    Object.keys(months).forEach((month) => {
      result.push({ [month]: months[month] });
    });
  }

  return result;
}

const LineGraph = ({ data }: LineGraphProps) => {
  const [dates, setDates] = useState(Object.keys(data));
  const [values, setValues] = useState(Object.values(data));
  const isDarkMode = useSelector(selectIsDarkMode);
  const [series, setSeries] = useState([
    {
      name: "series1",
      data: values,
    },
  ]);
  useEffect(() => {
    console.log(data);
    if (Object.values(data)) {
      setSeries([
        {
          name: "series1",
          data: Object.values(data),
        },
      ]);
      setDates(Object.keys(data));
      setValues(Object.values(data));
    }
  }, [data]);
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        style: {
          colors: isDarkMode ? "#fff" : "#475467",
          fontSize: "12px",
        },
      },
    },
    colors: ["#335CFF"],
    stroke: {
      width: 1,
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories:
        Object.keys(data).length < 8
          ? formatData(data, "days").map((e) => Object.keys(e)[0])
          : data.length < 32
          ? formatData(data, "weeks").map((e) => Object.keys(e)[0])
          : dates,
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: isDarkMode ? "#fff" : "#475467",
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: true,
        stroke: {
          color: "#335cff",
          width: 1,
          dashArray: 10,
        },
      },
    },
    markers: {
      size: 0,
      shape: "circle",
      colors: ["white"],
      strokeColors: "blue",
      hover: {
        size: 8,
      },
      strokeWidth: 5,
    },

    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    grid: {
      borderColor: isDarkMode ? "#444" : "#e7e7e7",
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        const date = w.globals.categoryLabels[dataPointIndex];
        return `
                    <div style="background-color: black; margin:10px; width:120px; color: white; padding: 8px; border-radius: 8px; display: flex; flex-direction: column; gap: 8px;">
                        <p style="font-size: 14px;">${value.toLocaleString()}</p>
                        <p style="font-size: 14px; opacity: 0.8;">${date}</p>
                    </div>
                `;
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default LineGraph;
