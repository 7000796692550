import React from "react";
import { useNavigate } from "react-router-dom";
import { Plus } from "untitledui-js-base";
import PrimaryButton from "../../components/PrimaryButton";


function DashboardHeading() {
  const navigate = useNavigate()
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col">
        <h1 className="font-semibold text-3xl text-primary-default h-10">
          Dashboard
        </h1>
      </div>
      <div>
        <PrimaryButton
          type="button"
          className=""
          onClick={() => navigate('/automations/create')}
        >
          <Plus size="20" /> Create new automation
        </PrimaryButton>
      </div>
    </div>
  );
}

export default DashboardHeading;
