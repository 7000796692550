import React from "react";
import FormikMultipleSelectField from "../../components/formikForms/FormikMultipleSelectField";

interface Filter {
  name: string;
  label: string;
  options: { value: string; label: string }[];
}

interface CustomFiltersProps {
  filters: Filter[];
  title: string;
  column: boolean;
}

const CustomFilters: React.FC<CustomFiltersProps> = ({
  filters,
  title,
  column = false,
}) => {
  console.log("filter.name12312312", filters);
  return (
    <div>
      <p className="text-lg font-semibold text-gray-900 mb-6">{title}</p>
      {/* Responsive Grid */}
      <div
        className={
          column
            ? "flex flex-col"
            : "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-4"
        }
      >
        {filters.map((filter) => (
          <div key={filter.name} className="flex flex-col">
            <label
              htmlFor={filter.name}
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              {filter.label}
            </label>
            <FormikMultipleSelectField
              name={filter.name}
              placeHolder={`Select ${filter.label}`}
              options={filter.options}
              selectClassName="border text-gray-700 font-medium text-sm rounded-xl"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomFilters;
