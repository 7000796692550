import React from "react";
import { Form } from "formik";
import { Input } from "antd";

interface AddNewAutomationStepOneFourProps {
  step: number; // Assuming `step` is a number
  onChange: (value: { step?: number; message?: string }) => void; // Typing onChange for clarity
  message: string; // Assuming `message` is a string
}

const AddNewAutomationStepOneFour: React.FC<AddNewAutomationStepOneFourProps> = ({
  onChange,
  step,
  message,
}) => {
  const handleStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10) || 0; // Ensure the value is a number
    onChange({ step: value });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    e.target.style.height = "auto"; // Reset height for dynamic resizing
    e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height based on content
    onChange({ message: value });
  };

  return (
    <Form className="mt-2 mb-2 w-full">
      <div>
        <p className="text-sm font-medium text-gray-600 mb-6">
          Set a follow-up message to send after a specified number of days if no
          reply is received.
        </p>

        <div>
          <div className="mt-2 text-xs">
            <label className="text-sm text-gray-600 font-medium">
              Send follow-up
              <Input
                min={1}
                type="number"
                className="w-14 h-8 border border-gray-50 px-2 py-1 rounded-md text-gray-600 text-center focus:border-blue-500 mx-2 shadow-md"
                value={step}
                onChange={handleStepChange}
                placeholder="Days"
              />{" "}
              days after the previous message
            </label>
          </div>
        </div>

        <div className="bg-white rounded-lg w-full flex overflow-hidden">
          <div className="w-full">
            <div className="flex w-full flex-col bg-gray-50">
              <span className="text-gray-600 text-xs mt-2 font-semibold">
                Message Body
              </span>
              <textarea
                value={message}
                onChange={handleMessageChange}
                placeholder="Type your follow-up message here..."
                onFocus={(e) => {
                  e.target.style.borderColor = "rgb(191, 219, 254)"; // Focus border color
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "rgb(209, 213, 219)"; // Blur border color
                }}
                style={{
                  width: "100%",
                  padding: "1rem",
                  color: "rgb(75 85 99)",
                  border: "1px solid rgb(209, 213, 219)",
                  marginTop: "0.5rem",
                  borderRadius: "0.5rem",
                  minHeight: "10rem",
                  resize: "none",
                  overflow: "hidden",
                  outline: "none",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AddNewAutomationStepOneFour;
