import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { thunkAction } from "../helpers";
import clientService from "../helpers/client";
import { Api } from "../constants/api";

const initialState = {
  loading: false,
  contentManager: {},
  lastPageToken: "",
};

const contentManagerSlice = createSlice({
  name: "contentManagerSlice",
  initialState,
  reducers: {
    setContentLoading(state, action) {
      state.loading = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContentManager.fulfilled, (state, action) => {
      state.loading = false;
      state.contentManager = {
        ...(state.contentManager || {}),
        [`${action.meta.arg.shop_id}${action.meta.arg.search ? `-${action.meta.arg.search}` : ""}`]:
          (action.payload.videos_performance_list || []).filter((el: any) => el.video_id),
      };
      return state;
    });
    builder.addMatcher(isAnyOf(getContentManager.pending), (state) => {
      state.loading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(getContentManager.rejected), (state) => {
      state.loading = false;
      return state;
    });
  },
});

export const getContentManager = createAsyncThunk(
  "contentManagerSlice/getContentManager",
  thunkAction((params: any) => {
    return clientService.post(
      Api.contentManagerSlice.getShopVideoPerformance,
      params
    );
  })
);

export const { setContentLoading } = contentManagerSlice.actions;
export default contentManagerSlice.reducer;
