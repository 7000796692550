export const Api = {
  auth: {
    me: "api/customers/me",
    login: "api/auth/login",
    register: "api/auth/register",
    refreshToken: "api/auth/refresh-token",
    logout: "api/auth/logout",
  },
  customer: {
    getList: "api/customer/list",
    register: "api/customer/register",
    editBasicInfo: "api/customer/edit",
    basicInfo: "api/customer/basic-info",
  },
  automations: {
    getAutomationDetail: "/api/automations/detail",
    getList: "api/automations/list",
    create: "api/automations/create",
    remove: "api/automations/remove",
    basicInfo: "api/customer/basic-info",
    stop: "api/automations/stop",
    update: "api/automations/update",
    followupStop: "api/followups/stop",
    start: "api/automations/start",
    followupStart: "api/followups/start",
    repliesStart: "api/replies/start",
    filters: (shopRegion: string) =>
      `api/automations/filters?shop_region=${shopRegion}`,
    getFollowUps: "/api/automation/followup_steps",
  },
  statistics: {
    stats: "api/statistics",
    messages: "api/messages/date_range",
    replies: "api/replies/date_range",
    messagedCreators: "/api/dashboard/messaged_creators",
    getEmailedCreators: "/api/dashboard/emailed_creators",
  },
  shops: {
    list: "api/shops/list",
    create: "api/shops/create",
    archive: "api/shops/archive",
    update: "api/shops/update",
    updateCreatorsToOmit: "/api/shops/set_creators_to_omit",
    statistics: "/api/shop/statistics",
    analytics: "/api/analytics/messages",
    userConfirmsAliasAdded: "api/shops/user_confirms_alias_added",
    logs: "/api/shops/log",
  },
  subscriptions: {
    list: "api/subscriptions/list",
    create: "api/subscriptions/create",
    link: "api/subscriptions/link-shop",
    cancel: "api/subscriptions/cancel",
    updateCreatorsToOmit: "/api/subscriptions/set_creators_to_omit",
  },
  invoices: {
    list: "api/invoices/list",
  },
  assistant: {
    getStatusTable: "/api/assistant/data",
    updateAction: "/api/assistant/actions/update",
    startAssistant: "/api/assistant/start",
    getMessageSettings: "/api/shop_messages/get",
    updateMessageSettings: "/api/shop_messages/update",
    updateContentGuideSetting: "/api/shop_messages/content_guides/update",
    getAssistantHistory: "/api/assistant/history",
    removeContentGuide: "/api/shop_messages/content_guides/remove",
  },
  stripePayment: {
    createCheckoutSession: "/api/stripe/create-checkout-session",
    getSession: (sessionId) => `/api/stripe/session/${sessionId}`,
    createPortalSession: "/api/stripe/create-portal-session",
    getRenevalDates: "/api/stripe/get-subscription-reneval-dates",
  },
  emailAutomations: {
    getList: "/api/email_automations/list",
    create: "/api/email_automations/create",
    remove: "/api/email_automations/delete",
    edit: "/api/email_automations/edit",
    uploadImage: "/api/email_automations/upload_image",
    stop: "/api/email_automations/stop",
    start: "/api/email_automations/start",
  },
  creator: {
    getList: "/api/crm/getMyCreators",
    filters: (shop_id) => `/api/crm/filterConfig?shop_id=${shop_id}`,
    addTag: "/api/crm/addTagsToCreators",
    removeTag: "/api/crm/deleteTagsFromCreators",
    getConfigCreators: "/api/get_config_creator"
  },
  groups: {
    getList: "/api/crm/getMyGroups",
    createGroup: "/api/crm/createGroup",
    getFilter: "/api/crm/filterConfig",
    getSingleGroupById: (groupId: string) => `/api/crm/groups/${groupId}`,
    getMyCreatorsByGroup: "/api/crm/getMyCreatorsByGroup",
  },
  contentManagerSlice: {
    getShopVideoPerformance: "/api/shop_videos",
  },
  videoGroups: {
    create: "/api/video-groups/",
    getByShop: "/api/video-groups/",
    get: "/api/video-groups",
    getUUID: "/api/video-groups-uuid",
    update: "/api/video-groups",
    delete: "/api/video-groups",
    config: "/api/get_shop_video_config",
  },
  sparkCodeForm: {
    getSparkCodeData: "/api/sparkcodeform/getSparkCodeData",
    submitFormData: "/api/sparkcodeform/submitFormData",
    getSparkFormPreviewData: "/api/sparkcodeformPreview/getPreviewFormData",
    getSparkCodesFormPreview: "/api/sparkcodeform/getSparkCodesFormPreview",
  },
  sparkCode: {
    getSparkCodes: "/api/sparkcode/getSparkCodes",

  },

};
