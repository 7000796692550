import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getVideoGroupUUID } from "../store/videoGroups";
import { Card, Spin, Tooltip, Modal } from "antd";

export default function CreatorContentFlywheels() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const videoGroups = useAppSelector((state) => state.videoGroups);
  const loading = videoGroups.loading;
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  useEffect(() => {
    if (params.uuid) {
      dispatch(getVideoGroupUUID(params.uuid));
    }
  }, [params.uuid]);

  const data = videoGroups.selectedVideoUUId || {};
  const videos = data.videos || [];
  const description = data.description || "Loading description...";
  const manualLinks = data.additional_info?.manualLinks || [];

  const totalGMV = videos.reduce(
    (sum, video) => sum + (video.gmv_amount || 0),
    0
  );

  const getGMV = description !== "Loading description..." && (
    <span className="gmv_icon text-white font-extrabold text-xl px-3 py-1 rounded-md inline-block">
      ${totalGMV.toLocaleString()}
    </span>
  );

  const renderDescription = () => {
    const parts = description.split("{total_gmv_generated}");
    return (
      <div className="flex text-center font-semibold text-[#101828] px-4 justify-center mb-10 flex-wrap md:leading-[60px] sm:leading-10 max-w-[800px] w-full text-[48px]">
        {parts[0] || ""}
        {getGMV}
        {parts[1] || ""}
      </div>
    );
  };

  const handleOverlayClick = (videoId: string) => {
    setSelectedVideo(videoId);
    setModalVisible(true);
  };

  const extractVideoId = (link) => {
    // Example: Match TikTok video URLs
    const tiktokRegex = /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(?:@[\w.-]+\/video\/|embed\/)(\d+)/;
  
    // Match the URL against the regex
    const match = link.match(tiktokRegex);
  
    // Return the video ID if the regex matches, otherwise null
    return match ? match[1] : null;
  }
  return (
    <div className="min-h-screen flex flex-col items-center pt-[64px] pb-[30px]">
      {/* Gradient Background */}
      <div className="absolute top-0 left-0 w-full h-[300px] bg-gradient-to-b from-[#6F8BFD4D] via-[#D5B5FF4D] to-[#FBFFD33F] z-[-1]" />

      <div className="max-w-[1200px] w-full relative z-20">
        <div className="p-4 flex justify-center">
          <img
            src="/reacher_logo.svg"
            alt="Reacher Logo"
            width="150"
            height="64"
            className="cursor-pointer"
          />
        </div>

        <div className="flex w-full justify-center">{renderDescription()}</div>

        {loading ? (
          <div className="flex justify-center items-center min-h-[50vh]">
            <Spin size="large" />
          </div>
        ) : (
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {videos.map((video) => (
              <Card
                key={video.id}
                className="shadow-lg rounded-md overflow-hidden border border-[#E4E7EC] w-[346px] mx-auto mb-[20px]"
                style={{
                  backgroundColor: "#FFFFFF",
                }}
                bodyStyle={{
                  padding: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="bg-gradient-to-b from-[#D9E9FD] to-[#F5DFF7] h-[200px] flex items-center justify-center rounded-t-md relative cursor-pointer"
                  onClick={() => handleOverlayClick(video.video_id)}
                >
                  <iframe
                    src={`https://www.tiktok.com/embed/${video.video_id}?autoplay=0`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: "none",
                      pointerEvents: "none", // Disables interaction with the iframe
                    }}
                    scrolling="no"
                    allowFullScreen
                  />
                  {/* Transparent overlay to capture clicks */}
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-transparent"
                    onClick={() => handleOverlayClick(video.video_id)}
                  />
                </div>

                <div className="flex items-center mt-4 space-x-3">
                  <div className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"></div>
                  <Link
                    className="font-bold text-[#101828]"
                    to={`https://www.tiktok.com/@${video.username}`}
                  >
                    @{video.username}
                  </Link>
                </div>

                <Tooltip title={video.title}>
                  <p
                    className="mt-2 text-sm text-[#667085] line-clamp-3"
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 3,
                      lineHeight: "1.5",
                    }}
                  >
                    {video.title}
                  </p>
                </Tooltip>

                <div className="mt-4 flex items-center justify-between bg-[#EBF1FF] border border-[rgba(51,92,255,0.1)] rounded-[12px] px-4 py-3">
                  <div className="flex items-center">
                    <img
                      src="/dollar.svg"
                      alt="Dollar Icon"
                      className="w-5 h-5 mr-2"
                    />
                    <span className="text-black font-medium text-sm">
                      GMV Generated:
                    </span>
                  </div>
                  <div className="text-black font-bold text-sm ml-2">
                    ${video.gmv_amount.toLocaleString()}
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}

        {manualLinks.length > 0 && (
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {manualLinks.map((link, index) => (
              <Card
                key={index}
                className="shadow-lg rounded-md overflow-hidden border border-[#E4E7EC] w-[346px] mx-auto mb-[20px]"
                style={{
                  backgroundColor: "#FFFFFF",
                }}
                bodyStyle={{
                  padding: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="bg-gradient-to-b from-[#D9E9FD] to-[#F5DFF7] h-[200px] flex items-center justify-center rounded-t-md relative cursor-pointer"
                  onClick={() => handleOverlayClick(extractVideoId(link))}
                >
                  <iframe
                    src={`https://www.tiktok.com/embed/${extractVideoId(link)}?autoplay=1`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: "none",
                    }}
                    scrolling="no"
                    allowFullScreen
                  />
                  {/* Transparent overlay to capture clicks */}
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-transparent"
                    onClick={() => handleOverlayClick(link)}
                  />
                </div>
                <div className="flex justify-center mt-4">
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#2563EB] font-bold text-center break-words"
                  >
                    Open Link
                  </a>
                </div>
              </Card>
            ))}
          </div>
        )}

        <Modal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          centered
          width={400}
          bodyStyle={{
            height: 700,
            padding: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          closeIcon={<span className="text-xl">✖</span>}
        >
          {selectedVideo && (
            <iframe
              src={`https://www.tiktok.com/embed/${selectedVideo}?autoplay=1`}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="no"
              allowFullScreen
            />
          )}
        </Modal>
      </div>
    </div>
  );
}
