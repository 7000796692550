import { Empty, Spin, Image, Tooltip } from "antd";
import { Link } from "react-router-dom";
import ReusableTable from "../../../pages/dashboard-new/components/TableComponent";
import {
  getCurrencySign,
  getStatusColor,
} from "../../../pages/my-creators/helper";
import { useAppSelector, useAppDispatch } from "../../../pages/hooks";
import { useEffect } from "react";
import { getMyCreatorsByGroup } from "../../../pages/store/creators";

const CRMPreview = ({ values }) => {
  const creators = useAppSelector((state) => state.creators);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();
  console.log("group_name123123", creators.singleGroupCreators);

  useEffect(() => {
    if (values.crm_group_id && typeof creators.singleGroupCreators?.[values.crm_group_id] === 'undefined') {
      dispatch(getMyCreatorsByGroup({ group_id: values.crm_group_id }));
    }
  }, [values.crm_group_id]);

  const fallbackImage = "/logo192.png";

  let columns = [
    {
      name: "Creator Name",
      dataIndex: "creator_name",
      key: "creator_name",
      fixed: "left",
      width: 200,
      render: (record: any) => (
        <div className="flex items-center">
          <img
            src={record.avatar_url}
            alt={record.creator_name}
            className="w-10 h-10 rounded-full mr-2"
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevent infinite loop in case fallback image fails
              e.currentTarget.src = fallbackImage; // Replace with fallback image
            }}
          />
          <div>
            <Link
              to={`https://tiktok.com/@${record.creator_name}`}
              target="_blank"
            >
              <div>{record.creator_name}</div>
            </Link>
            <div className="text-gray-500">
              Followers: {record.follower_num}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Product Info",
      dataIndex: "product_title",
      key: "product_title",
      width: 200,
      render: (record: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image
            width={50}
            height={50}
            src={record.sku_image}
            alt={record.product_title}
            style={{
              marginRight: "16px",
              objectFit: "cover",
            }}
          />
          <div className="ml-4" style={{ maxWidth: "200px" }}>
            <Tooltip title={record.product_title}>
              <div
                style={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  cursor: "pointer",
                }}
              >
                {record.product_title || "-"}
              </div>
            </Tooltip>
            <div style={{ color: "#888" }}>ID: {record.product_id || "-"}</div>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      dataIndex: "curr_status",
      key: "curr_status",
      render: (record) => (
        <span
          className={"px-5 py-2 rounded-md border border-stroke"}
          style={{
            color: getStatusColor(record.curr_status),
            backgroundColor: `${getStatusColor(record.curr_status)}23`,
            borderColor: `${getStatusColor(record.curr_status)}80`,
          }}
        >
          {record.curr_status ? record.curr_status : "-"}
        </span>
      ),
    },
    {
      name: "GMV",
      dataIndex: "gmv",
      key: "gmv",
      width: 100,
      render: (record: any) =>
        record.gmv ? (
          <span>
            {getCurrencySign(shops.selectedStoreRegion)}
            {record.gmv.toLocaleString()}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Video Posted",
      dataIndex: "video_num",
      key: "video_num",
      width: 150,
      render: (record) =>
        record.video_num ? <span>{record.video_num} videos</span> : "-",
    },
    {
      name: "Livestreams",
      dataIndex: "live_num",
      key: "live_num",
      width: 100,
      render: (record: any) =>
        record.live_num ? <span>{record.live_num} times</span> : "-",
    },
    {
      name: "CFR",
      dataIndex: "fulfillment_rate",
      key: "fulfillment_rate",
      width: 100,
      render: (record: any) =>
        record.fulfillment_rate ? (
          <span>{(record.fulfillment_rate * 100).toFixed(2)}%</span>
        ) : (
          "-"
        ),
    },
  ];

  const CustomTableTitle = () => (
    <div className="flex flex-row px-3xl py-2xl">
      <div className="h-14 flex flex-row space-x-md items-center py- flex-1">
        <div className="text-lg font-semibold text-primary-default">
          Creator Preview
        </div>
        <div className="flex items-center py-xxs px-md rounded-full border text-xs font-medium border-utility-brand-200 text-utility-brand-700 bg-utility-brand-50">
          {(creators.singleGroupCreators?.[values.crm_group_id] || []).length}{" "}
          Creators Filtered
        </div>
      </div>
    </div>
  );

  console.log("creators.singleGroupCreators131232", creators.singleGroupCreators)
  return (
    <div className="flex flex-col">
      {values.crm_group_id &&
      typeof creators.singleGroupCreators?.[values.crm_group_id] ===
        "undefined" ? (
        <Spin size="large" className="self-center my-20" />
      ) : (creators.singleGroupCreators?.[values.crm_group_id] || []).length >
        0 ? (
        <ReusableTable
          cols={columns}
          rows={creators.singleGroupCreators?.[values.crm_group_id]}
          pageSize={10}
          TableTitleComponent={CustomTableTitle}
        />
      ) : (
        <div className="mt-8 border border-gray-200 rounded-lg p-6">
          <div className="flex mb-5">
            <h3 className="text-lg font-semibold text-gray-900 mr-3">
              Creator Preview
            </h3>
            <div className="flex items-center py-xxs px-md rounded-full border text-xs font-medium border-utility-brand-200 text-utility-brand-700 bg-utility-brand-50">
              0 Creators Filtered
            </div>
          </div>
          <div className="border border-gray-200 rounded-lg p-6 text-center text-gray-500 flex flex-col justify-center items-center">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 24C0.5 10.7452 11.2452 0 24.5 0C37.7548 0 48.5 10.7452 48.5 24C48.5 37.2548 37.7548 48 24.5 48C11.2452 48 0.5 37.2548 0.5 24Z"
                fill="#F2F4F7"
              />
              <path
                d="M24.4998 21.0002V25.0002M24.4998 29.0002H24.5098M23.1151 15.892L14.8902 30.0986C14.434 30.8866 14.2059 31.2806 14.2396 31.6039C14.269 31.886 14.4168 32.1423 14.6461 32.309C14.9091 32.5002 15.3643 32.5002 16.2749 32.5002H32.7246C33.6352 32.5002 34.0904 32.5002 34.3534 32.309C34.5827 32.1423 34.7305 31.886 34.7599 31.6039C34.7936 31.2806 34.5655 30.8866 34.1093 30.0986L25.8844 15.892C25.4299 15.1068 25.2026 14.7142 24.9061 14.5824C24.6474 14.4673 24.3521 14.4673 24.0935 14.5824C23.7969 14.7142 23.5696 15.1068 23.1151 15.892Z"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p className="font-semibold mt-5">No data to display</p>
            <p className="w-[234px]">
              Please set a filter to see creators preview.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CRMPreview;
