import React, { useState } from "react";
import { Form, Formik, FormikValues, Field } from "formik";
import { MdTipsAndUpdates } from "react-icons/md";
import { Checkbox, Upload, message } from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import Papa from "papaparse";

const { Dragger } = Upload;

interface AddNewAutomationStepOneThreeProps {
  setFieldValue: any;
  initialValues: FormikValues;
}

const AddNewAutomationStepOneThree: React.FC<
  AddNewAutomationStepOneThreeProps
> = ({ setFieldValue, initialValues }) => {
  const [creatorsToOmitFile, setCreatorsToOmitFile] = useState<File | null>(
    null
  );
  const [creatorsToIncludeFile, setCreatorsToIncludeFile] =
    useState<File | null>(null);
  const [omitError, setOmitError] = useState<string | null>(null);
  const [includeError, setIncludeError] = useState<string | null>(null);

  const [creatorsToOmitList, setCreatorsToOmitList] = useState<string[]>(
    initialValues.creators_to_omit || []
  );
  const [creatorsToIncludeList, setCreatorsToIncludeList] = useState<string[]>(
    initialValues.creators_to_include || []
  );

  const handleFileChange = (
    file: File,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    setList: React.Dispatch<React.SetStateAction<string[]>>,
    setError: React.Dispatch<React.SetStateAction<string | null>>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => void,
    fieldName: string
  ) => {
    setError(null); // Clear any previous error
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const names = results.data
          .map((row: any) => row.creator_name)
          .filter((name: string) => name);
        if (names.length === 0) {
          setError(
            "The file structure is incorrect. Make sure it contains a 'creator_name' column."
          );
        } else {
          setList(names);
          setFieldValue(fieldName, names); // Update the form state
        }
      },
      error: (error) => {
        setError(`File parsing failed: ${error.message}`);
        message.error(`File parsing failed: ${error.message}`);
      },
    });
    setFile(file);
    return false; // Prevent automatic upload
  };

  const handleFileRemove = (
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    setList: React.Dispatch<React.SetStateAction<string[]>>,
    setError: React.Dispatch<React.SetStateAction<string | null>>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => void,
    fieldName: string
  ) => {
    setFile(null);
    setList([]);
    setError(null);
    setFieldValue(fieldName, []); // Reset the form state
  };

  const beforeUpload = (
    file: File,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    setList: React.Dispatch<React.SetStateAction<string[]>>,
    setError: React.Dispatch<React.SetStateAction<string | null>>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => void,
    fieldName: string
  ) => {
    setFile(null);
    setList([]);
    setFieldValue(fieldName, []); // Reset the form state
    handleFileChange(
      file,
      setFile,
      setList,
      setError,
      setFieldValue,
      fieldName
    );
    return false; // Return false to prevent automatic upload
  };

  const handleDeleteList = (
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    setList: React.Dispatch<React.SetStateAction<string[]>>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => void,
    fieldName: string
  ) => {
    setFile(null);
    setList([]);
    setFieldValue(fieldName, []);
  };

  return (
    <Form className=" mb-2 w-full">
      <p className="block text-md font-bold text-gray-800 mb-2">
        Creators to Include/Exclude
      </p>

      {/* Tips Section */}
      <div className="bg-yellow-50 border border-yellow-400 rounded-lg p-4 mb-6">
        <div className="flex items-center mb-2">
          <MdTipsAndUpdates className="text-yellow-500 w-5 h-5 mr-2" />
          <span className="font-medium text-yellow-600">
            Helpful Tips for Uploading and Managing Your Creator List
          </span>
        </div>
        <div className="text-gray-700 text-sm">
          <ul className="list-disc pl-5 p-1 text-sm text-gray-700">
            <li className="mb-2">
              <a
                href="https://docs.google.com/spreadsheets/d/1QNNMFCO_IxfRWVmYPZSRjaZWYS0gG0peOgDcBm6u8Ws/edit?gid=0#gid=0"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                Click here
              </a>{" "}
              to see the sample file. Please submit your files in this format.
            </li>
            <li>
              If you do not check the box below and upload a list of creators,
              they will be added to the pool of creators based on the selected
              filters.
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex mb-4 justify-between">
        <div className=" border rounded-md mt-4 bg-gray-50 ">
          <label
            className=" block text-gray-600 p-2 text-md font-medium  "
            htmlFor="creatorsToInclude"
          >
            Creators to Include
          </label>
          <Field name="creators_to_include">
            {({ field }) => (
              <>
                <Dragger
                  name="creatorsToInclude"
                  accept=".csv"
                  multiple={false}
                  beforeUpload={(file) =>
                    beforeUpload(
                      file,
                      setCreatorsToIncludeFile,
                      setCreatorsToIncludeList,
                      setIncludeError,
                      setFieldValue,
                      "creators_to_include"
                    )
                  }
                  onRemove={() =>
                    handleFileRemove(
                      setCreatorsToIncludeFile,
                      setCreatorsToIncludeList,
                      setIncludeError,
                      setFieldValue,
                      "creators_to_include"
                    )
                  }
                  fileList={
                    creatorsToIncludeFile ? [creatorsToIncludeFile] : []
                  }
                  className="block w-full pl-2 pr-2 pb-2 bg-white text-sm text-gray-900  rounded-lg cursor-pointer  "
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag CSV file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single CSV file upload. The file should have
                    one column with header "creator_name".
                  </p>
                </Dragger>
                {includeError && (
                  <div className="mt-2 text-red-600">{includeError}</div>
                )}
                {creatorsToIncludeList.length > 0 && (
                  <div className="ml-2">
                    <div className="mt-4 max-h-40  overflow-auto">
                      <h3 className="text-md text-gray-600 font-medium p-1">
                        Creators to Include:
                      </h3>
                      <ul className="list-disc pl-5">
                        {creatorsToIncludeList.map((name, index) => (
                          <li
                            key={index}
                            className="text-gray-600 pt-1 pl-1 pr-1 font-medium text-sm"
                          >
                            {name}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <button
                      type="button"
                      className="mt-2 bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
                      onClick={() =>
                        handleDeleteList(
                          setCreatorsToIncludeFile,
                          setCreatorsToIncludeList,
                          setFieldValue,
                          "creators_to_include"
                        )
                      }
                    >
                      <DeleteOutlined />
                    </button>
                  </div>
                )}
              </>
            )}
          </Field>
          <div>
            <div className="container flex mt-2 mb-2 p-1 pl-2">
              <Field name="only_message_include_list">
                {({ field }) => (
                  <Checkbox {...field} checked={field.value}></Checkbox>
                )}
              </Field>
              <span className="text-gray-700 text-sm ml-2">
                ONLY send messages to uploaded creators
              </span>
            </div>
            <div className="text-gray-700 text-sm pl-2">
              <span className="font-medium text-gray-600 p-1 pl-2">
                {" "}
                If you check this box:
              </span>

              <ul className="list-disc pl-5 ml-2 p-1 text-sm text-gray-700">
                <li className="mb-1">
                  Messages will ONLY be sent to the creators you uploaded
                  regardless if you picked filters or not.
                </li>
                <li>
                  Messages will be sent to these creators even if they've been
                  messaged before.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="border rounded-md  mt-4 bg-gray-50">
          <label
            className="   block text-gray-600 p-2 text-md font-medium "
            htmlFor="creatorsToOmit"
          >
            Creators to Omit
          </label>
          <Field name="creators_to_omit">
            {({ field }) => (
              <>
                <Dragger
                  name="creatorsToOmit"
                  accept=".csv"
                  multiple={false}
                  beforeUpload={(file) =>
                    beforeUpload(
                      file,
                      setCreatorsToOmitFile,
                      setCreatorsToOmitList,
                      setOmitError,
                      setFieldValue,
                      "creators_to_omit"
                    )
                  }
                  onRemove={() =>
                    handleFileRemove(
                      setCreatorsToOmitFile,
                      setCreatorsToOmitList,
                      setOmitError,
                      setFieldValue,
                      "creators_to_omit"
                    )
                  }
                  fileList={creatorsToOmitFile ? [creatorsToOmitFile] : []}
                  className="block w-full pl-2 pr-2 pb-2 bg-white text-sm text-gray-900  rounded-lg cursor-pointer  "
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>

                  <p className="ant-upload-text">
                    Click to Upload or drag CSV file to this area to upload
                  </p>
                  <p className="ant-upload-hint text-sm">
                    Support for a single CSV file upload. The file should have
                    one column with header "creator_name".
                  </p>
                </Dragger>
                {omitError && (
                  <div className="mt-2 text-red-600">{omitError}</div>
                )}
                {creatorsToOmitList.length > 0 && (
                  <>
                    <div className="mt-4 ml-2 max-h-40 overflow-auto">
                      <h3 className="text-md text-gray-600 font-medium">
                        Creators to Omit:
                      </h3>
                      <ul className="list-disc pl-5">
                        {creatorsToOmitList.map((name, index) => (
                          <li key={index}>{name}</li>
                        ))}
                      </ul>
                    </div>
                    <button
                      type="button"
                      className="mt-2 bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
                      onClick={() =>
                        handleDeleteList(
                          setCreatorsToOmitFile,
                          setCreatorsToOmitList,
                          setFieldValue,
                          "creators_to_omit"
                        )
                      }
                    >
                      <DeleteOutlined />
                    </button>
                  </>
                )}
              </>
            )}
          </Field>
        </div>
      </div>
    </Form>
  );
};

export default AddNewAutomationStepOneThree;
