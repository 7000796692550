import React from "react";
import TextError from "../../../pages/components/forms/TextError";
import TextField from "../../../pages/components/forms/TextField";

interface CustomTextAreaProps {
  label?: any;
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  maxLength?: number;
  disabled?: boolean;
  placeholder?: string;
  minimumRows?: number;
  maximumRows?: number;
  isRequired?: boolean;
  helperText?: string;
  error?: string | null;
  restProps?: any;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  name,
  label = "",
  value = "",
  onChange,
  disabled = false,
  maxLength = 100,
  placeholder = "",
  minimumRows = 3,
  maximumRows = 20,
  isRequired = false,
  helperText,
  error = null,
  restProps = {},
}) => {
  return (
    <div className="mb-4">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-black mb-2"
        >
          {label}
          {isRequired && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      <TextField
        restProps={restProps}
        initialValue={value}
        handleChangeOnFormik={(newValue) => onChange(name, newValue)}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        minimum_rows={minimumRows}
        maximum_rows={maximumRows}
      />
      {error && <TextError>{error}</TextError>}
      {helperText && <p className="text-xs text-gray-500 mt-1">{helperText}</p>}
    </div>
  );
};

export default CustomTextArea;
