import React from "react";
import UnslectedProgressIcon from "./svgs/UnslectedProgressIcon";
import CheckBox from "./svgs/CheckBox";

const steps = [1, 2, 3];

interface CustomStepsProps {
  currentStep: number;
}

const CustomStepsWithDynamicIcons: React.FC<CustomStepsProps> = ({
  currentStep,
}) => {
  return (
    <div className="flex items-center w-[300px]">
      {steps.map((_, index) => (
        <div key={index} className="relative flex-1 flex items-center">
          {/* Line Before the Icon */}
          {index !== 0 && (
            <div
              className={`absolute  h-[2px] ${
                index <= currentStep ? "bg-blue-500" : "bg-gray-300"
              }`}
            />
          )}

          {/* Step Icon */}
          <div
            className={`z-10 flex items-center justify-center w-8 h-8 rounded-full ${
              index === currentStep
                ? "bg-blue-500 text-white"
                : index > currentStep
                  ? "bg-gray-300 text-gray-500"
                  : ""
            }`}
          >
            {index < currentStep ? (
              <CheckBox />
            ) : index === currentStep ? (
              <div className="progress-icon">
                <div className="inner-circle"></div>
              </div>
            ) : (
              <UnslectedProgressIcon />
            )}
          </div>

          {/* Line After the Icon */}
          {index !== steps.length - 1 && (
            <div
              className={`absolute left-0 right-0 h-[2px] ${
                index < currentStep + 1 ? "bg-blue-500" : "bg-gray-300"
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default CustomStepsWithDynamicIcons;
