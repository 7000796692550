import React, { useEffect, useState } from "react";
import PlanGuard from "../../components/PlanGuard";
import { useAppDispatch, useAppSelector } from "../../hooks";

const AdminLogs = () => {
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();

  const failedContainerShops = shops.shopLogs.failed_container_shops || [];
  const failedLoginShops = shops.shopLogs.failed_login_shops || [];
  const failedAutomations = shops.shopLogs.failed_automations || [];
  const [displayedAutomations, setDisplayedAutomations] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedAutomationId, setSelectedAutomationId] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    if (failedAutomations.length > 0) {
      const firstEmail = failedAutomations[0]?.account_email;
      const firstAutomationId = failedAutomations[0]?.automation_id;
      const firstStatus = failedAutomations[0]?.status;
      setSelectedEmail(firstEmail);
      setSelectedAutomationId(firstAutomationId);
      setSelectedStatus(firstStatus);
      const initialAutomations = failedAutomations.filter(
        item => item.account_email === firstEmail && 
               item.automation_id === firstAutomationId &&
               item.status === firstStatus
      );
      setDisplayedAutomations(initialAutomations);
    }
  }, [failedAutomations]);

  const TableRows = ({ data }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <tr className="cursor-pointer">
          <td
            className={`py-5 px-4 text-center border-t`}
            onClick={() => setOpen(!open)}
          >
            <svg
              className={`w-6 h-6 transition-transform ${
                open ? "rotate-180" : "rotate-0"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </td>
          <td className="py-4 px-6 border-t">{data.account_email}</td>
          <td className="py-4 px-6 border-t">{data.machine}</td>
          <td className="py-4 px-6 border-t">{data.automation_id}</td>
          <td className="py-4 px-6 border-t">{data.automation_type}</td>
          <td className="py-4 px-6 border-t">{data.total_creators_messaged}</td>
        </tr>
        <tr>
          <td colSpan={6} className="pl-10">
            <h1 className={`text-lg font-semibold ${open ? "block" : "hidden"}`}>Daily Creators Messaged</h1>
          </td>
        </tr>
        <tr className={`${open ? "table-row" : "hidden"}`}>
          <td colSpan={6}>
            <table className="table-auto w-full text-sm text-gray-500">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-2 px-4">Date</th>
                  <th className="py-2 px-4">Creators Messaged</th>
                </tr>
              </thead>
              <tbody>
                {[...data.daily_creators_messaged]
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .slice(0, 5)
                  .map((daily, index) => (
                    <tr key={index}>
                      <td className="py-2 px-4">{daily.date}</td>
                      <td className="py-2 px-4">{daily.creators_messaged}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </td>
        </tr>
      </>
    );
  };

  return (
    <PlanGuard>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="relative overflow-x-auto w-[50%] border border-secondary rounded-xl p-2">
            <h3 className="font-semibold text-lg mb-4">Failed Containers</h3>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Machine
                  </th>
                </tr>
              </thead>
              <tbody>
                {failedContainerShops.map((item) => (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.account_email}
                    </th>
                    <td className="px-6 py-4">{item.machine}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="relative overflow-x-auto w-[50%] border border-secondary rounded-xl p-2">
            <h3 className="font-semibold text-lg mb-4">Failed Login Attempts</h3>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Machine
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Failed login count
                  </th>
                  
                  <th scope="col" className="px-6 py-3">
                    Container running
                  </th>
                </tr>
              </thead>
              <tbody>
                {failedLoginShops.map((item) => (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.account_email}
                    </th>
                    <td className="px-6 py-4">{item.machine}</td>
                    <td className="px-6 py-4">{item.failed_login_attempts}</td>
                    <td className="px-6 py-4">
                      {item.is_container_running ? "Yes" : "No"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        
        {/* <div className="relative overflow-x-auto w-full border border-secondary rounded-xl p-4">
          <h3 className="font-semibold text-lg mb-4">
            Automation Tracker
          </h3>
          <div className="mb-4 flex gap-4">
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
              value={selectedEmail}
              onChange={(e) => {
                const newEmail = e.target.value;
                setSelectedEmail(newEmail);
                const filteredAutomations = failedAutomations.filter(
                  (item) => item.account_email === newEmail
                );
                setDisplayedAutomations(filteredAutomations);
                setSelectedAutomationId("");
                setSelectedStatus("");
              }}
            >
              <option value="">Select Email</option>
              {[...new Set(failedAutomations.map((item) => item.account_email))].map((email) => (
                <option key={email} value={email}>
                  {email}
                </option>
              ))}
            </select>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
              value={selectedAutomationId}
              onChange={(e) => {
                const newAutomationId = e.target.value;
                setSelectedAutomationId(newAutomationId);
                const filteredAutomations = failedAutomations.filter(
                  (item) =>
                    item.account_email === selectedEmail &&
                    item.automation_id === newAutomationId
                );
                setDisplayedAutomations(filteredAutomations);
                setSelectedStatus("");
              }}
            >
              <option value="">Select Automation ID</option>
              {[...new Set(
                failedAutomations
                  .filter((item) => item.account_email === selectedEmail)
                  .map((item) => item.automation_id)
              )].map((automationId) => (
                <option key={automationId} value={automationId}>
                  {automationId}
                </option>
              ))}
            </select>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
              value={selectedStatus}
              onChange={(e) => {
                const newStatus = e.target.value;
                setSelectedStatus(newStatus);
                const filteredAutomations = failedAutomations.filter(
                  (item) =>
                    item.account_email === selectedEmail &&
                    item.automation_id === selectedAutomationId &&
                    item.status === newStatus
                );
                setDisplayedAutomations(filteredAutomations);
              }}
            >
              <option value="">Select Status</option>
              {[...new Set(
                failedAutomations
                  .filter(
                    (item) =>
                      item.account_email === selectedEmail &&
                      item.automation_id === selectedAutomationId
                  )
                  .map((item) => item.status)
              )].map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <table className="table-auto w-full text-sm text-gray-500">
            <thead className="bg-gray-50 text-gray-700 text-left">
              <tr>
                <th className="py-3 px-4"></th>
                <th className="py-3 px-4">Email</th>
                <th className="py-3 px-4">Machine</th>
                <th className="py-3 px-4">Automation ID</th>
                <th className="py-3 px-4">Automation Type</th>
                <th className="py-3 px-4">Total Creators Messaged</th>
              </tr>
            </thead>
            <tbody>
              {displayedAutomations.map((data, index) => (
                <TableRows key={index} data={data} />
              ))}
            </tbody>
          </table>
        </div> */}
      </div>
    </PlanGuard>
  );
};

export default AdminLogs;
