import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { thunkAction } from "../helpers";
import clientService from "../helpers/client";
import { Api } from "../constants/api";
import { DEFAULT_LIMIT, DEFAULT_PAGE_INDEX } from "../constants";

const initialState = {
  loading: false,
  data: {},
  total: 0,
  page: DEFAULT_PAGE_INDEX,
  pageSize: DEFAULT_LIMIT,
  params: {
    page: DEFAULT_PAGE_INDEX,
    pageSize: 20,
    orderBy: "",
  },
  filters: {},
  followUpSteps: {},
  automationDetails: {},
  messages: [],
  selectedAutommation: ""
};

const AutomationSlice = createSlice({
  name: "automation",
  initialState,
  reducers: {
    filterAutomation(state, action) {
      state.params = action.payload.data;
      return state;
    },
    setMessages(state, action) {
      state.messages = action.payload;
      return state;
    },
    setSelectedAutommation(state, action) {
      state.selectedAutommation = action.payload;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAutomations.fulfilled, (state, action) => {
      state.loading = false;
      state.data = { ...state.data, [action.meta.arg.shop_id]: action.payload };

      return state;
    });

    builder.addMatcher(
      isAnyOf(startAutomation.fulfilled,
      stopAutomation.fulfilled), (state) => {
        state.loading = false;

        return state;
      }
    );

    builder.addMatcher(
      isAnyOf(getRegionBasedFilters.fulfilled),
      (state, action) => {
        state.loading = false;
        state.filters = {
          ...state.filters,
          [action.meta.arg.shopRegion]: action.payload,
        };
        return state;
      }
    );

    builder.addMatcher(
      isAnyOf(getFollowupStepsAutomation.fulfilled),
      (state, action) => {
        state.loading = false;
        state.followUpSteps = {
          ...state.followUpSteps,
          [action.meta.arg.automation_id]: action.payload,
        };
        return state;
      }
    );

    builder.addMatcher(
      isAnyOf(getAutomationDetail.fulfilled),
      (state, action) => {
        state.loading = false;
        state.automationDetails = {
          ...state.automationDetails,
          [action.meta.arg.automation_id]: action.payload,
        };
        return state;
      }
    );

    builder.addMatcher(
      isAnyOf(postAddAutomation.fulfilled,
        deleteAutomation.fulfilled), (state) => {
          state.loading = false;

          return state;
        });
    
    builder.addMatcher(
      isAnyOf(updateAutomation.fulfilled), (state) => {
        state.loading = false;

        return state;
      }
    );

    builder.addMatcher(
      isAnyOf(postAddAutomation.fulfilled, deleteAutomation.fulfilled),
      (state) => {
        state.loading = false;

        return state;
      }
    );

    builder.addMatcher(isAnyOf(updateAutomation.fulfilled), (state) => {
      state.loading = false;

      return state;
    });

    builder.addMatcher(
      isAnyOf(
        getAutomations.pending,
        postAddAutomation.pending,
        deleteAutomation.pending,
        updateAutomation.pending,
        getRegionBasedFilters.pending,
        getFollowupStepsAutomation.pending,
        getAutomationDetail.pending
      ),
      (state, action) => {
        state.loading = true;
      }
    );

    builder.addMatcher(
      isAnyOf(startAutomation.pending, stopAutomation.pending),
      (state, action) => {
        state.loading = true;

        return state;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAutomations.rejected,
        postAddAutomation.rejected,
        startAutomation.rejected,
        stopAutomation.rejected,
        deleteAutomation.rejected,
        updateAutomation.rejected,
        getRegionBasedFilters.rejected,
        getFollowupStepsAutomation.rejected,
        getAutomationDetail.rejected
      ),
      (state, action) => {
        state.loading = false;
        return state;
      }
    );
  },
});

export const getAutomations = createAsyncThunk(
  "automation/getAutomations",
  thunkAction((payload: any) => {
    return clientService.post(Api.automations.getList, payload);
  })
);

export const getFollowupStepsAutomation = createAsyncThunk(
  "automation/getFollowupStepsAutomation",
  thunkAction((payload: any) => {
    return clientService.post(Api.automations.getFollowUps, payload);
  })
);

export const getAutomationDetail = createAsyncThunk(
  "automation/getAutomationDetail",
  thunkAction((payload: any) => {
    return clientService.post(Api.automations.getAutomationDetail, payload);
  })
);

export const postAddAutomation = createAsyncThunk(
  "automation/postAddAutomation",
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await clientService.post(
        Api.automations.create,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const startAutomation = createAsyncThunk(
  "automation/startAutomation",
  thunkAction((payload: any) => {
    return Promise.all([clientService.post(Api.automations.start, payload)]);
  })
);
export const stopAutomation = createAsyncThunk(
  "automation/stopAutomation",
  thunkAction((payload: any) => {
    return clientService.post(Api.automations.stop, payload);
  })
);

export const deleteAutomation = createAsyncThunk(
  "automation/deleteAutomation",
  thunkAction((payload: any) => {
    return clientService.post(Api.automations.remove, payload);
  })
);

export const updateAutomation = createAsyncThunk(
  "automation/updateAutomation",
  thunkAction((payload: any) => {
    return clientService.post(Api.automations.update, payload);
  })
);

export const getRegionBasedFilters = createAsyncThunk(
  'automation/getRegionBasedFilters',
  thunkAction((params: any) => {
    return clientService.get(Api.automations.filters(params.shopRegion));
  }),
);

export const { filterAutomation, setMessages, setSelectedAutommation } = AutomationSlice.actions;
export default AutomationSlice.reducer;
