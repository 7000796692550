import React, { useState, useEffect } from "react";
import { Spin, Empty } from "antd";
import PlanGuard from "../components/PlanGuard";
import DashboardHeading from "./components/DashboardHeading";
import ReusableTable from "./components/TableComponent";
import { Download01 } from "untitledui-js-base";
import { useAppSelector, useAppDispatch } from "../hooks";
import { getEmailedCreators } from "../store/statistics";
import * as XLSX from "xlsx";

const EmailedCreators = () => {
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const shops = useAppSelector((state) => state.shops);
  const statistics = useAppSelector((state) => state.statistics);
  const emailData = statistics.emailedCreators?.[shops?.selectedStoreId];
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shops?.selectedStoreId && (emailData || []).length === 0) {
      dispatch(
        getEmailedCreators({
          shop_id: shops.selectedStoreId,
        })
      );
    }
  }, [shops.selectedStoreId]);

  const handleDownload = (data) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Emailed Creators");
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    const blob = new Blob(
      [new Uint8Array(wbout.split("").map((c) => c.charCodeAt(0)))],
      { type: "application/octet-stream" }
    );
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "EmailedCreators.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const CustomTableTitle = () => (
    <div className="flex flex-row px-3xl py-2xl">
      <div className="h-14 flex flex-row space-x-md items-center py- flex-1">
        <p className="text-lg font-semibold text-primary-default">
          Emailed Creators
        </p>
        <div className="flex items-center py-xxs px-md rounded-full border text-xs font-medium border-utility-brand-200 text-utility-brand-700 bg-utility-brand-50">
          {(emailData || []).length}
        </div>
      </div>
      <div>
        <button
          className="flex flex-row rounded-md border border-secondary px-3.5 py-2.5 shadow-xs text-secondary-default space-x-xs items-center justify-center gap-xs"
          onClick={() => handleDownload(emailData || [])}
        >
          <Download01 size="20" className="font-semibold" />
          <p className="font-semibold text-sm">Download email history</p>
        </button>
      </div>
    </div>
  );

  const columns = [
    {
      name: "Recipient Name",
      render: (row) => (
        <p className="font-medium text-primary-default">{row.recipient_name}</p>
      ),
    },
    {
      name: "Recipient Email",
      render: (row) => (
        <p className="text-tertiary-default">{row.recipient_email}</p>
      ),
    },
    {
      name: "Mail Sender",
      render: (row) => (
        <p className="text-tertiary-default">{row.mail_auto_name}</p>
      ),
    },
    {
      name: "Date Sent",
      render: (row) => <p className="text-tertiary-default">{row.date_sent}</p>,
    },
    {
      name: "Actions",
      render: (row, setCurrMessage, showModal) => (
        <p
          className="font-semibold text-utility-brand-700 text-center cursor-pointer"
          onClick={() => {
            // Replace {creator_name} in modal content
            const updatedContent = row.content.replaceAll(
              "{creator_name}",
              row.recipient_name
            );
            setCurrMessage(updatedContent);
            showModal();
          }}
        >
          View Message
        </p>
      ),
    },
  ];

  return (
    <PlanGuard>
      <div className="flex flex-col">
        <DashboardHeading />
        {typeof emailData === "undefined" ? (
          <Spin size="large" className="self-center my-20" />
        ) : emailData.length > 0 ? (
          <ReusableTable
            cols={columns}
            rows={emailData}
            pageSize={10}
            TableTitleComponent={CustomTableTitle}
          />
        ) : (
          <Empty description="No Data Available" className="my-20" />
        )}
      </div>
    </PlanGuard>
  );
};

export default EmailedCreators;
