import { useLocation } from "react-router-dom";
import SidebarNew from "./SidebarNew";
import { useAppSelector } from "../hooks";
import Intercom from '@intercom/messenger-js-sdk';

const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const Wrapper = (props: P) => {
    const pathname = useLocation().pathname;
    const auth = useAppSelector((state) => state.auth);
    if(auth?.meInfo?.customer_id) {
      Intercom({
        app_id: 'hm3mho05',
        user_id: auth.meInfo.customer_id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: auth.meInfo.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: auth.meInfo.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      });
    }
  
    return ["/login", "/signup"].includes(pathname) ||
      pathname.startsWith("/content-flywheeling/creator") || pathname.startsWith("/spark-code-form") ? (
      <WrappedComponent {...props} />
    ) : (
      <div className="flex h-screen overflow-hidden bg-primary-default">
        <SidebarNew />
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-primary-default">
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              <WrappedComponent {...props} />
            </div>
          </main>
        </div>
      </div>
    );
  };

  return Wrapper;
};

export default withAuth;
