import React, { useEffect, useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import { useAppDispatch, useAppSelector } from "../../hooks";
import getValidationSchema from "./validationSchemaStepOne";
import AddNewAutomationStepOneThree from "./AddNewAutomationStepOneThree";
import AutomationFilterForm from "./AutomationFilterForm";
import { ArrowLeftIcon } from "@heroicons/react/16/solid";
import { getRegionBasedFilters } from "../../../pages/store/automation";
import CreatorPreview from "./CreatorPreview";
import FilterToggle from "./FilterToggle";

interface AddNewAutomationStepOneFilterFormProps {
  onNext: (values: FormikValues) => void;
  initialValues: FormikValues;
  onReturn: () => void;
  onPrev: () => void;
}

const AddNewAutomationStepOneFilterForm: React.FC<
  AddNewAutomationStepOneFilterFormProps
> = ({ onNext, onPrev, initialValues }) => {
  const [selectedOption, setSelectedOption] = useState("By Filters");
  const shops = useAppSelector((state) => state.shops);
  const validationSchema = getValidationSchema(shops.selectedStoreRegion);
  const dispatch = useAppDispatch();
  const automation = useAppSelector((state) => state.automation);

  useEffect(() => {
    if (
      shops.selectedStoreRegion &&
      (automation.filters?.[shops.selectedStoreRegion] || []).length === 0
    ) {
      dispatch(
        getRegionBasedFilters({ shopRegion: shops.selectedStoreRegion })
      );
    }
  }, [shops.selectedStoreRegion]);

  const getFilters = (values) => {
    let categoriesList = values.category.map((category) => ({
      Main: category,
    }));
    return {
      Creators: {
        "Product Categories": categoriesList,
        Followers: {
          "Follower Segments": values.followers,
        },
        Products: values.products,
      },
      Followers: {
        "Follower Age": values.age,
        "Follower Gender": values.gender,
      },
      Performance: {
        GMV: values.gmv,
        "Units Sold": values.units,
        "Average Views": values.views,
        "Engagement Rate": values.engagement,
        GPM: values.video_gpm,
        "Video Views": values.video_views,
        "Fulfillment Rate": values.fulfillment_rate,
        "Video GPM": values.video_gpm,
        "Live GPM": values.live_gpm,
        "High Sample Dispatch Rate": values.high_sample_dispatch_rate,
        "Quickly Response": values.quickly_response,
        Videos: values.videos,
      },
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onNext(values)}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue, validateField }) => (
        <Form>
          <div>
            {/* Toggle Button for Selecting Filter or List Upload */}
            <FilterToggle
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              weeklyAutomationScheduleProps={{
                values,
                setFieldValue,
                validateField,
              }}
            />

            {/* Render UI Based on Selected Option */}
            {selectedOption === "By Filters" ? (
              <div>
                {/* Filter Form */}
                <AutomationFilterForm
                  values={values}
                  setFieldValue={setFieldValue}
                  validateField={validateField}
                  shops={shops}
                  onPrev={onPrev}
                  isSubmitting={isSubmitting}
                />
              </div>
            ) : (
              <div>
                {/* List Upload Form */}
                <AddNewAutomationStepOneThree
                  setFieldValue={setFieldValue}
                  initialValues={values}
                />
              </div>
            )}

            {/* Creator Preview */}
            {selectedOption === "By Filters" && (
              <CreatorPreview
                filters={getFilters(values)}
                region={shops.selectedStoreRegion}
              />
            )}

            {/* Navigation Buttons */}
            <div className="flex justify-between items-center mt-10">
              <div className="flex flex-row">
                <button
                  className="bg-gray-100 flex items-center p-10 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded-md focus:outline-none"
                  onClick={onPrev}
                  type="button"
                >
                  <ArrowLeftIcon className="w-4 h-4 ml-2" />
                  Previous
                </button>
              </div>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300"
                type="submit"
              >
                Continue
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewAutomationStepOneFilterForm;
