import React, { useState, useEffect, useRef } from "react";
import { Steps, Collapse, Input, Tag, Spin, Tooltip, Button } from "antd";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import IconButton from "../../common/button";
import CustomInput from "../../common/input";
import MyContentTable from "./my-content-table";
import { useAppDispatch, useAppSelector } from "../hooks";
import { SaveOutlined } from "@ant-design/icons";
import {
  createVideoGroup,
  getVideoGroup,
  updateVideoGroup,
} from "../store/videoGroups";
import CustomTable from "../../common/table";
import { IoChevronBack } from "react-icons/io5";

const { Step } = Steps;
const { Panel } = Collapse;

const CreateFlywheel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inputRef = useRef<any>(null);
  const shops = useAppSelector((state) => state.shops);
  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams.entries());
  const { id } = paramsObject;
  const [currentStep, setCurrentStep] = useState(0);
  const [flywheelName, setFlywheelName] = useState("");
  const [description, setDescription] = useState("");
  const [flywheelNameError, setFlywheelNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [manualLinks, setManualLinks] = useState([]);
  const [newLink, setNewLink] = useState("");
  const videoGroups = useAppSelector((state) => state.videoGroups);
  const selectedVideoGroup = videoGroups.selectedVideoGroup;
  console.log("selectedVideoGroup1231321", selectedVideoGroup);
  const addCreatorName = () => {
    if (inputRef.current.input) {
      const inputElement = inputRef.current.input;

      // Get the cursor and selection details
      const start = inputElement.selectionStart;
      const end = inputElement.selectionEnd;
      const value = inputElement.value;

      // Replace selected text or insert at cursor position
      const newValue =
        value.slice(0, start) + "{total_gmv_generated}" + value.slice(end);

      // Update the Formik field value or local state
      setDescription(newValue);

      // Calculate the new cursor position after insertion/replacement
      const newCursorPosition = start + "{total_gmv_generated}".length;

      // Set focus and adjust the cursor position after the update
      setTimeout(() => {
        inputElement.focus();
        inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    }
  };
  const handleNext = () => {
    if (currentStep === 0) {
      let isValid = true;

      if (!flywheelName.trim()) {
        setFlywheelNameError("Flywheel Name is required!");
        isValid = false;
      } else {
        setFlywheelNameError("");
      }

      if (!description.trim()) {
        setDescriptionError("Flywheel Description is required!");
        isValid = false;
      } else {
        setDescriptionError("");
      }

      if (isValid) setCurrentStep(currentStep + 1);
    } else if (currentStep === 1) {
      if (selectedVideos.length === 0) {
        toast.error(
          "Please select a video to proceed further with the content wheeling."
        );
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  // useEffect(() => {
  //   return () => {
  //     setSelectedVideos([]);
  //   };
  // }, []);

  useEffect(() => {
    if (id) {
      dispatch(getVideoGroup(id));
    }
  }, [id]);

  useEffect(() => {
    if (selectedVideoGroup?.[id]) {
      setDescription(selectedVideoGroup?.[id].description || "");
      setFlywheelName(selectedVideoGroup?.[id].group_name || "");
      setSelectedVideos(selectedVideoGroup?.[id].videos);
      setManualLinks(
        (selectedVideoGroup?.[id].additional_info || {}).manualLinks || []
      );
    }
    return () => {
      setSelectedVideos([]);
      setDescription("");
      setFlywheelName("");
      setManualLinks([]);
    };
  }, [selectedVideoGroup?.[id]]);

  const handleAddLink = () => {
    if (newLink.trim()) {
      setManualLinks((prev) => [...prev, newLink]);
      setNewLink("");
    }
  };

  const handleRemoveLink = (link) => {
    setManualLinks((prev) => prev.filter((item) => item !== link));
  };

  const handleRemoveVideo = (videoId) => {
    setSelectedVideos((prev) => prev.filter((v) => v.id !== videoId));
  };

  const createGroup = async () => {
    const checkCRMVideo = ((selectedVideos || [])?.[0] || {})?.crm_video;
    console.log("selectedVideos1231232", selectedVideos);
    setLoading(true);
    const result = await dispatch(
      createVideoGroup({
        shop_id: shops.selectedStoreId,
        group_name: flywheelName,
        description: description,
        video_ids: checkCRMVideo
          ? selectedVideos.map((el) => el.video_id)
          : selectedVideos.map((el) => el.id),
        additional_info: {
          manualLinks,
        },
        crm_video: checkCRMVideo,
      })
    );
    toast.success("Content Flyweel Created Successfully!");
    setLoading(false);
    if (createVideoGroup.fulfilled.match(result)) {
      if (result.payload.data.id) {
        navigate(`/content-flywheels`, {
          replace: true,
        });
      }
    }
  };

  const updateGroup = async () => {
    setLoading(true);
    const checkCRMVideo = ((selectedVideos || [])?.[0] || {})?.crm_video;
    const result = await dispatch(
      updateVideoGroup({
        shop_id: shops.selectedStoreId,
        groupId: id,
        group_name: flywheelName,
        description: description,
        video_ids: checkCRMVideo
        ? selectedVideos.map((el) => el.video_id)
        : selectedVideos.map((el) => el.id),
        additional_info: {
          manualLinks,
        },
        crm_video: checkCRMVideo,
      })
    );
    toast.success("Content Flyweel Updated Successfully!");
    setLoading(false);
    if (updateVideoGroup.fulfilled.match(result)) {
      if (result.payload.data.id) {
        navigate(`/content-flywheels`, {
          replace: true,
        });
      }
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 300,
      render: (title) => (
        <Tooltip title={title}>
          <h4
            className="font-bold text-md truncate"
            style={{
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: 300,
              cursor: "pointer",
            }}
          >
            {title}
          </h4>
        </Tooltip>
      ),
    },
    {
      title: "Video ID",
      dataIndex: "video_id",
      key: "video_id",
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      render: (products) => {
        return products
          .filter((el) => el.id !== "0")
          .map((product) => (
            <div className="ml-4" style={{ maxWidth: "200px" }}>
              <Tooltip title={product.name}>
                <div
                  style={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    cursor: "pointer",
                  }}
                >
                  {product.name || "-"}
                </div>
              </Tooltip>
              <div style={{ color: "#888" }}>ID: {product.id || "-"}</div>
            </div>
          ));
      },
    },
    {
      title: "Creator",
      dataIndex: "username",
      key: "creator",
      render: (username) => <span className="text-gray-600">{username}</span>,
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
      render: (views) => <span className="text-gray-600">{views}</span>,
    },
    {
      title: "GMV",
      dataIndex: "gmv",
      key: "gmv",
      render: (_, record) => (
        <span className="text-gray-600">
          {record.gmv_amount} {record.gmv_currency}
        </span>
      ),
    },
  ];

  const manualLinkColumns = [
    {
      title: "Manual Link",
      dataIndex: "manual_link",
      key: "manual_link",
    },
  ];

  // Combine selectedVideos and manualLinks into a single data source
  const data = [
    ...selectedVideos.map((video, index) => ({
      key: `video-${index}`,
      title: video.title || "-",
      video_id: video.video_id,
      ...video,
    })),
  ];

  const manualLinkData = [
    ...manualLinks.map((link, index) => ({
      key: `link-${index}`,
      manual_link: link,
    })),
  ];

  return (
    <div className="create-flywheel-container p-4">
      <div className="flex items-center space-x-2 mb-20">
        <IoChevronBack
          className="text-xl text-gray-600 cursor-pointer"
          onClick={() => {
            navigate("/content-flywheels/my-flywheels");
          }}
        />
        <h3 className="font-semibold text-xl">Create Flywheel</h3>
      </div>

      {/* Step Indicator */}
      <Steps current={currentStep} onChange={setCurrentStep} className="mb-6">
        <Step title="Basic Information" />
        <Step title="Video Selection" />
        <Step title="Review Selection" />
      </Steps>

      {/* Step Content */}
      {currentStep === 0 && (
        <div className="step-content">
          <h3 className="font-semibold mb-4">Basic Information</h3>
          <div className="mb-4">
            <CustomInput
              label="Flywheel Name"
              placeholder="Enter flywheel name"
              value={flywheelName}
              onChange={setFlywheelName}
              infoTooltip="Enter a unique name for your Flywheel"
            />
            <p className="text-red-500 text-sm mt-1">{flywheelNameError}</p>
          </div>
          <div className="mb-4">
            <CustomInput
              label={
                <div className="flex items-center mr-2">
                  Flywheel Description
                  <Button
                    type="dashed"
                    onClick={() => addCreatorName()}
                    style={{
                      padding: "2px 8px",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                      color: "#1890ff",
                      borderColor: "#1890ff",
                      marginLeft: "4px",
                    }}
                  >
                    Add Total GMV Generated
                  </Button>
                </div>
              }
              placeholder="Check out top performing affiliate videos for this product!"
              value={description}
              onChange={setDescription}
              infoTooltip="This will appear at the top of the page"
              restProps={{ ref: inputRef }}
            />
            <p className="text-red-500 text-sm mt-1">{descriptionError}</p>
          </div>
        </div>
      )}
      {currentStep === 1 && (
        <>
          <div className="step-content">
            <h3 className="font-semibold mb-4">Video Selection</h3>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header={`Top Videos`} key="1">
                <MyContentTable
                  selectedVideos={selectedVideos}
                  setSelectedVideos={setSelectedVideos}
                />
              </Panel>
              <Panel header="Add Links Manually" key="2">
                <div className="flex flex-col">
                  <Input
                    placeholder="Enter Video Link"
                    value={newLink}
                    onChange={(e) => setNewLink(e.target.value)}
                    className="mb-2"
                  />
                  <IconButton
                    label="Add Link"
                    onClick={() => {
                      // Validation for valid TikTok links or general URLs
                      const isValidLink =
                        /^https:\/\/(www\.)?tiktok\.com\/.+/.test(
                          newLink.trim()
                        );
                      if (!isValidLink) {
                        alert("Please enter a valid TikTok link.");
                        return;
                      }
                      handleAddLink();
                    }}
                    buttonClass="bg-blue-600 text-white"
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="mt-6 bg-gray-100 p-4 border-t border-gray-300">
            <h4 className="font-semibold mb-2">Selected Items</h4>
            <div className="flex flex-wrap gap-2">
              {selectedVideos.map((video) => (
                <Tag
                  key={video.id}
                  closable
                  onClose={() => handleRemoveVideo(video.id)}
                >
                  {video.title || `Video ${video.id}`}
                </Tag>
              ))}
              {manualLinks.map((link, index) => (
                <Tag
                  key={index}
                  closable
                  onClose={() => handleRemoveLink(link)}
                >
                  {link}
                </Tag>
              ))}
            </div>
          </div>
        </>
      )}

      {currentStep === 2 && (
        <div className="step-content">
          <h3 className="font-semibold mb-4">Review Selection</h3>

          <div className="p-4 border border-gray-200 rounded shadow-sm mb-4">
            <h4 className="font-semibold mb-2">Step 1: Basic Information</h4>
            <div className="text-sm text-gray-600">
              <p>
                <span className="font-medium">Flywheel Name:</span>{" "}
                {flywheelName}
              </p>
              <p>
                <span className="font-medium">Flywheel Description:</span>{" "}
                {description}
              </p>
            </div>
          </div>

          <div className="p-4 border border-gray-200 rounded shadow-sm">
            <h4 className="font-semibold mb-2">Step 2: Selected Items</h4>
            <CustomTable
              columns={columns}
              data={data}
              showDownloadOptions={true}
              tableClassName="custom-table-class"
            />
            {manualLinkData.length > 0 && (
              <CustomTable
                columns={manualLinkColumns}
                data={manualLinkData}
                showDownloadOptions={true}
                tableClassName="custom-table-class"
              />
            )}
          </div>
        </div>
      )}

      {/* Selected Items (Bottom Bar) */}

      {/* Action Buttons */}
      <div className="mt-4 text-right flex justify-end">
        <IconButton
          label="Previous"
          onClick={() => setCurrentStep(currentStep - 1)}
          buttonClass="bg-gray-500 text-white mr-2"
          restProps={{ disabled: currentStep === 0 }}
        />
        {loading ? (
          <Spin />
        ) : (
          <IconButton
            label={
              currentStep === 2
                ? id
                  ? "Update Flywheel"
                  : "Create Flywheel"
                : "Next"
            }
            onClick={async () => {
              if (currentStep === 2) {
                if (id) {
                  updateGroup();
                } else {
                  createGroup();
                }
              } else {
                handleNext();
              }
            }}
            buttonClass="bg-blue-600 text-white"
            icon={<SaveOutlined />}
            textClass="text-sm font-medium"
          />
        )}
      </div>
    </div>
  );
};

export default CreateFlywheel;
