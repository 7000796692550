export const plans = {
  [process.env.REACT_APP_BASIC_ID as string]: {
    id: process.env.REACT_APP_BASIC_ID,
    name: "Basic Plan",
    price: 299,
  },
  [process.env.REACT_APP_PRO_ID as string]: {
    id: process.env.REACT_APP_PRO_ID,
    name: "Pro Plan",
    price: 549,
  },
  default: {
    name: "None",
  },
};
