import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";
import {
  getAutomationDetail,
} from "../store/automation";
import { useAppDispatch, useAppSelector } from "../hooks";
import Layout from "./layout";
import EditAutomationDetails from "./components/EditAutomationDetails";


const EditAutomations = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const automation = useAppSelector((state) => state.automation);
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth?.meInfo?.customer_id && shops.selectedStoreId && id) {
      dispatch(
        getAutomationDetail({
          shop_id: shops.selectedStoreId,
          automation_id: id,
        })
      );
    }
  }, [auth, shops.selectedStoreId, id]);

  console.log(
    "automation.automationDetails1232132",
    (automation.automationDetails || {})?.[id] || {}
  );
  const selectedAutomation = (automation.automationDetails || {})?.[id] || {};

  return (
    <Layout>
      <div>
        <div className="flex items-center justify-between pb-6 mb-6 border-b border-stroke border-gray-300">
          <h1 className="text-2xl text-blue-500 font-bold">Edit Automation</h1>
          <button
            className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out mr-4"
            onClick={() => {
              navigate("/automations/create");
            }}
          >
            Create New Automation
          </button>
        </div>
        {Object.values(selectedAutomation || {}).length > 0 ? (
          <EditAutomationDetails
            automationData={selectedAutomation}
            onReturn={() => {
              navigate("/automations");
            }}
          />
        ) : (
          <Spin />
        )}
      </div>
    </Layout>
  );
};

export default EditAutomations;

// ) : (
//   <AddNewAutomationForm
//     onReturn={() => setPageContentType("automations")}
//     automationData={selectedAutomation}
//     step={step}
//     setStep={setStep}
//   />
// )}
