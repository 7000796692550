import React, { useEffect, useState } from "react";
import { TimePicker, Checkbox } from "antd";
import { ErrorMessage } from "formik";
import TextError from "../../components/forms/TextError";
import dayjs, { Dayjs } from "dayjs";
import { useAppSelector } from "../../hooks";

interface WeeklyAutomationScheduleProps {
  format: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: { [key: string]: string };
  timePickerStyles?: React.CSSProperties;
  inputStyles?: React.CSSProperties;
  validateField: (field: string) => void;
}

const WeeklyAutomationSchedule: React.FC<WeeklyAutomationScheduleProps> = ({
  format,
  setFieldValue,
  values,
  timePickerStyles,
  inputStyles,
  validateField,
}) => {
  const [checkedDays, setCheckedDays] = useState<{ [key: string]: boolean }>(
    typeof values.schedule_checkboxes === "object" &&
      values.schedule_checkboxes !== null
      ? values.schedule_checkboxes
      : {}
  );
  const shops = useAppSelector((state) => state.shops);
  const defaultAmount = shops.selectedStoreRegion === "UK" ? "1000" : "2500";

  useEffect(() => {
    setFieldValue("schedule_checkboxes", checkedDays);
    validateField("schedule_checkboxes");
  }, [checkedDays, setFieldValue, validateField]);

  const handleCheckboxChange = (day: string) => {
    const isChecked = !checkedDays[day];
    const newCheckedDays = { ...checkedDays, [day]: isChecked };
    setCheckedDays(newCheckedDays);

    if (isChecked) {
      setFieldValue(`${day}_time`, "07:00");
      setFieldValue(`${day}_amount`, defaultAmount);
    } else {
      setFieldValue(`${day}_time`, "");
      setFieldValue(`${day}_amount`, "");
    }
    setFieldValue(`${day}_time`, "07:00");
    setFieldValue(`${day}_amount`, defaultAmount);
  };

  const InitialTimeValue = (value: string): Dayjs | string => {
    if (value === "" || value === null) {
      return "";
    } else {
      return dayjs(value, "HH:mm");
    }
  };

  const handleTimeChange = (
    name: string,
    time: Dayjs | null,
    amount_name: string,
    day: string
  ) => {
    if (!time) {
      setFieldValue(name, "");
      setFieldValue(amount_name, "");
      handleCheckboxChange(day);
    } else {
      setFieldValue(name, time.format("HH:mm"));
    }
  };

  return (
    <div className="mb-6  p-6 rounded-xl ">
      {/* Header */}
      <p className="text-lg font-bold text-black mb-1">Scheduling Settings</p>
      <p className="text-sm text-gray-500 mb-4">
        Start time is based on the UTC timezone
      </p>

      <div className="space-y-4">
        {[
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ].map((day, index) => (
          <div
            key={index}
            className="flex items-right justify-between mb-1 rounded-lg border-gray-200"
          >
            {/* Left: Toggle and Day Name */}
            <div className="flex items-center space-x-4">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={!!checkedDays[day]}
                  onChange={() => handleCheckboxChange(day)}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer-checked:bg-blue-600 peer peer-checked:after:translate-x-5 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
              </label>
              <p
                className={`text-sm font-medium ${
                  checkedDays[day] ? "text-gray-900" : "text-gray-400"
                }`}
              >
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </p>
            </div>

            {/* Right: Scheduling Inputs */}
            <div className="flex items-right ">
              {/* Start Time */}
              <div
                className={`flex items-center px-4 py-2 border rounded-lg ${
                  checkedDays[day]
                    ? "bg-white border-gray-300"
                    : "bg-gray-100 border-gray-200"
                }`}
              >
                {/* Start Time Label */}
                <label
                  className={`text-sm font-medium whitespace-nowrap ${
                    checkedDays[day] ? "text-gray-500" : "text-gray-400"
                  }`}
                  htmlFor={`${day}_time`}
                >
                  Start time
                </label>

                {/* Spacer */}
                <div className=""></div>

                {/* Time Picker */}
                <TimePicker
                  format={format}
                  value={
                    InitialTimeValue(values[`${day}_time`]) as Dayjs | undefined
                  }
                  onChange={(time) =>
                    handleTimeChange(`${day}_time`, time, `${day}_amount`, day)
                  }
                  style={{
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    textAlign: "right",
                    width: "100px",
                  }}
                  disabled={!checkedDays[day]}
                  className={`text-xl font-semibold focus:ring-0 ${
                    checkedDays[day] ? "text-gray-700" : "text-gray-900"
                  }`}
                />
              </div>

              {/* Number of Creators */}
              <div className="flex flex-row items-center pl-2">
                <label
                  className=" text-sm text-gray-500 p-2"
                  // htmlFor={`${day}_amount`}
                >
                  # of Creators
                </label>
                <input
                  type="numbeddr"
                  name={`${day}_amount`}
                  placeholder="3,000"
                  value={values[`${day}_amount`] || ""}
                  onChange={(e) =>
                    setFieldValue(`${day}_amount`, e.target.value)
                  }
                  className={`w-16 p-2 border text-sm rounded-lg focus:ring ${
                    checkedDays[day]
                      ? "text-gray-900 border-gray-300 focus:ring-blue-200"
                      : "text-gray-400 bg-gray-100"
                  }`}
                  disabled={!checkedDays[day]}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeklyAutomationSchedule;
