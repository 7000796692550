import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { thunkAction } from "../helpers";
import clientService from "../helpers/client";
import { Api } from "../constants/api";

const initialState = {
  loading: false,
  filterLoading: false,
  videoGroups: {},
  selectedVideoGroup: {},
  filters: {},
  selectedVideoUUId: {},
};

const videoGroupSlice = createSlice({
  name: "videoGroupSlice",
  initialState,
  reducers: {
    setVideoGroupLoading(state, action) {
      state.loading = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    // Create Video Group
    builder.addCase(createVideoGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.videoGroups = {
        ...state.videoGroups,
        [action.meta.arg.shop_id]: {
          ...(state.videoGroups || {})?.[action.meta.arg.shop_id],
          [action.meta.arg.group_id]: action.payload.data,
        },
      };
    });

    // Get All Video Groups by Shop
    builder.addCase(getVideoGroups.fulfilled, (state, action) => {
      state.loading = false;
      const shop_id = action.meta.arg.shop_id;
      state.videoGroups = {
        ...state.videoGroups,
        [shop_id]: action.payload.reduce((acc, group) => {
          acc[group.id] = group;
          return acc;
        }, {}),
      };
    });

    // Get Single Video Group
    builder.addCase(getVideoGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedVideoGroup = {
        ...state.selectedVideoGroup,
        [action.payload.id]: action.payload,
      };
    });

    builder.addCase(getVideoGroupUUID.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedVideoUUId = action.payload;
    });

    // Update Video Group
    builder.addCase(updateVideoGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.videoGroups = {
        ...state.videoGroups,
        [action.payload.data.id]: action.payload.data,
      };
      state.selectedVideoGroup = {
        ...state.selectedVideoGroup,
        [action.payload.data.id]: action.payload.data,
      };
    });

    // Delete Video Group
    builder.addCase(deleteVideoGroup.fulfilled, (state, action) => {
      state.loading = false;
      const shop_id = action.meta.arg.shop_id;
      delete state.videoGroups[shop_id][action.meta.arg.group_id];
    });

    // Delete Video Group
    builder.addCase(getVideoConfig.fulfilled, (state, action) => {
      state.filterLoading = false;
      state.filters = {
        ...state.filters,
        [action.meta.arg.shop_id]: action.payload,
      };
    });

    // Handle pending and rejected states
    builder.addMatcher(
      isAnyOf(
        createVideoGroup.pending,
        getVideoGroups.pending,
        getVideoGroup.pending,
        updateVideoGroup.pending,
        deleteVideoGroup.pending,
        getVideoGroupUUID.pending
      ),
      (state) => {
        state.loading = true;
        return state;
      }
    );
    builder.addMatcher(isAnyOf(getVideoConfig.pending), (state) => {
      state.filterLoading = true;
      return state;
    });
    builder.addMatcher(
      isAnyOf(
        createVideoGroup.rejected,
        getVideoGroups.rejected,
        getVideoGroup.rejected,
        updateVideoGroup.rejected,
        deleteVideoGroup.rejected,
        getVideoGroupUUID.rejected
      ),
      (state) => {
        state.loading = false;
        return state;
      }
    );
    builder.addMatcher(isAnyOf(getVideoConfig.rejected), (state) => {
      state.filterLoading = false;
      return state;
    });
  },
});

// Async Thunks
export const createVideoGroup = createAsyncThunk(
  "videoGroupSlice/createVideoGroup",
  thunkAction((params: any) => {
    return clientService.post(Api.videoGroups.create, params);
  })
);

export const getVideoGroups = createAsyncThunk(
  "videoGroupSlice/getVideoGroups",
  thunkAction((params: any) => {
    return clientService.get(
      `${Api.videoGroups.getByShop}?shop_id=${params.shop_id}`
    );
  })
);

export const getVideoGroup = createAsyncThunk(
  "videoGroupSlice/getVideoGroup",
  thunkAction((groupId: number) => {
    return clientService.get(`${Api.videoGroups.get}/${groupId}`);
  })
);

export const getVideoGroupUUID = createAsyncThunk(
  "videoGroupSlice/getVideoGroupUUID",
  thunkAction((uuid: string) => {
    return clientService.get(`${Api.videoGroups.getUUID}/${uuid}`);
  })
);

export const updateVideoGroup = createAsyncThunk(
  "videoGroupSlice/updateVideoGroup",
  thunkAction((params: any) => {
    return clientService.patch(
      `${Api.videoGroups.update}/${params.groupId}`,
      params
    );
  })
);

export const deleteVideoGroup = createAsyncThunk(
  "videoGroupSlice/deleteVideoGroup",
  thunkAction((params: any) => {
    return clientService.delete(`${Api.videoGroups.delete}/${params.group_id}`);
  })
);

export const getVideoConfig = createAsyncThunk(
  "videoGroupSlice/getVideoConfig",
  thunkAction((params: any) => {
    return clientService.get(
      `${Api.videoGroups.config}?shop_id=${params.shop_id}`
    );
  })
);

// Actions
export const { setVideoGroupLoading } = videoGroupSlice.actions;

// Reducer
export default videoGroupSlice.reducer;
