

const User = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_561_31970" style={{ "mask-type": "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
      <rect width="48" height="48" fill="url(#paint0_linear_561_31970)" />
    </mask>
    <g mask="url(#mask0_561_31970)">
      <circle cx="24" cy="24" r="23.5" fill="#EBF1FF" stroke="#C0D5FF" />
    </g>
    <rect x="8" y="8" width="32" height="32" rx="16" fill="#3559E9" />
    <path d="M23.9995 26.5C21.3578 26.5 19.0085 27.7755 17.5129 29.755C17.191 30.181 17.03 30.394 17.0353 30.6819C17.0393 30.9043 17.179 31.1849 17.354 31.3222C17.5805 31.5 17.8944 31.5 18.5222 31.5H29.4769C30.1047 31.5 30.4186 31.5 30.6451 31.3222C30.8201 31.1849 30.9598 30.9043 30.9638 30.6819C30.9691 30.394 30.8081 30.181 30.4862 29.755C28.9906 27.7755 26.6413 26.5 23.9995 26.5Z" stroke="white" stroke-width="1.336" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M23.9996 24C26.0706 24 27.7496 22.3211 27.7496 20.25C27.7496 18.1789 26.0706 16.5 23.9996 16.5C21.9285 16.5 20.2496 18.1789 20.2496 20.25C20.2496 22.3211 21.9285 24 23.9996 24Z" stroke="white" stroke-width="1.336" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_561_31970" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>

);

export default User;