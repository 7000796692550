import React, { useState } from "react";
// @ts-ignore
import { FormikValues } from "formik";
// @ts-ignore
import { FormikContext } from "formik";
import AddNewAutomationStepOneForm from "./AddNewAutomationStepOneForm";

import {
  postAddAutomation,
  getAutomations,
  setMessages,
  setSelectedAutommation,
} from "../../store/automation";
// @ts-ignore
import { useAppDispatch, useAppSelector } from "../../hooks";
// @ts-ignore
import { toast } from "react-toastify";
import AddNewAutomationStepTargetCollab from "./AddNewAutomationStepTargetCollab";
import AddNewAutomationStepOneFour from "./AddNewAutomationStepOneFour";

import { defaultFormData } from "./defaultFormData";

import "antd/dist/reset.css";
import "./progressBar.css";
import CustomStepsWithDynamicIcons from "./ProgressSteps";
import CRMGroupLinking from "./CRMGroupLinking";
import AddNewAutomationStepOneFilterForm from "../../../pages/automations/components/AddNewAutomationStepOneFilter";
import AddNewAutomationStepOneTwoV2 from "./AddNewAutomationStepOneTwoV2";
import { Spin } from "antd";

interface AddNewAutomationFormProps {
  onReturn: () => void;
  group?: any;
  groupGroupDetail?: any;
  step: any;
  setStep: any;
  initialValues: any;
}

const AddNewAutomationForm: React.FC<AddNewAutomationFormProps> = ({
  onReturn,
  group,
  groupGroupDetail = <div />,
  step,
  setStep,
}) => {
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const automation = useAppSelector((state) => state.automation);
  const [loading, setLoading] = useState(false);
  const messages = automation.messages;
  const automation_type = automation.selectedAutommation;
  const dispatch = useAppDispatch();

  const defaultAmount = shops.selectedStoreRegion === "UK" ? "1000" : "2500";
  const [formData, setFormData] = useState<FormikValues>(defaultFormData);

  const handleNext = (values: FormikValues) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setStep(step + 1);
  };

  const handleNextwithCommissionRates = (values: FormikValues) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setFormData((prevData) => ({
      ...prevData,
      target_collab_product_comission_rates: initializeCommissionRates(values),
    }));
    setStep(step + 1);
  };

  const initializeCommissionRates = (values: FormikValues) => {
    if (
      values.product_id &&
      values.target_collab_product_comission_rates.length === 1 &&
      !values.target_collab_product_comission_rates[0].product_id
    ) {
      return values.product_id.map((productId) => ({
        product_id: productId,
        commission_rate: "",
      }));
    }
    return values.target_collab_product_comission_rates;
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  type Result = {
    [key: string]: {
      startTime: string;
      maxCreators: number;
    };
  };

  function processFormSchedule(formValues) {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const result: Result = {};

    daysOfWeek.forEach((day) => {
      const timeKey = day.toLowerCase() + "_time";
      const amountKey = day.toLowerCase() + "_amount";

      const startTime = formValues[timeKey];
      let maxCreators = formValues[amountKey];
      const checkboxValue = formValues.schedule_checkboxes[day.toLowerCase()];

      if (checkboxValue) {
        //if (startTime !== "") {
        if (maxCreators === "") {
          maxCreators = parseInt(defaultAmount, 10);
        } else {
          maxCreators = parseInt(maxCreators, 10);
        }

        result[day] = {
          startTime,
          maxCreators,
        };
        //}
      }
    });

    return result;
  }

  const handleLoginSubmit = async (values, setSubmitting) => {
    try {
      // Set the product_ids [] if the automation type is Message or Message + Image
      console.log("12312312312312312321123123123", values);
      setLoading(true);
      let product_ids = values.product_id;
      if (
        automation_type === "Message" ||
        automation_type === "Message + Image"
      ) {
        product_ids = [];
      }
      // Create the categories list
      let categoriesList = values.category.map((category) => ({
        Main: category,
      }));
      console.log("categoriesList before sending as a payload", categoriesList);

      // Convert creators_to_omit and creators_to_include arrays to JSON strings
      const creatorsToOmitJson = JSON.stringify(values.creators_to_omit);
      const creatorsToIncludeJson = JSON.stringify(values.creators_to_include);

      // Process the time frame data
      const timeFrame = processFormSchedule(values);

      // Adjust content type preference for target collab automation
      let contentType = values.content_type;
      if (
        !["No preference", "Shoppable video", "Shoppable LIVE"].includes(
          values.content_type
        )
      ) {
        contentType = "No preference";
      }

      // Adjust the target collab message for creator name customization
      let targetCollabMessage = values.message_entry_target_collab;
      if (targetCollabMessage.includes("{creator_name}")) {
        // Replace all occurrences of {creator_name} with {{creators username}}
        targetCollabMessage = targetCollabMessage.replace(
          /{creator_name}/g,
          "{{creators username}}"
        );
      }

      // Adjust the target collab name so that it wont have consequtive empty spaces (only one space)
      let targetCollabName = values.target_collab_invitation_name;
      if (
        targetCollabName !== undefined &&
        targetCollabName !== null &&
        targetCollabName !== "" &&
        targetCollabName.includes("  ")
      ) {
        targetCollabName = targetCollabName.replace(/\s+/g, " ");
      }
      // Check if final char is empty space, if so remove it
      if (targetCollabName[targetCollabName.length - 1] === " ") {
        targetCollabName = targetCollabName.slice(0, -1);
      }

      // Create FormData and append the payload data
      const formData = new FormData();
      formData.append(
        "customer_id",
        auth?.meInfo?.customer_id?.toString() ?? ""
      );
      formData.append("shop_id", shops.selectedStoreId?.toString() ?? "");
      formData.append("shop_name", shops.selectedStoreName ?? "");
      formData.append("automation_name", values.automation_name ?? "");
      formData.append("status", "inactive");
      // ToDo: Will have to change this if new automations are added better solution required
      if (
        values.automation_type === "Message + Target Collab" ||
        values.automation_type ===
          "Message + Target Collab + Target Collab Card"
      ) {
        formData.append("automation_type", values.automation_type);
      } else {
        formData.append("automation_type", values.selectedMessageType);
      }
      formData.append("crm_group_id", values.crm_group_id ?? "");
      formData.append("creators_to_omit", creatorsToOmitJson);
      formData.append("creators_to_include", creatorsToIncludeJson);

      if (values.image) {
        formData.append("file", values.image);
        formData.append("file_name", values.image.name);
      }

      // Append config data as JSON string
      const config = {
        DESIRED_PRODUCT_IDS: product_ids,
        Filters: {
          Creators: {
            "Product Categories": categoriesList,
            Followers: {
              "Follower Segments": values.followers,
            },
            Products: values.products,
          },
          Followers: {
            "Follower Age": values.age,
            "Follower Gender": values.gender,
          },
          Performance: {
            GMV: values.gmv,
            "Units Sold": values.units,
            "Average Views": values.views,
            "Engagement Rate": values.engagement,
            GPM: values.video_gpm,
            "Video Views": values.video_views,
            "Fulfillment Rate": values.fulfillment_rate,
            "Video GPM": values.video_gpm,
            "Live GPM": values.live_gpm,
            "High Sample Dispatch Rate": values.high_sample_dispatch_rate,
            "Quickly Response": values.quickly_response,
            Videos: values.videos,
          },
        },
        // ToDo: will remove this when reacher changes are done
        CREATOR_MESSAGE: values.selectedMessageType != "Spark Code" ? values.message_entry : values.message_entry_spark_code,
        CREATOR_MESSAGE_CONTENT_FLYWHEEL:
          values.message_entry_content_flywheeling,
        CREATOR_MESSAGE_SPARK_CODE: values.message_entry_spark_code,
        SEND_UNREPLIED_FOLLOWUP: values.no_reply_automation,
        DAYS_BEFORE_FOLLOWUP: values.days,
        FOLLOWUP_MESSAGE: values.message_entry_no_reply,
        OFFER_FREE_SAMPLES: values.target_collab_offer_free_samples,
        AUTO_APPROVE: !values.target_collab_manually_approve,
        BASE_INVITATION_NAME: targetCollabName,
        TARGET_COLLAB_MESSAGE: targetCollabMessage,
        EMAIL: values.target_collab_email,
        PHONE_NUMBER: values.target_collab_phone,
        VALID_UNTIL: values.target_collab_valid_until,
        PRODUCTS: values.target_collab_product_comission_rates,
        ONLY_MESSAGE_INCLUDE_LIST: values.only_message_include_list,
        SCHEDULE: timeFrame,
        content_type: contentType,
        TC_DM_MESSAGE: values.tc_dm_message,
        addons: values.addons,
        automation_form_version: "V2",
        SELECTED_MESSAGE_TYPE: values.selectedMessageType,
        send_messages_to_all_creators_even_if_messaged_before:
          values.send_messages_to_all_creators_even_if_messaged_before,
        FOLLOW_UP_STEPS: values.followupSteps.map((step: any) => ({
          step_number: step.step_number,
          days_after_previous: step.days_after_previous,
          message: step.message,
        })),
      };

      // Add follow-up steps to the config

      formData.append("config", JSON.stringify(config));

      // Optionally append a file if it exists
      if (values.file) {
        formData.append("file", values.file);
        formData.append("file_name", values.file.name);
      }

      (values.addons || []).forEach((addon) => {
        if (addon.file) {
          formData.append("addon_files", addon.file);
        }
      });

      await dispatch(postAddAutomation(formData));
      dispatch(setMessages([]));
      dispatch(setSelectedAutommation(""));
      toast.success("Automation Created!");
      dispatch(getAutomations({ shop_id: shops.selectedStoreId }));
      onReturn();

      setSubmitting(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      // Handle failure if needed
      setLoading(false);
      setSubmitting(false);
    }
  };

  const renderStepForm = () => {
    console.log(formData, "formData");
    switch (step) {
      case 1:
        return (
          <AddNewAutomationStepOneForm
            onNext={handleNextwithCommissionRates}
            initialValues={formData}
            group={group}
          />
        );
      case 2:
        console.log("formData.outreach_type12321", formData.outreach_type);
        if (formData.outreach_type === "crm_group") {
          return (
            <CRMGroupLinking
              onNext={handleNext}
              onPrev={handlePrev}
              initialValues={formData}
              filteredCreators={[]}
            />
          );
        }
        return (
          <AddNewAutomationStepOneFilterForm
            onNext={handleNext}
            onPrev={handlePrev}
            initialValues={formData}
          />
        );
      case 3:
        if (
          formData.automation_type === "Message + Target Collab" ||
          formData.automation_type ===
            "Message + Target Collab + Target Collab Card"
        ) {
          return (
            <AddNewAutomationStepTargetCollab
              onNext={handleLoginSubmit}
              onPrev={handlePrev}
              initialValues={formData}
              automationType={formData.automation_type}
              group={group}
            />
          );
        } else {
          return (
            <AddNewAutomationStepOneTwoV2
              handleLoginSubmit={handleLoginSubmit}
              onPrev={handlePrev}
              initialValues={formData}
              group={group}
            />
          );
        }

      case 4:
        return (
          <AddNewAutomationStepOneFour
            onPrev={handlePrev}
            initialValues={formData}
            handleLoginSubmit={handleLoginSubmit}
            followup_steps={undefined}
          />
        );
      default:
        return null;
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      event.target.tagName !== "TEXTAREA" &&
      event.target.tagName !== "INPUT"
    ) {
      event.preventDefault(); // Prevent default form submit behavior of the form when the user presses Enter
    }
  };

  return (
    <div className=" mt-8">
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: semi-transparent background
            zIndex: 1000, // Ensure it's above other content
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <button
        // disabled={isSubmitting}
        onClick={onReturn}
        className=" absolute font-medium top-7 right-7 p-5 px-4 py-2 text-sm  text-gray-700 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 mr-3"
      >
        Discard Automation
      </button>
      <div className="flex justify-center my-10">
        <CustomStepsWithDynamicIcons currentStep={step - 1} />
      </div>

      {groupGroupDetail}
      <FormikContext.Provider value={{ formData }}>
        <div onKeyDown={handleKeyDown}>{renderStepForm()}</div>
      </FormikContext.Provider>
    </div>
  );
};

export default AddNewAutomationForm;
