import React from "react";
import { Spin } from "antd";
import { useAppSelector } from "./hooks";
import Analytics from "./dashboard-new/analytics";
import Dashboard from "./dashboard";
import DashboardSkeleton from "./components/skeletons/DashboardSkeleton";

const Page: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const customer_id = auth.meInfo.customer_id
  const shops = useAppSelector((state) => state.shops);

  if (!auth.meInfo.customer_id) {
    return (
      <Spin
        size="large"
        style={{
          fontSize: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Ensures the container takes the full viewport height.
          width: "100vw", // Ensures the container takes the full viewport width.
        }}
      />
    );
  }

  return (
    <div>

      {/* <Analytics/> */}
      {/* <Dashboard /> */}
      {typeof(shops.shops?.[customer_id]) !== 'undefined' ? <Analytics /> : <DashboardSkeleton /> }

    </div>
  );
};

export default Page;
