import React, { useState, useEffect } from "react";
import { Spin, Empty } from "antd";
import ReusableTable from "../../../pages/dashboard-new/components/TableComponent";
import { useAppDispatch, useAppSelector } from "../../../pages/hooks";
import { getConfigCreators } from "../../../pages/store/creators";
import { convertToURLSearchParams } from "../../../pages/groups/helper";

const CreatorPreview = ({ filters, region }) => {
  const dispatch = useAppDispatch();
  // const [creators, setCreators] = useState([]);
  const shops = useAppSelector((state) => state.shops);
  const creatorsData = useAppSelector((state) => state.creators);
  console.log("configCreators213123", filters);
  const filterKey = JSON.stringify(filters || {});

  const [pageSize] = useState(10); // Number of rows per page
  // const filterKey = (convertToURLSearchParams(filters) || "").toString();
  let key = `${shops.selectedStoreRegion}`;

  if (filterKey) {
    key = `${shops.selectedStoreRegion}-${filterKey}`;
  }

  const total =
    ((creatorsData.configCreators || {})?.[key] || {})?.total_creators || 0;
  console.log("key12312312", key);

  const creators: [] =
    ((creatorsData.configCreators || {})?.[key] || {})?.creators || [];
  // Fetch data from API
  const fetchCreators = async () => {
    dispatch(
      getConfigCreators({
        region: shops.selectedStoreRegion,
        filtersKey: filterKey,
        filters
      })
    );
  };

  // Fetch data on mount or when filters change
  console.log(
    "typeof ((creatorsData.configCreators || {})?.[key] || {})",
    (creatorsData.configCreators || {})?.[key] || {}
  );
  useEffect(() => {
    if (
      shops.selectedStoreRegion &&
      typeof (creatorsData.configCreators || {})?.[key] === "undefined"
    ) {
      fetchCreators();
    }
  }, [filterKey]);
  console.log(
    "creatorsData.configCreators || {})?.[key]",
    typeof (creatorsData.configCreators || {})?.[key] === "undefined"
  );
  // Dynamically define columns based on region and exclude unwanted fields
  const defineColumns = () => {
    const commonColumns = [
      {
        name: "Creator Name",
        render: (row) => <span>{row.creator_name}</span>,
      },
      {
        name: "Email",
        render: (row) => <span>{row.email}</span>,
      },
      {
        name: "Categories",
        render: (row) => (
          <span className="flex w-[100px]">{row.categories}</span>
        ),
      },
      {
        name: "Follower Segment",
        render: (row) => <span>{row.follower_segment}</span>,
      },
      {
        name: "Gender Segment",
        render: (row) => <span>{row.gender_segment}</span>,
      },
      {
        name: "Age Segment",
        render: (row) => <span>{row.age_segment}</span>,
      },
    ];

    const ukColumns = [
      {
        name: "Video GPM Segment",
        render: (row) => <span>{row.video_gpm_segment}</span>,
      },
      {
        name: "Response Time Segment",
        render: (row) => <span>{row.response_time_segment}</span>,
      },
      {
        name: "Sample Dispatch Segment",
        render: (row) => <span>{row.sample_dispatch_segment}</span>,
      },
      {
        name: "Products Segment",
        render: (row) => <span>{row.products_segment}</span>,
      },
      {
        name: "Videos Segment",
        render: (row) => <span>{row.videos_segment}</span>,
      },
      {
        name: "Live GPM Segment",
        render: (row) => <span>{row.live_gpm_segment}</span>,
      },
    ];

    const usColumns = [
      {
        name: "GMV Segment",
        render: (row) => <span>{row.gmv_segment}</span>,
      },
      {
        name: "Units Sold Segment",
        render: (row) => <span>{row.units_sold_segment}</span>,
      },
      {
        name: "Video Views Segment",
        render: (row) => <span>{row.video_views_segment}</span>,
      },
      {
        name: "Engagement Rate Segment",
        render: (row) => <span>{row.engagement_rate_segment}%</span>,
      },
      {
        name: "Fulfillment Rate Segment",
        render: (row) => <span>{row.fulfillment_rate_segment}%</span>,
      },
    ];

    if (region === "UK") {
      return [...commonColumns, ...ukColumns];
    } else {
      return [...commonColumns, ...usColumns];
    }
  };

  const CustomTableTitle = () => (
    <div className="flex flex-row px-3xl py-2xl">
      <div className="h-14 flex flex-row space-x-md items-center py- flex-1">
        <div className="text-lg font-semibold text-primary-default">
          Creator Preview
        </div>
        <div className="flex items-center py-xxs px-md rounded-full border text-xs font-medium border-utility-brand-200 text-utility-brand-700 bg-utility-brand-50">
          {total} Creators Filtered
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col p-5">
      {typeof (creatorsData.configCreators || {})?.[key] === "undefined" ? (
        <Spin size="large" className="self-center my-20" />
      ) : creators.length > 0 ? (
        <ReusableTable
          cols={defineColumns()}
          rows={creators}
          pageSize={pageSize}
          TableTitleComponent={CustomTableTitle}
        />
      ) : (
        <Empty description="No Data Available" className="my-20" />
      )}
    </div>
  );
};

export default CreatorPreview;
