import React from "react";

interface PrimaryButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode; // This prop will hold the button's content
  className?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  className = "",
  ...props
}) => {
  const defaultClasses =
    "px-3.5 py-2.5 flex items-center outline-none justify-center gap-xs rounded-md bg-brand-solid-default hover:bg-brand-solid-hover text-white";
  const combinedClasses = `${defaultClasses} ${className}`.trim();
  return (
    <div className="bg-brand-solid-default p-0.5 rounded-lg shadow-primary-button">
      <div className="bg-button-border p-0.5 rounded-md">
        <button className={combinedClasses} {...props}>
          {children}
        </button>
      </div>
    </div>
  );
};

export default PrimaryButton;
