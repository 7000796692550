// This component is to edit an existing shop name, given its old name and id.
// It will open a modal to edit the shop name.
// It will update the shop name in the database and the store slice state if it is the currently selected shop.

import { DeleteOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useState } from "react";
import { FiAlertOctagon } from "react-icons/fi";

interface DeleteShopModalProps {
  shopId: number;
  oldShopName: string;
  linked: any;
}

const deleteShop = () => {
  // Write deletion and subscription cancellation logic here
};
const subscriptionExists = () => {
  // Check if the shop has an active subscription
  // Return true if the shop has an active subscription
  return true;
};
const DeleteShopModal: React.FC<DeleteShopModalProps> = ({
  shopId,
  oldShopName,
  linked,
}) => {
  console.log(shopId);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const handleOpen = () => {
    setDeleteModalVisible(true);
  };

  const handleCancelDelete = () => {
    setAlertVisible(false);
    setDeleteModalVisible(false);
  };
  const handleAlert = () => {
    // alertVisible will always be false the first time this function is executed
    // It will be set to true if the shop has an active subscription
    // If alert is visible, shop does has an active subscription and even then user clicks Delete
    // then run delete logic
    if (alertVisible) {
      deleteShop();
    }

    // Check if the shop has an active subscription
    // If the shop has an active subscription, show the alert
    if (subscriptionExists()) {
      setAlertVisible(true);
    }
  };
  return (
    <>
      <button onClick={handleOpen}>
        <DeleteOutlined />
      </button>
      <Modal
        centered
        className="top-14 transition-none"
        footer={null}
        title={
          <p className="font-semibold bg-transparent text-start font-sans text-lg leading-6">
            Delete Shop
          </p>
        }
        open={deleteModalVisible}
        onCancel={handleCancelDelete}
      >
        <div className="flex flex-col">
          <p className="mt-1 text-sm text-slate-600">
            Are you sure you want to delete this shop? This action cannot be undone.
          </p>
          {alertVisible && (
            <div className="flex flex-row rounded-lg p-4 bg-amber-50 gap-x-3 mt-3">
              <div>
                <FiAlertOctagon className="text-xl mt-1 text-amber-600" />
              </div>
              <p className="text-sm text-gray-900 ">
                Please note that the shop has an active subscription. If you
                delete this shop your linked subscription will be cancelled.
              </p>
            </div>
          )}
          <div className="flex flex-row justify-between mt-3 gap-x-3">
            <button
              onClick={handleCancelDelete}
              className="p-2 border border-slate-200 font-semibold w-full rounded-lg"
            >
              I changed my mind!
            </button>
            <button
              onClick={handleAlert}
              className="p-2 border border-slate-200 font-semibold w-full rounded-lg bg-red-600 text-white"
            >
              Delete Shop
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteShopModal;
