import React from "react";
import { Field, ErrorMessage } from "formik";
import TextField from "../forms/TextField";
import TextError from "../forms/TextError";

interface FormikTextAreaProps {
  label?: string;
  name: string;
  maxLength?: number;
  disabled?: boolean;
  placeholder?: string;
  minimum_rows?: number;
  maximum_rows?: number;
  isRequired?: boolean;
  helperText?: string;
  newLabel?: any;
  restProps?: any;
}

const FormikTextArea: React.FC<FormikTextAreaProps> = ({
  name,
  label = "",
  disabled = false,
  maxLength = 100,
  placeholder = "",
  minimum_rows = 3,
  maximum_rows = 20,
  isRequired = false,
  helperText,
  newLabel = () => {},
  restProps = {}
}) => {
  return (
    <div className="mb-4">
      <Field name={name}>
        {({ field, form }: any) => (
          <>
            {label !== "" && (
              <label
                htmlFor={name}
                className="block text-sm font-medium text-black mb-2"
              >
                {label}
                {isRequired && <span className="text-red-500 ml-1">*</span>}
              </label>
            )}
            {newLabel !== "" && (
              <label
                htmlFor={name}
                className="block text-sm font-medium text-black mb-2"
              >
                {newLabel(form.setFieldValue, name)}
                {isRequired && <span className="text-red-500 ml-1">*</span>}
              </label>
            )}
            <TextField
              initialValue={field.value as string}
              handleChangeOnFormik={(value) => form.setFieldValue(name, value)}
              disabled={disabled}
              maxLength={maxLength}
              placeholder={placeholder}
              minimum_rows={minimum_rows}
              maximum_rows={maximum_rows}
              restProps={restProps}
            />
          </>
        )}
      </Field>
      <ErrorMessage name={name} component={TextError} />
      {helperText && <p className="text-xs text-gray-500 mt-1">{helperText}</p>}
    </div>
  );
};

export default FormikTextArea;
