import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Empty, Layout, Spin, Tooltip, Switch, Badge, Pagination } from "antd";
import { useInView } from "react-intersection-observer";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getContentManager } from "../store/contentManagerSlice";
import CustomModal from "../../common/modal";
import CustomFilters from "../customFilter/customFilters";
import FilterFooter from "../customFilter/customFilterFooter";
import { convertToURLSearchParams, getSelectedFilters } from "../groups/helper";
import { getVideoConfig } from "../store/videoGroups";
import { transformConfigData } from "./helper";
import { CiFilter } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import ColumnSelector from "../my-creators/columnSelector";

const ProductCard = ({ product }) => (
  <div className="p-2 border rounded-md bg-gray-50 hover:bg-gray-100 transition">
    <Tooltip title={product.name}>
      <div
        className="font-bold truncate"
        aria-label={`Product name: ${product.name}`}
      >
        {product.name || "-"}
      </div>
    </Tooltip>
    <div
      className="text-sm text-gray-500"
      aria-label={`Product ID: ${product.id}`}
    >
      ID: {product.id || "-"}
    </div>
  </div>
);


const VideoCard = ({ video, showVideo }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };
  const [ref, inView] = useInView({ triggerOnce: true });
  const products = video.products.filter((el) => el.id !== "0");
  return (
    <div
      ref={ref}
      className={`flex flex-col items-center border rounded-lg shadow-md hover:shadow-lg p-4 transition duration-300 ease-in-out`}
      style={{
        // width: "325px",
        background: "#fff",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {showVideo && inView && (
        <iframe
          id={`tiktok-video-${video.video_id}`}
          src={video.video_id ?`https://www.tiktok.com/embed/${video.video_id}?autoplay=0 `: `${video.video_url}?autoplay=0`}
          width="300"
          height="500"
          style={{
            border: "none",
            overflow: "hidden",
          }}
          scrolling="no"
          onError={handleError}
        />
      )}

      <div className="mt-4 w-full bg-white shadow-sm p-4 rounded-lg">
        {(video.video_id)&&(
          <div>
            <Link
              to={`https://www.tiktok.com/@${video.username}/video/${video.video_id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 font-medium hover:underline"
              aria-label="View on TikTok"
            >
              View on TikTok
            </Link>
            <Tooltip title={video.title}>
              <h4
                className="font-bold text-lg truncate mt-2"
                aria-label="Video title"
              >
                {video.title || "-"}
              </h4>
            </Tooltip>
          </div>)
        }
        <p className="text-gray-600 text-sm mt-1">
          Creator: <span className="font-medium">{video.username}</span>
        </p>
        <p className="text-gray-600 text-sm">
          GMV: {video.gmv_amount} {video.gmv_currency}
        </p>
        <p className="text-gray-600 text-sm">Units Sold: {video.units_sold || '-'}</p>
        <p className="text-gray-600 text-sm">Views: {video.views || '-'}</p>

        {products.length > 0 && (
          <div className="mt-4">
            <p className="text-gray-600 text-sm font-medium">Products:</p>
            <div className="space-y-2">
              {products.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MyContent = () => {
  const [showVideo, setShowVideo] = useState(true);
  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams.entries());
  const [filters, setFilters] = useState<any>({});
  const [openfiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8); // Default number of videos per page
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const contentManagerSlice = useAppSelector(
    (state) => state.contentManagerSlice
  );
  const shops = useAppSelector((state) => state.shops);
  const videoGroups = useAppSelector((state) => state.videoGroups);
  let key = `${shops.selectedStoreId}`;
  if (Object.keys(paramsObject).filter((el) => el !== "id").length > 0) {
    key = `${shops.selectedStoreId}-${location.search}`;
  }
  const contentManagerVideos = contentManagerSlice.contentManager?.[key] || [];
  const filterLoading = videoGroups.filterLoading;
  const selectedVideoGroup = videoGroups.selectedVideoGroup;

  const videoFilter =
    Object.values(videoGroups.filters?.[shops.selectedStoreId] || {}).length > 0
      ? transformConfigData(videoGroups.filters?.[shops.selectedStoreId])
      : {};

  useEffect(() => {
    if (
      Object.values(videoFilter || {}).length > 0 &&
      Object.values(filters || {}).length === 0
    ) {
      setFilters(videoFilter);
    }
  }, [videoFilter]);

  useEffect(() => {
    if ((contentManagerVideos || [])?.length === 0) {
      if (Object.keys(paramsObject).length) {
        // setFilters(updateFilters(transformApiData(creators.filters), paramsObject));
        let dataObject: any = {};
        Object.keys(paramsObject).forEach((key) => {
          dataObject[key] = (paramsObject[key] || "").split(",");
        });
        // getShopCreators(dataObject);
        dispatch(
          getContentManager({
            shop_id: shops.selectedStoreId,
            filters: dataObject,
            search: location.search,
          })
        );
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (contentManagerVideos.length) {
      setCurrentPage(1);
    }
  }, [contentManagerVideos.length]);

  useEffect(() => {
    if (
      (contentManagerSlice?.[key] || []).length === 0 &&
      shops.selectedStoreId
    ) {
      dispatch(
        getContentManager({
          shop_id: shops.selectedStoreId,
        })
      );
      if (
        Object.values(videoFilter || {}).length === 0 &&
        shops.selectedStoreId
      ) {
        dispatch(
          getVideoConfig({
            shop_id: shops.selectedStoreId,
          })
        );
      }
    }
  }, [key, shops.selectedStoreId]);

  const handleResetFilters = () => {
    const resetFilters = Object.keys(filters).reduce(
      (acc, key) => {
        const filter = filters[key as keyof typeof filters];
        acc[key as keyof typeof filters] = {
          ...filter,
          options: filter.options?.map((option) => ({
            ...option,
            selected: false,
          })),
          value: "",
          selected: false,
        };
        return acc;
      },
      {} as typeof filters
    );

    setFilters(resetFilters);
    navigate(`/content-flywheels/my-content`, { replace: true });
  };
  const filtersItems = [
    {
      name: "Clear All",
      handleClick: () => {
        handleResetFilters();
      },
      icon: <AiOutlineClose color={"#1E1E1E"} />,
      buttonClass:
        "flex items-center gap-2 text-gray-700 hover:text-black bg-transparent",
      textClass: "text-black",
    },
    {
      name: "Video Filters",
      handleClick: () => {
        setOpenFiltersDrawer(true);
      },
      icon: <CiFilter color={"#1E1E1E"} />,
      badge: Object.keys(paramsObject).filter((el) => el !== "id").length,
      buttonClass: "bg-gray-200 border-solid border-1 border-black",
      textClass: "text-black",
    },
  ];

  // Pagination logic
  const paginatedVideos = contentManagerVideos.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <Layout style={{ background: "white" }}>
      <header className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <h2 className="font-bold text-2xl mr-4">
            My Content ({contentManagerVideos.length})
          </h2>
          <Switch
            checked={showVideo}
            onChange={(checked) => setShowVideo(checked)}
            checkedChildren="Videos On"
            unCheckedChildren="Videos Off"
          />
        </div>

        <div className="flex space-x-4 items-center">
          <div className="flex mt-4">
            {filtersItems.map((filter, index) => (
              <div
                className="mr-4 hover:bg-black-500 hover:border-black-500 hover:text-white transition duration-300"
                key={`filter-${filter.name}`}
              >
                <Badge count={filter.badge || 0}>
                  <ColumnSelector
                    columns={[]}
                    onUpdate={() => {}}
                    showButton={
                      filter.name === "Clear All"
                        ? Object.keys(paramsObject).filter((el) => el !== "id")
                            .length > 0
                        : true
                    }
                    restProps={{
                      label: filter.name,
                      onClick: filter.handleClick,
                      icon: filter.icon,
                      buttonClass: filter.buttonClass,
                      textClass: filter.textClass,
                    }}
                  />
                </Badge>
              </div>
            ))}
          </div>
        </div>
      </header>

      <CustomModal
        visible={openfiltersDrawer}
        onCancel={() => {
          setOpenFiltersDrawer(false);
        }}
        title={
          <div>
            <div className="mb-2">More Filters</div>
            <div className="border-b-2 border-gray-300 mb-5" />
          </div>
        }
        showModalFooter={false}
        width={700}
        content={
          <>
            <div className="overflow-y-auto max-h-[60vh] min-h-[40vh]">
              {filterLoading ? (
                <Spin />
              ) : (
                <CustomFilters filters={filters} setFilters={setFilters} />
              )}
            </div>
            <FilterFooter
              onClearAll={() => {
                handleResetFilters();
                setOpenFiltersDrawer(false);
              }}
              onApplyFilters={() => {
                const selectedFilters = getSelectedFilters(filters);
                const urlParams = convertToURLSearchParams(selectedFilters);

                // Example of how to get the string representation for a URL
                navigate(
                  `/content-flywheels/my-content?${urlParams.toString()}`,
                  {
                    replace: true,
                  }
                );
                setOpenFiltersDrawer(false);
              }}
            />
          </>
        }
      />
      {typeof contentManagerSlice.contentManager?.[key] === "undefined" ? (
        <div className="flex justify-center items-center h-[500px]">
          <Spin />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-center">
            {paginatedVideos.map((video) => (
              <VideoCard
                key={video.video_id}
                video={video}
                showVideo={showVideo}
              />
            ))}
          </div>

          {typeof contentManagerSlice.contentManager?.[key] !== "undefined" &&
            paginatedVideos.length === 0 && (
              <div className="flex h-[80vh] items-center justify-center">
                <Empty />
              </div>
            )}

          {contentManagerVideos.length > 0 ? (
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={contentManagerVideos.length}
              onChange={(page, size) => {
                setCurrentPage(page);
                setPageSize(size);
              }}
              style={{ marginTop: "20px", textAlign: "center" }}
            />
          ) : (
            <div />
          )}
        </>
      )}
    </Layout>
  );
};

export default MyContent;
