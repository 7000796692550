import React, { useState } from "react";
import { FormikValues } from "formik";
import { FormikContext } from "formik";
import AddNewAutomationStepOneForm from "./AddNewAutomationStepOneForm";
import AddNewAutomationStepOneTwo from "./AddNewAutomationStepOneTwo";
import { getAutomations, updateAutomation } from "../../store/automation";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { toast } from "react-toastify";
import AddNewAutomationStepOneThree from "./AddNewAutomationStepOneThree";
import AddNewAutomationStepTargetCollab from "./AddNewAutomationStepTargetCollab";
import AddNewAutomationStepOneFour from "./AddNewAutomationStepOneFour";
import AddNewAutomationStepMessageImage from "./AddNewAutomationStepMessageImage";
import CRMGroupLinking from "./CRMGroupLinking";
import AddNewAutomationStepOneFilterForm from "./AddNewAutomationStepOneFilter";
import AddNewAutomationStepOneTwoSparkCode from "./AddNewAutomationStepOneTwoV2";
import AddNewAutomationStepOneTwoV2 from "./AddNewAutomationStepOneTwoV2";
import { Spin } from "antd";

const MESSAGE_ENTRY__DEFAULT_CONTENT =
  "Hey {creator_name}!\n\nWe love the content that you put out and would love to be a part of that. We can see our products aligning with your brand and would love to help others see that too.\n \nWe would love to get you involved in our campaign on TikTok Shop and we're willing to boost high quality content so you can make more sales with 20% commissions! As a token of our appreciation, we'd love to send you a sample!\n\nPlease let us know if you're interested and if you have any questions.\n\nThanks and I looking forward to collaborating with you!\n";

const MESSAGE_ENTRY_NO_REPLY_DEFAULT_CONTENT =
  "Hey there, just following up to see if you had a chance to check out any of our products? Let me know if you have any questions or need help with anything! We would love to work with you!";

const MESSAGE_ENTRY_INTEREST_DEFAULT_CONTENT =
  "Thank you for your interest in collaborating with us. Please add the products that you are interested in to your product showcase. We look forward to working with you!";

const MESSAGE_ENTRY_MAX_SAMPLES_REQUESTED_DEFAULT_CONTENT =
  "It looks like you have reached the maximum number of samples requested, but we would still love to work with you! Please join our target collaboration. Thanks! -Reacher";

const MESSAGE_ENTRY_TARGET_COLLAB_DEFAULT_CONTENT =
  "I would love to collaborate with you because I can see that you love to post related content!";

interface EditAutomationDetailsProps {
  automationData: any;
  onReturn: () => void;
}

const EditAutomationDetails: React.FC<EditAutomationDetailsProps> = ({
  automationData,
  onReturn,
}) => {
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();
  const schedule = automationData.config?.SCHEDULE || {};
  const [loading, setLoading] = useState(false);
  let initialProductCategories = []; // Initialize initialProductCategories with empty array
  if (automationData.config?.Filters?.Creators?.["Product Categories"]) {
    initialProductCategories = automationData.config?.Filters?.Creators?.[
      "Product Categories"
    ].map((category) => category.Main);
  }

  let targetCollabMessage =
    automationData.config?.TARGET_COLLAB_MESSAGE ||
    MESSAGE_ENTRY_TARGET_COLLAB_DEFAULT_CONTENT;
  if (targetCollabMessage.includes("{{creators username}}")) {
    targetCollabMessage = targetCollabMessage.replace(
      /{{creators username}}/g,
      "{creator_name}"
    );
  }

  const [step, setStep] = useState<number>(1);
  const defaultAmount = "2500";
  console.log("automationData.config1231232", automationData);
  const [formData, setFormData] = useState<FormikValues>({
    message_entry:
      automationData.config?.CREATOR_MESSAGE || MESSAGE_ENTRY__DEFAULT_CONTENT,
    message_entry_content_flywheeling:
      automationData.config.CREATOR_MESSAGE_CONTENT_FLYWHEEL,
    message_entry_spark_code : automationData.config?.CREATOR_MESSAGE_SPARK_CODE,
    message_entry_no_reply:
      automationData.config?.FOLLOWUP_MESSAGE ||
      MESSAGE_ENTRY_NO_REPLY_DEFAULT_CONTENT,
    message_entry_interest: MESSAGE_ENTRY_INTEREST_DEFAULT_CONTENT, // ToDo Update when the new feature is ready
    message_entry_max_samples:
      MESSAGE_ENTRY_MAX_SAMPLES_REQUESTED_DEFAULT_CONTENT, // ToDo Update when the new feature is ready
    selectedMessageType:
      automationData.config?.SELECTED_MESSAGE_TYPE || "Message",
    addons: automationData.config?.addons || [],
    // Target Collab Default Values
    message_entry_target_collab: targetCollabMessage,
    target_collab_invitation_name:
      automationData.config?.BASE_INVITATION_NAME || "",
    target_collab_valid_until: automationData.config?.VALID_UNTIL || "",
    target_collab_email: automationData.config?.EMAIL || "",
    target_collab_phone: automationData.config?.PHONE_NUMBER || "",
    target_collab_offer_free_samples:
      automationData.config?.OFFER_FREE_SAMPLES || false,
    target_collab_manually_approve:
      !automationData.config?.AUTO_APPROVE || false,
    target_collab_product_comission_rates: automationData.config?.PRODUCTS || [
      {},
    ],
    content_type: automationData.config?.content_type || "No preference",

    days: automationData.config?.DAYS_BEFORE_FOLLOWUP || "",
    no_reply_automation:
      automationData.config?.SEND_UNREPLIED_FOLLOWUP || false,
    interest_automation: false, // ToDo Update when the new feature is ready
    max_samples_automation: false, // ToDo Update when the new feature is ready

    // default values
    shop_name: "", // ToDo Update when the new feature is ready
    product_id: automationData.config?.DESIRED_PRODUCT_IDS || [],
    automation_name: automationData.automation_name || "",
    followers:
      automationData.config?.Filters?.Creators?.Followers?.[
        "Follower Segments"
      ] || [],
    engagement:
      automationData.config?.Filters?.Performance?.["Engagement Rate"] || "",
    gender:
      automationData.config?.Filters?.Followers?.["Follower Gender"] || "",
    automation_type: [
      "Message",
      "Message + Image",
      "Message + Product Card",
      "Spark Code",
      "Content Flywheel",
    ].includes(automationData.automation_type)
      ? "Message"
      : automationData.automation_type,
    gmv: automationData.config?.Filters?.Performance?.GMV || [],
    category: initialProductCategories,
    age: automationData.config?.Filters?.Followers?.["Follower Age"] || [],
    units: automationData.config?.Filters?.Performance?.["Units Sold"] || [],
    views: automationData.config?.Filters?.Performance?.["Average Views"] || "",
    fulfillment_rate:
      automationData.config?.Filters?.Performance?.["Fulfillment Rate"] || "",
    high_sample_dispatch_rate:
      automationData.config?.Filters?.Performance?.[
        "High Sample Dispatch Rate"
      ] || "",
    quickly_response:
      automationData.config?.Filters?.Performance?.["Quickly Response"] || "",
    videos: automationData.config?.Filters?.Performance?.["Videos"] || "",
    products: automationData.config?.Filters?.Creators?.["Products"] || "",
    creators_to_omit: automationData.creators_to_omit || [],
    creators_to_include: automationData.creators_to_include || [],
    only_message_include_list:
      automationData.config?.ONLY_MESSAGE_INCLUDE_LIST !== undefined
        ? automationData.config?.ONLY_MESSAGE_INCLUDE_LIST
        : false,
    video_gpm: automationData.config?.Filters?.Performance?.["Video GPM"] || [],
    live_gpm: automationData.config?.Filters?.Performance?.["Live GPM"] || [],
    video_views:
      automationData.config?.Filters?.Performance?.["Video Views"] || [],
    monday_time: schedule.Monday?.startTime || "07:00",
    tuesday_time: schedule.Tuesday?.startTime || "07:00",
    wednesday_time: schedule.Wednesday?.startTime || "07:00",
    thursday_time: schedule.Thursday?.startTime || "07:00",
    friday_time: schedule.Friday?.startTime || "07:00",
    saturday_time: schedule.Saturday?.startTime || "07:00",
    sunday_time: schedule.Sunday?.startTime || "07:00",
    monday_amount: String(schedule.Monday?.maxCreators || defaultAmount),
    tuesday_amount: String(schedule.Tuesday?.maxCreators || defaultAmount),
    wednesday_amount: String(schedule.Wednesday?.maxCreators || defaultAmount),
    thursday_amount: String(schedule.Thursday?.maxCreators || defaultAmount),
    friday_amount: String(schedule.Friday?.maxCreators || defaultAmount),
    saturday_amount: String(schedule.Saturday?.maxCreators || defaultAmount),
    sunday_amount: String(schedule.Sunday?.maxCreators || defaultAmount),
    schedule_checkboxes: {
      monday: !!schedule.Monday,
      tuesday: !!schedule.Tuesday,
      wednesday: !!schedule.Wednesday,
      thursday: !!schedule.Thursday,
      friday: !!schedule.Friday,
      saturday: !!schedule.Saturday,
      sunday: !!schedule.Sunday,
    },
    image: null,
    image_name: automationData.file_name || "",

    // Follow-up steps
    followupSteps:
      automationData.config?.FOLLOW_UP_STEPS.map((el, index) => ({
        ...el,
        message_label: " + Follow Up Message",
        message_value: "follow-up",
        id: index,
        message_type: "follow-up",
      })) || [],
    tc_dm_message: automationData.config?.TC_DM_MESSAGE || "",
    send_messages_to_all_creators_even_if_messaged_before:
      automationData.config
        ?.send_messages_to_all_creators_even_if_messaged_before || "",
    outreach_type: automationData?.crm_group_id ? "crm_group" : "list_upload",
    crm_group_id: automationData?.crm_group_id,
  });

  console.log(
    "automationData.config?.send_messages_to_all_creators_even_if_messaged_before",
    automationData.config
  );
  const handleNext = (values: FormikValues) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setStep(step + 1);
  };

  const handleNextwithCommissionRates = (values: FormikValues) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setFormData((prevData) => ({
      ...prevData,
      target_collab_product_comission_rates: initializeCommissionRates(values),
    }));
    setStep(step + 1);
  };

  const checkToInitializeCommissionRatesOnEditAutomation = (
    values: FormikValues
  ) => {
    const { product_id, target_collab_product_comission_rates } = values;
    // Check if target_collab_product_comission_rates is an array with a single empty object, i.e. [{}]
    if (
      Array.isArray(target_collab_product_comission_rates) &&
      target_collab_product_comission_rates.length === 1 &&
      Object.keys(target_collab_product_comission_rates[0]).length === 0
    ) {
      return false;
    }
    // Extract the product IDs from target_collab_product_comission_rates
    const targetProductIds = target_collab_product_comission_rates.map(
      (item) => item.product_id
    );
    // Check if every product ID in product_id has a correspondence in targetProductIds
    const allProductsMatch = product_id.every((id) =>
      targetProductIds.includes(id)
    );
    // Check if every product ID in targetProductIds has a correspondence in product_id
    const allTargetsMatch = targetProductIds.every((id) =>
      product_id.includes(id)
    );
    // Return true if both conditions are met, i.e. the product IDs match
    return allProductsMatch && allTargetsMatch;
  };

  const initializeCommissionRates = (values: FormikValues) => {
    const ProductIDsChanged =
      !checkToInitializeCommissionRatesOnEditAutomation(values);
    // Check if product ids changed or first time initialization of the target_collab_product_comission_rates
    if (
      ProductIDsChanged ||
      (values.product_id &&
        values.target_collab_product_comission_rates.length === 1 &&
        !values.target_collab_product_comission_rates[0].product_id)
    ) {
      return values.product_id.map((productId) => ({
        product_id: productId,
        commission_rate: "",
      }));
    }
    return values.target_collab_product_comission_rates;
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  type Result = {
    [key: string]: {
      startTime: string;
      maxCreators: number;
    };
  };

  function processFormScheduleEdit(formValues) {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const result: Result = {};

    daysOfWeek.forEach((day) => {
      const timeKey = day.toLowerCase() + "_time";
      const amountKey = day.toLowerCase() + "_amount";

      const startTime = formValues[timeKey];
      let maxCreators = formValues[amountKey];
      const checkboxValue = formValues.schedule_checkboxes[day.toLowerCase()];

      if (checkboxValue) {
        if (maxCreators === "") {
          maxCreators = parseInt(defaultAmount, 10);
        } else {
          maxCreators = parseInt(maxCreators, 10);
        }

        result[day] = {
          startTime,
          maxCreators,
        };
      }
    });

    return result;
  }

  const handleLoginSubmit = async (values, setSubmitting) => {
    try {
      console.log("12312312312312312321");
      setLoading(true);
      let product_ids = values.product_id;
      if (
        values.automation_type === "Message" ||
        values.automation_type === "Message + Image"
      ) {
        product_ids = [];
      }
      const timeFrame = processFormScheduleEdit(values);
      let categoriesList = values.category.map((category) => ({
        Main: category,
      }));
      console.log("categoriesList before sending as a payload", categoriesList);

      // Adjust content type preference for target collab automation
      let contentType = values.content_type;
      if (
        !["No preference", "Shoppable video", "Shoppable LIVE"].includes(
          values.content_type
        )
      ) {
        contentType = "No preference";
      }

      // Adjust the target collab message for creator name customization
      let targetCollabMessage = values.message_entry_target_collab;
      if (targetCollabMessage.includes("{creator_name}")) {
        // Replace all occurrences of {creator_name} with {{creators username}}
        targetCollabMessage = targetCollabMessage.replace(
          /{creator_name}/g,
          "{{creators username}}"
        );
      }

      // Adjust the target collab name so that it wont have consequtive empty spaces (only one space)
      let targetCollabName = values.target_collab_invitation_name;
      if (
        targetCollabName !== undefined &&
        targetCollabName !== null &&
        targetCollabName !== "" &&
        targetCollabName.includes("  ")
      ) {
        targetCollabName = targetCollabName.replace(/\s+/g, " ");
      }
      // Check if final char is empty space, if so remove it
      if (targetCollabName[targetCollabName.length - 1] === " ") {
        targetCollabName = targetCollabName.slice(0, -1);
      }

      const formData = new FormData();
      formData.append(
        "customer_id",
        auth?.meInfo?.customer_id?.toString() ?? ""
      );
      formData.append("shop_id", shops.selectedStoreId?.toString() ?? "");
      formData.append(
        "automation_id",
        automationData.automation_id?.toString() ?? ""
      );
      formData.append("shop_name", shops.selectedStoreName ?? "");
      formData.append("automation_name", values.automation_name ?? "");
      formData.append("status", automationData.status ?? "");
      if (
        values.automation_type === "Message + Target Collab" ||
        values.automation_type ===
          "Message + Target Collab + Target Collab Card"
      ) {
        formData.append("automation_type", values.automation_type);
      } else {
        formData.append("automation_type", values.selectedMessageType);
      }

      if (
        values.automation_type === "Message + Target Collab" ||
        values.automation_type ===
        "Message + Target Collab + Target Collab Card"
      ) {
        formData.append("automation_type", values.automation_type);
      } else {
        formData.append("automation_type", values.selectedMessageType);
      }
      formData.append(
        "creators_to_omit",
        JSON.stringify(values.creators_to_omit)
      );
      formData.append(
        "creators_to_include",
        JSON.stringify(values.creators_to_include)
      );

      if (values.image) {
        formData.append("file", values.image);
        formData.append("file_name", values.image.name);
      }

      const config = {
        DESIRED_PRODUCT_IDS: product_ids,
        Filters: {
          Creators: {
            "Product Categories": categoriesList,
            Followers: {
              "Follower Segments": values.followers,
            },
            Products: values.products,
          },
          Followers: {
            "Follower Age": values.age,
            "Follower Gender": values.gender,
          },
          Performance: {
            GMV: values.gmv,
            "Units Sold": values.units,
            "Average Views": values.views,
            "Engagement Rate": values.engagement,
            GPM: values.video_gpm,
            "Video Views": values.video_views,
            "Fulfillment Rate": values.fulfillment_rate,
            "Video GPM": values.video_gpm,
            "Live GPM": values.live_gpm,
            "High Sample Dispatch Rate": values.high_sample_dispatch_rate,
            "Quickly Response": values.quickly_response,
            Videos: values.videos,
          },
        },
        // ToDo: Will remove when reacher changes are done
        CREATOR_MESSAGE:  values.selectedMessageType != "Spark Code" ? values.message_entry : values.message_entry_spark_code,

        OFFER_FREE_SAMPLES: values.target_collab_offer_free_samples,
        AUTO_APPROVE: !values.target_collab_manually_approve,
        BASE_INVITATION_NAME: targetCollabName,
        TARGET_COLLAB_MESSAGE: targetCollabMessage,
        EMAIL: values.target_collab_email,
        PHONE_NUMBER: values.target_collab_phone,
        VALID_UNTIL: values.target_collab_valid_until,
        PRODUCTS: values.target_collab_product_comission_rates,
        ONLY_MESSAGE_INCLUDE_LIST: values.only_message_include_list,
        SCHEDULE: timeFrame,
        content_type: contentType,
        TC_DM_MESSAGE: values.tc_dm_message,
        SELECTED_MESSAGE_TYPE: values.selectedMessageType,
        CREATOR_MESSAGE_CONTENT_FLYWHEEL:
          values.message_entry_content_flywheeling,
        CREATOR_MESSAGE_SPARK_CODE: values.message_entry_spark_code,
        addons: values.addons,
        send_messages_to_all_creators_even_if_messaged_before:
          values.send_messages_to_all_creators_even_if_messaged_before,
        FOLLOW_UP_STEPS: values.followupSteps.map((step, index) => ({
          step_number: step.step_number,
          days_after_previous: step.days_after_previous,
          message: step.message,
        })),
      };

      formData.append("config", JSON.stringify(config));
      (values.addons || []).forEach((addon) => {
        if (addon.file && !addon.uploaded_file_url) {
          formData.append("addon_files", addon.file); // File object
        }
      });
      await dispatch(updateAutomation(formData));
      toast.success("Automation Updated!");
      dispatch(
        getAutomations({
          shop_id: shops.selectedStoreId,
        })
      );
      onReturn();

      setSubmitting(false);
      setLoading(false);
    } catch (error) {
      console.error("Error updating automation:", error);
      setSubmitting(false);
      setLoading(false);
    }
  };

  const renderStepForm = () => {
    switch (step) {
      case 1:
        return (
          <AddNewAutomationStepOneForm
            onNext={handleNextwithCommissionRates}
            initialValues={formData}
          />
        );
      case 2:
        console.log("formData.outreach_type12321", formData.outreach_type);
        if (formData.outreach_type === "crm_group") {
          return (
            <CRMGroupLinking
              onNext={handleNext}
              onPrev={handlePrev}
              initialValues={formData}
              filteredCreators={[]}
            />
          );
        }
        return (
          <AddNewAutomationStepOneFilterForm
            onNext={handleNext}
            onPrev={handlePrev}
            initialValues={formData}
          />
        );
      case 3:
        if (
          formData.automation_type === "Message + Target Collab" ||
          formData.automation_type ===
            "Message + Target Collab + Target Collab Card"
        ) {
          return (
            <AddNewAutomationStepTargetCollab
              onNext={handleLoginSubmit}
              onPrev={handlePrev}
              initialValues={formData}
              automationType={formData.automation_type}
            />
          );
        } else {
          return (
            <AddNewAutomationStepOneTwoV2
              handleLoginSubmit={handleLoginSubmit}
              onPrev={handlePrev}
              initialValues={formData}
            />
          );
        }
      case 4:
        return (
          <AddNewAutomationStepOneFour
            onPrev={handlePrev}
            initialValues={formData}
            handleLoginSubmit={handleLoginSubmit}
            followup_steps={undefined}
          />
        );
      default:
        return null;
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      event.target.tagName !== "TEXTAREA" &&
      event.target.tagName !== "INPUT"
    ) {
      event.preventDefault(); // Prevent default form submit behavior of the form when the user presses Enter
    }
  };

  return (
    <div className="px-4 mt-8">
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: semi-transparent background
            zIndex: 1000, // Ensure it's above other content
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <FormikContext.Provider value={{ formData }}>
        <div onKeyDown={handleKeyDown}>{renderStepForm()}</div>
      </FormikContext.Provider>
    </div>
  );
};

export default EditAutomationDetails;
