import React, { useState, useCallback } from "react";
import { Input, Button } from "antd";
import CustomModal from "../../../common/modal";
import InputField from "./InputField";
import TextError from "./TextError";

interface AddToListProps {
  name: string;
  values: any;
  form: any;
  placeHolder?: string;
  onChange: (value?: []) => void;
  listItems?: [];
  maxListLenght?: number;
  disabled?: boolean;
}

const AddProduct = ({
  inputValue,
  handleInputChange,
  handleKeyDown,
  placeHolder,
  disabled,
  handleAddClick,
  index, // Dynamically calculated index
}) => (
  <div className="flex items-center border border-gray-200 rounded-xl overflow-hidden">
    <Input
      type="text"
      name={`target_collab_product_comission_rates[${index}].product_id`}
      value={inputValue}
      onChange={(e) => handleInputChange(e.target.value)}
      placeholder={placeHolder}
      disabled={disabled}
      size="large"
      className="border-none flex-1 rounded-none px-4 py-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      onKeyDown={handleKeyDown}
    />
    <Button
      onClick={handleAddClick}
      type="primary"
      size="large"
      disabled={disabled}
      className="rounded-none px-6 h-[50px]"
    >
      Add
    </Button>
  </div>
);

const SingleProduct = ({
  item,
  index,
  form,
  listItems,
  name,
}: {
  item: string;
  index: number;
  form: any;
  listItems: any;
  name: string;
}) => (
  <div className="flex items-center border border-gray-200 rounded-lg p-4 shadow-sm bg-white hover:shadow-md transition-shadow duration-300">
    <div className="flex-1">
      <p className="text-sm font-medium text-gray-700 mb-2">
        Product ID: <span className="text-gray-900">{item}</span>
      </p>
      <InputField
        type="number"
        placeholder="0-80%"
        name={`target_collab_product_comission_rates[${index}].commission_rate`}
        min="0.00"
        max="80.00"
        step="0.01"
        size="sm"
        className="w-full border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
      />
    </div>
    <button
      type="button"
      className="ml-4 p-2 rounded-full bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
      onClick={() => {
        const updatedList = listItems.filter((_, i) => i !== index);
        form.setFieldValue(name, updatedList);
      }}
      aria-label="Remove Product"
    >
      &times;
    </button>
  </div>
);

const AddToList: React.FC<AddToListProps> = ({
  form = {},
  name,
  placeHolder,
  onChange,
  listItems = [], // Empty list by default
  maxListLenght = 100, // Default maximum list length is 100
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [openProductModal, setOpenProductModal] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value);
      if (error) setError(""); // Clear the error when the input changes
    },
    [error]
  );

  const handleAddClick = useCallback(() => {
    if (listItems.length >= maxListLenght) {
      setError("You cannot add more than 100 products.");
      return;
    }
  
    if (inputValue.trim()) {
      if (!listItems.includes(inputValue)) {
        if (/^\d+$/.test(inputValue)) {
          const newItems = [...listItems, inputValue];
          onChange(newItems); // Update parent state dynamically
          setInputValue("");
          form.setFieldValue(
            `target_collab_product_comission_rates[${listItems.length}].product_id`,
            inputValue
          );
        } else {
          setError("Please enter a valid input.");
        }
      } else {
        setError("This item is already in the list.");
      }
    } else {
      setError("Input cannot be empty.");
    }
  }, [inputValue, listItems, maxListLenght, onChange]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleAddClick();
      }
    },
    [handleAddClick]
  );

  return (
    <div className="space-y-4">
      <CustomModal
        visible={openProductModal}
        onCancel={() => setOpenProductModal(false)}
        title={
          <div>
            <div className="mb-2">Product Cards</div>
            <div className="border-b-2 font-normal border-gray-300 mb-5 text-sm">
              View all added product ID’s here.
            </div>
            <AddProduct
              inputValue={inputValue}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
              placeHolder={placeHolder}
              disabled={disabled}
              handleAddClick={handleAddClick}
              index={listItems.length}
            />
          </div>
        }
        showModalFooter={false}
        width={800}
        content={
          <div>
            <div
              className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 overflow-y-auto mb-10"
              style={{ maxHeight: "500px" }}
            >
              {listItems.map((item, index) => (
                <SingleProduct
                  item={item}
                  index={index}
                  key={index}
                  form={form}
                  listItems={listItems}
                  name={name}
                />
              ))}
            </div>
            <div className="flex justify-center gap-2 mt-4">
              <Button
                className="bg-blue-400 text-white w-full"
                style={{ height: 40 }}
                onClick={() => {
                  setOpenProductModal(false);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        }
      />
      <AddProduct
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        handleKeyDown={handleKeyDown}
        placeHolder={placeHolder}
        disabled={disabled}
        handleAddClick={handleAddClick}
      />
      <p className="text-sm text-gray-600">
        Total Products: <span className="font-medium">{listItems.length}</span>
      </p>
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {listItems.slice(0, 3).map((item, index) => (
          <SingleProduct
            item={item}
            index={index}
            key={index}
            form={form}
            listItems={listItems}
            name={name}
          />
        ))}
      </div>
      <div className="flex justify-between items-center mt-4">
        {listItems.length >= 4 && (
          <button
            type="button"
            onClick={() => setOpenProductModal(true)}
            className="border border-gray-200 py-2 px-4 rounded-md transition duration-300 w-full"
          >
            View All ({listItems.length} Products)
          </button>
        )}
      </div>
    </div>
  );
};

export default AddToList;
