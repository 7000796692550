import React, {useEffect, useRef, useState} from "react";
// @ts-ignore
import { FieldArray, FormikValues, Form, useFormikContext } from "formik";
// @ts-ignore
import { MdTipsAndUpdates } from "react-icons/md";
import FormikDatePickerComponent from "../../components/formikForms/FormikDatePicker";
import FormikSwitchComponent from "../../components/formikForms/FormikSwitch";
import FormikMultipleSelectField from "../../components/formikForms/FormikMultipleSelectField";
import FormikTextArea from "../../components/formikForms/FormikTextField";
import InputField from "../../components/forms/InputField";
import { CONTENT_PREFERENCE_OPTIONS } from "./defaultFormData";
import FormikAddToList from "../../components/formikForms/FormikAddToList";
// @ts-ignore
import { ArrowLeftIcon } from "@heroicons/react/16/solid";
import {Button, Tooltip} from "antd";
import MessageEntryTextField from "./MessageEntryTextField";

interface TargetCollabFullFormProps {
  values: FormikValues;
  shops: any;
  automationType: string;
  onPrev: () => void;
  isSubmitting: boolean;
  setFieldValue: any;
}

const TargetCollabFullForm: React.FC<TargetCollabFullFormProps> = ({
                                                                     values,
                                                                     shops,
                                                                     automationType,
                                                                     onPrev,
                                                                     isSubmitting,
                                                                     setFieldValue,
                                                                     errors
                                                                   }) => {
  console.log('errors12312312321', errors)
  const handleContentTypeChange = (type: string) => {
    setFieldValue("content_type", type);
  };

  const textAreaRef = useRef<any>(null);
  useEffect(() => {
    if (textAreaRef.current?.resizableTextArea?.textArea) {
      const textAreaElement = textAreaRef.current.resizableTextArea.textArea;
      setTimeout(() => {
        textAreaElement.focus();
      }, 0);
    }
  }, []);

  const addCreatorName = (setFieldValue: any, name: string) => {
    if (textAreaRef.current?.resizableTextArea?.textArea) {
      const textAreaElement = textAreaRef.current.resizableTextArea.textArea;

      // Get the cursor and selection details
      const start = textAreaElement.selectionStart;
      const end = textAreaElement.selectionEnd;
      const value = textAreaElement.value;

      // Replace selected text or insert at cursor position
      const newValue =
        value.slice(0, start) + "{{creators username}}" + value.slice(end);

      // Update the Formik field value
      setFieldValue(name, newValue);

      // Calculate the new cursor position after insertion/replacement
      const newCursorPosition = start + "{{creators username}}".length;

      // Set focus and adjust the cursor position after the update
      setTimeout(() => {
        textAreaElement.focus();
        textAreaElement.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    }
  };

  const messageEntryLabelTC = (setFieldValue, name) => (
    <div className="flex items-end justify-between space-x-2 text-gray-700 font-medium">
      <Tooltip title="Please avoid using the word 'amazon' in the message entry.">
        <div>
          <label className=" flex items-center  text-xs pl-1 font-semibold mt-6 text-gray-600">
            Message Body
          </label>
        </div>
      </Tooltip>
      <Button
        type="dashed"
        onClick={() => addCreatorName(setFieldValue, name)}
        style={{
          padding: '2px 8px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          color: '#1890ff',
          borderColor: '#1890ff',
        }}
      >
        Add {`{{creators username}}`}
      </Button>
    </div>
  );

  // @ts-ignore
  return (
    <Form className="mt-2 mb-2 w-full">
      <div className="  m-1 ml-4 mx-auto font-sans">
        {/*Invitation Section*/}
        <div className="flex flex-col md:flex-row ">
          {/* Left Section: Instruction Text */}
          <div className="md:w-2/5">
            <h3 className="text-xl font-medium text-gray-600 mb-4">
              Invitation Details
            </h3>
            <ul className="list-disc text-sm ml-5  space-y-2 text-gray-600">
              <li>
                <strong>Choose a name</strong> for this invitation that’s easy
                for you to remember. Creators won’t see this name.
              </li>
              <li>
                During the active period, creators can accept your invite to
                collaborate and promote your products on TikTok. If the invite
                expires, the shoppable video link will no longer work. To avoid
                this, we suggest setting the expiration date at least 3 months
                out.
              </li>
              <li>
                <strong>Commission rate:</strong> Open collaboration is set at{" "}
                <strong>10%</strong>.
              </li>
              <li>
                Set a higher commission rate to entice creators to promote that
                product. They will see a <strong>"higher commission"</strong>{" "}
                badge.
              </li>
            </ul>
          </div>

          {/* Right Section: Form Fields */}
          <div className="md:w-3/5 bg-white border border-gray-200 rounded-lg shadow-sm ml-5 p-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Invitation Name Field */}
              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-600 mb-2">
                  Invitation Name <span className="text-blue-500"> *</span>
                </label>
                <FormikTextArea
                  name="target_collab_invitation_name"
                  maxLength={25}
                  placeholder="Invitation name"
                  minimum_rows={1}
                  maximum_rows={1}
                  restProps={{
                    className:
                      "h-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500",
                  }}
                />
                <small className="block mt-1 text-sm text-gray-500">
                  {values.target_collab_invitation_name.length}/25 characters
                  left
                </small>
              </div>

              {/* Valid Until Field */}
              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700 mb-2 ">
                  Valid Until <span className="text-blue-500"> *</span>
                </label>
                <div className="mt-2">
                  <FormikDatePickerComponent
                    name="target_collab_valid_until"
                    restProps={{
                      className: "w-full h-[39px]",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product ID <span className="text-blue-500"> *</span>
              </label>
              <div className="flex gap-2">
                <FormikAddToList
                  name="product_id"
                  // label="Products"
                  placeHolder="i.e. 142735249054578"
                  listItems={values.product_id}
                  maxListLenght={20}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="mt-6 mb-6 border-t border-gray-300"></div>

        {/*Sample Section*/}
        <div className="mt-4 flex flex-col md:flex-row">
          {/*Left Section*/}
          <div className="md:w-2/5">
            <h3 className="text-lg font-medium text-gray-600 mb-2">
              Free Sample
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              Each invited creator can request a free sample of every product in
              the invitation, including any variations.
            </p>
          </div>

          {/*Right Section*/}
          <div className="md:w-3/5 ml-5">
            <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-sm">
              {/* Offer Free Samples Toggle */}
              <div className="flex items-center gap-3 mb-6">
                <label className="flex items-center gap-2">
                  <FormikSwitchComponent
                    name="target_collab_offer_free_samples"
                    checked={values.target_collab_offer_free_samples}
                  />
                  <span className="font-medium text-sm text-gray-600">
                    Offer free samples
                  </span>
                </label>
              </div>

              <div className="flex items-center gap-6 mb-6">
                {/* Radio Buttons for Manual and Auto Approve */}
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="target_collab_approval_method"
                    value="manual"
                    checked={values.target_collab_manually_approve}
                    disabled={!values.target_collab_offer_free_samples}
                    onChange={() =>
                      setFieldValue("target_collab_manually_approve", true)
                    }
                    className="form-radio w-4 h-4 accent-blue-600"
                  />
                  <span
                    className={`text-sm ${
                      values.target_collab_offer_free_samples
                        ? "text-gray-700"
                        : "text-gray-400"
                    }`}
                  >
                    Manually approve requests
                  </span>
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="target_collab_approval_method"
                    value="auto"
                    checked={!values.target_collab_manually_approve}
                    disabled={!values.target_collab_offer_free_samples}
                    onChange={() =>
                      setFieldValue("target_collab_manually_approve", false)
                    }
                    className="form-radio w-4 h-4 accent-blue-600"
                  />
                  <span
                    className={`text-sm ${
                      values.target_collab_offer_free_samples
                        ? "text-gray-700"
                        : "text-gray-400"
                    }`}
                  >
                    Auto approve requests
                  </span>
                </label>
              </div>

              {/* Preferred Content Type */}
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Preferred Content Type
                </label>
                <div className="flex gap-3">
                  {/* No Preference Button */}
                  <button
                    type="button"
                    onClick={() => handleContentTypeChange("No preference")}
                    className={`px-4 py-2 rounded-md border ${
                      values.content_type === "No preference"
                        ? "bg-blue-600 text-white"
                        : "border-gray-300 text-gray-700"
                    }`}
                  >
                    No preference
                  </button>

                  {/* Shoppable Video Button */}
                  <button
                    type="button"
                    onClick={() => handleContentTypeChange("Shoppable video")}
                    className={`px-4 py-2 rounded-md border ${
                      values.content_type === "Shoppable video"
                        ? "bg-blue-600 text-white"
                        : "border-gray-300 text-gray-700"
                    }`}
                  >
                    Shoppable Video
                  </button>

                  {/* Shoppable Live Button */}
                  <button
                    type="button"
                    onClick={() => handleContentTypeChange("Shoppable LIVE")}
                    className={`px-4 py-2 rounded-md border ${
                      values.content_type === "Shoppable LIVE"
                        ? "bg-blue-600 text-white"
                        : "border-gray-300 text-gray-700"
                    }`}
                  >
                    Shoppable Live
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="mt-6 mb-6 border-t border-gray-300"></div>
        {/* Email Section */}

        <div className="flex flex-col md:flex-row ">
          {/* Left: Description Section */}
          <div className="md:w-2/5">
            <h3 className="text-lg font-medium text-gray-600 mb-2">
              Contact Information
            </h3>
            <ul className="list-disc pl-5 text-sm text-gray-600 space-y-1">
              <li>Add your contact info to connect with creators.</li>
              <li>Phone number is optional.</li>
              <li>
                Please enter the phone number without the area code. Example:{" "}
                <strong>4081234567</strong>
              </li>
            </ul>
          </div>

          {/* Right: Input Fields */}
          <div className="md:w-3/5 ml-5 ">
            <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-sm w-full ">
              {/* Email Input */}
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email Address <span className="text-blue-500"> *</span>
                </label>
                <div className="">
                  {/*ToDo: WIll add logo */}
                  {/*<span className="absolute inset-y-0 left-0 flex items-center  text-gray-400">*/}
                  {/*  📧*/}
                  {/*</span>*/}
                  {/*<div className="ml-10">*/}
                  <InputField
                    type="text"
                    placeholder="example@email.com"
                    name="target_collab_email"
                    size="sm"
                  />
                  {/*</div>*/}
                </div>
              </div>

              {/* Phone Number Input */}
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Phone Number
                </label>
                <div className="w-full">
                  {/* Country Code Selector */}
                  {/*ToDo Add Country Prefix*/}
                  {/*<select*/}
                  {/*  className="border border-gray-300 rounded-l-md px-4 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"*/}
                  {/*  defaultValue="US"*/}
                  {/*>*/}
                  {/*  <option value="US">US</option>*/}
                  {/*  <option value="CA">CA</option>*/}
                  {/*  <option value="UK">UK</option>*/}
                  {/*  <option value="IN">IN</option>*/}
                  {/*</select>*/}

                  {/* Phone Number Input */}
                  <InputField
                    type="text"
                    placeholder="4821234567"
                    name="target_collab_phone"
                    size="sm"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Divider */}
        <div className="mt-6 mb-6 border-t border-gray-300"></div>
        <div>
          <div className="flex flex-col md:flex-row space-y-5 md:space-y-0">
            {/* Left Section: Description */}
            <div className="md:w-2/5">
              <h3 className="text-lg font-medium text-gray-600 mb-2">
                Target Collaboration Message
              </h3>
              <ul className="list-disc pl-5 text-sm text-gray-600 space-y-1">
                <li>
                  Send a message to introduce yourself and share a bit about
                  why you're excited to collaborate.
                </li>
                <li>
                  You can use <code>{`{{creators username}}`}</code> to send a message with the creator's name!

                </li>
                <li>
                  <strong>Note:</strong> The word "Amazon" is not allowed due
                  to TikTok rules.
                </li>
              </ul>
            </div>

            {/* Right Section: Message Input */}
            <div className="md:w-3/5 ml-5">
              <div className=" p-6 bg-white border border-gray-200 rounded-lg shadow-sm w-full">
                <div className="w-full ">
                  <div className="items-end justify-between space-x-2 text-gray-700 font-medium">
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      Message <span className="text-blue-500"> *</span>
                    </label>

                  </div>
                  <FormikTextArea name="message_entry_target_collab" maxLength={1800} newLabel={messageEntryLabelTC}  restProps={{ref: textAreaRef}}/>
                </div>
              </div>
            </div>
          </div>
          {/* Divider */}
          <div className="mt-6 mb-6 border-t border-gray-300"></div>


        </div>
        {/*Message Section*/}
        {automationType === "Message + Target Collab + Target Collab Card" && (
          <div>
            <div className="flex flex-col md:flex-row space-y-5 md:space-y-0">
              {/* Left Section: Description */}
              <div className="md:w-2/5">
                <h3 className="text-lg font-medium text-gray-600 mb-2">
                  Direct Message
                </h3>
                <ul className="list-disc pl-5 text-sm text-gray-600 space-y-1">
                  <li>
                    You can use <code>{`{creator_name}`}</code> to send a message with the creator's name!
                  </li>
                </ul>
              </div>

              {/* Right Section: Message Input */}
              <div className="md:w-3/5 ml-5">
                <div className=" p-6 bg-white border border-gray-200 rounded-lg shadow-sm w-full">
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      Message <span className="text-blue-500"> *</span>
                    </label>
                    {/*<FormikTextArea name="message_entry"  newLabel={messageEntryLabel} maxLength={1800}  restProps={{ref: textAreaRef}}/>*/}
                    <MessageEntryTextField field_name={"tc_dm_message"} />

                  </div>
                </div>
              </div>
            </div>
            {/* Divider */}
            <div className="mt-6 mb-6 border-t border-gray-300"></div>


          </div>
        )}


        {/*Need to add followup module*/}
      </div>
      {/*Steps Buttons*/}
      <div className="flex justify-between items-center mt-10">
        <div className="flex flex-row">
          <button
            className="bg-gray-100 flex items-center p-10 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded-md focus:outline-none"
            onClick={onPrev}
            type="button"
          >
            <ArrowLeftIcon className="w-4 h-4 ml-2"/>
            Previous
          </button>
        </div>
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300"
          type="submit"
        >
          Save & Exit
        </button>
      </div>
    </Form>
  );
};

export default TargetCollabFullForm;

// Old Design for reference

// <div className={group?.group ? group?.className : ''}>
//   <div className="flex flex-row">
//     {/* Left Form Section */}
//     <div className="w-1/2 p-6">
//       <h3 className="text-1xl font-bold mb-4">Edit Target Collaboration</h3>
//       <div className="bg-white p-6 rounded-lg shadow-md border border-gray-300">
//         <h1 className="text-2xl text-blue-500 font-bold mb-8">
//           {shops.selectedStoreName}'s Invite
//         </h1>
//         <div className="flex items-center mb-2">
//           <label className="block text-sm font-semibold text-black mr-2 mb-6">
//             Invitation Name: <span className="text-red-500">*</span>
//           </label>
//           <FormikTextArea
//             name="target_collab_invitation_name"
//             maxLength={25}
//             placeholder="i.e. My Invitation"
//             minimum_rows={1}
//             maximum_rows={1}
//           />
//         </div>
//         <div className="flex items-center">
//           <label className="block text-sm font-semibold text-black mr-2 mb-6">
//             Email: <span className="text-red-500">*</span>
//           </label>
//           <div className="ml-14 pl-3">
//             <InputField
//               type="text"
//               placeholder="i.e. email@email.com"
//               name="target_collab_email"
//               size="sm"
//             />
//           </div>
//         </div>
//         <div className="flex items-center">
//           <label className="block text-sm font-semibold text-black mr-2 mb-6 ">
//             Phone Number:
//           </label>
//           <div className="ml-3">
//             <InputField
//               type="text"
//               placeholder="i.e. 4821234567"
//               name="target_collab_phone"
//               size="sm"
//             />
//           </div>
//         </div>
//         <div className="flex items-center">
//           <label className="block text-sm font-semibold text-black mr-2 pb-6">
//             Valid Until: <span className="text-red-500">*</span>
//           </label>
//           <div className="mb-6 ml-9">
//             <FormikDatePickerComponent name="target_collab_valid_until"/>
//           </div>
//         </div>
//         <div className="mb-6">
//           <label className="block text-sm font-semibold text-black mr-2 pb-4">
//             Message: <span className="text-red-500">*</span>
//           </label>
//           <div>
//             <FormikTextArea
//               name="message_entry_target_collab"
//               maxLength={500}
//             />
//           </div>
//         </div>
//         {/*<div className="flex items-center">*/}
//         {/*  <label className="block text-sm font-semibold text-black mr-2 pb-6">*/}
//         {/*    Offer Free Samples?*/}
//         {/*  </label>*/}
//         {/*  <div className="mb-6 ml-2">*/}
//         {/*    <FormikSwitchComponent*/}
//         {/*      name="target_collab_offer_free_samples"*/}
//         {/*      checked={values.target_collab_offer_free_samples}*/}
//         {/*    />*/}
//         {/*  </div>*/}
//         {/*</div>*/}
//         {/*<div className="flex items-center">*/}
//         {/*  <label className="block text-sm font-semibold text-black mr-2 pb-6">*/}
//         {/*    Manually Approve Free Samples?*/}
//         {/*  </label>*/}
//         {/*  <div className="mb-6 ml-2">*/}
//         {/*    <FormikSwitchComponent*/}
//         {/*      name="target_collab_manually_approve"*/}
//         {/*      disabled={!values.target_collab_offer_free_samples}*/}
//         {/*      checked={values.target_collab_manually_approve}*/}
//         {/*    />*/}
//         {/*  </div>*/}
//         {/*</div>*/}
//         <div>
//           <h3 className="font-bold mb-4 mt-4">Commission Rates:</h3>
//           <FieldArray
//             name="target_collab_product_comission_rates"
//             render={(arrayHelpers) => (
//               <div>
//                 {values.target_collab_product_comission_rates.map(
//                   (product, index) => (
//                     <div
//                       className="flex items-center mb-2"
//                       key={product.product_id}
//                     >
//                       <label className="block text-sm font-semibold text-black mr-2 pb-6">
//                         Product ID: {product.product_id}
//                       </label>
//                       <InputField
//                         type="number"
//                         placeholder="Enter commission rate"
//                         name={`target_collab_product_comission_rates[${index}].commission_rate`}
//                         min="0.00"
//                         max="80.00"
//                         step="0.01"
//                         size="sm"
//                       />
//                     </div>
//                   )
//                 )}
//               </div>
//             )}
//           />
//         </div>
//         <div className="mb-4 w-64">
//           <FormikMultipleSelectField
//             name="content_type"
//             placeHolder="Preferred Content Type"
//             options={CONTENT_PREFERENCE_OPTIONS}
//             label="Preferred Content Type"
//             isMultiple={false}
//           />
//         </div>
//       </div>
//     </div>
//
//     {/*/!* Right Tips Section *!/*/}
//     {/*<div className="w-1/2 p-6">*/}
//     {/*  <h3 className="block flex items-center text-1xl font-bold mb-4">*/}
//     {/*    <MdTipsAndUpdates className="mr-2"/> Tips and Tricks*/}
//     {/*  </h3>*/}
//     {/*  <div className="bg-white p-6 rounded-lg shadow-md border border-gray-300">*/}
//     {/*    <label className="block text-sm font-semibold text-black">*/}
//     {/*      Invitation Name:*/}
//     {/*    </label>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-gray-700">*/}
//     {/*      Give this invitation a name that is easy to remember and*/}
//     {/*      understand.*/}
//     {/*    </p>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-gray-700 mb-6">*/}
//     {/*      Creators won't see this name.*/}
//     {/*    </p>*/}
//     {/*    <label className="block text-sm font-semibold text-black">*/}
//     {/*      Valid Until:*/}
//     {/*    </label>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-gray-700 mb-6">*/}
//     {/*      During the valid period, creators can accept your invitation to*/}
//     {/*      collaborate and promote your products on TikTok.*/}
//     {/*    </p>*/}
//     {/*    <label className="block text-sm font-semibold text-black">*/}
//     {/*      Contact Info (Email and Phone Number):*/}
//     {/*    </label>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-gray-700">*/}
//     {/*      Add your contact info to connect with creators.*/}
//     {/*    </p>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-gray-700">*/}
//     {/*      Phone number is optional.*/}
//     {/*    </p>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-gray-700 mb-6">*/}
//     {/*      Please enter the phone number without the area code. Example:*/}
//     {/*      4081234567*/}
//     {/*    </p>*/}
//     {/*    <label className="block text-sm font-semibold text-black">*/}
//     {/*      Message:*/}
//     {/*    </label>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-gray-700">*/}
//     {/*      Send a message to introduce yourself and share a bit about why*/}
//     {/*      you're excited to collaborate.*/}
//     {/*    </p>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-red-700 mb-6">*/}
//     {/*      You can use {"{creator_name}"} to send a message with the*/}
//     {/*      creator's username!*/}
//     {/*    </p>*/}
//     {/*    <label className="block text-sm font-semibold text-black">*/}
//     {/*      Commission Rates:*/}
//     {/*    </label>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-gray-700">*/}
//     {/*      Commission rate in open collaboration: 10%*/}
//     {/*    </p>*/}
//     {/*    <p className="before:content-['●'] before:mr-2 text-gray-700 mb-6">*/}
//     {/*      Set a higher commission rate to entice creators to promote that*/}
//     {/*      product. They will see a "higher commission" badge.*/}
//     {/*    </p>*/}
//     {/*  </div>*/}
//     {/*</div>*/}
//   </div>
//
//   {/* Conditional Creator DM Message */}
//   {automationType === "Message + Target Collab + Target Collab Card" && (
//     <>
//       <label className="block text-sm font-semibold text-black">
//         Creator DM Message:
//       </label>
//       <p className="before:content-['●'] before:mr-2 text-gray-700">
//         This message will be sent to creators after the target collab
//         invitation, and is sent through Creator DM.
//       </p>
//       <div className="mb-6">
//         <label className="block text-sm font-semibold text-black mr-2 pb-4">
//           Creator DM Message: <span className="text-red-500">*</span>
//         </label>
//         <FormikTextArea name="tc_dm_message" maxLength={1800}/>
//       </div>
//     </>
//   )}
//   {!group?.group && (
//     <div className="flex justify-between mb-4 w-full mt-6">
//       <button
//         className=" bg-black hover:bg-gray-900 text-white font-semibold py-3 px-4 rounded-md focus:outline-none focus:shadow-outline"
//         onClick={onPrev}
//       >
//         Back
//       </button>
//       <button
//         className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out"
//         type="submit"
//         disabled={isSubmitting}
//       >
//         Next
//       </button>
//     </div>
//   )}
// </div>
// {group?.content || <div/>}
