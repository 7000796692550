import React, { useEffect, useRef } from "react";
import { Button, Tooltip, Select, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import FormikTextArea from "../../../pages/components/formikForms/FormikTextField";
import { useAppDispatch, useAppSelector } from "../../../pages/hooks";
import { getVideoGroups } from "../../../pages/store/videoGroups";

const { Text } = Typography;
const { Option } = Select;

const ContentFlywheelingMessage: React.FC = () => {
  const textAreaRef = useRef<any>(null);
  const shops = useAppSelector((state) => state.shops);
  const navigate = useNavigate();
  const videoGroups = useAppSelector((state) => state.videoGroups);
  const flywheels = Object.values(
    videoGroups.videoGroups[shops.selectedStoreId] || {}
  ).map((el) => ({
    id: el.id,
    name: el.group_name,
    url: `${window.location.origin}/content-flywheeling/creator/${el.uuid}`,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shops.selectedStoreId) {
      dispatch(getVideoGroups({ shop_id: shops.selectedStoreId }));
    }
  }, [shops.selectedStoreId]);

  useEffect(() => {
    if (textAreaRef.current?.resizableTextArea?.textArea) {
      const textAreaElement = textAreaRef.current.resizableTextArea.textArea;
      setTimeout(() => {
        textAreaElement.focus();
      }, 0);
    }
  }, []);

  const addToTextArea = (
    setFieldValue: any,
    name: string,
    textToAdd: string
  ) => {
    if (textAreaRef.current?.resizableTextArea?.textArea) {
      const textAreaElement = textAreaRef.current.resizableTextArea.textArea;

      const start = textAreaElement.selectionStart;
      const end = textAreaElement.selectionEnd;
      const value = textAreaElement.value;

      const newValue = value.slice(0, start) + textToAdd + value.slice(end);

      setFieldValue(name, newValue);

      const newCursorPosition = start + textToAdd.length;

      setTimeout(() => {
        textAreaElement.focus();
        textAreaElement.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    }
  };

  const messageEntryLabel = (setFieldValue, name) => (
    <div className="flex flex-col space-y-2 text-gray-700 font-medium">
      <div>
        <Tooltip title="Please avoid using the word 'amazon' in the message entry.">
          <label className="flex items-center text-sm font-semibold text-gray-700">
            Message Body
          </label>
        </Tooltip>
        <Text type="secondary" className="text-xs pl-1">
          Add content flywheeling URLs to your message to share curated content
          with creators.
        </Text>
      </div>
      <div className="flex items-center space-x-2">
        <Button
          type="dashed"
          onClick={() => addToTextArea(setFieldValue, name, "{creator_name}")}
          style={{
            padding: "2px 8px",
            fontSize: "14px",
            color: "#1890ff",
            borderColor: "#1890ff",
          }}
        >
          Add {`{creator_name}`}
        </Button>
        {flywheels.length > 0 && (
          <Select
            placeholder="Select Flywheel"
            style={{ minWidth: "300px" }} // Adjust width
            dropdownStyle={{ maxHeight: "300px", overflow: "auto" }} // Dropdown height
            onSelect={(value: string) =>
              value.startsWith("append")
                ? addToTextArea(setFieldValue, name, value.split(":")[1])
                : window.open(value.split(":")[1], "_blank")
            }
          >
            {flywheels.map((flywheel) => (
              <Option key={flywheel.id} value={`open:${flywheel.url}`}>
                <div className="flex justify-between items-center">
                  <span style={{ color: "#40a9ff" }}>Open {flywheel.name}</span>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      addToTextArea(setFieldValue, name, flywheel.url);
                    }}
                    style={{
                      color: "#52c41a",
                      cursor: "pointer",
                      marginLeft: "12px",
                    }}
                  >
                    Append URL
                  </span>
                </div>
              </Option>
            ))}
          </Select>
        )}

        <div className="flex flex-col items-center space-y-2">
          <Button
            type="primary"
            onClick={() => {
              navigate("/content-flywheels/create");
            }}
          >
            Create Content Flywheel
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <FormikTextArea
      name="message_entry_content_flywheeling"
      newLabel={messageEntryLabel}
      maxLength={2000}
      helperText='Make sure to include "{creator_name}" or append content flywheeling links for better personalization.'
      restProps={{ ref: textAreaRef }}
    />
  );
};

export default ContentFlywheelingMessage;
