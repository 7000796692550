// AutomationForm.tsx
import React, { useEffect } from "react";
// @ts-ignore
import { Form, FormikValues } from "formik";
import InputField from "../../components/forms/InputField";
import { AUTOMATION_TYPE_OPTIONS, OUTREACH_TYPES } from "./defaultFormData";
import { useAppDispatch, useAppSelector } from "../../../pages/hooks";
import { getRegionBasedFilters } from "../../../pages/store/automation";
import FormikRadioButtonGroup from "../../../pages/components/formikForms/RadioButtonsFormik";
import { ArrowRightIcon } from "@heroicons/react/16/solid";
import FormikCheckbox from "../../../pages/components/formikForms/CheckBoxFormik";

interface AutomationFormProps {
  values: FormikValues;
  setFieldValue: any;
  validateField: any;
  shops: any;
  isSubmitting: boolean;
}

const AutomationForm: React.FC<AutomationFormProps> = ({values}) => {
  return (
    <Form>
      <div className="flex h-min flex flex-column justify-center ">
        {/* Left Section */}
        <div className="w-[492px]">
          <div>
            <div className="mb-2 pr-5">
              <label
                htmlFor="1"
                className="block text-sm  font-medium text-gray-700 mb-1"
              >
                Automation Name
                <span className="text-blue-500"> *</span>
              </label>
              <InputField
                type="text"
                placeholder="Enter Automation Name"
                name="automation_name"
                className=" border font-medium border-gray-300 rounded-lg shadow-sm "
              />
            </div>
            <div className="mb-2">
              <FormikRadioButtonGroup
                name="automation_type"
                options={AUTOMATION_TYPE_OPTIONS}
                consistentLabel="Automation Type"
              />
            </div>
            <div className="mb-2">
              <FormikRadioButtonGroup
                name="outreach_type"
                options={OUTREACH_TYPES}
                consistentLabel="Outreach Type"
              />
            </div>
            {(values.automation_type === "Message" ||
              values.automation_type === "Message + Image" ||
              values.automation_type === "Message + Product Card") && (
              <div className="mb-2">
                <FormikCheckbox
                  name="send_messages_to_all_creators_even_if_messaged_before"
                  label="Send messages to all creators in this automation even if they have been messaged before"
                  consistentLabel="Sent to All"
                />
              </div>
            )}
            {/* {
              { value: "Message", label: "Message" },
              { value: "Message + Image", label: "Message + Image" },
              { value: "Message + Product Card", label: "Message + Product Card" },
            } */}
          </div>
          <div className=" flex mt-10 justify-end bottom-6 right-6 sm:bottom-8 sm:right-8">
            <button
              type="submit"
              className="flex items-center px-5 py-3 bg-blue-600 text-white text-sm font-medium rounded-lg shadow-lg hover:bg-blue-700 hover:scale-105 transform transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Continue
              <ArrowRightIcon className="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AutomationForm;
