import React from "react";
import { AiOutlineShop } from "react-icons/ai";
import { capitalizeWords, getStatusColor, labels } from "../my-creators/helper";

interface GroupDataProps {
  shop_id: number;
  group_name: string;
  filter_config: object[];
  creator_count: number;
  gmv: number;
  group_tags: string[] | null;
  follower_count?: string;
}

const CustomGroupComponent: React.FC<GroupDataProps> = ({
  group_name,
  filter_config,
  creator_count = "",
  gmv = "",
  group_tags,
}) => {
  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-md">
      {/* Group Name */}
      <p className="text-sm mb-5">Group Details:</p>
      <div className="mb-3">
        <h3 className="text-lg font-bold text-gray-800 flex items-center mb-4">
          <AiOutlineShop className="mr-2 text-gray-700" />
          {group_name}
        </h3>
      </div>

      {/* Creator Count and GMV */}
      <div className="flex justify-between gap-6 mb-6">
        <div className="border rounded-lg p-4 bg-white w-full max-w-[250px]">
          <p className="text-sm">Creator Count:</p>
          <p className="text-2xl font-bold text-gray-900 mt-2">
            {creator_count.toLocaleString()}
          </p>
        </div>
        <div className="border rounded-lg p-4 bg-white w-full max-w-[250px]">
          <p className="text-sm">GMV:</p>
          <p className="text-2xl font-bold text-gray-900 mt-2">
            ${gmv.toLocaleString()}
          </p>
        </div>
      </div>

      {/* Filter Config */}
      <div>
        {filter_config.map((block, blockIndex) => (
          <div key={`filter-config-block-${blockIndex}`} className="mb-6">
            {/* Block of conditions */}
            <div className="bg-white border rounded-lg p-4 shadow-sm">
              {block.conditions.map((condition, conditionIndex) => {
                if (condition.filterKey && condition.selectedOption) {
                  const humanizedFilter = `${labels[condition.filterKey] || condition.filterKey}: ${
                    condition.isNot ? "(is not)" : "(is)"
                  }`;

                  return (
                    <div
                      key={`filter-condition-${blockIndex}-${conditionIndex}`}
                      className="mb-4"
                    >
                      <div className="flex flex-row">
                        <p className="text-sm font-medium text-gray-800">
                          {humanizedFilter}&nbsp;
                        </p>
                        <p className="text-sm font-bold text-gray-800">
                          {condition.selectedOption}
                        </p>
                      </div>

                      {conditionIndex < block.conditions.length - 1 && (
                        <div className="mt-2">
                          <span className="text-sm text-white bg-black px-1 py-1 rounded-lg">
                            AND
                          </span>
                        </div>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </div>

            {/* OR between filter blocks */}
            {blockIndex < filter_config.length - 1 && (
              <div className="flex items-center justify-center my-4">
                <div className="flex-grow border-t border-gray-300"></div>
                <span className="mx-4 text-sm font-bold text-gray-700">OR</span>
                <div className="flex-grow border-t border-gray-300"></div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Group Tags */}
      {group_tags && (
        <div className="mt-4">
          <h4 className="font-semibold">Group Tags:</h4>
          <div className="mt-2 flex flex-wrap gap-2">
            {group_tags.length > 0 ? (
              group_tags.map((tag) => (
                <span
                  key={tag}
                  className="bg-gray-100 text-gray-800 text-sm font-semibold px-2.5 py-1 rounded"
                >
                  {tag}
                </span>
              ))
            ) : (
              <span className="bg-gray-100 text-gray-500 text-sm font-semibold px-2.5 py-1 rounded">
                No Group Tags
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomGroupComponent;
