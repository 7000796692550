import React, { useState } from "react";
import { Button, Modal, Segmented } from "antd";
import WeeklyAutomationSchedule from "./WeeklyAutomationSchedule";

export const SchedulingSettingsButton = ({
  setOpenModal,
  openModal,
  weeklyAutomationScheduleProps,
}) => {
  const format = "HH:mm";
  const timePickerStyles = {
    outline: "none", // Remove default outline
    boxShadow: "none", // Remove default shadow
    border: "1px solid #e5e7eb", // Border to match react-select
    padding: "8px", // Add some padding
    width: "86%", // Set width to 86%
    borderRadius: "4px", // Match rounded corners
    color: "gray", // Text color
    fontSize: "1rem", // Font size
    lineHeight: "1.5", // Line height
  };
  const inputStyles = {
    padding: "10px", // Add padding
    width: "86%", // Ensure it takes full width
    height: "40px", // Set a fixed height to match TimePicker
    color: "gray", // Text color
    fontSize: "1rem", // Font size to match TimePicker
    lineHeight: "1.5", // Line height to match TimePicker
  };
  return (
    <>
      <Modal
        title=""
        centered
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        width={800} // Adjust width as needed
      >
        {/* WeeklyAutomationSchedule Component */}
        <WeeklyAutomationSchedule
          {...weeklyAutomationScheduleProps}
          inputStyles={inputStyles}
          format={format}
          timePickerStyles={timePickerStyles}
        />
      </Modal>
      <Button
        onClick={setOpenModal}
        className="border border-gray-300 rounded-lg px-6 py-2 text-gray-800 font-medium shadow-sm hover:shadow-md transition-shadow duration-200"
      >
        Scheduling Settings
      </Button>
    </>
  );
};

const FilterToggle = ({
  selectedOption = "By Filters",
  setSelectedOption,
  weeklyAutomationScheduleProps = {},
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="flex items-center my-10 justify-between">
      {/* Segmented Control for Toggle */}
      <Segmented
        options={["By Filters", "List Upload(OPTIONAL)"]}
        value={selectedOption}
        onChange={(value) => setSelectedOption(value)}
        className="custom-segmented"
      />

      {/* Scheduling Settings Button */}
      <SchedulingSettingsButton
        weeklyAutomationScheduleProps={weeklyAutomationScheduleProps}
        setOpenModal={setOpenModal}
        openModal={openModal}
      />
    </div>
  );
};

export default FilterToggle;
