const UnslectedProgressIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1055_10798)">
        <path d="M0.5 12C0.5 5.37258 5.87258 0 12.5 0C19.1274 0 24.5 5.37258 24.5 12C24.5 18.6274 19.1274 24 12.5 24C5.87258 24 0.5 18.6274 0.5 12Z" fill="#F9FAFB" />
        <path d="M1.25 12C1.25 5.7868 6.2868 0.75 12.5 0.75C18.7132 0.75 23.75 5.7868 23.75 12C23.75 18.2132 18.7132 23.25 12.5 23.25C6.2868 23.25 1.25 18.2132 1.25 12Z" stroke="#E4E7EC" stroke-width="1.5" />
        <circle cx="12.5" cy="12" r="4" fill="#D0D5DD" />
      </g>
      <defs>
        <clipPath id="clip0_1055_10798">
          <path d="M0.5 12C0.5 5.37258 5.87258 0 12.5 0C19.1274 0 24.5 5.37258 24.5 12C24.5 18.6274 19.1274 24 12.5 24C5.87258 24 0.5 18.6274 0.5 12Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnslectedProgressIcon;
