const Confetti = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_564_24731)">
      <path d="M0 10C0 4.47715 4.47715 0 10 0H38C43.5229 0 48 4.47715 48 10V38C48 43.5229 43.5228 48 38 48H10C4.47715 48 0 43.5228 0 38V10Z" fill="url(#paint0_linear_564_24731)" />
      <g clip-path="url(#clip0_564_24731)">
        <path d="M29.7442 28.67L14.1144 35.4709C13.0956 35.8763 12.0831 34.8726 12.4797 33.8503L19.3098 17.9745" fill="#FFB636" />
        <path d="M12.4806 33.8504L13.0234 32.5887L13.1629 32.3757C15.8628 28.2627 18.6022 24.1748 21.4352 20.1522L24.6117 23.4082C22.215 25.0536 14.4328 33.0958 12.8027 35.229C12.4479 34.9026 12.2754 34.3792 12.4806 33.8504Z" fill="#FFD469" />
        <path d="M26.268 21.5818C29.1674 24.4812 30.7533 27.5963 29.8101 28.5395C28.8669 29.4826 25.7519 27.8968 22.8524 24.9974C19.953 22.098 18.3672 18.983 19.3104 18.0398C20.2535 17.0965 23.3686 18.6824 26.268 21.5818Z" fill="#A06C33" />
        <path d="M19.2939 27.3539C19.2939 27.6357 19.0654 27.8641 18.7837 27.8641C18.5019 27.8641 18.2734 27.6357 18.2734 27.3539C18.2734 27.072 18.5019 26.8436 18.7837 26.8436C19.0655 26.8436 19.2939 27.072 19.2939 27.3539Z" fill="#F7F9AA" />
        <path d="M35.5306 21.9919C35.5306 22.3964 35.2028 22.7242 34.7983 22.7242C34.3939 22.7242 34.0661 22.3964 34.0661 21.9919C34.0661 21.5875 34.3939 21.2596 34.7983 21.2596C35.2028 21.2596 35.5306 21.5875 35.5306 21.9919ZM30.6405 31.743C30.236 31.743 29.9082 32.0708 29.9082 32.4752C29.9082 32.8797 30.236 33.2075 30.6405 33.2075C31.0449 33.2075 31.3728 32.8797 31.3728 32.4752C31.3728 32.0708 31.0449 31.743 30.6405 31.743Z" fill="#FFB636" />
        <path d="M28.6854 15.7283C28.6226 16.1532 28.348 16.5285 27.9321 16.758C27.6449 16.9164 27.3073 16.9988 26.9568 16.9988C26.8451 16.9987 26.7336 16.9905 26.6232 16.9741C26.3753 16.9375 26.1272 16.9749 25.9425 17.0768C25.8598 17.1224 25.7175 17.2223 25.6944 17.3785C25.6714 17.5348 25.7786 17.6716 25.8446 17.7392C25.9879 17.8861 26.2067 17.9914 26.4469 18.0308C26.4535 18.0315 26.46 18.0317 26.4666 18.0327C27.48 18.1825 28.2039 18.9852 28.0801 19.822C28.0173 20.247 27.7428 20.6223 27.3268 20.8518C27.0396 21.0103 26.702 21.0926 26.3515 21.0926C26.2399 21.0925 26.1284 21.0843 26.0179 21.068C25.7701 21.0313 25.5219 21.0687 25.3372 21.1706C25.2545 21.2162 25.1122 21.3161 25.0891 21.4724C25.0521 21.7231 25.36 22.0541 25.8612 22.1282C26.1338 22.1685 26.3222 22.4222 26.2819 22.695C26.2453 22.9429 26.0322 23.1211 25.7888 23.1211C25.7644 23.1211 25.7399 23.1193 25.7151 23.1157C24.7018 22.9659 23.978 22.1632 24.1017 21.3264C24.1645 20.9014 24.439 20.5261 24.855 20.2966C25.2326 20.0882 25.6974 20.0115 26.1639 20.0804C26.4118 20.117 26.6599 20.0797 26.8445 19.9778C26.9273 19.9321 27.0695 19.8323 27.0927 19.676C27.1291 19.4294 26.8312 19.1055 26.3444 19.0246C26.3364 19.0238 26.3284 19.0231 26.3204 19.0219C25.8539 18.9529 25.4311 18.745 25.13 18.4363C24.7983 18.0963 24.644 17.6575 24.7069 17.2325C24.7697 16.8076 25.0443 16.4323 25.4602 16.2028C25.8378 15.9945 26.3026 15.9177 26.7691 15.9866C27.0169 16.0232 27.2651 15.9859 27.4498 15.884C27.5325 15.8384 27.6748 15.7385 27.6979 15.5822C27.7209 15.426 27.6137 15.2892 27.5477 15.2216C27.4004 15.0706 27.1738 14.963 26.9258 14.9264C26.6532 14.8861 26.4648 14.6324 26.5051 14.3596C26.5454 14.0869 26.7992 13.8988 27.0718 13.9389C27.5383 14.0079 27.9611 14.2158 28.2622 14.5245C28.594 14.8645 28.7482 15.3033 28.6854 15.7283Z" fill="#BEA4FF" />
        <path d="M15.8315 19.486C15.8315 19.9443 15.46 20.3158 15.0017 20.3158C14.5434 20.3158 14.1719 19.9443 14.1719 19.486C14.1719 19.0277 14.5434 18.6561 15.0017 18.6561C15.46 18.6561 15.8315 19.0276 15.8315 19.486ZM30.6649 18.5272C30.1354 18.5272 29.7062 18.9565 29.7062 19.4859C29.7062 20.0154 30.1354 20.4446 30.6649 20.4446C31.1943 20.4446 31.6236 20.0154 31.6236 19.4859C31.6236 18.9565 31.1944 18.5272 30.6649 18.5272ZM22.1033 30.5583C21.5738 30.5583 21.1446 30.9875 21.1446 31.517C21.1446 32.0464 21.5739 32.4757 22.1033 32.4757C22.6328 32.4757 23.062 32.0464 23.062 31.517C23.062 30.9875 22.6328 30.5583 22.1033 30.5583ZM34.8206 26.1686C34.9656 25.8879 34.8556 25.5428 34.5748 25.3978C34.4629 25.34 34.3293 25.2633 34.1746 25.1746C32.8156 24.3949 29.9878 22.7726 26.6581 25.461C26.4122 25.6594 26.3739 26.0197 26.5724 26.2655C26.7708 26.5113 27.131 26.5498 27.3769 26.3512C30.0973 24.1548 32.2932 25.4144 33.6052 26.1671C33.7647 26.2586 33.9154 26.345 34.0497 26.4144C34.1165 26.4489 34.1894 26.4699 34.2643 26.4762C34.3391 26.4825 34.4145 26.474 34.4861 26.4512C34.5577 26.4284 34.6241 26.3917 34.6815 26.3432C34.7389 26.2947 34.7862 26.2354 34.8206 26.1686Z" fill="#FF6E83" />
        <path d="M32.3828 14.9419C32.3828 15.2237 32.1543 15.4521 31.8725 15.4521C31.5908 15.4521 31.3623 15.2237 31.3623 14.9419C31.3623 14.6601 31.5907 14.4317 31.8725 14.4317C32.1544 14.4316 32.3828 14.6601 32.3828 14.9419ZM14.1714 12.5568C13.8896 12.5568 13.6611 12.7852 13.6611 13.067C13.6611 13.3488 13.8896 13.5773 14.1714 13.5773C14.4532 13.5773 14.6816 13.3488 14.6816 13.067C14.6816 12.7852 14.4532 12.5568 14.1714 12.5568ZM22.172 19.2157C22.2297 19.1026 22.3009 18.9737 22.3762 18.8373C22.7584 18.1449 23.2819 17.1967 23.3134 16.0923C23.3512 14.7656 22.6602 13.561 21.2597 12.512C21.0165 12.3299 20.6719 12.3795 20.4899 12.6225C20.3079 12.8656 20.3573 13.2102 20.6004 13.3922C21.7137 14.2261 22.2415 15.099 22.2141 16.061C22.1902 16.8987 21.7595 17.6789 21.4135 18.3058C21.3337 18.4503 21.2584 18.5867 21.1925 18.7158C21.0545 18.9863 21.1619 19.3175 21.4324 19.4555C21.5096 19.4951 21.5951 19.5157 21.6819 19.5158C21.7833 19.5158 21.8828 19.4878 21.9693 19.4349C22.0558 19.3819 22.126 19.3061 22.172 19.2157Z" fill="#59CAFC" />
      </g>
    </g>
    <defs>
      <filter id="filter0_i_564_24731" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.278431 0 0 0 0 0.815686 0 0 0 0.47 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_564_24731" />
      </filter>
      <linearGradient id="paint0_linear_564_24731" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#F3F5F7" />
      </linearGradient>
      <clipPath id="clip0_564_24731">
        <rect width="24" height="24" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>

);

export default Confetti;