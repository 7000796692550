import React, { useState, useMemo, useCallback, useEffect } from "react";
// @ts-ignore
import { Formik, Field, ErrorMessage, Form } from "formik";
// @ts-ignore
import { ArrowLeftIcon } from "@heroicons/react/16/solid";
// @ts-ignore
import { CloseOutlined } from "@ant-design/icons";

// @ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

import MessageEntryTextField from "./MessageEntryTextField";
import ContnetFlywheelingMessage from "./ContnetFlywheelingMessage";
import FormikAddToList from "../../components/formikForms/FormikAddToList";
import AddNewAutomationStepMessageImage from "./AddNewAutomationStepMessageImage";
import MessageImageForm from "./MessageImageForm";
// @ts-ignore
import { Button, Col, Row } from "antd";
// @ts-ignore
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
// @ts-ignore
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import CustomImageUploader from "./CustomImageUploader";
import CustomMessageEntryTextField from "./CustomMessageEntryTextField";
import { useAppDispatch, useAppSelector } from "../../../pages/hooks";
import AddNewAutomationStepOneFour from "./AddNewAutomationStepOneFour";
import { setSelectedAutommation } from "../../../pages/store/automation";
import FormikButtonComponent from "../../../pages/components/formikForms/FormikButtonComponent";
import { MdTipsAndUpdates } from "react-icons/md";
import { DEFAULT_FOLLOW_UP_MESAGE } from "./defaultFormData";
import {createAsyncThunk} from "@reduxjs/toolkit";
import clientService from "./../../helpers/client";
import {Api} from "./../../constants/api";

interface StepOneTwoFormProps {
  onPrev: () => void;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isSubmitting: boolean;
}

interface SortableItemProps {
  id: string;
  children: React.ReactNode;
}

const GridIcon = ({
  size = 24,
  color = "currentColor",
  className = "",
  ...props
}) => {
  const dotSize = size / 16; // Dynamically calculate dot size based on the overall icon size
  const spacing = size / 4; // Spacing between dots

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill="none"
      className={className}
      {...props}
    >
      {/* Row 1 */}
      <circle cx={spacing} cy={spacing} r={dotSize} fill={color} />
      <circle cx={2 * spacing} cy={spacing} r={dotSize} fill={color} />
      <circle cx={3 * spacing} cy={spacing} r={dotSize} fill={color} />

      {/* Row 2 */}
      <circle cx={spacing} cy={2 * spacing} r={dotSize} fill={color} />
      <circle cx={2 * spacing} cy={2 * spacing} r={dotSize} fill={color} />
      <circle cx={3 * spacing} cy={2 * spacing} r={dotSize} fill={color} />

      {/* Row 3 */}
      <circle cx={spacing} cy={3 * spacing} r={dotSize} fill={color} />
      <circle cx={2 * spacing} cy={3 * spacing} r={dotSize} fill={color} />
      <circle cx={3 * spacing} cy={3 * spacing} r={dotSize} fill={color} />
    </svg>
  );
};

const SortableItem: React.FC<SortableItemProps> = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    // transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: "rgb(249, 250, 251)",
  };

  const [isGrabbing, setIsGrabbing] = useState(false);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseDown={() => setIsGrabbing(true)}
      onMouseUp={() => setIsGrabbing(false)}
      onMouseLeave={() => setIsGrabbing(false)}
      className={`w-full bg-white p-2 rounded-md shadow-sm ${
        isGrabbing
          ? "scale-105 shadow-lg cursor-grabbing transition-transform"
          : "cursor-grab transition-transform"
      }`}
    >
      {children}
    </div>
  );
};

const MessageAddV2: React.FC<StepOneTwoFormProps> = ({
  onPrev,
  values,
  setFieldValue,
  isSubmitting,
}) => {
  const [selectedMessageType, setSelectedMessageType] =
    useState<string>("Message");
  const messages = values.addons;
  const followupSteps = values.followupSteps;

  const setMessages = (values) => {
    setFieldValue("addons", values);
  };

  const setFollowUps = (values) => {
    setFieldValue("followupSteps", values);
  };
  // Add new add-ons to the message list
  const handleAddOnClick = useCallback(
    (addon: any) => {
      const messageTypeCount = messages.filter(
        (messages) => messages.message_type === addon.value
      ).length;

      setMessages([
        ...messages,
        {
          id: `addon-${uuidv4()}`,
          message_type: addon.value,
          message_label: addon.label + " " + (messageTypeCount + 1),
          message_order_index: messages.length + 1,
          message_type_index: messageTypeCount + 1,
        },
      ]);
    },
    [messages]
  );
  const handleFollowUpClick = useCallback(
    (addon: any) => {
      setFollowUps([
        ...followupSteps,
        {
          id: `addon-${uuidv4()}`,
          message_label: addon.label + " " + (followupSteps.length + 1),
          message_type: addon.value,
          days_after_previous: (followupSteps.length + 1) * 2,
          step_number: followupSteps.length + 1,
          message: DEFAULT_FOLLOW_UP_MESAGE,
        },
      ]);
    },
    [followupSteps]
  );

  // Remove a message by index
  const removeMessage = (id: string) => {
    setMessages(messages.filter((message) => message.id !== id));
  };

  // Remove a follow by index
  const removeFollowUp = (id: string) => {
    setFollowUps(followupSteps.filter((message) => message.id !== id));
  };

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = messages.findIndex((item) => item.id === active.id);
      const newIndex = messages.findIndex((item) => item.id === over.id);
      setMessages(arrayMove(messages, oldIndex, newIndex));
    }
  };

  const shops = useAppSelector((state) => state.shops);
  const shopSelected =shops.selectedStoreId?.toString()
  // console.log("values", shopSelected);
  const dispatch = useAppDispatch();
  const getFormData = createAsyncThunk(
    "sparkCodeForm:getSparkCodesFormPreview",
    async (payload: any) => {
      return clientService.post(Api.sparkCodeForm.getSparkCodesFormPreview, payload);
    }
  );
  const [returnedUrl, setReturnedUrl] = useState("");
  useEffect(() => {

    dispatch(getFormData({ shop_id: shopSelected, group_id: values.crm_group_id }))
      .then((response: any) => {
        if (response && response.payload) {
          setReturnedUrl(response.payload.data.url); // Update the state with the returned URL
          console.log("Returned URL:", response.payload.data.url);
        }
      })
      .catch((error) => console.error("Failed to load form data", error));

    console.log("URL after dispatch:", returnedUrl);
  }, [dispatch, shopSelected]);

  function handleShowPreview() {
    window.open(returnedUrl, "_blank"); // Open the URL in a new tab
  }

  const getCustomerIdByShopId = (shopId: number, data: any): number | null => {
    for (const customerId in data) {
      const shops = data[customerId];
      if (shops.some((shop) => shop.shop_id === shopId)) {
        return parseInt(customerId);
      }
    }
    return null; // Return null if not found
  };

  // console.log("customerIDS224e2",customerId)

  const renderRightPanel = useMemo(() => {
    switch (values.selectedMessageType) {
      case "Message":
        return <MessageEntryTextField />;
      case "Message + Image":
        return (
          <MessageImageForm
            setFieldValue={setFieldValue}
            values={values}
            onPrev={onPrev}
            isSubmitting={isSubmitting}
          />
        );
      case "Message + Product Card":
        return (
          <div>
            <div className="mb-10">
              <MessageEntryTextField />
            </div>
            <FormikAddToList
              name="product_id"
              label="Product ID"
              placeHolder="i.e. 142735249054578"
              listItems={values.product_id}
              maxListLenght={100}
            />
          </div>
        );
      case "Spark Code":
        return (
          <div>
            <MessageEntryTextField field_name={"message_entry_spark_code"} />
            <div className="border border-gray-300 rounded-lg bg-gray-50 p-2">
              <label className="block text-sm font-semibold text-gray-700">
                Spark Code Submission
              </label>
              <input
                disabled={true}
                type="url"
                name="form_url"
                placeholder="portal.reacherapp.com/spark-code-form"
                className="border mt-1 border-gray-300 rounded-md w-full px-3 py-2 text-sm text-gray-700 focus:ring-1 focus:ring-blue-500 focus:outline-none"
              />
              <Button
                className={`mt-2 bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300`}
                onClick={() => handleShowPreview()}
              >
                View Preview
              </Button>

            </div>
          </div>
        );
      case "Content Flywheel":
        return <ContnetFlywheelingMessage />;
      default:
        return <p className="text-gray-700">Select a message type to begin.</p>;
    }
  }, [selectedMessageType, setFieldValue, values, onPrev, isSubmitting]);

  // Memoized function for rendering each message
  const renderMessage = useCallback(
    (message, index) => {
      if (message.message_type === "initial_message") {
        return (
          <div className="bg-gray-50 rounded-xl p-2 mb-3">
            <h3 className="mb-3 pt-1 text-gray-600 font-semibold ">
              {message.message_label.substring(3)}
            </h3>
            <CustomMessageEntryTextField
              fieldName="message_entry"
              value={message.message_entry}
              onChange={(key: string, value: string) => {
                const updatedMessages = [...messages];
                updatedMessages[index] = { ...message, [key]: value };
                setMessages(updatedMessages);
              }}
            />
          </div>
        );
      } else if (message.message_type === "image") {
        return (
          <div className="bg-gray-50 rounded-xl p-2 mb-3">
            <h3 className="mb-3 pt-1 text-gray-600 font-semibold ">
              {message.message_label.substring(3)}
            </h3>
            <CustomImageUploader
              value={message.file_name}
              onChange={(file) => {
                const updatedMessages = [...messages];
                updatedMessages[index] = {
                  ...message,
                  file: file || {},
                  file_name: (file || {})?.name,
                  uploaded_file_url: ""
                };
                setMessages(updatedMessages);
              }}
              label="Upload Image"
              // previousImageName={message.file_name}
              error={values.imageError}
            />
          </div>
        );
      } else if (message.message_type === "follow-up") {
        return (
          <div className="bg-gray-50 rounded-xl p-2 mb-3">
            <h3 className="mb-3 pt-1 text-gray-600 font-semibold ">
              {message.message_label.substring(3)}
            </h3>

            <AddNewAutomationStepOneFour
              name={message.follow_up}
              step={message.message_type_index}
              onChange={(steps) => {
                const updatedMessages = [...messages];
                updatedMessages[index] = { ...message, follow_ups: steps };
                setMessages(updatedMessages);
              }}
              initialValues={values.followup_steps}
            />
          </div>
        );
      }
      return <div />;
    },
    [messages, values.image_name, values.imageError]
  );

  const renderFollowUp = useCallback(
    (followUp, index) => {
      return (
        <div className="bg-gray-50 rounded-xl p-2 mb-3">
          <h3 className="mb-3 pt-1 text-gray-600 font-semibold ">
            {followUp.message_label.substring(3)}
          </h3>
          <AddNewAutomationStepOneFour
            step={followUp.days_after_previous}
            message={followUp.message}
            onChange={(value) => {
              // console.log("steps2131231", value);

              // Ensure followupSteps is updated immutably to trigger re-renders
              const updatedFollowUps = [...followupSteps];

              if (typeof value.step !== "undefined") {
                updatedFollowUps[index].days_after_previous = value.step; // Updated `step_number` field
              }
              if (typeof value.message !== "undefined") {
                updatedFollowUps[index].message = value.message; // Updated `message` field
              }

              setFollowUps(updatedFollowUps); // Set updated array
            }}
          />
        </div>
      );
    },
    [followupSteps]
  );

  const messageTypes = [
    "Message",
    "Message + Image",
    "Message + Product Card",
    "Spark Code",
    "Content Flywheel",
  ];
  console.log("213123valuessss",values.crm_group_id);

  return (
    <Form>
      <div className={`flex h-screen`}>
        {/*Left Panel*/}
        <div className="w-2/5 p-3 bg-white overflow-y-auto">
          <h3 className="text-lg font-semibold text-gray-900 mb-6">
            Message Setup
          </h3>

          {/* Message Type Buttons */}
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Message Type
            </h4>
            <FormikButtonComponent
              name="selectedMessageType"
              options={messageTypes}
            />
          </div>
          <div className="mt-6 mb-6 border-t border-gray-300"></div>
          {/* Add Ons */}
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">Add ons</h4>
            <div className="grid grid-cols-2 gap-2">
              {[
                {
                  value: "initial_message",
                  label: " + Addon Message",
                },
                {
                  value: "image",
                  label: " + Image",
                },
                ...((values.outreach_type != "crm_group") ? [
                    {
                      value: "follow-up",
                      label: " + Follow Up Message",
                    },
                  ]
                  : []),
              ].map((addon) => (
                <Button
                  key={addon.value}
                  type="button"
                  className="bg-white text-sm font-medium text-gray-600 border rounded-lg border-gray-300 hover:border-blue-600 hover:text-blue-800"
                  onClick={() => {
                    if (addon.value === "follow-up") {
                      handleFollowUpClick(addon);
                    } else {
                      handleAddOnClick(addon);
                    }
                  }}
                >
                  {addon.label}
                </Button>
              ))}
            </div>
          </div>
          <div className="mt-6 mb-6 border-t border-gray-300"></div>
          {/* Message Order */}
          <div>
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Message Order
            </h4>

            <div
              className={`flex items-center 
                      bg-gray-50   rounded-lg  justify-between bg-g mb-2 pr-2 p-3.5`}
            >
              <span className="ml-2 text-sm font-semibold text-gray-600">
                {values.selectedMessageType}
              </span>
            </div>

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={messages}
                strategy={verticalListSortingStrategy}
              >
                {messages.map((message) => (
                  <div>
                    {message.message_type != "follow-up" && (
                      <div
                        className={`flex items-center 
                    rounded-xl  justify-between bg-gray-50 mb-2 pr-2`}
                      >
                        <SortableItem key={message.id} id={message.id}>
                          <div className="flex items-center p-1 pl-2  ">
                            <GridIcon />

                            <span className="ml-2 text-gray-600 font-semibold text-sm">
                              {message.message_label.substring(3)}
                            </span>
                          </div>
                        </SortableItem>
                        <Button
                          type="button"
                          className=" bg-gray-50 border border-none rounded-lg  hover:border-blue-600 hover:text-blue-800"
                          onClick={() => removeMessage(message.id)}
                        >
                          <CloseOutlined className="text-lg text-gray-600 bg-gray-50 hover:text-blue-600 " />
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </SortableContext>
            </DndContext>
          </div>

          <div className="mt-6 mb-6 border-t border-gray-300"></div>
          {(values.outreach_type != "crm_group") && (
            <>
                {/* Follow Up Order */}
                  <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-2">
                    Follow Ups
                  </h4>

                  {followupSteps.map((message) => (
                    <div>
                      {message.message_type == "follow-up" && (
                        <div
                          className={`flex items-center
                          border rounded-xl  border-none justify-between bg-gray-50 mb-2 pr-2`}
                        >
                          <div className="flex items-center p-1 pl-2 ">
                            <span className="ml-2 text-gray-600 font-semibold text-sm">
                              {message.message_label.substring(3)}
                            </span>
                          </div>
                          <Button
                            type="button"
                            className=" bg-gray-50 border border-none rounded-lg  hover:border-blue-600 hover:text-blue-800"
                            onClick={() => removeFollowUp(message.id)}
                          >
                            <CloseOutlined className="text-lg text-gray-600 bg-gray-50 hover:text-blue-600 " />
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

          </div>

        {/* Right Panel */}
        <div className="w-3/5 p-3 overflow-y-auto">
          {/*Tips*/}
          <div className="bg-yellow-50 border border-yellow-400 rounded-lg p-4 mb-6">
            <div className="flex items-center mb-2">
              <MdTipsAndUpdates className="text-yellow-500 w-5 h-5 mr-2" />
              <span className="font-medium text-yellow-600">
                Helpful Tips for You
              </span>
            </div>
            <p className="text-gray-700 text-sm">
              You can use{" "}
              <span className="font-semibold">{"{creator_name}"}</span> to send
              a message with the creator's name!
            </p>
          </div>

          <div className="bg-gray-50 border rounded-xl mb-3">
            <h4 className="text-lg font-semibold text-gray-600 px-3 pt-2">
              {values.selectedMessageType}
            </h4>
            <div className="  mb-2 px-2 pb-2">{renderRightPanel}</div>
          </div>
          <div className="">
            {messages.map((msg, index) => renderMessage(msg, index))}
            {followupSteps.map((followUp, index) =>
              renderFollowUp(followUp, index)
            )}
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between items-center mt-10">
        <button
          className="bg-gray-100 flex items-center p-10 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded-md focus:outline-none"
          onClick={onPrev}
          type="button"
        >
          <ArrowLeftIcon className="w-4 h-4 ml-2" />
          Previous
        </button>
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300"
          type="submit"
        >
          Save & Exit
        </button>
      </div>
    </Form>
  );
};

export default MessageAddV2;
