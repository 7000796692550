import React, { ReactElement, useEffect } from "react";
import { Select } from "antd";
import { Check, ChevronDown } from "untitledui-js-base";

const { Option } = Select;

interface SidebarSelectorProps {
  placeHolder?: string;
  value: number | null;
  options: { value: number; label: string }[];
  onChange: (value?: number) => void;
  LeftIcon?: ReactElement;
  containerstyle?: React.CSSProperties;
  inputClassName?: string;
  preDefinedValue?: boolean;
}

const SidebarSelector: React.FC<SidebarSelectorProps> = ({
  placeHolder,
  value,
  options,
  onChange,
  LeftIcon,
  containerstyle,
  inputClassName,
  preDefinedValue = true,
}) => {
  useEffect(() => {
    if (preDefinedValue && !value && options.length > 0) {
      onChange(options[0].value);
    }
  }, [preDefinedValue, value, options, onChange]);

  return (
    <div className={`relative w-full ${inputClassName}`}>
      <Select
        showSearch
        value={value}
        placeholder={placeHolder || "Select..."}
        onChange={(selectedValue) => onChange(selectedValue)}
        optionFilterProp="label"
        className="w-full rounded-lg custom-placeholder"
        style={{ ...(containerstyle || {}), borderRadius: "8px" }}
        suffixIcon={
          <ChevronDown size="18" className="text-quaternary-default" />
        }
        dropdownClassName="custom-dropdown"
        dropdownRender={(menu) => (
          <div className="bg-white rounded-lg shadow-lg p-1 text-secondary-default">
            {menu}
          </div>
        )}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value} label={option.label}>
            <div className="flex items-center gap-3 py-1 rounded-md font-medium text-base">
              {LeftIcon && <span>{LeftIcon}</span>}
              <span className="text-secondary-default">{option.label}</span>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SidebarSelector;
