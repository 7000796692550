import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spin, Steps } from "antd";
import CustomModal from "../../common/modal";
import { useAppDispatch, useAppSelector } from "../hooks";
import ShowGroupDetail from "./showGroupDetail";
import { ApiTwoTone, MessageTwoTone } from "@ant-design/icons";
import {
  DEFAULT_CREATOR_DM_MESSAGE,
  defaultFormData,
  message_and_taget_collab_description,
  new_automation_description,
  validationSchema as validationSchemaStepTarget,
  validationSchemaMessage,
  validationSchemaStepTwo,
  checkToInitializeCommissionRatesOnEditAutomation,
} from "../automations/components/defaultFormData";
import { Formik, FormikValues } from "formik";
import getValidationSchema from "../automations/components/validationSchemaStepOne";
import AutomationForm from "../automations/components/AutomationForm";
import TargetCollabFullForm from "../automations/components/TargetCollabFullForm";
import MessageImageForm from "../automations/components/MessageImageForm";
import StepOneTwoForm from "../automations/components/StepOneTwoForm";
import {
  getAutomations,
  postAddAutomation,
  updateAutomation,
} from "../store/automation";
import { toast } from "react-toastify";
import { processFormSchedule } from "./helper";
import { getSingleGroup } from "../store/creators";

const AutomationModal: React.FC = (props: any) => {
  const {
    visible,
    setVisible,
    groupId,
    edit,
    config,
    automation_id,
    productIds,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const creators = useAppSelector((state) => state.creators);
  const shops = useAppSelector((state) => state.shops);
  const auth = useAppSelector((state) => state.auth);
  const validationSchemaStep1 = getValidationSchema(shops.selectedStoreRegion);
  const data = creators?.singleGroup?.[groupId];
  const singleGroupCreators = creators.singleGroupCreators?.[groupId] || [];
  const selectedProductIds = productIds
    ? productIds
    : edit
      ? config.product_id
      : [...new Set(singleGroupCreators.map((item) => item.product_id))];

  console.log("selectedProductIds12312321", creators);
  // Step state to track the current step
  const [step, setStep] = useState(1);

  console.log("config12312312", config);
  useEffect(() => {
    if (typeof groupId !== 'undefined' && (Object.values(creators?.singleGroup?.[groupId] || {}).length === 0)) {
      console.log('12312312312321312')
      dispatch(getSingleGroup({ id: groupId }));
    }
  }, [creators?.singleGroup?.[groupId]]);

  // Initialize formData state
  const [formData, setFormData] = useState<FormikValues>({
    ...defaultFormData,
    product_id: selectedProductIds,
  }); // Use default form data

  useEffect(() => {
    if (selectedProductIds.length) {
      setFormData({ ...defaultFormData, product_id: selectedProductIds });
    }
  }, [selectedProductIds.length]);

  useEffect(() => {
    if (edit) {
      console.log("insideee", config);
      setFormData(config);
    }
  }, [edit, groupId]);

  const handleCancel = () => {
    setStep(1);
    setVisible(false);
    if (edit) {
      setFormData(config);
    } else {
      setFormData({ ...defaultFormData, product_id: selectedProductIds });
    }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const steps = [
    {
      title: <span style={{ fontWeight: "bold" }}>New Automation</span>,
      description: new_automation_description,
      icon: <ApiTwoTone />,
    },
    {
      title: (
        <span style={{ fontWeight: "bold" }}>Message & Target Collab</span>
      ),
      description: message_and_taget_collab_description,
      icon: <MessageTwoTone />,
    },
  ];

  const initializeCommissionRates = (values: FormikValues) => {
    if (edit) {
      const ProductIDsChanged =
        !checkToInitializeCommissionRatesOnEditAutomation(values);
      // Check if product ids changed or first time initialization of the target_collab_product_comission_rates
      if (
        ProductIDsChanged ||
        (values.product_id &&
          values.target_collab_product_comission_rates.length === 1 &&
          !values.target_collab_product_comission_rates[0].product_id)
      ) {
        return values.product_id.map((productId) => ({
          product_id: productId,
          commission_rate: "",
        }));
      }
      return values.target_collab_product_comission_rates;
    }
    if (
      values.product_id &&
      values.target_collab_product_comission_rates.length === 1 &&
      !values.target_collab_product_comission_rates[0].product_id
    ) {
      return values.product_id.map((productId) => ({
        product_id: productId,
        commission_rate: "",
      }));
    }
    return values.target_collab_product_comission_rates;
  };

  const handleNext = async (newData: any) => {
    const groupAutomation = {
      ...formData,
      ...newData,
      crm_group_id: data.group_id,
      target_collab_product_comission_rates: initializeCommissionRates(newData),
    };
    if (step === 2) {
      setLoading(true);
      // Set the product_ids [] if the automation type is Message or Message + Image

      let product_ids = groupAutomation.product_id;
      if (
        groupAutomation.automation_type === "Message" ||
        groupAutomation.automation_type === "Message + Image"
      ) {
        product_ids = [];
      }
      // Create the categories list
      let categoriesList = groupAutomation.category.map((category) => ({
        Main: category,
      }));
      console.log("categoriesList before sending as a payload", categoriesList);

      // Convert creators_to_omit and creators_to_include arrays to JSON strings
      const creatorsToOmitJson = JSON.stringify(
        groupAutomation.creators_to_omit
      );
      const creatorsToIncludeJson = JSON.stringify(
        groupAutomation.creators_to_include
      );

      // Process the time frame data
      const timeFrame = processFormSchedule(groupAutomation);

      // Adjust content type preference for target collab automation
      let contentType = groupAutomation.content_type;
      if (
        !["No preference", "Shoppable video", "Shoppable LIVE"].includes(
          groupAutomation.content_type
        )
      ) {
        contentType = "No preference";
      }

      // Adjust the target collab message for creator name customization
      let targetCollabMessage = groupAutomation.message_entry_target_collab;
      if (targetCollabMessage.includes("{creator_name}")) {
        // Replace all occurrences of {creator_name} with {{creators username}}
        targetCollabMessage = targetCollabMessage.replace(
          /{creator_name}/g,
          "{{creators username}}"
        );
      }

      // Adjust the target collab name so that it wont have consequtive empty spaces (only one space)
      let targetCollabName = groupAutomation.target_collab_invitation_name;
      if (
        targetCollabName !== undefined &&
        targetCollabName !== null &&
        targetCollabName !== "" &&
        targetCollabName.includes("  ")
      ) {
        targetCollabName = targetCollabName.replace(/\s+/g, " ");
      }
      // Check if final char is empty space, if so remove it
      if (targetCollabName[targetCollabName.length - 1] === " ") {
        targetCollabName = targetCollabName.slice(0, -1);
      }

      // Create FormData and append the payload data
      const formData = new FormData();
      if (edit) {
        formData.append("automation_id", automation_id?.toString() ?? "");
      }
      formData.append(
        "customer_id",
        auth?.meInfo?.customer_id?.toString() ?? ""
      );
      formData.append("shop_id", shops.selectedStoreId?.toString() ?? "");
      formData.append("shop_name", shops.selectedStoreName ?? "");
      formData.append("automation_name", groupAutomation.automation_name ?? "");
      formData.append("status", "inactive");
      formData.append("automation_type", groupAutomation.automation_type ?? "");
      formData.append("creators_to_omit", creatorsToOmitJson);
      formData.append("creators_to_include", creatorsToIncludeJson);

      if (groupAutomation.image) {
        formData.append("file", groupAutomation.image);
        formData.append("file_name", groupAutomation.image.name);
      }

      // Append config data as JSON string
      const config = {
        DESIRED_PRODUCT_IDS: product_ids,
        Filters: {
          Creators: {
            "Product Categories": categoriesList,
            Followers: {
              "Follower Segments": groupAutomation.followers,
            },
          },
          Followers: {
            "Follower Age": groupAutomation.age,
            "Follower Gender": groupAutomation.gender,
          },
          Performance: {
            GMV: groupAutomation.gmv,
            "Units Sold": groupAutomation.units,
            "Average Views": groupAutomation.views,
            "Engagement Rate": groupAutomation.engagement,
            GPM: groupAutomation.video_gpm,
            "Video Views": groupAutomation.video_views,
            "Fulfillment Rate": groupAutomation.fulfillment_rate,
          },
        },
        CREATOR_MESSAGE: groupAutomation.message_entry,
        SEND_UNREPLIED_FOLLOWUP: groupAutomation.no_reply_automation,
        DAYS_BEFORE_FOLLOWUP: groupAutomation.days,
        FOLLOWUP_MESSAGE: groupAutomation.message_entry_no_reply,
        OFFER_FREE_SAMPLES: groupAutomation.target_collab_offer_free_samples,
        AUTO_APPROVE: !groupAutomation.target_collab_manually_approve,
        BASE_INVITATION_NAME: targetCollabName,
        TARGET_COLLAB_MESSAGE: targetCollabMessage,
        EMAIL: groupAutomation.target_collab_email,
        PHONE_NUMBER: groupAutomation.target_collab_phone,
        VALID_UNTIL: groupAutomation.target_collab_valid_until,
        PRODUCTS: groupAutomation.target_collab_product_comission_rates,
        ONLY_MESSAGE_INCLUDE_LIST: groupAutomation.only_message_include_list,
        SCHEDULE: timeFrame,
        content_type: contentType,
        TC_DM_MESSAGE: groupAutomation.tc_dm_message,
        FOLLOW_UP_STEPS: groupAutomation.followupSteps || [],
      };

      formData.append("config", JSON.stringify(config));
      formData.append("crm_group_id", groupAutomation.crm_group_id);

      // Optionally append a file if it exists
      if (groupAutomation.file) {
        formData.append("file", groupAutomation.file);
        formData.append("file_name", groupAutomation.file.name);
      }

      console.log("FormData before sending", formData);

      if (edit) {
        await dispatch(updateAutomation(formData));
      } else {
        await dispatch(postAddAutomation(formData));
      }
      toast.success("Automation Created!");
      dispatch(getAutomations({ shop_id: shops.selectedStoreId }));
      dispatch(getSingleGroup({ id: groupId }));
      handleCancel();
      setLoading(false);
      if (edit) {
        setFormData(config);
      } else {
        setFormData({ ...defaultFormData, product_id: selectedProductIds });
      }
      navigate(`/automations`);
    } else {
      setStep(step + 1);
      setFormData(groupAutomation);
    }
  };

  const handleNextwithCommissionRates = (values: FormikValues) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setFormData((prevData) => ({
      ...prevData,
      target_collab_product_comission_rates: initializeCommissionRates(values),
    }));
    setStep(step + 1);
  };

  const renderStepForm = ({ content, contentStep2 }) => {
    switch (step) {
      case 1:
        return (
          <Formik
            initialValues={formData}
            validationSchema={validationSchemaStep1}
            onSubmit={(values) => handleNextwithCommissionRates(values)}
            enableReinitialize
          >
            {({ isSubmitting, values, setFieldValue, validateField }) => (
              <AutomationForm
                values={values}
                setFieldValue={setFieldValue}
                validateField={validateField}
                shops={shops}
                group={{
                  group: data,
                  content,
                }}
                isSubmitting={isSubmitting}
                onReturn={() => setVisible(false)}
              />
            )}
          </Formik>
        );
      case 2:
        if (
          formData.automation_type === "Message + Target Collab" ||
          formData.automation_type ===
            "Message + Target Collab + Target Collab Card"
        ) {
          return (
            <Formik
              initialValues={{
                ...formData,
                tc_dm_message:
                  formData.tc_dm_message || DEFAULT_CREATOR_DM_MESSAGE,
              }}
              validationSchema={validationSchemaStepTarget}
              onSubmit={(values) => handleNext(values)}
              enableReinitialize
            >
              {({ isSubmitting, values }) => (
                <TargetCollabFullForm
                  values={values}
                  shops={shops}
                  automationType={formData.automation_type}
                  onPrev={handlePrev}
                  group={{
                    group: data,
                    content: contentStep2,
                  }}
                  isSubmitting={isSubmitting}
                />
              )}
            </Formik>
          );
        } else if (formData.automation_type === "Message + Image") {
          return (
            <Formik
              initialValues={formData}
              validationSchema={validationSchemaMessage}
              onSubmit={(values) => handleNext(values)}
              enableReinitialize
            >
              {({ setFieldValue, values, isSubmitting }) => (
                <MessageImageForm
                  setFieldValue={setFieldValue}
                  values={values}
                  onPrev={handlePrev}
                  group={{
                    group: data,
                    content: contentStep2,
                  }}
                  isSubmitting={isSubmitting}
                />
              )}
            </Formik>
          );
        } else {
          return (
            <Formik
              initialValues={formData}
              validationSchema={validationSchemaStepTwo}
              onSubmit={(values) => handleNext(values)}
              enableReinitialize
            >
              {() => (
                <StepOneTwoForm
                  onPrev={handlePrev}
                  group={{
                    group: data,
                    content: contentStep2,
                  }}
                />
              )}
            </Formik>
          );
        }
      default:
        return null;
    }
  };

  const content = (
    <div className="flex justify-end gap-4 mt-8">
      <Button onClick={handleCancel} type="link" className="text-gray-600">
        Cancel
      </Button>
      <button className="bg-blue-600 text-white px-5 rounded-lg" type="submit">
        Continue
      </button>
    </div>
  );

  const contentStep2 = (
    <div className="flex justify-between gap-4 mt-8">
      <div>
        <Button
          onClick={() => setStep(1)}
          className="bg-gray-100 border border-gray-300"
        >
          Previous
        </Button>
      </div>
      <div className="flex">
        <Button onClick={handleCancel} type="link" className="text-gray-600">
          Cancel
        </Button>
        {loading ? (
          <Spin />
        ) : (
          <button
            className="bg-blue-600 text-white px-5 rounded-lg"
            type="submit"
          >
            Save & Exit
          </button>
        )}
      </div>
    </div>
  );

  const automationContent = (
    <div className="mb-10 w-full lg:w-[90vw]">
      <Steps current={step - 1} items={steps} className="mb-6 mt-6" />

      <div className="flex flex-row flex-wrap justify-between">
        <div className="w-full lg:w-[50vw]">
          {renderStepForm({ content, contentStep2 })}
        </div>
        <div className="w-full lg:w-[35vw]">
          {Object.values(data || {}).length > 0 ? (
            <ShowGroupDetail {...data} />
          ) : (
            <Spin />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <CustomModal
      visible={visible}
      title="Configure Automation"
      content={automationContent}
      onCancel={handleCancel}
      confirmText="Save & Exit"
      cancelText="Cancel"
    />
  );
};

export default AutomationModal;
