import { createBrowserRouter } from "react-router-dom";
import App from "./pages";
import Login from "./pages/login";
import Signup from "./pages/signup";
import MyAccount from "./pages/myaccount";
import AIAssistant from "./pages/aiassistant";
import Automations from "./pages/automations";
import AutomationDetail from "./pages/automations/automationDetail";
import EditAutomation from "./pages/automations/editAutomationDetail";
import CreateAutomation from "./pages/automations/createAutomation";
import MyCreators from "./pages/my-creators";
import Groups from "./pages/groups";
import SingleGroup from "./pages/groups/singleGroup";
import CreateGroup from "./pages/groups/createGroup";
import SelectPlan from "./pages/select-plan";
import PaymentSuccess from "./pages/paymentsuccess";
import ShopManagement from "./pages/shops";
import EmailAutomations from "./pages/email-automations";
import Subscriptions from "./pages/subscriptions";
import EmailedCreators from "./pages/dashboard-new/emailed-creators";
import MessagedCreator from "./pages/dashboard-new/messaged-creators";
import ContentFlywheels from "./pages/content-flywheels";
import MyContent from "./pages/content-flywheels/my-content";
import CreateContentFlywheel from "./pages/content-flywheels/create";
import SparkCodes from "./pages/spark-codes";
import RootLayout from "./rootLayout"; // Import the RootLayout component
import SparkCodeForm from "./pages/sparkcodeform";
import CreatorContentFlywheels from "./pages/content-flywheels/creator-content-flywheels";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />, // Apply AuthProvider globally
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/myaccount",
        element: <MyAccount />,
      },
      {
        path: "/aiassistant",
        element: <AIAssistant />,
      },
      {
        path: "/automations",
        element: <Automations />,
      },
      {
        path: "/automations/:id",
        element: <AutomationDetail />,
      },
      {
        path: "/automations/edit/:id",
        element: <EditAutomation />,
      },
      {
        path: "/automations/create",
        element: <CreateAutomation />,
      },
      {
        path: "/my-creators",
        element: <MyCreators />,
      },
      {
        path: "/groups",
        element: <Groups />,
      },
      {
        path: "/groups/create",
        element: <CreateGroup />,
      },
      {
        path: "/groups/:id",
        element: <SingleGroup />,
      },
      {
        path: "/content-flywheels",
        element: <ContentFlywheels />,
      },
      {
        path: "/content-flywheels/my-content",
        element: <MyContent />,
      },
      {
        path: "/content-flywheels/edit",
        element: <CreateContentFlywheel />,
      },
      {
        path: "/content-flywheeling/creator/:uuid",
        element: <CreatorContentFlywheels />,
      },
      {
        path: "/content-flywheels/my-flywheels",
        element: <ContentFlywheels />,
      },
      {
        path: "/content-flywheels/create",
        element: <CreateContentFlywheel />,
      },
      {
        path: "/spark-codes",
        element: <SparkCodes />,
      },
      {
        path: "/paymentsuccess",
        element: <PaymentSuccess />,
      },
      {
        path: "/shopmanagement",
        element: <ShopManagement />,
      },
      {
        path: "/emailedcreators",
        element: <EmailedCreators />,
      },
      {
        path: "/messagedcreators",
        element: <MessagedCreator />,
      },
      {
        path: "/email-automations",
        element: <EmailAutomations />,
      },
      {
        path: "/subscriptions",
        element: <Subscriptions />,
      },
      {
        path: "/subscriptions/invoices",
        element: <Subscriptions />,
      },
      {
        path: "/spark-code-form/:id",
        element: <SparkCodeForm />,
      }
    ],
  },
]);
