import { useEffect, useState } from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spin, Tooltip, Tag } from "antd";
import CustomTable from "../../common/table";
import SearchComponent from "../../common/search";
import IconButton from "../../common/button";
import Layout from "./layout";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  getGroups,
  setFilterLoading,
  setGroupLoading,
} from "../store/creators";
import { FaRegSadTear } from "react-icons/fa";
import {
  humanizeConditions,
  calculateSelectedConditions,
  getAutomationStatus,
  groupLabels
} from "../my-creators/helper";

const Groups = () => {
  const navigate = useNavigate();
  const shops = useAppSelector((state) => state.shops);
  const creators = useAppSelector((state) => state.creators);
  const groupsLoading = creators.groupsLoading;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const paramsObject = Object.fromEntries(searchParams.entries());

  let key = `${shops.selectedStoreId}`;
  if (Object.values(paramsObject).length > 0) {
    key = `${shops.selectedStoreId}-${location.search}`;
  }

  const getShopGroups = async () => {
    await dispatch(
      getGroups({
        shop_id: shops.selectedStoreId,
        filtersKey: location.search,
      })
    );
  };

  useEffect(() => {
    if (
      shops.selectedStoreId &&
      (creators?.groups?.[key] || [])?.length === 0 &&
      Object.keys(paramsObject).length === 0
    ) {
      getShopGroups();
    }
  }, [
    shops.selectedStoreId,
    Object.keys(paramsObject).length,
    (creators?.groups?.[key] || [])?.length,
  ]);

  useEffect(() => {
    dispatch(setFilterLoading(false));
    if ((creators?.groups?.[key] || [])?.length === 0) {
      if (Object.keys(paramsObject).length) {
        getShopGroups();
      }
    }
  }, [location.search]);

  const handleSearch = (query: string) => {
    setSearch(query);
  };

  const groupsValues = creators?.groups?.[key] || [];

  const filtered =
    (search
      ? groupsValues.filter((group) =>
          group.group_name.toLowerCase().includes(search.toLowerCase())
        )
      : groupsValues) || [];

  useEffect(() => {
    if (groupsValues.length > 0) {
      dispatch(setGroupLoading(false));
    }
  }, [groupsValues]);

  console.log("filtered12312312", filtered);
  
  const columns = [
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
      width: 200,
      render: (text, record) => (
        <Link
          className="font-medium cursor-pointer"
          to={`/groups/${record.group_id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Group Conditions",
      dataIndex: "filter_config",
      key: "filter_config",
      render: (data) => (
        <Tooltip title={humanizeConditions(data)}>
          <span className="text-blue-500 cursor-pointer">
            {calculateSelectedConditions(data)} conditions set
          </span>
        </Tooltip>
      ),
    },
    {
      title: "No. of Creators",
      dataIndex: "creator_count",
      key: "creator_count",
      render: (count) => (count ? count : "-"),
    },
    {
      title: "Group GMV",
      dataIndex: "gmv",
      key: "gmv",
      render: (gmv) =>
        gmv ? <span className="font-medium">${gmv}</span> : "-",
    },
    {
      title: "Automation Status",
      dataIndex: "automations",
      key: "automations",
      render: (automations) => {
        const automationCount = automations.length;

        // Determine overall automation status
        let automationStatus = "None";
        let statusColor = "gray";

        // Define logic to determine the overall automation status
        if (automationCount > 0) {
          const hasRunning = automations.some(
            (automation) =>
              automation.status === "running" ||
              automation.status === "continue"
          );
          const hasPaused = automations.some(
            (automation) => automation.status === "paused_by_limit"
          );
          const hasFailed = automations.some(
            (automation) =>
              automation.status === "stopped"
          );
          const hasCompleted = automations.some(
            (automation) => automation.status === "completed"
          );

          if (hasRunning) {
            automationStatus = "Continue";
            statusColor = "green";
          } else if (hasPaused) {
            automationStatus = "Paused By Limit";
            statusColor = "orange";
          } else if (hasFailed) {
            automationStatus = "Failed";
            statusColor = "red";
          } else if (hasCompleted) {
            automationStatus = "Completed";
            statusColor = "blue";
          }
        }

        // Tooltip content for automations
        const tooltipContent = (
          <div>
            {automations.map((automation) => (
              <div key={automation.automation_id}>
                <p>
                  <strong>{automation.automation_name}</strong>
                </p>
                <p>Status: {automation.status}</p>
                <p>
                  Created At:{" "}
                  {new Date(automation.created_at).toLocaleDateString()}
                </p>
                <p>Status Details: {automation.status_details}</p>
                <hr />
              </div>
            ))}
          </div>
        );

        return automationCount ? (
          <Tooltip title={tooltipContent} className="flex items-center">
            <Tag color={statusColor} className="cursor-pointer">
              {automationStatus} ({automationCount} automations)
            </Tag>
            <InfoCircleOutlined className="ml-1 text-gray-400" />
          </Tooltip>
        ) : (
          <Tag color={statusColor} className="cursor-pointer">
            {automationStatus} ({automationCount} automations)
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (action, record) => (
        <div className="flex space-x-2">
          <button
            className="bg-gray-200 p-1 rounded"
            onClick={() => {
              navigate(`/groups/create/?id=${record.group_id}`);
            }}
          >
            <HiOutlinePencil size={20} />
          </button>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      {/* <TutorialModal
        isModalOpen={isModalOpen}
        closeModal={() => {
          setIsOpenModalOpen(false);
        }}
      /> */}
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-xl">Groups</h3>
        <div className="flex">
          {/* <div
            className="flex items-center cursor-pointer mr-2"
            onClick={() => setIsOpenModalOpen(true)}
          >
            <LuBook className="mr-1 text-gray-20" />
            <h3 className="text-l text-gray-20 mr-2">View Tutorial</h3>
          </div> */}
          <IconButton
            label="Create a group"
            onClick={() => {
              navigate("/groups/create");
            }}
            icon={<PlusOutlined />}
            buttonClass="bg-black text-white hover:bg-gray-800 px-4 py-2 rounded-md"
            textClass="text-sm font-medium"
          />
        </div>
      </div>
      <div className="flex justify-between flex-wrap mt-5">
        <div className="mt-4">
          <SearchComponent
            search={search}
            setSearch={setSearch}
            onSearch={handleSearch}
            placeholder="Search by group name"
            inputStyle={{ width: 372, height: 48 }}
          />
        </div>
      </div>
      {groupsLoading || filtered.length > 0 || groupsValues.length > 0 ? (
        groupsLoading ? (
          <div className="flex items-center justify-center h-[55vh]">
            <Spin size="large" />
          </div>
        ) : (
          <CustomTable columns={columns} data={filtered} loading={false} labels={groupLabels} showDownloadOptions  />
        )
      ) : (
        <div className="flex justify-center items-center flex-col mt-10">
          <FaRegSadTear size={60} className="text-gray-400 mb-4" />
          <p className="text-gray-500">No Data</p>
        </div>
      )}
    </Layout>
  );
};

export default Groups;
