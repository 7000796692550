// AddNewAutomationStepTargetCollab.tsx
import React from "react";
import { Formik, FormikValues } from "formik";
import { useAppSelector } from "../../hooks";
import TargetCollabFullForm from "./TargetCollabFullForm";
import { DEFAULT_CREATOR_DM_MESSAGE } from "./defaultFormData";
import { validationSchema } from "./defaultFormData";

interface AddNewAutomationStepTargetCollabProps {
  onNext: (values: FormikValues) => void;
  onPrev: () => void;
  initialValues: FormikValues;
  automationType: string;
}

const AddNewAutomationStepTargetCollab: React.FC<
  AddNewAutomationStepTargetCollabProps
> = ({ onNext, onPrev, initialValues, automationType }) => {
  const shops = useAppSelector((state) => state.shops);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        tc_dm_message:
          initialValues.tc_dm_message || DEFAULT_CREATOR_DM_MESSAGE,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onNext(values)}
      enableReinitialize
    >
      {({isSubmitting, values, setFieldValue, errors}) => (
        <TargetCollabFullForm
          values={values}
          shops={shops}
          automationType={automationType}
          onPrev={onPrev}
          setFieldValue={setFieldValue}
          isSubmitting={isSubmitting}
          errors={errors}
        />
      )}
    </Formik>
  );
};

export default AddNewAutomationStepTargetCollab;
