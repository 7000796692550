import type { TableProps } from "antd";
import { Table, Tag } from "antd";
import { json2csv } from "json-2-csv";
import React, { useEffect, useState } from "react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { LuLayoutDashboard, LuLoader } from "react-icons/lu";
import { Api } from "../../../pages/constants/api";
import { handleError } from "../../../pages/helpers";
import clientService from "../../../pages/helpers/client";

const columns: TableProps["columns"] = [
  {
    title: "Invoice Id",
    dataIndex: "id",
    key: "subscription",
    render: (item) => <p className="font-medium text-sm">{item}</p>,
  },
  {
    title: "Qty Basic",
    dataIndex: "basic_plans",
    key: "subscription",
    render: (item) => <p className="font-medium text-sm">{item}</p>,
  },
  {
    title: "Qty Pro",
    dataIndex: "pro_plans",
    key: "subscription",
    render: (item) => <p className="font-medium text-sm">{item}</p>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 200,
    render: (item) =>
      item ? (
        <Tag
          className={`font-medium text-sm rounded-3xl p-1 px-3 ${
            item === "Upcoming"
              ? "bg-blue-100 text-blue-500"
              : item === "Paid"
                ? "bg-green-100 text-green-600"
                : "bg-red-100 text-red-500"
          }`}
        >
          {item}
        </Tag>
      ) : null,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (item) => <p className="font-medium text-sm">${item / 100}</p>,
  },
  {
    title: "Due Date",
    dataIndex: "due_date",
    key: "dueDate",
  },
  {
    title: "Billing Month",
    dataIndex: "billing_month",
    key: "dueDate",
  },
  {
    title: "Download Invoice",
    key: "invoice_pdf",
    dataIndex: "invoice_pdf",
    render: (downloadLink) =>
      downloadLink ? (
        <div
          className="font-medium text-sm cursor-pointer"
          onClick={() => {
            if (downloadLink) {
              window.open(downloadLink, "_blank");
            }
          }}
        >
          {"Download"}
        </div>
      ) : (
        "-"
      ),
  },
];

const InvoicesTable: React.FC<{ invoices: any[] }> = ({ invoices }) => {
  const [loading, setLoading] = useState(false);
  const checkout = async () => {
    try {
      if (!loading) {
        setLoading(true);
        const { data } = await clientService.post(
          Api.stripePayment.createPortalSession,
          { current_domain: window.location.origin }
        );
        setLoading(false);
        window.open(data.url, "_blank");
      }
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };
  const downloadCSV = async () => {
    try {
      const filteredData = invoices.map(({ download, ...rest }) => ({
        ...rest,
        amount: rest.amount / 100, // Adjust amount to dollars if needed
      }));

      const csv = await json2csv(filteredData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "invoices.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log("CSV downloaded successfully");
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  useEffect(() => {}, []);
  return (
    <div className="mt-5">
      <Table
        rowKey={(e) => e.id}
        columns={columns}
        dataSource={invoices}
        pagination={false}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <p className="font-bold text-xl align-baseline">Invoices</p>
                </div>
                <div className="flex flex-row items-center justify-center space-x-3">
                  <div
                    onClick={checkout}
                    className={` text-black text-xs cursor-pointer font-semibold leading-none rounded-md border border-gray-300 py-2 px-3 gap-x-2 bg-white flex flex-row items-center`}
                  >
                    <LuLayoutDashboard className="text-xl" />
                    <p>Billing Portal</p>
                    {loading && <LuLoader className="animate-spin" />}
                  </div>
                  <div
                    className={` text-black text-xs cursor-pointer font-semibold leading-none rounded-md border border-gray-300 py-2 px-3 gap-x-2 bg-white flex flex-row items-center`}
                  >
                    <IoCloudDownloadOutline className="text-xl" />
                    <p onClick={downloadCSV}>Download CSV</p>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
export default InvoicesTable;
