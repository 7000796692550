import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./layout";
import AddNewAutomationForm from "./components/AddNewAutomationForm";
import { useAppDispatch } from "../hooks";
import { setMessages, setSelectedAutommation } from "../store/automation";

const Automations = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setMessages([]));
      dispatch(setSelectedAutommation(""));
    };
  }, []);

  return (
    <Layout>
      <div>
        <div className="">
          <h1 className="text-2xl font-semibold text-gray-900">
            Create Automation
          </h1>
        </div>

        <AddNewAutomationForm
          onReturn={() => navigate(`/automations`)}
          step={step}
          setStep={setStep}
        />
      </div>
    </Layout>
  );
};

export default Automations;
