// AddNewAutomationStepOneForm.tsx
import React from "react";
import { Formik, FormikValues } from "formik";
import { useAppSelector } from "../../hooks";
import getValidationSchema from "./validationSchemaStepOne";
import AutomationForm from "./AutomationForm";

interface AddNewAutomationStepOneFormProps {
  onNext: (values: FormikValues) => void;
  initialValues: FormikValues;
}

const AddNewAutomationStepOneForm: React.FC<AddNewAutomationStepOneFormProps> = ({
  onNext,
  initialValues,
}) => {
  const shops = useAppSelector((state) => state.shops);
  const validationSchema = getValidationSchema(shops.selectedStoreRegion);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onNext(values)}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue, validateField }) => (
        <AutomationForm
          values={values}
          setFieldValue={setFieldValue}
          validateField={validateField}
          shops={shops}
          isSubmitting={isSubmitting}
        />
      )}
    </Formik>
  );
};

export default AddNewAutomationStepOneForm;
