import { useState, useEffect } from "react";
import { CiFilter } from "react-icons/ci";
import { FiColumns } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { toast } from "react-toastify";
import { FaRegSadTear } from "react-icons/fa";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Badge, Tag, Spin, Tooltip, Image } from "antd";
import { TikTokOutlined } from "@ant-design/icons";
import CustomTable from "../../common/table";
import SearchComponent from "../../common/search";
import IconButton from "../../common/button";
import Layout from "./layout";
import CustomFilters from "../customFilter/customFilters";
import FilterFooter from "../customFilter/customFilterFooter";
import {
  updateFilters,
  convertToURLSearchParams,
  getSelectedFilters,
  getSelectedColumns,
  transformApiData,
  getStatusColor,
  createFieldJson,
  getCurrencySign,
  creatorLabels,
} from "./helper";
import CustomModal from "../../common/modal";
import BatchRemoveTagsModal from "./batchRemoveTagsModal";
import ColumnSelector from "./columnSelector";
import {
  addTagsToCreators,
  getCreators,
  getFilters,
  removeTagsToCreators,
  setFilterLoading,
  setSavedTags,
} from "../store/creators";
import { useAppDispatch, useAppSelector } from "../hooks";
import CustomButtonGroup from "./CustomButtonGroup";
import TagSelectComponent from "./TagSelect";


const MyCreators = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const creators = useAppSelector((state) => state.creators);
  // const [isModalOpen, setIsOpenModalOpen] = useState(false);
  const shops = useAppSelector((state) => state.shops);
  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams.entries());
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [search, setSearch] = useState("");
  const creatorLoading = creators.creatorLoading;
  const filterLoading = creators.filtersLoading;
  const [isAddTagModalVisible, setIsAddTagModalVisible] = useState<any>({
    selectedRow: "",
    data: {},
  });
  const savedTags = creators?.savedTags;
  const [isRemoveModalVisible, setIsRemoveTagModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openfiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const [createTag, setCreateTag] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const tags = filters?.tags?.options || [];
  console.log("savedTags12312321", savedTags);
  const getShopCreators = async (dataObject: any) => {
    await dispatch(
      getCreators({
        shop_id: shops.selectedStoreId,
        shop_region: shops.selectedStoreRegion || "US",
        filters: dataObject,
        filtersKey: location.search,
      })
    );
  };

  const getCreatorFilters = async () => {
    if (shops.selectedStoreId) {
      await dispatch(
        getFilters({
          shop_id: shops.selectedStoreId,
        })
      );
    }
  };

  let key = `${shops.selectedStoreId}-${shops.selectedStoreRegion}`;
  if (Object.values(paramsObject).length > 0) {
    key = `${shops.selectedStoreId}-${shops.selectedStoreRegion}-${location.search}`;
  }

  useEffect(() => {
    if (
      shops.selectedStoreId &&
      shops.selectedStoreRegion &&
      (creators?.creators?.[key] || [])?.length === 0 &&
      Object.keys(paramsObject).length === 0
    ) {
      getShopCreators({});
    }
  }, [
    shops.selectedStoreId,
    shops.selectedStoreRegion,
    Object.keys(paramsObject).length,
  ]);

  useEffect(() => {
    if ((creators?.creators?.[key] || []).length) {
      setTableColumns(
        createFieldJson(
          columns.map((column) => column.key),
          creatorsValues?.[0]
        )
      );
    }
  }, [(creators?.creators?.[key] || [])?.length]);

  useEffect(() => {
    if (Object.keys(creators.filters).length === 0) {
      getCreatorFilters();
    } else {
      dispatch(setFilterLoading(false));
      setFilters(
        updateFilters(transformApiData(creators.filters), paramsObject)
      );
      if ((creators?.creators?.[key] || [])?.length === 0) {
        if (Object.keys(paramsObject).length) {
          let dataObject: any = {};
          Object.keys(paramsObject).forEach((key) => {
            dataObject[key] = (paramsObject[key] || "").split(",");
          });
          getShopCreators(dataObject);
        }
      }
    }
  }, [creators.filters, location.search]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("newSelectedRowKeys12321", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleMultiSelectChange = (values: string[]) => {
    setIsAddTagModalVisible({
      ...isAddTagModalVisible,
      data: {
        ...isAddTagModalVisible.data,
        [isAddTagModalVisible.selectedRow]: { tags: values },
      },
    });
  };

  const handleSearch = (query: string) => {
    setSearch(query);
  };

  const removeTag = (tag: string, record: any) => {
    const tags = (savedTags?.[record.id]?.tags || record?.tags || []).filter(
      (el: any) => el !== tag
    );
    dispatch(
      setSavedTags({
        ...savedTags,
        [record.id]: { tags: tags },
      })
    );
    dispatch(
      removeTagsToCreators({
        shop_id: shops.selectedStoreId,
        creator_ids: [record.id],
        tags: [tag],
      })
    );
  };

  const handleBulkRemoveTags = () => {
    let existingTags: any = {};
    selectedRowKeys.forEach((el: string) => {
      existingTags[el] = {
        tags: [],
      };
    });
    dispatch(
      setSavedTags({
        ...savedTags,
        ...existingTags,
      })
    );
    dispatch(
      removeTagsToCreators({
        shop_id: shops.selectedStoreId,
        creator_ids: selectedRowKeys,
        tags: ["DELETE_ALL"],
      })
    );
    setIsRemoveTagModalVisible(false);
  };

  const filtersItems = [
    {
      name: "Clear All",
      handleClick: () => {
        handleResetFilters();
      },
      icon: <AiOutlineClose color={"#1E1E1E"} />,
      buttonClass:
        "flex items-center gap-2 text-gray-700 hover:text-black bg-transparent",
      textClass: "text-black",
    },
    {
      name: "Creator Filters",
      handleClick: () => {
        setOpenFiltersDrawer(true);
      },
      icon: <CiFilter color={"#1E1E1E"} />,
      badge: Object.keys(paramsObject).length,
      buttonClass: "bg-gray-200 border-solid border-1 border-black",
      textClass: "text-black",
    },
    {
      name: "Columns",
      handleClick: () => {},
      icon: <FiColumns color={"#1E1E1E"} />,
      showPopOver: true,
      buttonClass: "bg-gray-200 border-solid border-1 border-black",
      textClass: "text-black",
    },
  ];
  const fallbackImage = "/logo192.png";
  let columns = [
    {
      title: "Creator Name",
      dataIndex: "creator_name",
      key: "creator_name",
      width: 230,
      render: (creatorName: string, record: { avatar_url: string }) => (
        <div className="flex items-center">
          <img
            src={record.avatar_url}
            alt={creatorName}
            className="w-10 h-10 rounded-full mr-2"
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevent infinite loop in case fallback image fails
              e.currentTarget.src = fallbackImage; // Replace with fallback image
            }}
          />
          <div>
            <Link to={`https://tiktok.com/@${creatorName}`} target="_blank">
              <div>{creatorName}</div>
            </Link>
            <div className="text-gray-500">
              Followers: {record.follower_num}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: 200,
      render: (tags: string[], record: any) => {
        const updatedTags = [];
        const lowercaseSet = new Set();
        (savedTags[record.id] ? savedTags[record.id].tags : tags || []).forEach(
          (tag) => {
            const lowerTag = tag.toLowerCase();
            if (!lowercaseSet.has(lowerTag)) {
              lowercaseSet.add(lowerTag);
              updatedTags.push(tag);
            }
          }
        );
        return (
          <div>
            {updatedTags.length > 0 ? (
              <div className="flex flex-wrap">
                {updatedTags.map((tag, index) => (
                  <Tag
                    key={`tags-${index}`}
                    className="flex bg-gray-200 text-black px-2 py-1 rounded space-between items-center mb-2"
                    closable
                    style={{ justifyContent: "space-between", minWidth: 30 }}
                    closeIcon={<AiOutlineClose className="cursor-pointer" />}
                    onClose={() => {
                      removeTag(tag, record);
                    }}
                  >
                    {tag}
                  </Tag>
                ))}
                {createTag ? (
                  <div className="m-3" />
                ) : (
                  <IconButton
                    label={""}
                    onClick={() => {
                      handleAddTags({ ...record, tags: updatedTags });
                    }}
                    icon={<MdOutlineAdd color={"#1E1E1E"} />}
                    buttonClass="bg-gray-200 border-solid"
                    textClass="text-black"
                    style={{ height: 27, width: 40 }}
                  />
                )}
              </div>
            ) : createTag ? (
              <span>None</span>
            ) : (
              // <span className="text-gray-500">No Tags</span>
              <IconButton
                label={"Add Tags"}
                onClick={() => {
                  handleAddTags({ ...record, tags: [] });
                }}
                icon={<MdOutlineAdd color={"#1E1E1E"} />}
                buttonClass="bg-gray-200 border-solid"
                textClass="text-black"
              />
            )}
          </div>
        );
      },
    },

    {
      title: "Product Info",
      dataIndex: "product_title",
      key: "product_title",
      width: 200,
      render: (_: any, record: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image
            width={50}
            height={50}
            src={record.sku_image}
            alt={record.product_title}
            style={{
              marginRight: "16px",
              objectFit: "cover",
            }}
          />
          <div className="ml-4" style={{ maxWidth: "200px" }}>
            <Tooltip title={record.product_title}>
              <div
                style={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  cursor: "pointer",
                }}
              >
                {record.product_title || "-"}
              </div>
            </Tooltip>
            <div style={{ color: "#888" }}>ID: {record.product_id || "-"}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "curr_status",
      key: "curr_status",
      render: (status: string) => (
        <span
          className={"px-5 py-2 rounded-md border border-stroke"}
          style={{
            color: getStatusColor(status),
            backgroundColor: `${getStatusColor(status)}23`,
            borderColor: `${getStatusColor(status)}80`,
          }}
        >
          {status ? status : "-"}
        </span>
      ),
    },
    {
      title: "Video Posted",
      dataIndex: "video_num",
      key: "video_num",
      width: 150,
      render: (videoNum: any) =>
        videoNum ? <span>{videoNum} videos</span> : "-",
    },
    {
      title: "Livestreams",
      dataIndex: "live_num",
      key: "live_num",
      width: 100,
      render: (liveNum: any) => (liveNum ? <span>{liveNum} times</span> : "-"),
    },
    {
      title: "CFR",
      dataIndex: "fulfillment_rate",
      key: "fulfillment_rate",
      width: 100,
      render: (value: any) =>
        value ? <span>{(value * 100).toFixed(2)}%</span> : "-",
    },
    {
      title: "GMV",
      dataIndex: "gmv",
      key: "gmv",
      width: 100,
      render: (gmv: any) =>
        gmv ? (
          <span>
            {getCurrencySign(shops.selectedStoreRegion)}
            {gmv.toLocaleString()}
          </span>
        ) : (
          "-"
        ),
    },
  ];

  const isLink = (val: any) => {
    try {
      const url = new URL(val);
      if (url.protocol === "http:" || url.protocol === "https:") {
        return (
          <a href={val} target="_blank" rel="noopener noreferrer">
            <TikTokOutlined style={{ fontSize: "24px", color: "#000" }} />
          </a>
        );
      }
    } catch (e) {
      return val;
    }
  };

  const newColumns = getSelectedColumns(columns, tableColumns);
  const values = newColumns.map((column: any) => {
    return {
      title: column.label,
      dataIndex: column.field,
      key: column.field,
      render: (value: any) => (value ? isLink(value) : "-"),
    };
  });

  const tempColumns = columns.concat(values);

  const updatedColumns = tempColumns.filter((el) =>
    tableColumns.some((data) => data.selected && el.dataIndex === data.field)
  );

  const handleAddTags = (record: any) => {
    if (record.id === "all") {
      if (selectedRowKeys.length === 0) {
        toast.error("Please select at least one row");
      } else {
        // console.log("selectedRowKeys13213", selectedRowKeys);
        handleOpenModalAdd(record, selectedRowKeys);
      }
    } else {
      handleOpenModalAdd(record);
    }
  };

  const handleRemoveTags = () => {
    // setSelectedTags([]);
    if (selectedRowKeys.length === 0) {
      toast.error("Please select at least one row");
    } else {
      setIsRemoveTagModalVisible(true);
    }
  };

  const handleExit = () => {
    setCreateTag(false);
    // Add your function logic here
  };

  const buttons = [
    {
      label: "Add tags",
      onClick: () => handleAddTags({ id: "all", tags: [] }),
      type: "primary",
      className: "bg-black",
    },
    {
      label: "Remove tags",
      onClick: handleRemoveTags,
      type: "default",
    },
    { label: "Exit", onClick: handleExit, type: "text" },
  ];

  const handleOpenModalAdd = (record: any, selectedRowKeys: any = []) => {
    if (selectedRowKeys.length) {
      let initialTags: any = {};
      let existingTags: any = {};
      selectedRowKeys.forEach((el: string) => {
        const user = (creators?.creators?.[key] || [])?.find(
          (creator) => creator.id === el
        );
        initialTags[el] = { tags: [] };
        existingTags[el] = {
          tags: savedTags?.[user.id]?.tags || user?.tags || [],
        };
      });
      setIsAddTagModalVisible({
        data: {
          ...isAddTagModalVisible.data,
          ...initialTags,
        },
        selectedRow: record.id,
      });
      dispatch(
        setSavedTags({
          ...savedTags,
          ...existingTags,
        })
      );
    } else {
      setIsAddTagModalVisible({
        data: {
          ...isAddTagModalVisible.data,
          [record.id]: { tags: [] },
        },
        selectedRow: record.id,
      });
      dispatch(
        setSavedTags({
          ...savedTags,
          [record.id]: { tags: record.tags },
        })
      );
    }
  };

  const handleCancel = () => {
    setIsAddTagModalVisible({
      data: isAddTagModalVisible.data,
      selectedRow: "",
    });
  };

  const handleConfirm = () => {
    // Add your tag addition logic here
    if (isAddTagModalVisible.selectedRow === "all") {
      let initialTags: any = {};
      let existingTags: any = {};
      selectedRowKeys.forEach((el: string) => {
        const user = (creators?.creators?.[key] || [])?.find(
          (creator) => creator.id === el
        );
        initialTags[el] = { tags: [] };
        existingTags[el] = {
          tags: (savedTags?.[user.id]?.tags || []).concat(
            isAddTagModalVisible.data[isAddTagModalVisible.selectedRow].tags
          ),
        };
      });
      dispatch(
        setSavedTags({
          ...savedTags,
          ...existingTags,
        })
      );

      dispatch(
        addTagsToCreators({
          shop_id: shops.selectedStoreId,
          creator_ids: selectedRowKeys,
          tags: isAddTagModalVisible.data[isAddTagModalVisible.selectedRow]
            .tags,
        })
      );
      setIsAddTagModalVisible({
        data: {},
        selectedRow: "",
      });
    } else {
      dispatch(
        setSavedTags({
          ...savedTags,
          [isAddTagModalVisible.selectedRow]: {
            tags: [
              ...savedTags[isAddTagModalVisible.selectedRow].tags,
              ...isAddTagModalVisible.data[isAddTagModalVisible.selectedRow]
                .tags,
            ],
          },
        })
      );
      dispatch(
        addTagsToCreators({
          shop_id: shops.selectedStoreId,
          creator_ids: [isAddTagModalVisible.selectedRow],
          tags: isAddTagModalVisible.data[isAddTagModalVisible.selectedRow]
            .tags,
        })
      );
      setIsAddTagModalVisible({
        data: isAddTagModalVisible.data,
        selectedRow: "",
      });
    }
    setCreateTag(false);
  };

  const handleUpdateColumns = (updatedColumns: Column[]) => {
    setTableColumns(updatedColumns);
    // setTableColumns(values);
  };

  const handleResetFilters = () => {
    const resetFilters = Object.keys(filters).reduce(
      (acc, key) => {
        const filter = filters[key as keyof typeof filters];
        acc[key as keyof typeof filters] = {
          ...filter,
          options: filter.options?.map((option) => ({
            ...option,
            selected: false,
          })),
          value: "",
          selected: false,
        };
        return acc;
      },
      {} as typeof filters
    );

    setFilters(resetFilters);
    navigate(`/my-creators`, { replace: true });
  };

  const creatorsValues = creators?.creators?.[key] || [];

  const filterCreatorTags = (values: any) => {
    if (paramsObject["tags"]) {
      const updatedTags = paramsObject["tags"].split(",");
      return values.filter((creator: any) =>
        updatedTags.some((tag: any) =>
          ((savedTags?.[creator.id] || {}).tags || creator.tags).includes(tag)
        )
      );
    }
    return values;
  };
  const filtered =
    filterCreatorTags(
      search
        ? creatorsValues.filter((creator) =>
            creator.creator_name.toLowerCase().includes(search.toLowerCase())
          )
        : creatorsValues
    ) || [];

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      const selectedKeys = selectedRows.map((row) => row.key);
      setSelectedRowKeys(selectedKeys);
    },
    onSelectAll: (selected: boolean) => {
      if (selected) {
        console.log("12312312312321321");
        const allKeys = filtered.map((item) => item.id); // Select all keys from the data
        console.log("allKeys12312312", allKeys);
        setSelectedRowKeys(allKeys);
      } else {
        setSelectedRowKeys([]); // Deselect all rows
      }
    },
  };

  return (
    <Layout>
      {/* <TutorialModal
        isModalOpen={isModalOpen}
        closeModal={() => {
          setIsOpenModalOpen(false);
        }}
      /> */}
      <CustomModal
        visible={openfiltersDrawer}
        onCancel={() => {
          setOpenFiltersDrawer(false);
        }}
        title={
          <div>
            <div className="mb-2">More Filters</div>
            <div className="border-b-2 border-gray-300 mb-5" />
          </div>
        }
        showModalFooter={false}
        width={700}
        content={
          <>
            <div className="overflow-y-auto max-h-[60vh] min-h-[40vh]">
              {filterLoading ? (
                <Spin />
              ) : (
                <CustomFilters filters={filters} setFilters={setFilters} />
              )}
            </div>
            <FilterFooter
              onClearAll={() => {
                handleResetFilters();
                setOpenFiltersDrawer(false);
              }}
              onApplyFilters={() => {
                const selectedFilters = getSelectedFilters(filters);
                const urlParams = convertToURLSearchParams(selectedFilters);

                // Example of how to get the string representation for a URL
                navigate(`/my-creators?${urlParams.toString()}`, {
                  replace: true,
                });
                setOpenFiltersDrawer(false);
              }}
            />
          </>
        }
      />
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-xl">My Creators</h3>
        {/* <div
          className="flex items-center cursor-pointer"
          onClick={() => setIsOpenModalOpen(true)}
        >
          <LuBook className="mr-1 text-gray-20" />
          <h3 className="text-l text-gray-20 mr-2">View Tutorial</h3>
        </div> */}
      </div>

      <>
        <div className="flex justify-between flex-wrap">
          <div className="mt-4">
            <SearchComponent
              search={search}
              setSearch={setSearch}
              onSearch={handleSearch}
              placeholder="Search by name"
              label={"Creator Name"}
            />
          </div>

          <div className="flex mt-4">
            {filtersItems.map((filter, index) => (
              <div
                className="mr-4 hover:bg-black-500 hover:border-black-500 hover:text-white transition duration-300"
                key={`filter-${filter.name}`}
              >
                <Badge count={filter.badge || 0}>
                  <ColumnSelector
                    columns={tableColumns}
                    onUpdate={handleUpdateColumns}
                    showButton={
                      filter.name === "Clear All"
                        ? Object.keys(paramsObject).length > 0
                        : true
                    }
                    restProps={{
                      label: filter.name,
                      showPopOver: filter.showPopOver,
                      onClick: filter.handleClick,
                      icon: filter.icon,
                      buttonClass: filter.buttonClass,
                      textClass: filter.textClass,
                    }}
                  />
                </Badge>
              </div>
            ))}
          </div>
        </div>
        {creatorLoading || filtered.length > 0 || creatorsValues.length > 0 ? (
          <div className="mt-4 mb-8">
            <div className="flex justify-between mb-4">
              <div>
                {!createTag && (
                  <IconButton
                    label={"Tag creators"}
                    onClick={() => {
                      setSelectedRowKeys([]);
                      setCreateTag(true);
                    }}
                    buttonClass="bg-black"
                    textClass="text-white"
                  />
                )}

                <CustomButtonGroup buttons={buttons} show={createTag} />
              </div>
            </div>

            <CustomModal
              visible={isAddTagModalVisible.selectedRow}
              title="Batch add tags"
              content={
                <div>
                  <p className="text-gray-500">
                    Only 3 tags can be added per creator.
                  </p>
                  <TagSelectComponent
                    isAddTagModalVisible={isAddTagModalVisible}
                    tags={tags}
                    handleMultiSelectChange={handleMultiSelectChange}
                  />
                </div>
              }
              onCancel={() => handleCancel()}
              onConfirm={handleConfirm}
              confirmText="Add tags"
              cancelText="Cancel"
              confirmButtonType="primary"
              showModalFooter
            />
            <BatchRemoveTagsModal
              visible={isRemoveModalVisible}
              onCancel={() => {
                setIsRemoveTagModalVisible(false);
              }}
              onConfirm={handleBulkRemoveTags}
            />
            {creatorLoading ? (
              <div className="flex items-center justify-center h-[55vh]">
                <Spin size="large" />
              </div>
            ) : (
              <CustomTable
                columns={updatedColumns}
                data={filtered.map((el: any) => ({ key: el.id, ...el }))}
                loading={false}
                rowSelection={createTag && rowSelection}
                restProps={{
                  scroll: { x: "max-content" },
                }}
                labels={creatorLabels}
                showDownloadOptions
              />
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col mt-10">
            <FaRegSadTear size={60} className="text-gray-400 mb-4" />
            <p className="text-gray-500">No Data</p>
          </div>
        )}
      </>
    </Layout>
  );
};

export default MyCreators;
