import React, { useEffect, useState } from "react";
import { Upload, Tooltip } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

interface CustomImageUploaderProps {
  value?: File | null;
  onChange: (file: File | null) => void;
  accept?: string;
  label?: string;
  hint?: string;
  error?: string | null;
  allowRemove?: boolean;
  previousImageName?: string;
}

const CustomImageUploader: React.FC<CustomImageUploaderProps> = ({
  value = null,
  onChange,
  accept = ".png,.jpg,.jpeg",
  label = "Upload Image",
  hint = "Click or drag image file to this area to upload",
  error = null,
  allowRemove = true,
  previousImageName = "",
}) => {
  const [file, setFile] = useState<File | null>(value);

  useEffect(() => {
    if (file?.preview) {
      return () => {
        URL.revokeObjectURL(file.preview); // Clean up the object URL
      };
    }
  }, [file]);

  const handleFileChange = (file: File) => {
    if (file.preview) {
      URL.revokeObjectURL(file.preview); // Revoke the previous URL
    }
    file.preview = URL.createObjectURL(file); // Generate a new preview URL
    setFile(file);
    onChange(file);
    return false; // Prevent the file from being uploaded automatically
  };

  const handleFileRemove = () => {
    if (file?.preview) {
      URL.revokeObjectURL(file.preview); // Revoke the URL
    }
    setFile(null);
    onChange(null);
  };

  return (
    <div>
      {label && <label className="block text-xs mt-2 font-semibold text-gray-600 mb-2">{label}</label>}
      <Dragger
        accept={accept}
        multiple={false}
        beforeUpload={(file) => handleFileChange(file)}
        onRemove={allowRemove ? handleFileRemove : undefined}
        fileList={
          file
            ? [
                {
                  uid: file.name || "xxx-xxx-xxx",
                  name: file.name || "image.jpg",
                  status: "done",
                  url: file.preview || "image.jpg",
                },
              ]
            : []
        }
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{hint}</p>
        <p className="ant-upload-hint">Support for a single file upload</p>
      </Dragger>
      {error && <div className="mt-2 text-red-600">{error}</div>}
      {previousImageName && (
        <div className="mt-2 flex text-sm items-center text-black font-semibold">
          <span>
            Previously Uploaded Image:{" "}
            <span className="text-blue-600">{previousImageName}</span>
          </span>
        </div>
      )}
    </div>
  );
};

export default CustomImageUploader;
