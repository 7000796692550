import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  startAutomation,
  stopAutomation,
  deleteAutomation,
  getAutomations,
  postAddAutomation,
  getAutomationDetail,
} from "../../store/automation";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { toast } from "react-toastify";
import { handleError, handleWarning } from "../../helpers";
import {
  Button,
  Input,
  InputRef,
  Select,
  Space,
  Table,
  TableColumnType,
  TableProps,
  Tag,
  Tooltip,
} from "antd";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import AutomationListActions from "./AutomationListActions";
import dayjs from "dayjs";
import AffiliateManagerModal from "../../dashboard-new/AffiliateManagerModal";
import VerifyingAffiliateManagerModal from "../../dashboard-new/VerifyingModal";
import {getShopsList, userConfirmsAliasAdded} from "../../store/shops";

interface Shop {
  shop_id: string | number;
  account_email: string;
  onboarding_status: string;
  onboarding_link: string;
}

interface AutomationListProps {
  automations: any;
  isAutomationsLoading: boolean;
  onRowClick?: (dataItem: any) => void; // Function to handle row click
  onboarding_status: string;
  shop: Shop;
  // refreshShops: () => void;
}

interface DataType {
  key: string;
  automation_name: string;
  automation_type: string;
  created_at: string;
  sent_messages: string;
  remaining_creators: string;
  status: string;
  automation_id: number; // Unique identifier for the automation, this will be used as the action column & key for the table
}

type DataIndex = keyof DataType;

const AutomationList: React.FC<AutomationListProps> = ({
  automations,
  onRowClick,
  onboarding_status,
  shop,
  // refreshShops,
}) => {
  const shops = useAppSelector((state) => state.shops);
  const admin = useAppSelector((state) => state.admin);
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams.entries());

  const [tableData, setTableData] = useState<DataType[]>([]);
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isModalContactVisible, setIsContactModalVisible] = useState(false);
  const [automationIdToStart, setAutomationIdToStart] = useState<number | null>(
    null
  );
  const [verificationStatus, setVerificationStatus] = useState("Not verified");

  useEffect(() => {
    setTableDataLoading(true);

    // Transform the data for our table format
    console.log(automations);
    if (!automations) return;
    /*
    const transformedData = automations.map(item => ({
      key: item.automation_id.toString(),
      automation_name: item.automation_name,
      automation_type: item.automation_type,
      created_at: item.created_at.split('T')[0],
      sent_messages: item.sent_messages.toString(),
      remaining_creators: item.remaining_creators,
      status: item.status,
      automation_id: item.automation_id
    }));
    */
    const transformedData = automations.map((item) => ({
      ...item,
      key: item.automation_id.toString(),
      created_at: item.created_at.split("T")[0],
    }));
    console.log("Transformed Data: ", transformedData);
    setTableData(transformedData);
    setTableDataLoading(false);
  }, [automations]);
  const refreshShops = async () => {
    return dispatch(getShopsList({customer_id: auth.meInfo.customer_id}));
  };
  // State to track starting
  const [isRunning, setIsRunning] = useState(false);

  const handleStartAutomation = async (
    automationId: number,
    shop_id: number
  ) => {
    const result = await dispatch(
      startAutomation({
        automation_id: automationId,
        shop_id: shop_id,
      })
    );

    if (startAutomation.rejected.match(result)) {
      // @ts-ignore
      if (result.payload || !result.payload) {
        handleWarning(result.payload);
      }
      return;
    }

    toast.success("Automation Started!");
    dispatch(
      getAutomations({
        shop_id: shop_id,
      })
    );
  };

  function getShopObject(shopId: number, data: Record<string, any[]>): any {
    for(let i of data){
      if(i.shop_id === shopId)
      return i
    }
  }


  const handleStart = async (automationId: number, selectedStoreId: number) => {
    console.log(onboarding_status, "onboarding_status");
    if (
      onboarding_status !== "onboard_complete" &&
      onboarding_status !== "added_to_affiliate_portal" && 
      onboarding_status !== "container_created"
    ) {
      const latestShops = await refreshShops();
      const shops = latestShops?.payload || [];
      shop = await getShopObject(selectedStoreId, shops);
      console.log(shop, "shop");
      if (shop.onboarding_status === "onboard_complete" || shop.onboarding_status === "added_to_affiliate_portal" || shop.onboarding_status === "container_created") {
        console.log("Shop is ready to start automation");
      } else{
        if (
          onboarding_status !== "onboard_complete" &&
          onboarding_status !== "added_to_affiliate_portal" && 
          onboarding_status !== "container_created"
        ) {
          setIsModalVisible(true);
          setAutomationIdToStart(automationId);
          return
        }
      }
    }

    setIsRunning(true);
    await handleStartAutomation(automationId, selectedStoreId);
    setIsRunning(false);
  };

  const handleEdit = () => {

  }

  // State to track stopping
  const [isStopping, setIsStopping] = useState(false);

  const handleStopAutomation = async (
    automationId: number,
    shop_id: number
  ) => {
    const result = await dispatch(
      stopAutomation({
        automation_id: automationId,
        shop_id: shop_id,
      })
    );

    if (stopAutomation.rejected.match(result)) {
      // @ts-ignore
      if (result.payload || !result.payload) {
        handleWarning(result.payload);
      }
      return;
    }

    toast.success("Automation Stopped!");
    dispatch(
      getAutomations({
        shop_id: shop_id,
      })
    );
  };

  const handleStop = async (automationId: number, selectedStoreId: number) => {
    setIsStopping(true);
    await handleStopAutomation(automationId, selectedStoreId);
    setIsStopping(false);
  };

  // State to track deletion
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAutomation = async (
    automationId: number,
    shop_id: number
  ) => {
    const result = await dispatch(
      deleteAutomation({
        automation_id: automationId,
        shop_id: shop_id,
      })
    );

    if (deleteAutomation.rejected.match(result)) {
      // @ts-ignore
      if (result.payload || !result.payload) {
        handleError(result.payload);
      }
      return;
    }

    toast.success("Automation Deleted!");
    dispatch(
      getAutomations({
        shop_id: shop_id,
      })
    );
  };

  const handleDelete = async (
    automationId: number,
    selectedStoreId: number
  ) => {
    setIsDeleting(true);
    await handleDeleteAutomation(automationId, selectedStoreId);
    setIsDeleting(false);
  };

  // Column Search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleDuplicate = async (automationData) => {
    try {
      // Extract the existing automation data
      const result = await dispatch(getAutomationDetail({
        shop_id: shops.selectedStoreId,
        automation_id: automationData.automation_id
      }))
      let valid_until = automationData?.valid_until || "";
      automationData = result.payload
      let {
        automation_type,
        creators_to_omit,
        creators_to_include,
        config,
      } = automationData;

      // Set the product_ids to an empty array if the automation type is Message or Message + Image
      let product_ids = config?.DESIRED_PRODUCT_IDS || [];
      if (automation_type === "Message" || automation_type === "Message + Image") {
        product_ids = [];
      }
  
      // Prepare the categories list for duplication
      let categoriesList = config?.Filters?.Creators?.["Product Categories"] || [];
      categoriesList = categoriesList.map((category) => ({
        Main: category.Main,
      }));
  
      // Process time frame data from existing automation or newValues
      const timeFrame = config?.SCHEDULE || {};
  
      // Adjust content type preference for target collab automation
      let contentType = config?.content_type || "No preference";
      if (
        !["No preference", "Shoppable video", "Shoppable LIVE"].includes(
          contentType
        )
      ) {
        contentType = "No preference";
      }
  
      // Adjust target collab message
      let targetCollabMessage = config?.TARGET_COLLAB_MESSAGE || "";
      if (targetCollabMessage.includes("{creator_name}")) {
        targetCollabMessage = targetCollabMessage.replace(
          /{creator_name}/g,
          "{{creators username}}"
        );
      }
  
      // Adjust target collab name
      let targetCollabName = config?.BASE_INVITATION_NAME || "";
      if (targetCollabName.includes("  ")) {
        targetCollabName = targetCollabName.replace(/\s+/g, " ");
      }
      if (targetCollabName[targetCollabName.length - 1] === " ") {
        targetCollabName = targetCollabName.slice(0, -1);
      }
  
      // Create FormData and append the payload data
      const formData = new FormData();
      formData.append(
        "customer_id",
        auth?.meInfo?.customer_id?.toString() ?? ""
      );
      formData.append("shop_id", shops.selectedStoreId?.toString() ?? "");
      formData.append("shop_name", shops.selectedStoreName ?? "");
      formData.append("automation_name", automationData.automation_name ?? "");
      formData.append("status", "inactive"); // New automation starts as inactive
      formData.append("automation_type", automation_type ?? "");
      formData.append("creators_to_omit", JSON.stringify(creators_to_omit || []));
      formData.append(
        "creators_to_include",
        JSON.stringify(creators_to_include || [])
      );
  
      // Add image file if it exists in the original automation
      if (automationData.file) {
        formData.append("file", automationData.file);
        formData.append("file_name", automationData.file.name);
      }
  
      // Append config data as JSON string
      const duplicatedConfig = {
        ...config,
        DESIRED_PRODUCT_IDS: product_ids,
        Filters: {
          ...config.Filters,
          Creators: {
            ...config.Filters.Creators,
            "Product Categories": categoriesList,
          },
        },
        BASE_INVITATION_NAME: targetCollabName,
        TARGET_COLLAB_MESSAGE: targetCollabMessage,
        content_type: contentType,
        SCHEDULE: timeFrame,
        VALID_UNTIL: valid_until
      };
  
      // Add follow-up steps if they exist
      if (config?.FOLLOW_UP_STEPS) {
        duplicatedConfig.FOLLOW_UP_STEPS = config.FOLLOW_UP_STEPS
      }
  
      formData.append("config", JSON.stringify(duplicatedConfig));
  
      console.log("Duplicated FormData before sending", formData);
  
      // Submit the duplicated automation
      await dispatch(postAddAutomation(formData));
      toast.success("Automation Duplicated Successfully!");
      dispatch(getAutomations({ shop_id: shops.selectedStoreId }));
      return 
    } catch (error) {
      console.error("Error duplicating automation:", error);
    }
  };

  // Columns
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "automation_name",
      key: "automation_name",
      fixed: "left",
      ...getColumnSearchProps("automation_name"),
      render: (name: string, record: any) => {
        return record.crm_group_id ? (
          <div>
            <Link to={`/groups/${record.crm_group_id}`}>{name}</Link>
            <Tooltip title="Group">
              <InfoCircleOutlined className="ml-3 text-gray-400" />
            </Tooltip>
          </div>
        ) : (
          name
        );
      },
    },
    {
      title: "ID",
      dataIndex: "automation_id",
      key: "automation_id",
      fixed: "left",
      ...getColumnSearchProps("automation_id"),
      render: (automation_id: string) => {
        return (
          <Link to={`/automations/edit/${automation_id}`}>{automation_id}</Link>
        )
      }
    },
    {
      title: "Type",
      dataIndex: "automation_type",
      key: "automation_type",
      filters: [
        {
          text: "Message + Product Card",
          value: "Message + Product Card",
        },
        {
          text: "Message + Target Collab",
          value: "Message + Target Collab",
        },
        {
          text: "Message",
          value: "Message",
        },
      ],
      onFilter: (value, record) =>
        record.automation_type.startsWith(value as string),
      filterSearch: true,
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        // Parse the strings with timezone awareness
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        // Compare the Date objects
        return dateA.getTime() - dateB.getTime();
      },
    },
    {
      title: "Creators Reached",
      dataIndex: "sent_messages",
      key: "sent_messages",
      sorter: (a, b) =>
        parseInt(a.sent_messages, 10) - parseInt(b.sent_messages, 10),
    },
    {
      title: "Creators Remaining",
      key: "remaining_creators",
      dataIndex: "remaining_creators",
      sorter: (a, b) =>
        parseInt(a.remaining_creators, 10) - parseInt(b.remaining_creators, 10),
    },
    {
      title: "Last Finished At",
      key: "last_run_end",
      dataIndex: "last_run_end",
      width: 200,
      render: (text) => {
        return text
          ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") // Format date and time
          : "-"; // Show '-' if the value is not available
      },
      sorter: (a, b) => {
        const dateA = a.last_run_end ? new Date(a.last_run_end).getTime() : 0;
        const dateB = b.last_run_end ? new Date(b.last_run_end).getTime() : 0;
        return dateA - dateB;
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "150px",
      fixed: "right",
      render: (status: string, record) => {
        let color = "green";
        let displayText = "Running";

        if (status === "stopped") {
          color = "gray";
          displayText = "Stopped";
        } else if (status === "failed") {
          color = "gray";
          displayText = "Failed";
        } else if (status === "completed") {
          color = "green";
          displayText = "Completed";
        } else if (status === "continue") {
          color = "green";
          displayText = record?.status_msg;
        } else if (status === "running") {
          color = "green";
          displayText = "Running";
        } else if (status === "paused_by_limit") {
          color = "yellow";
          displayText = "Paused by TikTok’s Limit";
        } else if (status === "queue") {
          color = "yellow";
          displayText = "In Queue";
        }
        const statusDetails = record?.status_details || null;

        return (
          <>
            <div className="flex">
              <Tag color={color} key={status} style={{ cursor: "pointer" }}>
                {displayText}
              </Tag>
              {statusDetails && (
                <Tooltip title={statusDetails}>
                  <InfoCircleOutlined className="text-gray-400" />
                </Tooltip>
              )}
            </div>
          </>
        );
      },
      filters: [
        {
          text: "Continue",
          value: "continue",
        },
        {
          text: "Stopped",
          value: "stopped",
        },
        {
          text: "Paused by limit",
          value: "paused",
        },
        {
          text: "Running",
          value: "running",
        },
        {
          text: "Failed",
          value: "failed",
        },
        {
          text: "Completed",
          value: "completed",
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value as string),
      filterSearch: true,
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      width: "240px",
      render: (text, record) => (
        <AutomationListActions
          record={record}
          isRunning={isRunning} // Pass any additional props you need
          isStopping={isStopping}
          isDeleting={isDeleting}
          handleStart={handleStart}
          handleStop={handleStop}
          handleDelete={handleDelete}
          handleDuplicate={handleDuplicate}
          onRowClick={onRowClick}
          shops={shops}
        />
      ),
    },
  ];

  const handleEmailSearch = (value: any) => {
    setSelectedEmail(value);
    if (value) {
      const filteredEmailData = tableData.filter(
        (el: any) => el.account_email === value
      );
      setTableData(filteredEmailData);
    } else {
      const transformedData = automations.map((item) => ({
        ...item,
        key: item.automation_id.toString(),
        created_at: item.created_at.split("T")[0],
      }));
      setTableData(transformedData);
    }
  };

  const onlyUnique = (value: any, index: any, array: any) => {
    return array.indexOf(value) === index;
  };

  const handleConfirm = () => {
    setButtonDisabled(true);
    setVerificationStatus("Verifying");
    dispatch(
      userConfirmsAliasAdded({
        shop_id: Number(shop.shop_id),
        automation_id: automationIdToStart,
      })
    )
      .then(() => {
        setIsModalVisible(false);
        setIsContactModalVisible(true);
        setButtonDisabled(false);
        setVerificationStatus("Verified");
      })
      .catch((error: any) => {
        setButtonDisabled(false);
        console.error("Error confirming alias:", error);
        setVerificationStatus("Verification failed");
      });
  };

  const refreshAutomations = () => {
    dispatch(getAutomations({ shop_id: Number(shop.shop_id) }));
  };

  const handleCloseModalContact = () => {
    setIsContactModalVisible(false);
    // refreshShops();
    refreshAutomations();
  };

  return (
    <div className="mt-10">
      {shop?.onboarding_status !== "added_to_affiliate_portal" &&
        shop?.onboarding_status !== "onboard_complete" &&
        shop?.onboarding_status !== "container_created" &&
        (
          <>
            <AffiliateManagerModal
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              onConfirm={handleConfirm}
              account_email={(shop as Shop)?.account_email}
              disabled={buttonDisabled}
              onboarding_status={shop?.onboarding_status}
            />
            <VerifyingAffiliateManagerModal
              visible={isModalContactVisible}
              account_email={(shop as Shop)?.account_email}
              onCancel={handleCloseModalContact}
              onboarding_status={shop?.onboarding_status}
            />
          </>
        )}

      <div className="flex justify-between">
        {false && (
          <Select
            size="large"
            onChange={(value) => {
              handleEmailSearch(value);
            }}
            placeholder="Selected Region"
            value={selectedEmail}
            style={{ minWidth: 300 }}
          >
            {tableData
              .map((el: any) => el.account_email)
              .filter(onlyUnique)
              .map((option, index) => (
                <Select.Option key={`${option}-${index}`} value={option}>
                  {option.label}
                </Select.Option>
              ))}
          </Select>
        )}
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={tableDataLoading}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
            defaultPageSize: 5,
          }}
          // scroll={{ x: 1100 }}
        />
      </div>
    </div>
  );
};

export default AutomationList;
