import React from "react";
import { Input, Tooltip } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";

// Define the props for the custom input component
interface CustomInputProps {
  label: any;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  infoTooltip?: string;
  customStyle?: any;
  restProps?: any
}

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  infoTooltip,
  customStyle = {},
  restProps = {}
}) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-1 flex items-center">
        {label}
        {infoTooltip && (
          <Tooltip title={infoTooltip}>
            <AiOutlineInfoCircle className="ml-1 text-gray-400 cursor-pointer" />
          </Tooltip>
        )}
      </label>
      <Input
        {...restProps}
        className="border rounded px-2 py-1"
        placeholder={placeholder}
        value={value}
        style={customStyle}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default CustomInput;
