import React from "react";
import { Field, ErrorMessage } from "formik";
import { Button } from "antd";

interface FormikButtonComponentProps {
  name: string;
  options: string[];
}

const FormikButtonComponent: React.FC<FormikButtonComponentProps> = ({
  name,
  options,
}) => {
  return (
    <div>
      <Field name={name}>
        {({ form }: any) => (
          <div className="grid grid-cols-2 gap-2">
            {options.map((option) => (
              <Button
                key={option}
                type="button"
                className={`py-3 px-3 rounded-lg text-sm font-medium ${
                  form.values[name] === option
                    ? "bg-blue-100 text-blue-800 border border-blue-600"
                    : "bg-white text-gray-600 border border-gray-300 hover:border-blue-600 hover:text-blue-800"
                }`}
                onClick={() => form.setFieldValue(name, option)}
              >
                {option}
              </Button>
            ))}
          </div>
        )}
      </Field>
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm mt-2"
      />
    </div>
  );
};

export default FormikButtonComponent;
