const Skeletons = () => {
    return (
        <div className="animate-pulse">
            <div className="w-1/3 bg-gray-300 rounded-3xl h-10 mb-9"></div>

            {/* Table Skeleton */}
            <div className="bg-white shadow rounded-lg">
                {/* Table Header */}
                <div className="grid grid-cols-12 gap-4 px-6 py-4">
                    <div className="col-span-2 bg-gray-300 h-6 rounded-md"></div>
                    <div className="col-span-3 bg-gray-300 h-6 rounded-md"></div>
                    <div className="col-span-2 bg-gray-300 h-6 rounded-md"></div>
                    <div className="col-span-3 bg-gray-300 h-6 rounded-md"></div>
                    <div className="col-span-2 bg-gray-300 h-6 rounded-md"></div>
                </div>

                {/* Table Rows Skeleton */}
                <div className="divide-y divide-gray-200">
                    {Array.from({ length: 5 }).map((_, index) => (
                        <div key={index} className="grid grid-cols-12 gap-4 px-6 py-4">
                            <div className="col-span-2 bg-gray-200 h-6 rounded-md"></div>
                            <div className="col-span-3 bg-gray-200 h-6 rounded-md"></div>
                            <div className="col-span-2 bg-gray-200 h-6 rounded-md"></div>
                            <div className="col-span-3 bg-gray-200 h-6 rounded-md"></div>
                            <div className="col-span-2 bg-gray-200 h-6 rounded-md"></div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Pagination Skeleton */}
            <div className="mt-5 flex justify-between">
                <div className="bg-gray-300 h-6 w-24 rounded-md"></div>
                <div className="flex flex-row">
                    <div className="bg-gray-300 h-6 w-12 rounded-md ml-2"></div>
                    <div className="bg-gray-300 h-6 w-12 rounded-md ml-2"></div>
                </div>
            </div>
        </div>
    );
};

export default Skeletons