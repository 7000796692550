import { Spin } from "antd";
import React from "react";
import { useAppSelector } from "../../../pages/hooks";

function WeeklyOutreach({
  name,
  value,
  prepend,
  defaultValue,
}: {
  name: string;
  value: string;
  prepend?: string;
  defaultValue?: string;
}) {
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const customer_id = auth.meInfo.customer_id;
  const isDataAvailable = typeof shops.shops?.[customer_id] !== "undefined";

  return (
    <div className="flex-1 bg-primary-default p-6 border-r border-secondary">
      <p className="text-tertiary-default mb-2 text-sm font-medium">{name}</p>
      {typeof value !== "undefined" ? (
        <p className="text-primary-default font-semibold text-2xl">
          {prepend}
          {value ? value : (defaultValue || "-") }
        </p>
      ) : isDataAvailable ? (
        "-"
      ) : (
        <Spin />
      )}
    </div>
  );
}

export default WeeklyOutreach;
