import { Calendar, ChevronLeft, ChevronRight } from "untitledui-js-base";
import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import PrimaryButton from "../../../pages/components/PrimaryButton";

type DateRangePickerProps = {
  filterDateRange: (startDate: Date, endDate: Date) => void;
  defaultStartDate: any,
  defaultEndDate: any
};

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({
  filterDateRange,
  defaultStartDate,
  defaultEndDate
}) => {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(defaultStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(defaultEndDate);
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth()
  );
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );
  const calendarRef = useRef<HTMLDivElement>(null);
  const toggleCalendar = useCallback(() => {
    setIsCalendarVisible((prev) => !prev);
  }, []);

  const handleDateClick = useCallback(
    (date: Date) => {
      if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
        setSelectedStartDate(date);
        setSelectedEndDate(null);
      } else if (selectedStartDate && !selectedEndDate) {
        if (date >= selectedStartDate) {
          setSelectedEndDate(date);
        } else {
          setSelectedStartDate(date);
        }
      }
    },
    [selectedStartDate, selectedEndDate]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsCalendarVisible(false);
      }
    };
    if (isCalendarVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isCalendarVisible]);

  const renderDays = useMemo(() => {
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDay = new Date(currentYear, currentMonth, 1).getDay();
    const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1;

    const prevMonthDays = new Date(currentYear, currentMonth, 0).getDate();
    const days: JSX.Element[] = [];

    for (let i = adjustedFirstDay - 1; i >= 0; i--) {
      const prevDate = new Date(
        currentYear,
        currentMonth - 1,
        prevMonthDays - i
      );
      const isSelectedStart =
        selectedStartDate &&
        prevDate.toDateString() === selectedStartDate.toDateString();
      const isSelectedEnd =
        selectedEndDate &&
        prevDate.toDateString() === selectedEndDate.toDateString();
      const isInRange =
        selectedStartDate &&
        selectedEndDate &&
        prevDate > selectedStartDate &&
        prevDate < selectedEndDate;
      const isHovered =
        selectedStartDate &&
        !selectedEndDate &&
        hoveredDate &&
        prevDate > selectedStartDate &&
        prevDate <= hoveredDate;

      days.push(
        <div
          className={`h-10 w-10 flex items-center ${isSelectedStart && "rounded-l-full bg-active"
            } ${isSelectedEnd && "rounded-r-full bg-active"}`}
          key={`prev-${i}`}
        >
          <div
            onClick={() => handleDateClick(prevDate)}
            onMouseEnter={() => setHoveredDate(prevDate)}
            className={`p-2 h-10 w-10 text-center cursor-pointer transition-colors hover:bg-active text-gray-400
                        ${!isInRange && !isHovered && "hover:rounded-full"}
                        ${isSelectedStart || isSelectedEnd
                ? "bg-brand-solid-default z-50 rounded-full hover:bg-brand-solid-hover text-white"
                : ""
              }
                        ${isHovered || isInRange ? "bg-active font-medium" : ""}
                        ${(isInRange || isHovered) && prevDate.getDay() === 1
                ? "rounded-l-full"
                : ""
              }
                        ${(isInRange || isHovered) && prevDate.getDay() === 0
                ? "rounded-r-full"
                : ""
              }
                    `}
          >
            {prevDate.getDate()}
          </div>
        </div>
      );
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(currentYear, currentMonth, day);
      const isSelectedStart =
        selectedStartDate &&
        currentDate.toDateString() === selectedStartDate.toDateString();
      const isSelectedEnd =
        selectedEndDate &&
        currentDate.toDateString() === selectedEndDate.toDateString();
      const isInRange =
        selectedStartDate &&
        selectedEndDate &&
        currentDate > selectedStartDate &&
        currentDate < selectedEndDate;
      const isHovered =
        selectedStartDate &&
        !selectedEndDate &&
        hoveredDate &&
        currentDate > selectedStartDate &&
        currentDate <= hoveredDate;

      const isMonday = currentDate.getDay() === 1;
      const isSunday = currentDate.getDay() === 0;
      days.push(
        <div
          className={`h-10 w-10 flex items-center ${isSelectedStart && "rounded-l-full bg-active"
            } ${isSelectedEnd && "rounded-r-full bg-active"}`}
          key={day}
        >
          <div
            onClick={() => handleDateClick(currentDate)}
            onMouseEnter={() => setHoveredDate(currentDate)}
            className={`p-2 h-10 w-10 text-center cursor-pointer transition-colors hover:bg-active ${!isInRange && !isHovered && "hover:rounded-full"
              }
                            ${isSelectedStart || isSelectedEnd
                ? "bg-brand-solid-default z-50 rounded-full hover:bg-brand-solid-hover text-white"
                : ""
              }
                            ${isHovered || isInRange
                ? "bg-active font-medium"
                : ""
              }
                            ${(isInRange || isHovered) && isMonday
                ? "rounded-l-full"
                : ""
              }
                            ${(isInRange || isHovered) && isSunday
                ? "rounded-r-full"
                : ""
              }
                            `}
          >
            {day}
          </div>
        </div>
      );
    }

    const remainingCells = 42 - days.length;
    for (let i = 1; i <= remainingCells; i++) {
      const nextDate = new Date(currentYear, currentMonth + 1, i);
      const isSelectedStart =
        selectedStartDate &&
        nextDate.toDateString() === selectedStartDate.toDateString();
      const isSelectedEnd =
        selectedEndDate &&
        nextDate.toDateString() === selectedEndDate.toDateString();
      const isInRange =
        selectedStartDate &&
        selectedEndDate &&
        nextDate > selectedStartDate &&
        nextDate < selectedEndDate;
      const isHovered =
        selectedStartDate &&
        !selectedEndDate &&
        hoveredDate &&
        nextDate > selectedStartDate &&
        nextDate <= hoveredDate;

      days.push(
        <div
          className={`h-10 w-10 flex items-center ${isSelectedStart && "rounded-l-full bg-active"
            } ${isSelectedEnd && "rounded-r-full bg-active"}`}
          key={`next-${i}`}
        >
          <div
            onClick={() => handleDateClick(nextDate)}
            onMouseEnter={() => setHoveredDate(nextDate)}
            className={`p-2 h-10 w-10 text-center cursor-pointer transition-colors hover:bg-active text-gray-400
                        ${!isInRange && !isHovered && "hover:rounded-full"}
                        ${isSelectedStart || isSelectedEnd
                ? "bg-brand-solid-default z-50 rounded-full hover:bg-brand-solid-hover text-white"
                : ""
              }
                        ${isHovered || isInRange ? "bg-active font-medium" : ""}
                        ${(isInRange || isHovered) && nextDate.getDay() === 1
                ? "rounded-l-full"
                : ""
              }
                        ${(isInRange || isHovered) && nextDate.getDay() === 0
                ? "rounded-r-full"
                : ""
              }
                    `}
          >
            {nextDate.getDate()}
          </div>
        </div>
      );
    }

    return days;
  }, [
    currentYear,
    currentMonth,
    selectedStartDate,
    selectedEndDate,
    hoveredDate,
    handleDateClick,
  ]);

  const handlePrevMonth = useCallback(() => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear((prevYear) => prevYear - 1);
    } else {
      setCurrentMonth((prevMonth) => prevMonth - 1);
    }
  }, [currentMonth]);

  const handleNextMonth = useCallback(() => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear((prevYear) => prevYear + 1);
    } else {
      setCurrentMonth((prevMonth) => prevMonth + 1);
    }
  }, [currentMonth]);

  return (
    <div className="relative" ref={calendarRef}>
      <button
        onClick={toggleCalendar}
        className={`w-60 px-3 py-3 duration-300 ease-in-out ${isCalendarVisible && 'outline outline-utility-brand-500 outline-offset-1'} border text-secondary-default border-primary shadow-xs rounded-md space-x-2 flex items-center  bg-primary-default hover:bg-primary-hover duration-300 ease-in-out h-11`}
      >
        <Calendar />
        <div
          className={`flex flex-row text-nowrap ${selectedStartDate && selectedEndDate
            ? "justify-between"
            : "justify-center space-x-3"
            } text-sm w-full font-semibold`}
        >
          <span>
            {selectedStartDate
              ? selectedStartDate.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
              : "--/--/--"}
          </span>
          <span>-</span>
          <span>
            {selectedEndDate
              ? selectedEndDate.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
              : "--/--/--"}
          </span>
        </div>
      </button>

      {isCalendarVisible && (
        <div className="absolute top-12 w-80 right-0 bg-primary-default border border-secondary rounded-xl justify-center shadow-lg z-10">
          <div>
            <div className="flex justify-between items-center text-secondary-default h-9 mx-6 mt-5 mb-3">
              <button
                className="w-9 h-9 flex items-center justify-center rounded-md hover:bg-active"
                onClick={handlePrevMonth}
              >
                <ChevronLeft />
              </button>
              <h3 className="font-semibold">
                {new Date(currentYear, currentMonth).toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })}
              </h3>
              <button
                className="w-9 h-9 flex items-center justify-center rounded-md hover:bg-active"
                onClick={handleNextMonth}
              >
                <ChevronRight />
              </button>
            </div>
            <div className="mx-6 flex flex-row justify-between mb-3 space-x-2 items-center text-primary-default text-base">
              <div className="w-32 rounded-md px-lg py-md border border-primary shadow-xs text-center text-nowrap ">
                {selectedStartDate
                  ? selectedStartDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                  : "MM/DD/YYYY"}
              </div>
              <span className="text-xl">-</span>
              <div className="w-32 rounded-md px-lg py-md border border-primary shadow-xs text-center text-nowrap ">
                {selectedEndDate
                  ? selectedEndDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                  : "MM/DD/YYYY"}
              </div>
            </div>
            <div className="grid grid-cols-7 text-center mx-6 text-sm font-medium">
              <div className="h-10 w-10 grid place-items-center">Mo</div>
              <div className="h-10 w-10 grid place-items-center">Tu</div>
              <div className="h-10 w-10 grid place-items-center">We</div>
              <div className="h-10 w-10 grid place-items-center">Th</div>
              <div className="h-10 w-10 grid place-items-center">Fr</div>
              <div className="h-10 w-10 grid place-items-center">Sa</div>
              <div className="h-10 w-10 grid place-items-center">Su</div>
            </div>
            <div className="grid grid-cols-7 gap-y-1 mx-6 text-secondary-default mb-5">
              {renderDays}
            </div>
          </div>
          <div className="border-t border-t-secondary grid grid-cols-2 gap-x-3 bg-transparent items-stretch p-4 text-sm font-semibold">
            <button
              className="rounded-md grid place-items-center h-10 flex-1 border border-secondary py-2.5 px-3.5 text-secondary-default"
              onClick={() => setIsCalendarVisible(false)}
            >
              Cancel
            </button>
            {/* <button
              className="rounded-md grid place-items-center h-10 border border-secondary flex-1 py-2.5 px-3.5 text-white bg-brand-solid-default"
              onClick={() => {
                filterDateRange(selectedStartDate!, selectedEndDate!);
                toggleCalendar();
              }}
            >
              Apply
            </button> */}
            <PrimaryButton
              type="button"
              className="!py-1 !h-8 !rounded-md !w-full"
              onClick={() => {
                filterDateRange(selectedStartDate!, selectedEndDate!);
                toggleCalendar();
              }}            >
              Apply
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDateRangePicker;
