import React from "react";
import { Button } from "antd";
import User from "./svgs/User";
import TikTok from "./svgs/Tiktok";
import { useAppSelector } from "../hooks";

// Sample data array with onClick functions for each item

const GettingStarted = ({
  handleOpenModal,
  handleOpenModalTTS,
  onboarding_status,
}) => {
  const shops = useAppSelector((state) => state.shops);
  const shopAffiliated = shops.shopAffiliatedWithReacher;
  const steps = [
    {
      id: 1,
      title: "Setup Affiliate Manager",
      verified: false,
      status: onboarding_status === "added_to_affiliate_portal" ?
      "progress" :
      onboarding_status === "onboard_complete" || onboarding_status === "container_created"
        ? "complete"
        : "pending",
      buttonLabel:  onboarding_status === "added_to_affiliate_portal" ?
      "Verifying..." :
      onboarding_status === "onboard_complete" || onboarding_status === "container_created"
        ? "Verified"
        : "Verify",
      icon: <User />,
      onClick: () => {
        handleOpenModal(true);
      },
    },
    {
      id: 2,
      title: "Authenticate on TTS App",
      verified: shopAffiliated,
      buttonLabel: "Authenticate",
      icon: <TikTok />,
      onClick: () => handleOpenModalTTS(true),
      hide: false,
    },
  ];
  return (
    <div className="w-full flex flex-col space-y-4">
      <h3 className="text-lg font-semibold mb-4">Getting Started</h3>
      <div className="flex flex-row flex-wrap justify-between">
        {steps.map((step) => {
          if (step.hide) return <div />;
          return (
            <div
              key={step.id}
              className="flex items-center justify-between p-4 border rounded-lg shadow-md bg-white w-[calc(50%-8px)] h-[96px]"
            >
              <div className="flex items-center space-x-4">
                {step.icon}
                <div>
                  <span className="block font-medium mb-2">{step.title}</span>
                  <div className="flex">

                    {
                      step.id === 1 ? (
                        <div
                        className={`flex items-center p-2 border border-gray-200 rounded-md justify-center text-xs`}
                      >
                          <div
                            className={`mr-2 w-2 h-2 rounded-full text-xs ${
                              step.status === "complete" ? "bg-green-500" : 
                              step.status === "progress" ? "bg-yellow-500" :
                              "bg-red-500"
                            }`}
                          ></div>
                          <span
                            className={`${
                              step.status === "complete" ? "text-green-700" :
                              step.status === "progress" ? "text-yellow-700" :
                              "text-red-700"
                            } font-medium text-x`}
                          >
                            {step.status === "complete" ? "Verified" :
                             step.status === "progress" ? "Verifying..." :
                             "Not verified"}
                          </span>
                        </div>

                      ):(
                        <div
                        className={`flex items-center p-2 border border-gray-200 rounded-md justify-center text-xs`}
                      >
                        <div
                          className={`mr-2 w-2 h-2 rounded-full text-xs ${
                            step.verified ? "bg-green-500" : "bg-red-500"
                          }`}
                        ></div>
                        <span
                          className={`${step.verified ? "text-green-700" : "text-red-700"} font-medium text-x`}
                        >
                          {step.verified ? "Verified" : "Not verified"}
                        </span>
                      </div>
  
                      )
                    }

                  </div>
                </div>
              </div>

  
              {
                step.id === 1 ? (
                  <Button
                    type="primary"
                    className={`bg-blue-500 px-5 text-white rounded-md ${
                      step.status === "complete" || step.status === "progress" ? 'cursor-not-allowed' : 'hover:bg-blue-600'
                    } disabled:bg-gray-400 disabled:text-white`}
                    onClick={step.status !== "complete" && step.status !== "progress" ? step.onClick : undefined}
                    disabled={step.status === "complete" || step.status === "progress"}
                  >
                    {step.buttonLabel}
                  </Button>
                ):(
                  <Button
                  type="primary"
                  className={`bg-blue-500 px-5 text-white rounded-md ${
                    !step.verified ? 'hover:bg-blue-600' : 'cursor-not-allowed'
                  } disabled:bg-gray-400 disabled:text-white`}
                  onClick={!step.verified ? step.onClick : undefined}
                  disabled={step.verified}
                >
                  {step.buttonLabel}
                </Button>
                )}
                 
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GettingStarted;
