import React from 'react'
import { plans } from '../../../utils/plans';

const BillingSummary = ({ quantities }: any) => {
    const { id: b_id, name: b_name, price: b_price } = plans[process.env.REACT_APP_BASIC_ID as string];
    const { id: p_id, name: p_name, price: p_price } = plans[process.env.REACT_APP_PRO_ID as string];
    return (
        <div className='flex flex-col bg-white justify-between mt-8 p-4 rounded-xl'>
            <p className='font-semibold text-black text-base'>Billing Summary</p>

            {(quantities.find((item: any) => item.id === b_id)?.qty) ? <div className='flex flex-row justify-between border-b py-3 border-gray-100'>
                <p className='text-gray-500 font-medium text-sm'>{b_name} x {quantities.find((item: any) => item.id === b_id)?.qty}</p>
                <p className='font-semibold text-sm text-black'>${(quantities.find((item: any) => item.id === b_id)?.qty) * b_price}</p>
            </div> : null}
            {(quantities.find((item: any) => item.id === p_id)?.qty) ? <div className='flex flex-row justify-between border-b py-3 border-gray-100'>
                <p className='text-gray-500 font-medium text-sm'>{p_name} x {quantities.find((item: any) => item.id === p_id)?.qty}</p>
                <p className='font-semibold text-sm text-black'>${(quantities.find((item: any) => item.id === p_id)?.qty) * p_price}</p>
            </div> : null}
            <div className='flex flex-row justify-between pt-3'>
                <p className='text-black text-sm font-medium'>Total</p>
                <p className='text-blue-600 text-sm font-semibold'>${((quantities.find((item: any) => item.id == b_id)?.qty) * b_price) + ((quantities.find((item: any) => item.id === p_id)?.qty) * p_price)}</p>
            </div>
        </div>
    )
}

export default BillingSummary