import React from "react";
import { Badge } from "antd";
import CustomModal from "../../common/modal";
import { UserOutlined } from "@ant-design/icons";

interface VerifyingAffiliateManagerModalProps {
  visible: boolean;
  onboarding_status: string;
  onCancel: () => void;
  account_email: string;
}

const VerifyingAffiliateManagerModal: React.FC<
  VerifyingAffiliateManagerModalProps
> = ({ visible, onboarding_status, onCancel, account_email }) => {
  const content = (
    <div className="space-y-4">
      {/* Status Section */}
      <div className="flex items-center">
        <Badge
          status={onboarding_status === "complete" ? "success" : "warning"}
          text={
            <span className={onboarding_status === "complete" ? "text-green-500" : "text-yellow-500"}>
              {onboarding_status === "complete" ? "Verified" : 
               onboarding_status === "progress" ? "Verifying..." : 
               "Not verified"}
            </span>
          }
        />
      </div>

      {/* Affiliate Manager Email Verification */}
      <div>
        <p className="font-semibold">AM email:</p>
        <p>
          Verifying that <strong>{account_email}</strong> has been added to
          your shop as an Affiliate Manager.
        </p>
      </div>
      {onboarding_status === "complete" ? (
        <div />
      ) : (
        <div>
          <p className="font-semibold">Help?</p>
          <p className="text-sm text-gray-700">
            If your account is not verified within 1-2 minutes, please contact
            support at{" "}
            <a
              href="mailto:contact@reacherai.com"
              className="text-blue-500 underline"
            >
              contact@reacherai.com
            </a>{" "}
            or click the button below.
          </p>
        </div>
      )}
    </div>
  );

  return (
    <CustomModal
      visible={visible}
      title={
        <div className="flex items-center space-x-2">
          <UserOutlined className="text-lg" />
          <span>Set up affiliate manager</span>
        </div>
      }
      content={content}
      onCancel={onCancel}
      confirmText={onboarding_status === "complete" ? undefined : "Contact Reacher"}
      showModalFooter={onboarding_status !== "complete"} // Show footer only if not verified
      width={500}
    />
  );
};

export default VerifyingAffiliateManagerModal;
