import React, { useEffect, useRef } from "react";
import { Button, Tooltip } from "antd";
import CustomTextArea from "./CustomTextArea";

interface CustomMessageEntryTextFieldProps {
  fieldName: string;
  value: string;
  onChange: (name: string, value: string) => void;
}

const CustomMessageEntryTextField: React.FC<CustomMessageEntryTextFieldProps> = ({
  fieldName = "message_entry",
  value,
  onChange,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      setTimeout(() => {
        textAreaRef.current.focus();
      }, 0);
    }
  }, []);

  const addCreatorName = () => {
    if (textAreaRef.current) {
      const textAreaElement = textAreaRef.current;

      // Get the cursor and selection details
      const start = textAreaRef.current.resizableTextArea.textArea.selectionStart;
      const end = textAreaRef.current.resizableTextArea.textArea.selectionEnd;

      // Replace selected text or insert at cursor position
      const newValue =
        value.slice(0, start) + "{creator_name}" + value.slice(end);

      // Update the field value
      onChange(fieldName, newValue);

      // Calculate the new cursor position after insertion
      const newCursorPosition = start + "{creator_name}".length;

      // Set focus and adjust the cursor position
      setTimeout(() => {
        textAreaElement.focus();
        textAreaRef.current.resizableTextArea.textArea.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    }
  };

  const messageEntryLabel = (
    <div className="flex items-end justify-between space-x-2 text-gray-700 font-medium">
      <Tooltip title="Please avoid using the word 'amazon' in the message entry.">
        <div>
          <label className="flex items-center text-xs pl-1 font-semibold mt-6 text-gray-700">
            Message Body
          </label>
        </div>
      </Tooltip>
      <Button
        type="dashed"
        onClick={addCreatorName}
        style={{
          padding: "2px 8px",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          color: "#1890ff",
          borderColor: "#1890ff",
        }}
      >
        Add {`{creator_name}`}
      </Button>
    </div>
  );

  return (
    <CustomTextArea
      name={fieldName}
      value={value}
      onChange={onChange}
      label={messageEntryLabel}
      maxLength={2000}
      placeholder="Enter your message here..."
      helperText='Make sure to include "{creator_name}" to personalize the message.'
      restProps={{
        ref: (ref: HTMLTextAreaElement) => {
          textAreaRef.current = ref; // Capture the reference to the textarea
        },
      }}
    />
  );
};

export default CustomMessageEntryTextField;
