import React, { useEffect, useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import InputField from "../../components/forms/InputField";
import FormikSelectField from "../../components/formikForms/FormikSelectField";
import { useAppSelector, useAppDispatch } from "../../hooks";
import getValidationSchema from "./emailValidationSchemaStepOne";
import { getRegionBasedFilters } from "../../store/automation";
import CustomFilters from "../../../pages/automations/components/CustomFilters";
import { Spin, InputNumber } from "antd";

interface AddNewEmailAutomationStepOneFormProps {
  onNext: (values: FormikValues) => void;
  initialValues: FormikValues;
  onReturn: () => void;
  unusedEmails: { email_account_id: string; account: string }[];
  email_account?: string;
  inputNumber: number;
  setInputNumber: (value: number) => void;
}

const AddNewEmailAutomationStepOneForm: React.FC<
  AddNewEmailAutomationStepOneFormProps
> = ({ onNext, onReturn, initialValues, unusedEmails, email_account, inputNumber, setInputNumber }) => {

  const dispatch = useAppDispatch();
  const shops = useAppSelector((state) => state.shops);
  const automation = useAppSelector((state) => state.automation);

  const filters = automation.filters?.[shops.selectedStoreRegion] || [];
  const loading = automation.loading;
  useEffect(() => {
    if (shops.selectedStoreRegion) {
      dispatch(
        getRegionBasedFilters({ shopRegion: shops.selectedStoreRegion })
      );
    }
  }, [shops.selectedStoreRegion, dispatch]);

  const validationSchema = getValidationSchema(shops.selectedStoreRegion);

  const creatorsFilters = filters.filter((filter: any) =>
    ["category", "followers", "products"].includes(filter.name)
  );
  const followersFilters = filters.filter((filter: any) =>
    ["age", "gender"].includes(filter.name)
  );
  const performanceFilters = filters.filter((filter: any) =>
    [
      "gmv",
      "engagement",
      "units",
      "views",
      "fulfillment_rate",
      "gpm",
      "video_views",
      "quickly_response",
      "videos",
      "video_gpm",
      "live_gpm",
      "high_sample_dispatch_rate",
    ].includes(filter.name)
  );

  const handleChange = (value: number) => {
    setInputNumber(value)
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log("Form submitted", values);
        onNext(values);
      }}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form className="mt-6 mb-2 w-full">
          {/* Basic Form Fields */}
          <InputField
            type="text"
            placeholder="i.e. Mystery Box New Year Sale"
            name="automation_name"
            label="Automation Name"
            size="md"
            isRequired={true}
          />

          <FormikSelectField
            name="email_account_id"
            label="Select Email Account"
            options={[
              ...(email_account
                ? [
                    {
                      value: initialValues.email_account_id,
                      label: email_account,
                    },
                  ]
                : []),
              ...unusedEmails.map((email) => ({
                value: email.email_account_id.toString(),
                label: email.account,
              })),
            ]}
            placeHolder="Select an email account"
            isRequired={true}
          />
          <div className="flex flex-col items-start space-y-2 pb-6">
            <label className="text-gray-700 font-semibold">
              Emails Sent per Day
            </label>
            <InputNumber
              name="email_sent_per_day"
              min={1}
              max={60}
              value={inputNumber}
              defaultValue={40}
              onChange={handleChange}
              className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <p className="text-sm text-gray-500">
              Please enter a value between 1 and 60.
            </p>
          </div>
          {loading ? (
            <Spin />
          ) : (
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-10">
              <CustomFilters filters={creatorsFilters} title="Creators" column={true} />
              <CustomFilters filters={followersFilters} title="Followers" column={true} />
              <CustomFilters filters={performanceFilters} title="Performance" column={true} />
            </div>
          )}
          <div className="flex justify-between mb-4 w-full mt-2">
            <button
              className="bg-black hover:bg-gray-900 text-white font-semibold py-3 px-4 rounded-md focus:outline-none focus:shadow-outline"
              disabled={isSubmitting}
              onClick={onReturn}
            >
              Return to Automations
            </button>

            <button
              className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out"
              type="submit"
              onClick={() => console.log("Form errors:", errors)}
            >
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewEmailAutomationStepOneForm;
