import React, {useState} from "react";
import {Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
import {Button} from "antd";

const AddNewEntryForm = ({isFormOpen, setIsFormOpen, addNewProduct}) => {
  const [localValues, setLocalValues] = useState({
    name: "",
    video_url: "",
    spark_code: "",
  });
  const initialValues = {
    name: "",
    video_url: "",
    spark_code: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Product Name is required"),
    video_url: Yup.string()
      .url("Invalid URL format")
      .required("Video URL is required"),
    spark_code: Yup.string().required("Spark Code is required"),
  });

  const handleChange = (field, value) => {
    setLocalValues((prev) => ({...prev, [field]: value}));
  };
  const [outerrors, setErrors] = useState({
    name: "",
    video_url: "",
    spark_code: "",
  });


  const handleClick = () => {
    const errors = {};
    if (!localValues.name) errors.name = "Product Name is required";
    if (!localValues.video_url)
      errors.video_url = "Video URL is required";
    else if (
      !/^https?:\/\/.+$/.test(localValues.video_url)
    )
      errors.video_url = "Invalid URL format";
    if (!localValues.spark_code) errors.spark_code = "Spark Code is required";
    if (Object.keys(errors).length === 0) {
      const videoIdMatch = localValues.video_url.match(/\/video\/(\d+)/);
      const videoId = videoIdMatch ? videoIdMatch[1] : "";

      const newProduct = {
        products: [{"name": localValues.name, "id": ""}],

        video_url: localValues.video_url,
        spark_code: localValues.spark_code,
        video_id: videoId,
      };

      addNewProduct(newProduct); // Push the new product to Formik's products array
      setIsFormOpen(false); // Close the modal
      setLocalValues({name: "", video_url: "", spark_code: ""}); // Reset the form

    } else {
      setErrors(errors);
    }


  };

  return (
    isFormOpen && (
      <Form>
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4">Add New Entry</h2>
            <div className="mb-4">
              <label className="block text-sm mb-1">Product Name</label>
              <input
                type="text"
                value={localValues.name}
                onChange={(e) => handleChange("name", e.target.value)}
                placeholder="Enter Product Name"
                className="w-full p-2 border rounded"
              />
              {outerrors.name && (
                <div className="text-red-500 text-sm mt-1">
                  {outerrors.name}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-sm mb-1">Video URL</label>
              <input
                type="text"
                value={localValues.video_url}
                onChange={(e) => handleChange("video_url", e.target.value)}
                placeholder="Enter Video URL"
                className="w-full p-2 border rounded"
              />
              {outerrors.video_url && (
                <div className="text-red-500 text-sm mt-1">
                  Video URL is required
                </div>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-sm mb-1">Spark Code</label>
              <textarea
                value={localValues.spark_code}
                onChange={(e) => handleChange("spark_code", e.target.value)}
                placeholder="Enter Spark Code"
                className="w-full p-2 border rounded"
              ></textarea>
              {outerrors.spark_code && (
                <div className="text-red-500 text-sm mt-1">
                  Spark Code is required
                </div>
              )}
            </div>

            <div className="flex justify-end gap-2">
              <Button
                type="button"
                onClick={() => setIsFormOpen(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => handleClick()}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Add Entry
              </Button>
            </div>
          </div>
        </div>
      </Form>
    )
  );
};
export default AddNewEntryForm;