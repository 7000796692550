import React from "react";
import CustomTable from "../../../common/table";

const PreviousInvitationCreators: React.FC = ({ data }) => {
  const paginatedData = data;

  const columns = [
    {
      title: "Creator",
      dataIndex: "creator",
      key: "creator",
    },
  ];

  return (
    <div className="border border-stroke border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-800 mb-2">
        Previously Invited Creators
      </h3>
      <CustomTable
        showDownloadOptions
        columns={columns}
        data={paginatedData.map((item, index) => ({
          creator: item,
        }))}
        loading={false}
      />
    </div>
  );
};

export default PreviousInvitationCreators;
