// defaultFormData.ts
import * as Yup from "yup";

export const MESSAGE_ENTRY__DEFAULT_CONTENT =
  "Hey {creator_name}!\n\nWe love the content that you put out and would love to be a part of that. We can see our products aligning with your brand and would love to help others see that too.\n \nWe would love to get you involved in our campaign on TikTok Shop and we're willing to boost high-quality content so you can make more sales with 20% commissions! As a token of our appreciation, we'd love to send you a sample!\n\nPlease let us know if you're interested and if you have any questions.\n\nThanks and I look forward to collaborating with you!\n";

export const MESSAGE_ENTRY_NO_REPLY_DEFAULT_CONTENT =
  "Hey there, just following up to see if you had a chance to check out any of our products? Let me know if you have any questions or need help with anything! We would love to work with you!";

export const MESSAGE_ENTRY_INTEREST_DEFAULT_CONTENT =
  "Thank you for your interest in collaborating with us. Please add the products that you are interested in to your product showcase. We look forward to working with you!";

export const MESSAGE_ENTRY_MAX_SAMPLES_REQUESTED_DEFAULT_CONTENT =
  "It looks like you have reached the maximum number of samples requested, but we would still love to work with you! Please join our target collaboration. Thanks! -Reacher";

export const MESSAGE_ENTRY_TARGET_COLLAB_DEFAULT_CONTENT =
  "Hey {{creators username}}! I would love to collaborate with you because I can see that you love to post related content!";

export const DEFAULT_CREATOR_DM_MESSAGE =
  "Hey {creator_name} ! We just sent over a target collaboration invitation, please check it out. We're excited to work with you on promoting our products through TikTok. Let me know if you have any questions about the collaboration or our products!";

export const DEFAULT_FOLLOW_UP_MESAGE =
  "Hi {creator_name}, we wanted to follow up on our previous message.";

export const DEFAULT_CREATOR_CONTENT_FLYWHEELING = `Hi {creator_name}! 👋
We’re loving your content! 🎥✨ To help you boost your success, we’ve compiled some of the best-performing TikTok videos from other creators driving amazing results.

💡 Why check these out?
- Get inspired by winning ideas.
- Create trends that audiences love.
- Maximize your revenue potential with proven strategies.

Here are a few examples to explore:
📹 

We can’t wait to see your unique spin on these ideas! Let us know if you’d like more tips—happy creating! 🚀
`;

export const DEFAULT_SPARK_CODE_MESSAGE = `Hi {creator_name}!👋,
We absolutely love the content you create and would be thrilled to use it as Spark Ads!
Could you please share your video Spark code by clicking the link below?
Thank you, and we’re excited to collaborate with you!`;
const defaultAmount = "2500"; // You can make this dynamic

export const defaultFormData = {
  message_entry: MESSAGE_ENTRY__DEFAULT_CONTENT,
  message_entry_no_reply: MESSAGE_ENTRY_NO_REPLY_DEFAULT_CONTENT,
  message_entry_interest: MESSAGE_ENTRY_INTEREST_DEFAULT_CONTENT,
  message_entry_max_samples:
    MESSAGE_ENTRY_MAX_SAMPLES_REQUESTED_DEFAULT_CONTENT,
  selectedMessageType: "Message",
  addons: [],
  // Target Collab Default Values
  message_entry_target_collab: MESSAGE_ENTRY_TARGET_COLLAB_DEFAULT_CONTENT,
  message_entry_content_flywheeling: DEFAULT_CREATOR_CONTENT_FLYWHEELING,
  message_entry_spark_code: DEFAULT_SPARK_CODE_MESSAGE,
  target_collab_invitation_name: "",
  target_collab_valid_until: "",
  target_collab_email: "",
  target_collab_phone: "",
  target_collab_offer_free_samples: false,
  target_collab_manually_approve: false,
  target_collab_product_comission_rates: [{}],
  content_type: "No preference",

  days: "",
  no_reply_automation: false,
  interest_automation: false,
  max_samples_automation: false,

  // Default values for product automation
  product_id: [],
  automation_name: "",
  followers: [],
  engagement: "",
  gender: "",
  automation_type: "Message",
  outreach_type: "list_upload",
  gmv: [],
  category: [],
  age: [],
  units: [],
  products: [],
  high_sample_dispatch_rate: [],
  quickly_response: [],
  videos: [],
  views: "",
  fulfillment_rate: "",
  creators_to_omit: [],
  creators_to_include: [],
  only_message_include_list: false,
  video_gpm: [],
  live_gpm: [],
  video_views: [],
  monday_time: "07:00",
  tuesday_time: "07:00",
  wednesday_time: "07:00",
  thursday_time: "07:00",
  friday_time: "07:00",
  saturday_time: "07:00",
  sunday_time: "07:00",
  monday_amount: defaultAmount,
  tuesday_amount: defaultAmount,
  wednesday_amount: defaultAmount,
  thursday_amount: defaultAmount,
  friday_amount: defaultAmount,
  saturday_amount: defaultAmount,
  sunday_amount: defaultAmount,
  schedule_checkboxes: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  },
  image: null,
  send_messages_to_all_creators_even_if_messaged_before: true,
  followupSteps: []
};

export const new_automation_description = "Enter the automation essentials.";
export const message_and_taget_collab_description =
  "Enter the message to be sent to the creator.";

export const creators_and_timeframe_description =
  "Upload the creators to omit/include.";
export const follow_up_description = "Enter the follow up settings.";

export const CONTENT_PREFERENCE_OPTIONS = [
  { value: "No preference", label: "No preference" },
  { value: "Shoppable video", label: "Shoppable video" },
  { value: "Shoppable LIVE", label: "Shoppable LIVE" },
];

export const CATEGORY_OPTIONS = [
  { value: "Automotive & Motorcycle", label: "Automotive & Motorcycle" },
  { value: "Baby & Maternity", label: "Baby & Maternity" },
  { value: "Beauty & Personal Care", label: "Beauty & Personal Care" },
  { value: "Books, Magazines & Audio", label: "Books, Magazines & Audio" },
  { value: "Collectibles", label: "Collectibles" },
  {
    value: "Computers & Office Equipment",
    label: "Computers & Office Equipment",
  },
  { value: "Fashion Accessories", label: "Fashion Accessories" },
  { value: "Food & Beverages", label: "Food & Beverages" },
  { value: "Furniture", label: "Furniture" },
  { value: "Health", label: "Health" },
  { value: "Home Improvement", label: "Home Improvement" },
  { value: "Home Supplies", label: "Home Supplies" },
  { value: "Household Appliances", label: "Household Appliances" },
  {
    value: "Jewelry Accessories & Derivatives",
    label: "Jewelry Accessories & Derivatives",
  },
  { value: "Kitchenware", label: "Kitchenware" },
  { value: "Luggage & Bags", label: "Luggage & Bags" },
  { value: "Menswear & Underwear", label: "Menswear & Underwear" },
  { value: "Pet Supplies", label: "Pet Supplies" },
  { value: "Phones & Electronics", label: "Phones & Electronics" },
  { value: "Shoes", label: "Shoes" },
  { value: "Sports & Outdoor", label: "Sports & Outdoor" },
  {
    value: "Textiles & Soft Furnishings",
    label: "Textiles & Soft Furnishings",
  },
  { value: "Tools & Hardware", label: "Tools & Hardware" },
  { value: "Toys & Hobbies", label: "Toys & Hobbies" },
  { value: "Womenswear & Underwear", label: "Womenswear & Underwear" },
];

export const CATEGORY_OPTIONS_UK = [
  { value: "Beauty", label: "Beauty" },
  { value: "Electronics", label: "Electronics" },
  { value: "Fashion", label: "Fashion" },
  { value: "Food", label: "Food" },
  { value: "Home & Lifestyle", label: "Home & Lifestyle" },
  { value: "Mum & Baby", label: "Mum & Baby" },
  { value: "Personal Care & Health", label: "Personal Care & Health" },
];

export const AGE_OPTIONS = [
  { value: "18-24", label: "18 - 24" },
  { value: "25-34", label: "25 - 34" },
  { value: "35-44", label: "35 - 44" },
  { value: "45-54", label: "45 - 54" },
  { value: "55+", label: "55+" },
];

export const AGE_OPTIONS_UK = [
  { value: "18-24", label: "18 - 24" },
  { value: "25-34", label: "25 - 34" },
  { value: "35 and above", label: "35 and above" },
];

export const GENDER_OPTIONS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "All", label: "All" },
];

// ["$0-$50", "$50-$100", "$100-$1K", "$1K-$5K", "$5K-$10K", "$10K-$25K", "$25K-$50K", "$50K+"]
export const GMV_OPTIONS = [
  { value: "$0-$50", label: "$0 - $50" },
  { value: "$50-$100", label: "$50 - $100" },
  { value: "$100-$1K", label: "$100 - $1K" },
  { value: "$1K-$5K", label: "$1K - $5K" },
  { value: "$5K-$10K", label: "$5K - $10K" },
  { value: "$10K-$25K", label: "$10K - $25K" },
  { value: "$25K-$50K", label: "$25K - $50K" },
  { value: "$50K+", label: "$50K+" },
];

export const GPM_OPTIONS_UK = [
  { value: "£0-£5", label: "£0 - £5" },
  { value: "£5-£10", label: "£5 - £10" },
  { value: "£10-£25", label: "£10 - £25" },
  { value: "£25-£50", label: "£25 - £50" },
  { value: "£50+", label: "£50+" },
];

export const VIDEO_VIEWS_OPTIONS_UK = [
  { value: "0-100", label: "0 - 100" },
  { value: "100-500", label: "100 - 500" },
  { value: "500-1000", label: "500 - 1000" },
  { value: "1000-5000", label: "1000 - 5000" },
  { value: "5000+", label: "5000+" },
];

// ["All", "0-1K", "1K-5K", "5K-10K", "10K-25K", "25K-50K", "50K-100K", "100K-250K", "250K-500K", "500K-1M", "1M+"]
export const FOLLOWERS_COUNT_OPTIONS = [
  { value: "0-1K", label: "0 - 1K" },
  { value: "1-5K", label: "1K - 5K" },
  { value: "5-10K", label: "5K - 10K" },
  { value: "10K-25K", label: "10K - 25K" },
  { value: "25K-50K", label: "25K - 50K" },
  { value: "50K-100K", label: "50K - 100K" },
  { value: "100K-250K", label: "100K - 250K" },
  { value: "250K-500K", label: "250K - 500K" },
  { value: "500K-1M", label: "500K - 1M" },
  { value: "1M+", label: "1M+" },
];

export const FOLLOWERS_COUNT_OPTIONS_UK = [
  { value: "0-1K", label: "0 - 1K" },
  { value: "1K-5K", label: "1K - 5K" },
  { value: "5K-10K", label: "5K - 10K" },
  { value: "10K-50K", label: "10K - 50K" },
  { value: "50K-100K", label: "50K - 100K" },
  { value: "more than 100K", label: "More Than 100K" },
];

export const ENGAGEMENT_OPTIONS = [
  { value: "1", label: "More than 1%" },
  { value: "2", label: "More than 2%" },
  { value: "3", label: "More than 3%" },
  { value: "4", label: "More than 4%" },
  { value: "5", label: "More than 5%" },
  { value: "10", label: "More than 10%" },
  { value: "15", label: "More than 15%" },
  { value: "20", label: "More than 20%" },
];

export const UNITS_SOLD_OPTIONS = [
  { value: "0-10", label: "0 - 10" },
  { value: "10-100", label: "10 - 100" },
  { value: "100-1K", label: "100 - 1K" },
  { value: "1K+", label: "1K+" },
];

export const AVERAGE_VIEWS_OPTIONS = [
  { value: "100", label: "More than 100 views" },
  { value: "500", label: "More than 500 views" },
  { value: "1000", label: "More than 1000 views" },
  { value: "5000", label: "More than 5000 views" },
  { value: "10000", label: "More than 10000 views" },
  { value: "50000", label: "More than 50000 views" },
  { value: "100000", label: "More than 100000 views" },
];

export const AUTOMATION_TYPE_OPTIONS = [
  { value: "Message", label: (
      <div>
        Direct Message
        <br />
        <div className="mt-1 font-normal text-xs">
        This includes Message, Message + Image, Message + Product Card, Spark Codes, Content Flywheel
        </div>
      </div>
    ), },
  // // { value: "Message + Image", label: "Message + Image" },
  // { value: "Message + Product Card", label: "Message + Product Card" },
  { value: "Message + Target Collab", label: "Target Collaboration" },
  {
    value: "Message + Target Collab + Target Collab Card",
    label: "Target Collaboration + Message + Target Collaboration Card",
  },
  // {
  //   value: "Spark Code",
  //   label: "Spark Code",
  // },
  // {
  //   value: "Content Flywheel",
  //   label: "Content Flywheel",
  // },
];

export const OUTREACH_TYPES = [
  { value: "list_upload", label: "New Creators/List Upload" },
  { value: "crm_group", label: "CRM Group" },
];

export const FULFILLMENT_RATE_OPTIONS = [
  { value: "10", label: "More than 10%" },
  { value: "20", label: "More than 20%" },
  { value: "30", label: "More than 30%" },
  { value: "40", label: "More than 40%" },
  { value: "50", label: "More than 50%" },
  { value: "60", label: "More than 60%" },
  { value: "70", label: "More than 70%" },
  { value: "80", label: "More than 80%" },
];

export const validationSchema = Yup.object().shape({
  target_collab_invitation_name: Yup.string().required(
    "Invitation Name is required"
  ),
  target_collab_email: Yup.string().required("Email is required"),
  target_collab_phone: Yup.string()
    .matches(
      /^\d*$/,
      "Phone number must consist of numbers without any country code, signs, or spaces"
    )
    .notRequired(),
  target_collab_valid_until: Yup.string().required("Valid Until is required"),
  message_entry_target_collab: Yup.string().required("Message is required"),
  target_collab_product_comission_rates: Yup.array().of(
    Yup.object().shape({
      product_id: Yup.string().required(),
      commission_rate: Yup.number()
        .min(0, "Commission rate must be at least 0.00")
        .max(80, "Commission rate must be at most 80.00")
        .required("Commission rate is required"),
    })
  ),
  tc_dm_message: Yup.string().when("automationType", {
    is: "Message + Target Collab + Target Collab Card",
    then: Yup.string().required("TC Creator DM Message is required"),
  }),
});

export const validationSchemaMessage = Yup.object().shape({
  // message_entry: Yup.string().required("Message Entry is required"),
  message_entry: Yup.string()
    .test(
      "no-amazon",
      'The message cannot contain the word "amazon".',
      (value) => !/amazon/i.test(value || "")
    )
    .required("The message entry is required"),
});

export const validationSchemaStepTwo = Yup.object().shape({
  // message_entry: Yup.string().required("Message Entry is required"),
  message_entry: Yup.string()
    .test(
      "no-amazon",
      'The message cannot contain the word "amazon".',
      (value) => !/amazon/i.test(value || "")
    )
    .required("The message entry is required"),
});

export const getEditConfig = (automationData: any) => {
  const schedule = automationData.config?.SCHEDULE || {};
  let targetCollabMessage =
    automationData.config?.TARGET_COLLAB_MESSAGE ||
    MESSAGE_ENTRY_TARGET_COLLAB_DEFAULT_CONTENT;
  if (targetCollabMessage.includes("{{creators username}}")) {
    targetCollabMessage = targetCollabMessage.replace(
      /{{creators username}}/g,
      "{creator_name}"
    );
  }

  let initialProductCategories = [];
  if (automationData.config?.Filters?.Creators?.["Product Categories"]) {
    initialProductCategories = automationData.config?.Filters?.Creators?.[
      "Product Categories"
    ].map((category) => category.Main);
  }

  return {
    message_entry:
      automationData.config?.CREATOR_MESSAGE || MESSAGE_ENTRY__DEFAULT_CONTENT,
    message_entry_no_reply:
      automationData.config?.FOLLOWUP_MESSAGE ||
      MESSAGE_ENTRY_NO_REPLY_DEFAULT_CONTENT,
    message_entry_interest: MESSAGE_ENTRY_INTEREST_DEFAULT_CONTENT, // ToDo Update when the new feature is ready
    message_entry_max_samples:
      MESSAGE_ENTRY_MAX_SAMPLES_REQUESTED_DEFAULT_CONTENT, // ToDo Update when the new feature is ready

    // Target Collab Default Values
    message_entry_target_collab: targetCollabMessage,
    target_collab_invitation_name:
      automationData.config?.BASE_INVITATION_NAME || "",
    target_collab_valid_until: automationData.config?.VALID_UNTIL || "",
    target_collab_email: automationData.config?.EMAIL || "",
    target_collab_phone: automationData.config?.PHONE_NUMBER || "",
    target_collab_offer_free_samples:
      automationData.config?.OFFER_FREE_SAMPLES || false,
    target_collab_manually_approve:
      !automationData.config?.AUTO_APPROVE || false,
    target_collab_product_comission_rates: automationData.config?.PRODUCTS || [
      {},
    ],
    content_type: automationData.config?.content_type || "No preference",

    days: automationData.config?.DAYS_BEFORE_FOLLOWUP || "",
    no_reply_automation:
      automationData.config?.SEND_UNREPLIED_FOLLOWUP || false,
    interest_automation: false, // ToDo Update when the new feature is ready
    max_samples_automation: false, // ToDo Update when the new feature is ready

    // default values
    shop_name: "", // ToDo Update when the new feature is ready
    product_id: automationData.config?.DESIRED_PRODUCT_IDS || [],
    automation_name: automationData.automation_name || "",
    followers:
      automationData.config?.Filters?.Creators?.Followers?.[
        "Follower Segments"
      ] || [],
    engagement:
      automationData.config?.Filters?.Performance?.["Engagement Rate"] || "",
    gender:
      automationData.config?.Filters?.Followers?.["Follower Gender"] || "",
    automation_type: automationData.automation_type || "Message + Product Card",
    gmv: automationData.config?.Filters?.Performance?.GMV || [],
    category: initialProductCategories,
    age: automationData.config?.Filters?.Followers?.["Follower Age"] || [],
    units: automationData.config?.Filters?.Performance?.["Units Sold"] || [],
    views: automationData.config?.Filters?.Performance?.["Average Views"] || "",
    fulfillment_rate:
      automationData.config?.Filters?.Performance?.["Fulfillment Rate"] || "",
    creators_to_omit: automationData.creators_to_omit || [],
    creators_to_include: automationData.creators_to_include || [],
    only_message_include_list:
      automationData.config?.ONLY_MESSAGE_INCLUDE_LIST !== undefined
        ? automationData.config?.ONLY_MESSAGE_INCLUDE_LIST
        : false,
    video_gpm: automationData.config?.Filters?.Performance?.["Video GPM"] || [],
    live_gpm: automationData.config?.Filters?.Performance?.["Live GPM"] || [],
    video_views:
      automationData.config?.Filters?.Performance?.["Video Views"] || [],
    monday_time: schedule.Monday?.startTime || "07:00",
    tuesday_time: schedule.Tuesday?.startTime || "07:00",
    wednesday_time: schedule.Wednesday?.startTime || "07:00",
    thursday_time: schedule.Thursday?.startTime || "07:00",
    friday_time: schedule.Friday?.startTime || "07:00",
    saturday_time: schedule.Saturday?.startTime || "07:00",
    sunday_time: schedule.Sunday?.startTime || "07:00",
    monday_amount: String(schedule.Monday?.maxCreators || defaultAmount),
    tuesday_amount: String(schedule.Tuesday?.maxCreators || defaultAmount),
    wednesday_amount: String(schedule.Wednesday?.maxCreators || defaultAmount),
    thursday_amount: String(schedule.Thursday?.maxCreators || defaultAmount),
    friday_amount: String(schedule.Friday?.maxCreators || defaultAmount),
    saturday_amount: String(schedule.Saturday?.maxCreators || defaultAmount),
    sunday_amount: String(schedule.Sunday?.maxCreators || defaultAmount),
    schedule_checkboxes: {
      monday: !!schedule.Monday,
      tuesday: !!schedule.Tuesday,
      wednesday: !!schedule.Wednesday,
      thursday: !!schedule.Thursday,
      friday: !!schedule.Friday,
      saturday: !!schedule.Saturday,
      sunday: !!schedule.Sunday,
    },
    image: null,
    image_name: automationData.file_name || "",

    // Follow-up steps
    followupSteps: automationData.followup_steps || [],
    tc_dm_message: automationData.config?.TC_DM_MESSAGE || "",
  };
};

export const checkToInitializeCommissionRatesOnEditAutomation = (
  values: any
) => {
  const { product_id, target_collab_product_comission_rates } = values;
  // Check if target_collab_product_comission_rates is an array with a single empty object, i.e. [{}]
  if (
    Array.isArray(target_collab_product_comission_rates) &&
    target_collab_product_comission_rates.length === 1 &&
    Object.keys(target_collab_product_comission_rates[0]).length === 0
  ) {
    return false;
  }
  // Extract the product IDs from target_collab_product_comission_rates
  const targetProductIds = target_collab_product_comission_rates.map(
    (item) => item.product_id
  );
  // Check if every product ID in product_id has a correspondence in targetProductIds
  const allProductsMatch = product_id.every((id) =>
    targetProductIds.includes(id)
  );
  // Check if every product ID in targetProductIds has a correspondence in product_id
  const allTargetsMatch = targetProductIds.every((id) =>
    product_id.includes(id)
  );
  // Return true if both conditions are met, i.e. the product IDs match
  return allProductsMatch && allTargetsMatch;
};
