const TikTok = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_561_31977" style={{ "mask-type": "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
      <rect width="48" height="48" fill="url(#paint0_linear_561_31977)" />
    </mask>
    <g mask="url(#mask0_561_31977)">
      <circle cx="24" cy="24" r="23.5" fill="#EBF1FF" stroke="#C0D5FF" />
    </g>
    <rect x="8" y="8" width="32" height="32" rx="16" fill="#3559E9" />
    <path d="M19.2816 26.3703C19.3793 25.5617 19.7109 25.1089 20.336 24.6447C21.2302 24.0161 22.3473 24.3717 22.3473 24.3717V22.2622C22.6189 22.2553 22.8906 22.2714 23.1592 22.3103V25.025C23.1592 25.025 22.0425 24.6694 21.1482 25.2984C20.5235 25.7622 20.1911 26.2153 20.0938 27.024C20.0908 27.4631 20.1732 28.0371 20.5527 28.5334C20.4588 28.4853 20.3632 28.4305 20.2658 28.369C19.4298 27.8076 19.2775 26.9655 19.2816 26.3703ZM27.7717 18.3618C27.1565 17.6877 26.9239 17.0071 26.8398 16.5289H27.6137C27.6137 16.5289 27.4594 17.7826 28.5839 19.0154L28.5995 19.032C28.2965 18.8414 28.0185 18.6163 27.7717 18.3618ZM31.4998 20.273V22.9331C31.4998 22.9331 30.5122 22.8944 29.7814 22.7085C28.761 22.4485 28.1051 22.0496 28.1051 22.0496C28.1051 22.0496 27.652 21.7652 27.6154 21.7454V27.2385C27.6154 27.5444 27.5316 28.3082 27.2763 28.9453C26.9429 29.7787 26.4284 30.3257 26.3338 30.4375C26.3338 30.4375 25.7081 31.177 24.6043 31.6749C23.6092 32.1241 22.7356 32.1127 22.4745 32.1241C22.4745 32.1241 20.9644 32.1839 19.6055 31.3008C19.3116 31.1061 19.0373 30.8857 18.7861 30.6423L18.7929 30.6471C20.1521 31.5303 21.662 31.4705 21.662 31.4705C21.9234 31.4591 22.797 31.4705 23.7917 31.0213C24.8945 30.5233 25.5213 29.7839 25.5213 29.7839C25.6149 29.672 26.1317 29.125 26.4637 28.2913C26.7184 27.6546 26.8028 26.8904 26.8028 26.5845V21.0921C26.8395 21.1122 27.2922 21.3967 27.2922 21.3967C27.2922 21.3967 27.9484 21.7958 28.9689 22.0555C29.7 22.2414 30.6872 22.2801 30.6872 22.2801V20.1957C31.025 20.2714 31.3129 20.2919 31.4998 20.273Z" fill="#EE1D52" />
    <path d="M30.688 20.1957V22.2794C30.688 22.2794 29.7008 22.2408 28.9697 22.0549C27.9492 21.7948 27.293 21.396 27.293 21.396C27.293 21.396 26.8403 21.1116 26.8036 21.0914V26.5852C26.8036 26.891 26.7199 27.6552 26.4645 28.2919C26.1311 29.1257 25.6167 29.6727 25.5221 29.7845C25.5221 29.7845 24.896 30.524 23.7925 31.0219C22.7978 31.4711 21.9242 31.4597 21.6628 31.4711C21.6628 31.4711 20.153 31.5309 18.7937 30.6478L18.7869 30.6429C18.6434 30.504 18.5083 30.3572 18.3824 30.2035C17.9486 29.6746 17.6827 29.0493 17.6159 28.8708C17.6158 28.8701 17.6158 28.8693 17.6159 28.8686C17.5084 28.5585 17.2826 27.8138 17.3134 27.0926C17.368 25.8201 17.8157 25.039 17.934 24.8433C18.2475 24.3097 18.6552 23.8323 19.139 23.4323C19.5659 23.0872 20.0497 22.8126 20.5708 22.6198C21.134 22.3934 21.7371 22.272 22.3478 22.2622V24.3717C22.3478 24.3717 21.2307 24.0174 20.3368 24.6447C19.7117 25.1089 19.3801 25.5617 19.2824 26.3703C19.2783 26.9655 19.4306 27.8076 20.2659 28.3693C20.3633 28.4311 20.459 28.4859 20.5528 28.5338C20.6987 28.7235 20.8763 28.8887 21.0784 29.023C21.8944 29.5394 22.5781 29.5755 23.4524 29.2401C24.0353 29.0158 24.4742 28.5104 24.6776 27.9503C24.8055 27.6006 24.8038 27.2486 24.8038 26.8845V16.5289H26.8386C26.9227 17.0071 27.1553 17.6877 27.7705 18.3618C28.0173 18.6163 28.2952 18.8414 28.5983 19.032C28.6878 19.1247 29.1457 19.5826 29.7334 19.8638C30.0373 20.0091 30.3576 20.1205 30.688 20.1957Z" fill="white" />
    <path d="M16.8057 28.2231V28.2248L16.8562 28.3616C16.8504 28.3457 16.8316 28.2973 16.8057 28.2231Z" fill="#69C9D0" />
    <path d="M20.5705 22.6198C20.0495 22.8126 19.5656 23.0872 19.1387 23.4324C18.6548 23.8332 18.2472 24.3117 17.9341 24.8463C17.8158 25.0413 17.3681 25.823 17.3135 27.0955C17.2826 27.8168 17.5085 28.5614 17.616 28.8715C17.6159 28.8723 17.6159 28.873 17.616 28.8738C17.6838 29.0506 17.9487 29.676 18.3824 30.2064C18.5084 30.3602 18.6435 30.5069 18.787 30.6459C18.3272 30.3411 17.9172 29.9726 17.5702 29.5525C17.1402 29.0282 16.875 28.4093 16.8058 28.2266C16.8057 28.2253 16.8057 28.224 16.8058 28.2227V28.2205C16.698 27.9107 16.4714 27.1657 16.503 26.4435C16.5576 25.171 17.0052 24.3899 17.1236 24.1942C17.4366 23.6596 17.8442 23.1812 18.3282 22.7803C18.755 22.4351 19.2389 22.1605 19.76 21.9678C20.085 21.8385 20.4236 21.7432 20.7699 21.6834C21.2918 21.596 21.8249 21.5885 22.3492 21.6609V22.2622C21.738 22.2718 21.1343 22.3932 20.5705 22.6198Z" fill="#69C9D0" />
    <path d="M26.8399 16.529H24.8051V26.8849C24.8051 27.2489 24.8051 27.6 24.6789 27.9507C24.4734 28.5104 24.0363 29.0158 23.4537 29.2401C22.579 29.5768 21.8954 29.5395 21.0797 29.023C20.8773 28.8894 20.6992 28.7246 20.5527 28.5354C21.2476 28.8907 21.8696 28.8845 22.6401 28.5881C23.2224 28.3638 23.6602 27.8584 23.865 27.2984C23.9932 26.9486 23.9915 26.5966 23.9915 26.2329V15.875H26.8012C26.8012 15.875 26.7697 16.1324 26.8399 16.529ZM30.6876 19.6194V20.1957C30.3578 20.1204 30.038 20.009 29.7347 19.8638C29.147 19.5827 28.6891 19.1247 28.5996 19.0321C28.7035 19.0974 28.8113 19.1569 28.9225 19.2102C29.637 19.5521 30.3407 19.6542 30.6876 19.6194Z" fill="#69C9D0" />
    <defs>
      <linearGradient id="paint0_linear_561_31977" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default TikTok;