import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clientService from "../helpers/client";
import { Api } from "../constants/api";

interface ShopState {
  shops: any;
  isFetching: boolean;
  isCreating: boolean;
  isArchiving: boolean;
  isNameUpdating: boolean;
  loadSuccess: boolean | null;
  creatorsLoading: boolean;
  creatorsLoadSuccess: boolean;
  updateSuccess: string | null;
  archiveSuccess: string | null;
  isNameUpdateSuccess: string | null;
  selectedStoreId: number | null;
  selectedStoreName: string | null;
  selectedStoreRegion: string | null;
  shopStatistics: any;
  shopLoading: boolean;
  shopAnalytics: any;
  shopLogs: any;
  isAliasAdding: boolean;
  aliasAddingSuccess: string | null;
  aliasLoadSuccess: boolean;
  shopAffiliatedWithReacher: boolean; 
}

const initialState: ShopState = {
  shops: {},
  isFetching: false,
  isCreating: false,
  isArchiving: false,
  isNameUpdating: false,
  loadSuccess: null,
  creatorsLoading: false,
  creatorsLoadSuccess: true,
  updateSuccess: null,
  archiveSuccess: null,
  isNameUpdateSuccess: null,
  selectedStoreId: null,
  selectedStoreName: null,
  selectedStoreRegion: null,
  shopStatistics: {},
  shopLoading: false,
  shopAnalytics: {},
  shopLogs: {},
  isAliasAdding: false,
  aliasAddingSuccess: null,
  aliasLoadSuccess: false,
  shopAffiliatedWithReacher: false
};

export const getShopsList = createAsyncThunk(
  "shops/getShopsList",
  async (payload) => {
    const response = await clientService.post(Api.shops.list, payload);
    return response.data;
  }
);

export const uploadCreatorsListToOmit = createAsyncThunk(
  "shops/uploadCreatorsListToOmit",
  async (payload) => {
    const response = await clientService.post(
      Api.shops.updateCreatorsToOmit,
      payload
    );
    return response.data;
  }
);

export const userConfirmsAliasAdded = createAsyncThunk(
  "shops/userConfirmsAliasAdded",
  async (payload: { shop_id: number, automation_id?: number | null }) => {
    const response = await clientService.post(
      Api.shops.userConfirmsAliasAdded,
      { ...payload, automation_id: payload.automation_id || null }
    );
    return response.data;
  }
);

export const createShop = createAsyncThunk(
  "shops/createShop",
  async (payload) => {
    const response = await clientService.post(Api.shops.create, payload);
    return response.data;
  }
);

export const archiveShop = createAsyncThunk(
  "shops/archiveShop",
  async (payload) => {
    const response = await clientService.post(Api.shops.archive, payload);
    return response.data;
  }
);

export const updateShopName = createAsyncThunk(
  "shops/updateShop",
  async (payload) => {
    const response = await clientService.post(Api.shops.update, payload);
    return response.data;
  }
);

export const getShopStatistics = createAsyncThunk(
  "shops/getShopStatistics",
  async (payload) => {
    const response = await clientService.post(Api.shops.statistics, payload);
    return response.data;
  }
);


export const getShopLogs = createAsyncThunk(
  "shops/getShopLogs",
  async (payload) => {
    const response = await clientService.get(Api.shops.logs);
    return response.data;
  }
);


export const getShopAnalytics = createAsyncThunk(
  "shops/getShopAnalytics",
  async (payload) => {
    const response = await clientService.post(Api.shops.analytics, payload);
    return response.data;
  }
);



const ShopsSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    setSelectedStoreName: (state, action) => {
      state.selectedStoreName = action.payload;
    },
    setSelectedStoreId: (state, action) => {
      state.selectedStoreId = action.payload.value; // Update the selected store ID
      state.selectedStoreName = (state.shops?.[action.payload.customer_id] || []).find(
        (shop) => shop.shop_id === action.payload.value
      )?.shop_name; // Update the selected store name
      state.selectedStoreRegion = (state.shops?.[action.payload.customer_id] || []).find(
        (shop) => shop.shop_id === action.payload.value
      )?.shop_region; // Update the selected store region
      state.shopAffiliatedWithReacher = (state.shops?.[action.payload.customer_id] || []).find(
        (shop) => shop.shop_id === action.payload.value
      )?.shop_affiliated_with_reacher; 
    },
    clearSelectedStoreId: (state) => {
      state.selectedStoreId = null; // Clear the selected store ID
      state.selectedStoreName = null; // Clear the selected store name
      state.selectedStoreRegion = null; // Clear the selected store region
      state.shopAffiliatedWithReacher = false;
    },
    adminChangedCustomer: (state) => {
      state.selectedStoreId = null;
      state.selectedStoreName = null;
      state.selectedStoreRegion = null;
      state.shopAffiliatedWithReacher = false;
      state.loadSuccess = false;
    },
    clearState: (state) => {
      Object.assign(state, initialState);
      sessionStorage.clear(); // Optionally, clear all session storage on logout
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShopsList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getShopsList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.shops = {
        ...state.shops,
        [action?.meta?.arg?.customer_id]: action.payload,
      };
      state.loadSuccess = true;
      state.creatorsLoadSuccess = true;
    });
    builder.addCase(getShopsList.rejected, (state, action) => {
      state.isFetching = false;
      state.loadSuccess = null;
    });
    builder.addCase(getShopStatistics.pending, (state) => {
      state.shopLoading = true;
    });
    builder.addCase(getShopLogs.pending, (state) => {
      state.shopLoading = true;
    });
    builder.addCase(getShopLogs.fulfilled, (state, action) => {
      state.shopLoading = false;
      state.shopLogs = {
        failed_login_shops: action.payload.failed_login_shops || [],
        failed_container_shops: action.payload.failed_container_shops || [],
        failed_automations: action.payload.failed_automations || [],
      };
    });
    builder.addCase(getShopLogs.rejected, (state) => {
      state.shopLoading = false;
    });
    builder.addCase(getShopStatistics.fulfilled, (state, action) => {
      state.shopStatistics = {
        ...state.shopStatistics,
        [action?.meta?.arg?.shop_id]: action.payload
      };
    });
    builder.addCase(getShopStatistics.rejected, (state) => {
      state.shopLoading = false;
    });
    builder.addCase(getShopAnalytics.pending, (state) => {
      state.shopLoading = true;
    });
    builder.addCase(getShopAnalytics.fulfilled, (state, action) => {
      state.shopAnalytics = {
        ...state.shopAnalytics,
        [action?.meta?.arg?.shop_id]: {
          ...(state.shopAnalytics || {})?.[action?.meta?.arg?.shop_id],
          [action?.meta?.arg?.data_type]: {
            ...(((state.shopAnalytics || {})?.[action?.meta?.arg?.shop_id] || {})?.[action?.meta?.arg?.data_type] || {}),
            [`${action?.meta?.arg?.start_date}-${action?.meta?.arg?.end_date}${action?.meta?.arg?.automation_id ? `-${action?.meta?.arg?.automation_id}` : ""}`]: action.payload
          }
        }
      };
    });
    builder.addCase(getShopAnalytics.rejected, (state) => {
      state.shopLoading = false;
    });
    builder.addCase(uploadCreatorsListToOmit.pending, (state) => {
      state.creatorsLoading = true;
    });
    builder.addCase(uploadCreatorsListToOmit.fulfilled, (state, action) => {
      state.creatorsLoading = false;
      state.creatorsLoadSuccess = false;
    });
    builder.addCase(uploadCreatorsListToOmit.rejected, (state, action) => {
      state.creatorsLoading = false;
    });

    builder.addCase(userConfirmsAliasAdded.pending, (state) => {
      state.isAliasAdding = true;
    });
    builder.addCase(userConfirmsAliasAdded.fulfilled, (state, action) => {
      state.isAliasAdding = false;
      state.aliasAddingSuccess = action.payload.message;
      state.aliasLoadSuccess = false;
    });
    builder.addCase(userConfirmsAliasAdded.rejected, (state, action) => {
      state.isAliasAdding = false;
      state.aliasAddingSuccess = null;
    });

    builder.addCase(createShop.pending, (state) => {
      state.isCreating = true;
    });
    builder.addCase(createShop.fulfilled, (state, action) => {
      state.isCreating = false;
      state.updateSuccess = action.payload.message;
      state.loadSuccess = false;
    });
    builder.addCase(createShop.rejected, (state, action) => {
      state.isCreating = false;
      state.updateSuccess = null;
    });
    builder.addCase(archiveShop.pending, (state) => {
      state.isArchiving = true;
    });
    builder.addCase(archiveShop.fulfilled, (state, action) => {
      state.isArchiving = false;
      state.archiveSuccess = action.payload.message;
      state.loadSuccess = false;
    });
    builder.addCase(archiveShop.rejected, (state, action) => {
      state.isArchiving = false;
      state.archiveSuccess = null;
    });
    builder.addCase(updateShopName.pending, (state) => {
      state.isNameUpdating = true;
    });
    builder.addCase(updateShopName.fulfilled, (state, action) => {
      state.isNameUpdating = false;
      state.isNameUpdateSuccess = action.payload.message;
      state.loadSuccess = false;
    });
    builder.addCase(updateShopName.rejected, (state, action) => {
      state.isNameUpdating = false;
      state.isNameUpdateSuccess = null;
    });
  },
});

export const {
  setSelectedStoreName,
  setSelectedStoreId,
  clearSelectedStoreId,
  adminChangedCustomer,
  clearState,
} = ShopsSlice.actions;
export default ShopsSlice.reducer;
