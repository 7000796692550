import { useEffect, useState } from "react";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Collapse, Select, Spin, Tag, Tooltip } from "antd";
import { IoChevronBack } from "react-icons/io5";
import CustomTable from "../../common/table";
import IconButton from "../../common/button";
import Layout from "./layout";
import CustomModal from "../../common/modal";
import { LuTrash2 } from "react-icons/lu";
import { HiOutlinePencil } from "react-icons/hi2";
import AutomationOverview from "./automation";
import type { CollapseProps } from "antd";
import {
  deleteGroup,
  getGroups,
  getMyCreatorsByGroup,
  getSingleGroup,
  setGroupLoading,
} from "../store/creators";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getCurrencySign, getStatusColor } from "../my-creators/helper";
import AutomationModal from "./groupAutomationModal";

const { Option } = Select;

const SingleGroup = ({ groupId }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  if (!id) {
    id = groupId;
  }

  const dispatch = useAppDispatch();
  const creators = useAppSelector((state) => state.creators);
  const shops = useAppSelector((state) => state.shops);
  const singleGroup = creators?.singleGroup?.[id];
  const [showAutomationModal, setShowAutomationModal] = useState(false);
  const paramsObject = { id };
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isAddTagModalVisible, setIsAddTagModalVisible] = useState(false);
  const tags = [
    { value: "tag1", label: "Tag 1", selected: false },
    { value: "tag2", label: "Tag 2", selected: false },
    { value: "tag3", label: "Tag 3", selected: false },
  ];
  const groupLoading = creators.groupsLoading;
  const groupCreators = creators?.singleGroupCreators?.[paramsObject?.id];

  const handleMultiSelectChange = (values: string[]) => {
    setSelectedTags(values);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  useEffect(() => {
    if (paramsObject?.id && !groupCreators) {
      dispatch(getMyCreatorsByGroup({ group_id: paramsObject?.id }));
      dispatch(getSingleGroup({ id: paramsObject?.id }));
    }
  }, [paramsObject?.id]);

  useEffect(() => {
    if (groupCreators) {
      dispatch(setGroupLoading(false));
    }
  }, [groupCreators]);

  const fallbackImage = "/logo192.png";

  const columns = [
    {
      title: "TikTok Handle",
      dataIndex: "creator_name",
      key: "creator_name",
      render: (
        creatorName: string,
        record: { avatar_url: string; follower_num: any }
      ) => (
        <div className="flex items-center">
          <img
            src={record?.avatar_url || fallbackImage}
            alt={creatorName}
            className="w-8 h-8 rounded-full mr-2"
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevent infinite loop in case fallback image fails
              e.currentTarget.src = fallbackImage; // Replace with fallback image
            }}
          />
          <div>
            <Link to={`https://tiktok.com/@${creatorName}`} target="_blank">
              <div>{creatorName}</div>
            </Link>

            <div className="text-gray-500">
              Followers: {record.follower_num}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Product Info",
      dataIndex: "product_title",
      key: "product_title",
      width: 200,
      render: (_: any, record: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="ml-4" style={{ maxWidth: "200px" }}>
            <Tooltip title={record.product_title}>
              <div
                style={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  cursor: "pointer",
                }}
              >
                {record.product_title || "-"}
              </div>
            </Tooltip>
            <div style={{ color: "#888" }}>ID: {record.product_id || "-"}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "curr_status",
      key: "curr_status",
      render: (status: string) => (
        <span
          className={"px-5 py-2 rounded-md border border-stroke"}
          style={{
            color: getStatusColor(status),
            backgroundColor: `${getStatusColor(status)}23`,
            borderColor: `${getStatusColor(status)}80`,
          }}
        >
          {status ? status : "-"}
        </span>
      ),
    },
    {
      title: "GMV",
      dataIndex: "gmv",
      key: "gmv",
      render: (gmv) => (
        <span className="font-medium">
          {getCurrencySign(shops.selectedStoreRegion)}
          {gmv.toFixed(2)}
        </span>
      ),
    },
    {
      title: "Date Entered Group",
      dataIndex: "added_date",
      key: "added_date",
      render: (date) => <span>{new Date(date).toLocaleDateString()}</span>,
    },
  ];

  const handleCancel = () => {
    setIsAddTagModalVisible(false);
  };

  const handleConfirm = () => {
    console.log("Tags added:", tags);
    // Add your tag addition logic here
    setIsAddTagModalVisible(false);
  };

  const handleDelete = async () => {
    const userConfirmed = window.confirm(
      "Caution: Proceeding may cause loss of unsaved data. Do you want to continue?"
    );
    if (userConfirmed) {
      const result = await dispatch(
        deleteGroup({
          id,
          data: {
            group_id: id,
            shop_id: shops.selectedStoreId,
          },
        })
      );
      if (deleteGroup.fulfilled.match(result)) {
        await dispatch(
          getGroups({
            shop_id: shops.selectedStoreId,
          })
        );
      }
      navigate("/groups", { replace: true });
    }
  };

  const automation = (singleGroup?.automations || []).length !== 0;

  const statusMapping: any = {
    inactive: { color: "gray", displayText: "Inactive" },
    stopped: { color: "red", displayText: "Stopped" },
    paused_by_limit: { color: "yellow", displayText: "Paused by limit" },
    failed: { color: "red", displayText: "Failed" },
    completed: { color: "green", displayText: "Completed" },
    continue: { color: "green", displayText: "Continue" },
    running: { color: "green", displayText: "Running" },
  };

  const automationOverview = (singleGroup?.automations || []).map(
    (automation) => {
      const creatorsRemaining = (groupCreators || [])?.length; // Example value, replace with your logic
      const status = automation.status;
      console.log("12312321status", status);
      const { color, displayText } = statusMapping[status] || {};
      const statusDetails =
        automation &&
        "status_details" in automation &&
        automation.status_details
          ? `${automation.status_details}`
          : "";
      return {
        status: automation.running, // Assuming 'running' reflects status
        name: automation.automation_name, // Assuming 'running' reflects status
        types: [
          {
            title: "Message type:",
            value: automation.automation_type || "N/A",
          },
          {
            title: "Total Creators:",
            value: creatorsRemaining,
          },
          {
            title: "Status:",
            value: (
              <>
                {statusDetails ? (
                  <div className="flex">
                    <Tag
                      color={color}
                      key={status}
                      style={{ cursor: "pointer" }}
                    >
                      {displayText}
                    </Tag>
                    <Tooltip title={statusDetails}>
                      <InfoCircleOutlined className="text-gray-400" />
                    </Tooltip>
                  </div>
                ) : (
                  <Tag color={color} key={status}>
                    {displayText}
                  </Tag>
                )}
              </>
            ),
          },
          {
            title: "Date created:",
            value: automation.created_at.split("T")[0],
          },
        ],
      };
    }
  );
  console.log("automationOverview12312", automationOverview);
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: `Automation Overview (${automationOverview?.length})`,
      children: (automationOverview || []).map((automation, index) => (
        <AutomationOverview
          automationName={automation.name}
          onClick={() => {
            navigate("/automations");
          }}
          details={automation.types}
          key={`creators-automation-${index}`}
        />
      )),
    },
  ];

  return (
    <Layout>
      {!groupId && (
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <IoChevronBack
              className="text-xl text-gray-600 cursor-pointer"
              onClick={() => {
                navigate("/groups");
              }}
            />
            <h3 className="font-semibold text-xl">Groups</h3>
          </div>
          <div className="flex">
            <div className="flex space-x-4 mr-4">
              <button
                className="bg-gray-200 p-2 rounded border border-gray-300"
                onClick={() => {
                  navigate(`/groups/create?id=${id}`);
                }}
              >
                <HiOutlinePencil size={20} />
              </button>
              <button
                className="bg-gray-200 p-2 rounded border border-gray-300"
                onClick={() => {
                  handleDelete();
                }}
              >
                <LuTrash2 size={20} />
              </button>
            </div>

            {singleGroup ? (
              <IconButton
                label={`Create ${automation ? "new" : ""} automation`}
                onClick={() => {
                  navigate('/automations/create')
                }}
                icon={<PlusOutlined />}
                buttonClass="bg-black text-white px-4 py-2 rounded-md"
                textClass="text-sm font-medium"
                style={{ height: 40 }}
              />
            ) : (
              <Spin />
            )}
          </div>
        </div>
      )}
      {!groupId && automation && (
        <div className="mt-10">
          <Collapse
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
            expandIconPosition={"end"}
          />
        </div>
      )}
      {groupId && (
        <div className="flex items-center space-x-2">
          <h3 className="font-semibold text-xl">
            Group Preview ({(groupCreators || []).length})
          </h3>
        </div>
      )}

      <div className="mt-4 mb-8">
        <CustomModal
          visible={isAddTagModalVisible}
          showModalFooter
          title="Batch add tags"
          content={
            <div>
              <p className="text-gray-500">
                Only 3 tags can be added per creator.
              </p>
              <Select
                mode="multiple"
                value={tags
                  ?.filter((option) => selectedTags.includes(option.value))
                  .map((option) => option.value)}
                onChange={(values) =>
                  handleMultiSelectChange(values as string[])
                }
                className="w-full mt-2"
                placeholder="Select tags"
              >
                {tags?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
          }
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          confirmText="Add tags"
          cancelText="Cancel"
          confirmButtonType="primary"
        />
      </div>
      {groupLoading || !groupCreators ? (
        <div className="flex items-center justify-center h-[55vh]">
          <Spin size="large" />
        </div>
      ) : (
        <CustomTable
          columns={columns}
          data={groupCreators}
          loading={false}
          showDownloadOptions
          pageSizeProp={5}
          labels={{
            creator_name: "TikTok Handle",
            product_title: "Product Info",
            curr_status: "Status",
            gmv: "GMV",
            added_date: "Date Entered Group",
          }}
        />
      )}
    </Layout>
  );
};

export default SingleGroup;
