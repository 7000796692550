import CustomTable from "../../../common/table";
import React, {useEffect, useState} from "react";
import {Button} from "antd";
import {useAppDispatch} from "./../../hooks";
import {createAsyncThunk} from "@reduxjs/toolkit";
import clientService from "./../../helpers/client";
import {Api} from "./../../constants/api";

const SparkCodeCreatorPreview =({automationData}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [tableData, setTableData] = useState([]);
  const getFormPreviewData = createAsyncThunk(
    "sparkCodeForm:getSparkCodeData",
    async (payload: any) => {
      return clientService.post(Api.sparkCodeForm.getSparkFormPreviewData, payload);
    }
  );

  useEffect(() => {
    setLoading(true);
    setLoadingMessage("Please wait, data is being fetched...");

    // Replace getFormPreviewData with your actual Redux action
    dispatch(getFormPreviewData({ shop_id: automationData.shop_id,
      automation_id: automationData.automation_id  }))
      .then((response: any) => {
        console.log("Preview Data Loaded:", response.payload);
        const data = response.payload.data;

        // Transform data to match the table structure
        const formattedData = data.map((item: any) => ({
          key: item.creator_id, // Unique key for each row
          creator: item.username || "Unknown Creator",
          form_preview: item.preview_link || null, // API should return preview_link
        }));

        setTableData(formattedData);
      })
      .catch((error) => console.error("Failed to load form preview data", error))
      .finally(() => setLoading(false));
  }, [dispatch]);







  const columns = [
    {
      title: "Creator",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Form Preview",
      dataIndex: "form_preview",
      key: "form_preview",
      width: 150,
      render: (_: any, record: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="primary"
            size="small"
            style={{ margin: "0 auto" }}
            onClick={() => {
              if (record.form_preview) {
                window.open(record.form_preview, "_blank");
              } else {
                alert("No link available");
              }
            }}
          >
            Open Preview
          </Button>
        </div>
      ),
    }

  ];

return (

    <div className="border border-stroke border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-800 mb-2">
        Creators Spark Code Submission Form  Preview
      </h3>
      <CustomTable
        showDownloadOptions
        columns={columns}
        data={tableData.map((item, index) => ({
          key: index, // Ensure each row has a unique key
          username: item.creator, // Map the creator field properly
          form_preview: item.form_preview, // Map the form_preview field properly
        }))}
        loading={false}
        tableClassName={"h-max"}
      />
    </div>


)}
export default SparkCodeCreatorPreview;