import React, {useEffect} from "react";

// @ts-ignore
import {useDispatch, useSelector} from "react-redux";
// @ts-ignore

import {Input, Button, Image, Tooltip} from "antd";

// @ts-ignore

import {DownloadOutlined, FilterOutlined, SearchOutlined} from "@ant-design/icons";
import CustomTable from "../../common/table"; // Adjust the path if needed

// @ts-ignore

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";// Adjust the import path for your API config
import clientService from "../helpers/client";
import {Api} from "../constants/api";
// @ts-ignore
import {useAppSelector} from "../hooks/index";
// @ts-ignore
import {TikTokOutlined} from "@ant-design/icons";
// @ts-ignore
import {CopyOutlined} from "@ant-design/icons";
// @ts-ignore
import {message} from "antd";
// @ts-ignore
import {Link} from "react-router-dom";


// AsyncThunk to fetch Spark Codes
export const getSparkCodes = createAsyncThunk(
  "sparkCode:getSparkCodes",
  async (payload: any) => {
    const response = await clientService.post(Api.sparkCode.getSparkCodes, payload);
    return response.data;
  }
);

// Redux Slice
const sparkCodesSlice = createSlice({
  name: "sparkCodes",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder: {
    addCase: (arg0: any, arg1: (state: { loading: boolean; }) => void) => {
      (): any;
      new(): any;
      addCase: {
        (arg0: any, arg1: (state: { loading: boolean; data: any; }, action: { payload: any; }) => void): {
          (): any;
          new(): any;
          addCase: {
            (arg0: any, arg1: (state: { loading: boolean; error: any; }, action: {
              error: { message: any; };
            }) => void): void;
            new(): any;
          };
        };
        new(): any;
      };
    };
  }) => {
    builder
      .addCase(getSparkCodes.pending, (state: { loading: boolean; }) => {
        state.loading = true;
      })
      .addCase(getSparkCodes.fulfilled, (state: { loading: boolean; data: any; }, action: { payload: any; }) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getSparkCodes.rejected, (state: { loading: boolean; error: any; }, action: {
        error: { message: any; };
      }) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const sparkCodesReducer = sparkCodesSlice.reducer;

// SparkCodesTable Component
const SparkCodesTable: React.FC = () => {

  const dispatch = useDispatch();
  const {data: sparkCodes, loading} = useSelector((state: any) => state.sparkCodesReducer);
  const shopId = useAppSelector((state: { shops: { selectedStoreId: any; }; }) => state.shops.selectedStoreId);
  // Fetch data on component mount
  useEffect(() => {
    dispatch(getSparkCodes({"shop_id": shopId})); // Pass any necessary payload here
  }, [shopId]);
  const isLink = (val: any) => {
    try {
      const url = new URL(val);
      if (url.protocol === "http:" || url.protocol === "https:") {
        return (
          <a href={val} target="_blank" rel="noopener noreferrer">
            <TikTokOutlined style={{fontSize: "24px", color: "#000"}}/>
          </a>
        );
      }
    } catch (e) {
      return val;
    }
  };
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success("Copied to clipboard!");
      },
      () => {
        message.error("Failed to copy!");
      }
    );
  };
  const fallbackImage = "/logo192.png";
  // Columns Configuration
  // @ts-ignore
  const columns = [
    {
      title: "Creator Name",
      dataIndex: "username",
      key: "username",
      width: 230,
      render: (username: string, record: { avatar_url: string }) => (
        <div className="flex items-center">
          <img
            src={record.avatar_url}
            alt={username}
            className="w-10 h-10 rounded-full mr-2"
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevent infinite loop in case fallback image fails
              e.currentTarget.src = fallbackImage; // Replace with fallback image
            }}
          />
          <div>
            <Link to={`https://tiktok.com/@${username}`} target="_blank">
              <div>{username}</div>
            </Link>

            <div className="text-gray-500">

              Followers: {record.follower_num}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Spark Code",
      dataIndex: "spark_code",
      key: "spark_code",
      render: (sparkCode: string) => (
        <div style={{display: "flex", alignItems: "center", gap: 8}}>
          <span>{sparkCode}</span>
          <Button
            icon={<CopyOutlined/>}
            onClick={() => copyToClipboard(sparkCode)}
            size="small"
          />
        </div>
      ),
    },
    {
      title: "Product Info",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (_: any, record: any) => (
        <div style={{display: "flex", alignItems: "center"}}>
          {/*<Image*/}
          {/*  width={50}*/}
          {/*  height={50}*/}
          {/*  src={ record.product_name === record.product_title?  record.sku_image: fallbackImage }*/}
          {/*  alt={record.product_name}*/}
          {/*  style={{*/}
          {/*    marginRight: "16px",*/}
          {/*    objectFit: "cover",*/}
          {/*  }}*/}
          {/*/>*/}
          <div className="ml-4" style={{maxWidth: "200px"}}>
            <Tooltip title={record.product_name}>
              <div
                style={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  cursor: "pointer",
                }}
              >
                {record.product_name || ""}
              </div>
            </Tooltip>
            {/*<div style={{color: "#888"}}>ID: {record.product_id || ""}</div>*/}
          </div>
        </div>
      ),
    },

    {
      title: "Video URL",
      dataIndex: "video_url",
      key: "video_url",
      render: (url: string) => isLink(url),
    },
    {
      title: "GMV",
      dataIndex: "gmv_amount",
      key: "gmv_amount",
      render: (gmv_amount: number | null) => gmv_amount !== null ? `$${gmv_amount.toLocaleString()}` : 'N/A',
    },
    {
      title: "Video Views",
      dataIndex: "views",
      key: "views",
    },
  ];

  return (
    <div>
      <div className=" bg-gray-100 rounded-lg">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <h1 className="text-lg pt-2 px-2 font-semibold text-gray-600">Spark Codes</h1>
          </div>
        </div>


        {/*/!* Filters *!/*/}
        {/*<div className="flex justify-end items-center mb-4 gap-2">*/}
        {/*    <Button*/}
        {/*        icon={<FilterOutlined/>}*/}
        {/*        className="bg-gray-200 text-gray-700 hover:bg-gray-300"*/}
        {/*    >*/}
        {/*        Min. GMV*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*        icon={<FilterOutlined/>}*/}
        {/*        className="bg-gray-200 text-gray-700 hover:bg-gray-300"*/}
        {/*    >*/}
        {/*        Min. View Count*/}
        {/*    </Button>*/}
        {/*</div>*/}

        {/* Table */}
        <div className="bg-white border border-gray-200 rounded-lg  pt-2 shadow">
          <CustomTable
            columns={columns}
            data={sparkCodes}
            loading={loading}
            showDownloadOptions
            labels={{
              creator_name: "TikTok Handle",
              spark_code: "Spark Code",
              video_url: "Video URL",
              gmv: "GMV",
              content_video_views: "Video Views",
            }}
          />
        </div>
      </div>

      {/* CustomTable Component */}
      {/*<CustomTable*/}
      {/*    columns={columns}*/}
      {/*    data={sparkCodes}*/}
      {/*    loading={loading}*/}
      {/*    showDownloadOptions*/}
      {/*    labels={{*/}
      {/*        creator_name: "TikTok Handle",*/}
      {/*        spark_code: "Spark Code",*/}
      {/*        video_url: "Video URL",*/}
      {/*        gmv: "GMV",*/}
      {/*        content_video_views: "Video Views",*/}
      {/*    }}*/}
      {/*/>*/}
    </div>
  );
};

export default SparkCodesTable;








