import React, { useRef } from "react";
import { Form } from "formik";

import { MdTipsAndUpdates } from "react-icons/md";

import MessageEntryTextField from "./MessageEntryTextField";
import { ArrowLeftIcon } from "@heroicons/react/16/solid";
import ContnetFlywheelingMessage from "./ContnetFlywheelingMessage";

interface StepOneTwoFormProps {
  onPrev: () => void;
  group: any;
  values: any;
}

const StepOneTwoForm: React.FC<StepOneTwoFormProps> = ({
  onPrev,
  group,
  values,
}) => {
  return (
    <Form>
      <div className={group?.group ? group?.className : ""}>
        <div className="flex flex-col">
          {/* Left Panel */}
          <div className="flex">
            <div className="w-1/4   p-3">
              <h3 className="text-lg font-semibold text-gray-900 mb-6">
                Message Setup
              </h3>
              <div className="flex items-center justify-between p-3 hover:bg-blue-100 bg-blue-50 cursor-pointer rounded-lg">
                <span className="text-sm text-blue-600 font-medium">
                  Initial Message
                </span>
                <span className="text-blue-600">{">"}</span>
              </div>
            </div>

            {/* Right Panel */}
            <div className="w-3/4 p-3  ">
              {/* Automation Detail Header */}

              {/* Tips Section */}
              <div className="bg-yellow-50 border border-yellow-400 rounded-lg p-4 mb-6">
                <div className="flex items-center mb-2">
                  <MdTipsAndUpdates className="text-yellow-500 w-5 h-5 mr-2" />
                  <span className="font-medium text-yellow-600">
                    Helpful Tips for You
                  </span>
                </div>
                <p className="text-gray-700 text-sm">
                  You can use{" "}
                  <span className="font-semibold">{"{creator_name}"}</span> to
                  send a message with the creator's name!
                </p>
              </div>

              {/* Initial Message Section */}
              <div className="  border bg-gray-50 rounded-lg p-2 pl-2 pt-3 mb-6">
                <div className="  mb-2 justify-between items-center">
                  <div className="flex justify-between items-center ">
                    <label className="block text-sm pl-1 font-semibold text-gray-700">
                      Initial Message
                    </label>
                  </div>
                </div>
                {values.automation_type === "Content Flywheel" ? (
                  <ContnetFlywheelingMessage />
                ) : (
                  <MessageEntryTextField />
                )}
              </div>

              {/* Spark Code Submission Section */}
              {values.automation_type === "Spark Code" && (
                <div className=" border border-gray-300 rounded-lg bg-gray-50 p-2 ">
                  <div className=" justify-between items-center">
                    <div className="flex justify-between items-center ">
                      <label className="block text-sm font-semibold text-gray-700">
                        Spark Code Submission
                      </label>
                    </div>
                    <div className="mt-4">
                      <label className="text-xs font-semibold  text-gray-700">
                        Form URL
                      </label>
                    </div>
                  </div>
                  <input
                    disabled={true}
                    type="url"
                    name="form_url"
                    placeholder="https://reacherapp.com/submit-spark-code"
                    className="border mt-1 border-gray-300 rounded-md w-full px-3 py-2 text-sm text-gray-700 focus:ring-1 focus:ring-blue-500 focus:outline-none"
                  />
                </div>
              )}
            </div>
          </div>
          {/* Navigation Buttons */}
          <div className="flex justify-between items-center mt-10">
            <div className="flex flex-row">
              <button
                className="bg-gray-100 flex  items-center p-10 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded-md focus:outline-none"
                onClick={onPrev}
                type="button"
              >
                <ArrowLeftIcon className="w-4 h-4 ml-2" />
                Previous
              </button>
            </div>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300"
              type="submit"
            >
              Continue
            </button>
          </div>
        </div>
        {group?.content || <div />}
      </div>
    </Form>
  );
};

export default StepOneTwoForm;
