import React from 'react'

const AddToCart = ({ id, name, price, handleQuantityChange, quantities }: any) => {

    return (
        <div className='flex flex-row justify-between mt-4'>
            <div className="flex flex-col">
                <p className='font-medium text-base text-black'>{name}</p>
                <p className='font-semibold text-sm text-blue-600'>${price}</p>
            </div>
            {/* Counter */}
            <div className='flex flex-row'>
                <div onClick={() => handleQuantityChange(id, 'dec')} className="select-none bg-white text-black font-semibold text-xl cursor-pointer leading-none rounded-l-md border border-gray-300 py-2 px-3 h-10 w-11 grid place-items-center">-</div>
                <div className="select-none bg-white text-black font-semibold text-sm  border-t border-b border-gray-300 py-2 px-3 h-10 w-11 grid place-items-center">{quantities.find((item: any) => item.id === id)?.qty}</div>
                <div onClick={() => handleQuantityChange(id, 'inc')} className="select-none bg-white text-black font-semibold text-xl cursor-pointer leading-none rounded-r-md border border-gray-300 py-2 px-3 h-10 w-11 grid place-items-center">+</div>            </div>
        </div>
    )
}

export default AddToCart