import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAutomationDetail,
  getAutomations,
  getFollowupStepsAutomation,
} from "../store/automation";
import { useAppDispatch, useAppSelector } from "../hooks";
import ViewAutomationDetails from "./components/ViewAutomationDetails";
import Layout from "./layout";
import AutomationModal from "../groups/groupAutomationModal";
import { getEditConfig } from "./components/defaultFormData";
import { Spin } from "antd";

const Automations = (props: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const automation = useAppSelector((state) => state.automation);
  const [showAutomationModal, setShowAutomationModal] = useState(false);
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();

  const selectedAutomation = (automation.automationDetails || {})?.[id] || {};

  useEffect(() => {
    if (auth?.meInfo?.customer_id && shops.selectedStoreId && id) {
      dispatch(
        getAutomationDetail({
          shop_id: shops.selectedStoreId,
          automation_id: id,
        })
      );
    }
  }, [auth, shops.selectedStoreId, id]);
  // /api/automations/detail
  console.log(
    "singleCustomerAutomations1312312312",
    automation.automationDetails
  );
  return (
    <Layout>
      {Object.values(selectedAutomation).length > 0 ? (
        <div>
          <div className="flex items-center justify-between pb-6 mb-6 border-b border-stroke border-gray-300">
            <div>
              <h1 className="text-2xl text-blue-500 font-bold">
                Automation Details
              </h1>
              <p className="text-sm text-gray-500">
                Detailed view of the selected automation configuration.
              </p>
            </div>

            <button
              className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out mr-4"
              onClick={() => navigate("/automations/create")}
            >
              Create New Automation
            </button>
          </div>
          <ViewAutomationDetails
            automationData={selectedAutomation}
            onReturn={() => {
              navigate("/automations");
            }}
            onEdit={() => {
              navigate(
                `/automations/edit/${selectedAutomation.automation_id}`
              );
            }}
          />
        </div>
      ) : (
        <Spin />
      )}
    </Layout>
  );
};

export default Automations;
