import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getShopLogs, getShopsList, setSelectedStoreId } from "../store/shops";

const withShop = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const Wrapper = (props: P) => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.auth);
    const shops = useAppSelector((state) => state.shops);

    const customer_id = auth?.meInfo?.customer_id;
    const isAuthenticated = auth.isLogin && !auth.loading && customer_id;

    const userShops = useMemo(() => shops.shops?.[customer_id] || [], [shops.shops, customer_id]);

    console.log("userShops12312312", userShops)
    const hasShops = userShops.length > 0;

    const fetchShops = async () => {
      if (!hasShops) {
        try {
          await dispatch(getShopsList({ customer_id }));
        } catch (error) {
          console.error("Error fetching shops:", error);
        }
      }
    };

    const setDefaultShop = () => {
      const storedStoreName = shops.selectedStoreName;
      let storeId;

      if (!shops.selectedStoreId && hasShops) {
        if (storedStoreName) {
          // Set store ID based on name
          storeId = userShops.find((shop) => shop.shop_name === storedStoreName)?.shop_id;
        } else {
          // Default to the first shop
          storeId = userShops[0]?.shop_id;
        }
        if (storeId) {
          dispatch(setSelectedStoreId({ value: storeId, customer_id }))
          dispatch(getShopLogs({}));
        };
      }
    };

    useEffect(() => {
      if (isAuthenticated) {
        fetchShops();
        setDefaultShop();
      }
    }, [isAuthenticated, userShops.length, shops.selectedStoreName]);

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};

export default withShop;
