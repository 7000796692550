import React, { useState } from "react";
import { Select, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

interface FilterOption {
  label: string;
  value: string;
}

interface FilterGroup {
  label: string;
  name?: string;
  options?: FilterOption[];
  type?: string;
}

interface Filters {
  status: FilterGroup;
  followerCount: FilterGroup;
  totalGMV: FilterGroup;
  shopGMV: FilterGroup;
  creatorFulfillmentRate: FilterGroup;
  gender: FilterGroup;
  products: FilterGroup;
  tags: FilterGroup;
}

interface CustomFiltersProps {
  filters: Filters;
  convertToQueryString: any;
  conditionBlocks: any;
  setConditionBlocks: any;
}

interface ConditionGroup {
  conditions: Array<{
    filterKey?: keyof Filters;
    selectedOption?: string;
    isNot?: boolean;
  }>;
}

const CustomFilters: React.FC<CustomFiltersProps> = ({
  filters,
  convertToQueryString,
  conditionBlocks,
  setConditionBlocks,
}) => {
  // Handle filter selection
  const handleFilterChange = (
    blockIndex: number,
    conditionIndex: number,
    filterKey: keyof Filters
  ) => {
    let updatedBlocks = [...conditionBlocks];

    // Create a deep copy of conditions array
    let updatedConditions = [...updatedBlocks[blockIndex].conditions];
    updatedConditions[conditionIndex] = {
      ...updatedConditions[conditionIndex],
      filterKey,
      selectedOption: null, // Reset option when filter changes
    };

    updatedBlocks[blockIndex] = {
      ...updatedBlocks[blockIndex],
      conditions: updatedConditions,
    };

    setConditionBlocks(updatedBlocks);

    if (filterKey && updatedConditions[conditionIndex].selectedOption) {
      convertToQueryString(updatedBlocks);
    }
  };

  const handleOptionChange = (
    blockIndex: number,
    conditionIndex: number,
    optionValue: string
  ) => {
    let updatedBlocks = [...conditionBlocks];

    let updatedConditions = [...updatedBlocks[blockIndex].conditions];
    updatedConditions[conditionIndex] = {
      ...updatedConditions[conditionIndex],
      selectedOption: optionValue,
    };

    updatedBlocks[blockIndex] = {
      ...updatedBlocks[blockIndex],
      conditions: updatedConditions,
    };

    setConditionBlocks(updatedBlocks);

    if (updatedConditions[conditionIndex].filterKey && optionValue) {
      convertToQueryString(updatedBlocks);
    }
  };

  // Handle toggle between is / is not
  const handleToggle = (
    blockIndex: number,
    conditionIndex: number,
    toggleType: "is" | "is not"
  ) => {
    let updatedBlocks = [...conditionBlocks];
    let updatedConditions = [...updatedBlocks[blockIndex].conditions];

    updatedConditions[conditionIndex] = {
      ...updatedConditions[conditionIndex],
      isNot: toggleType === "is not",
    };

    updatedBlocks[blockIndex] = {
      ...updatedBlocks[blockIndex],
      conditions: updatedConditions,
    };

    setConditionBlocks(updatedBlocks);

    convertToQueryString(updatedBlocks);
  };

  // Remove a condition
  const handleRemoveCondition = (
    blockIndex: number,
    conditionIndex: number
  ) => {
    let updatedBlocks = [...conditionBlocks]; // Shallow copy of blocks
    let updatedConditions = [...updatedBlocks[blockIndex].conditions];
    updatedConditions.splice(conditionIndex, 1);
    if (updatedConditions.length === 0) {
      updatedBlocks.splice(blockIndex, 1);
    } else {
      updatedBlocks[blockIndex] = {
        ...updatedBlocks[blockIndex],
        conditions: updatedConditions,
      };
    }
    setConditionBlocks(updatedBlocks);
    convertToQueryString(updatedBlocks);
  };

  const handleAddCondition = (blockIndex: number) => {
    let updatedBlocks = [...conditionBlocks];

    let updatedConditions = [...updatedBlocks[blockIndex].conditions];
    updatedConditions.push({
      filterKey: undefined,
      selectedOption: undefined,
      isNot: false,
    });
    updatedBlocks[blockIndex] = {
      ...updatedBlocks[blockIndex],
      conditions: updatedConditions,
    };

    setConditionBlocks(updatedBlocks);
  };

  // Add a new block (OR)
  const handleAddBlock = () => {
    setConditionBlocks([
      ...conditionBlocks,
      {
        conditions: [{ filterKey: null, selectedOption: null, isNot: false }],
      },
    ]);
  };

  const getValue = (option: any, filter: any) => {
    if(filter.extra) {
      const values = option.split('-')
      const id = values[values.length - 1]
      const data = filter.extra.find((el: any) => el[filter.fetchKeyName] === id)
      return data[filter.fetchKeyName]
    }
     
    return option.replaceAll(',', ' ')
  }
  return (
    <div className="space-y-4">
      {conditionBlocks.map((block: ConditionGroup, blockIndex: number) => (
        <div key={blockIndex}>
          <div className="border p-4 rounded-md bg-blue-50 shadow-sm mb-4">
            {block.conditions.map((condition, conditionIndex) => (
              <div
                key={conditionIndex}
                className="flex items-center space-x-4 mb-4 rounded-md"
              >
                <div className="flex-col w-5/6">
                  {/* Filter selection dropdown */}
                  <Select
                    placeholder="Select a filter"
                    showSearch
                    value={condition.filterKey || undefined}
                    onChange={(value: keyof Filters) =>
                      handleFilterChange(blockIndex, conditionIndex, value)
                    }
                    className="w-full"
                  >
                    {Object.keys(filters).map((key) => {
                      const filterKey = key as keyof Filters;
                      return (
                        <Option key={filterKey} value={filterKey}>
                          {filters[filterKey].label}
                        </Option>
                      );
                    })}
                  </Select>

                  {/* 'is' / 'is not' toggle buttons */}
                  <div className="flex space-x-2 py-4">
                    <Button
                      className={`px-4 py-2 rounded-md ${
                        !condition.isNot
                          ? "bg-black text-white"
                          : "bg-white text-black"
                      }`}
                      type="primary"
                      onClick={() =>
                        handleToggle(blockIndex, conditionIndex, "is")
                      }
                    >
                      is
                    </Button>
                    <Button
                      className={`px-4 py-2 rounded-md ${
                        condition.isNot
                          ? "bg-black text-white"
                          : "bg-white text-black"
                      }`}
                      type="default"
                      onClick={() =>
                        handleToggle(blockIndex, conditionIndex, "is not")
                      }
                    >
                      is not
                    </Button>
                  </div>

                  {/* Option selection dropdown */}
                  <Select
                    placeholder="Select option"
                    value={
                      Array.isArray(condition.selectedOption)
                        ? condition.selectedOption
                        : []
                    }
                    showSearch
                    allowClear
                    onChange={(value: string[]) =>
                      handleOptionChange(blockIndex, conditionIndex, value)
                    }
                    className="w-full"
                    mode="multiple"
                  >
                    {filters[condition.filterKey]?.options?.map((option) => (
                      <Option key={option.value} value={getValue(option.value, filters[condition.filterKey])}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                  {conditionIndex < block.conditions.length - 1 && (
                    <div className="font-bold text-black mt-4">AND</div>
                  )}
                </div>

                {/* Remove condition button (Cross icon) */}
                <Button
                  onClick={() =>
                    handleRemoveCondition(blockIndex, conditionIndex)
                  }
                  icon={<CloseOutlined />}
                  className="ml-2 text-red-600"
                />
              </div>
            ))}

            {/* + AND button, only show at the end of each block */}
            <div className="text-right">
              <Button
                className="mt-4 w-full bg-white-100 text-blue-600"
                onClick={() => handleAddCondition(blockIndex)}
              >
                + AND
              </Button>
            </div>
          </div>
          {blockIndex < conditionBlocks.length - 1 && (
            <div className="flex items-center mb-4">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="mx-4 font-bold text-black">OR</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
          )}
        </div>
      ))}

      {/* + OR button for adding a new condition block */}
      <Button
        className="w-full bg-blue-600 text-white mt-4"
        onClick={handleAddBlock}
      >
        + OR
      </Button>
    </div>
  );
};

export default CustomFilters;
