import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppSelector } from "../hooks";

const PlanGuard = ({ children }) => {
  const navigate = useNavigate();
  const shops = useAppSelector((state) => state.shops);
  const auth = useAppSelector((state) => state.auth);
  const customer_id = auth.meInfo.customer_id
  // Logic to determine if the user has a valid plan
  const selectedShop = (shops.shops?.[customer_id] || []).find(
    (shop) => shop.shop_id === shops.selectedStoreId
  );
  const userInactive = ["inactive", "payment_pending"].includes((selectedShop || {})?.status);

  useEffect(() => {
    if ((Object.values(selectedShop || {}).length > 0 && userInactive) && (shops.shops?.[customer_id] || []).length > 0) {
      navigate("/subscriptions", { replace: true });
    } else if((typeof(shops.shops?.[customer_id]) !== 'undefined' && shops.shops?.[customer_id].length === 0)) {
      navigate("/shopmanagement", { replace: true });
    }

  }, [userInactive]);

  return userInactive ? null : children;
};

export default PlanGuard;
