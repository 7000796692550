import React from 'react';

interface LoadingScreenProps {
    message?: string; // Optional message to display
    className?: string; // Optional additional styles
}

const LoadingScreenV2: React.FC<LoadingScreenProps> = ({message = "Loading...", className = ""}) => {
    return (
        <div className={`flex flex-col items-center justify-center min-h-screen bg-white ${className}`}>
            <div className="flex items-center justify-center">
                <div
                    className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid border-gray-200"></div>
            </div>
            <h2 className="text-sm font-medium text-gray-600 mt-4">{message}</h2>
        </div>
    );
};

export default LoadingScreenV2;
