import { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const TagSelectComponent = ({
  tags,
  isAddTagModalVisible,
  handleMultiSelectChange,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleTagSelect = (values) => {
    handleMultiSelectChange(values);
    setDropdownOpen(false); // Close dropdown after selection
  };

  return (
    <Select
      mode="tags"
      value={
        (
          isAddTagModalVisible.data[isAddTagModalVisible.selectedRow] || {}
        )?.tags
      }
      onChange={handleTagSelect}
      className="w-full mt-2"
      placeholder="Select tags"
      open={dropdownOpen}
      onDropdownVisibleChange={(open) => setDropdownOpen(open)} // Control dropdown visibility
    >
      {tags?.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default TagSelectComponent;