import React from "react";
import { Card } from "antd";
import { CiShare1 } from "react-icons/ci";

// Define the type for each detail entry
interface DetailEntry {
  title: string;
  value: string | number;
}

// Define the prop type for the component
interface AutomationOverviewProps {
  details: DetailEntry[];
  automationName: string;
  onClick: () => void;
}

const AutomationOverview: React.FC<AutomationOverviewProps> = ({
  details,
  automationName,
  onClick,
}) => {
  return (
    <div className="p-4">
      <div
        className="text-gray-600 mb-4 flex items-center cursor-pointer"
        onClick={onClick}
      >
        <p className="mr-2">{automationName}</p> <CiShare1 />
      </div>

      {/* Dynamically Render Cards */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        {details.map((entry, index) => (
          <Card key={index} title={entry.title}>
            <p className="text-gray-500">{entry.value}</p>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AutomationOverview;
