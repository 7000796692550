import { SearchOutlined } from "@ant-design/icons";
import type { TableProps } from "antd";
import { Button, Input, Modal, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FaRegCircle } from "react-icons/fa6";
import { FaCircleCheck } from "react-icons/fa6";
import { FiAlertOctagon } from "react-icons/fi";
import { LuLoader } from "react-icons/lu";
import { toast } from "react-toastify";
import { handleError } from "../../../pages/helpers";
import { useAppDispatch, useAppSelector } from "../../../pages/hooks";
import { getShopsList } from "../../../pages/store/shops";
import {
  cancelSubscription,
  getInvoicesData,
  getSubscriptionsData,
  linkSubscription,
} from "../../../pages/store/subscriptions";

const SubscriptionTable: React.FC<{ subscriptions: any[] }> = ({
  subscriptions,
}) => {
  const columns: TableProps<any>["columns"] = [
    {
      title: "Subscription",
      dataIndex: "plan",
      key: "subscription",
      minWidth: 300,
      render: (item) => <p className="font-medium text-sm">{item}</p>,
    },
    {
      title: "Amount",
      dataIndex: "unit_amount",
      key: "amount",
      render: (item) => <p className="font-medium text-sm">${item / 100}</p>,
    },
    {
      title: "Linked Shops",
      dataIndex: "shop_name",
      key: "linkedshops",
      render: (item, record) =>
        item ? (
          item
        ) : (
          <p
            className="font-medium text-blue-600 text-sm cursor-pointer"
            onClick={() => handleCancelClickLink(record)}
          >
            Link Shop
          </p>
        ),
    },
    {
      title: "Next payment",
      dataIndex: "current_period_end",
      key: "nextpay",
      render: (item) => <p>{new Date(item).toLocaleDateString("en-US")}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (item, record) => (
        <Button
          className="font-medium text-sm cursor-pointer"
          onClick={() => handleCancelClickDelete(record)}
        >
          Cancel Subscription
        </Button>
      ),
    },
  ];
  const dispatch = useAppDispatch();
  const { shops } = useAppSelector((state: any) => state.shops);
  const auth = useAppSelector((state) => state.auth);
  const customer_id = auth.meInfo.customer_id;
  const { cancelLoad, linkLoad } = useAppSelector(
    (state: any) => state.subscriptions
  );
  const [filteredShops, setFilteredShops] = useState(
    (shops?.[customer_id] || []).filter((i: any) => i.subscription_id === null)
  );
  const [isModalVisibleLink, setIsModalVisibleLink] = useState(false);
  const [deleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
  const [selectedSubscriptionLink, setSelectedSubscriptionLink] =
    useState<any>(null);
  const [selectedShop, setSelectedShop] = useState<any>("");

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState<any>([]);

  useEffect(() => {
    const indexOfLastSubscription = currentPage * 10;
    const indexOfFirstSubscription = indexOfLastSubscription - 10;
    setFilteredSubscriptions(
      subscriptions.slice(indexOfFirstSubscription, indexOfLastSubscription)
    );
  }, [currentPage, subscriptions]);

  useEffect(() => {
    console.log("Filtered Subscriptions >> ", filteredSubscriptions);
  }, [filteredSubscriptions]);

  const nextPage = () => {
    if (currentPage < Math.ceil(subscriptions?.length / 10)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCancelClickDelete = (subscription: any) => {
    setSelectedSubscription(subscription.id); // Store the clicked subscription details
    setIsDeleteModalVisible(true); // Open modal
  };

  const handleOkDelete = async () => {
    try {
      await dispatch(cancelSubscription(selectedSubscription)).then(
        async () => {
          if (
            !(
              subscriptions.find((i) => selectedSubscription === i?.id)
                ?.shop_id === null
            )
          ) {
            await dispatch(
              getShopsList({ customer_id: auth.meInfo.customer_id })
            ).then(async () => {
              await dispatch(getSubscriptionsData());
            });
          } else {
            await dispatch(getSubscriptionsData());
          }
        }
      );
      toast.success("Subscription Cancelled Successfully");
      setIsDeleteModalVisible(false);
      setSelectedSubscription(null);
      dispatch(getInvoicesData());
    } catch (error) {
      handleError(error);
      setIsDeleteModalVisible(false);
      setSelectedSubscription(null);
    }
  };

  const handleLinkCancel = () => {
    if (!linkLoad) {
      setIsModalVisibleLink(false);
      setSelectedShop("");
      setSearch("");
      setSelectedSubscriptionLink(null); // Close modal without deleting
    }
  };

  const handleCancelClickLink = (subscription: any) => {
    setSelectedSubscriptionLink(subscription.id); // Store the clicked subscription details
    setIsModalVisibleLink(true); // Open modal
  };

  const handleOkLink = async () => {
    try {
      const res = await dispatch(
        linkSubscription({
          subscriptionIDToBeLink: selectedSubscriptionLink,
          selectedShop,
        })
      );

      if (res?.error) {
        toast.error("Shop linking to subscription failed.");
        setSelectedSubscription(null);
        setIsModalVisibleLink(false);
      } else {
        await dispatch(
          getShopsList({ customer_id: auth.meInfo.customer_id })
        ).then(async () => {
          await dispatch(
            getSubscriptionsData({
              customer_id: auth.meInfo.customer_id,
              stripe_customer_id: auth.meInfo.stripe_customer_id,
            })
          );
        });

        toast.success("Shop linked to subscription successfully.");
        setSelectedSubscription(null);
        setIsModalVisibleLink(false);
      }
    } catch (error) {
      handleError(error);
      setSelectedSubscription(null);
      setIsModalVisibleLink(false);
    }
  };

  const handleCancelDelete = () => {
    if (!cancelLoad) {
      setIsDeleteModalVisible(false); // Close modal without deleting
      setSelectedSubscription(null);
    }
  };

  const [type, setType] = useState("all");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search && (shops?.[customer_id] || [])) {
      setFilteredShops(
        filteredShops.filter((i: any) =>
          i?.shop_name?.toLowerCase().includes(search?.toLowerCase())
        )
      );
    } else if ((shops?.[customer_id] || []).length) {
      setFilteredShops(
        (shops?.[customer_id] || []).filter(
          (i: any) => i.subscription_id === null
        )
      );
    }
  }, [search, (shops?.[customer_id] || []).length]);

  return (
    <div className="mt-5">
      <Modal
        maskClosable={!cancelLoad}
        centered
        className="top-14 transition-none"
        footer={null}
        title={
          <p className="font-semibold bg-transparent text-start font-sans text-lg leading-6">
            We're sad to see you leave!
          </p>
        }
        open={deleteModalVisible}
        onCancel={handleCancelDelete}
      >
        <div className="flex flex-col">
          <p className="mt-1 text-sm text-slate-600">
            Are you sure you want to cancel this subscription? This action
            cannot be undone.
          </p>
          {!(
            subscriptions.find((i) => selectedSubscription === i?.id)
              ?.shop_id === null
          ) ? (
            <div className="flex flex-row rounded-lg p-4 bg-amber-50 gap-x-3 mt-3">
              <div>
                <FiAlertOctagon className="text-xl mt-1 text-amber-600" />
              </div>
              <p className="text-sm text-gray-900">
                Please note that the shop linked to this plan will lose access
                to all the features included, and will be de-linked from this
                plan.
              </p>
            </div>
          ) : null}
          <div className="flex flex-row justify-between mt-3 gap-x-3">
            <button
              onClick={handleCancelDelete}
              className="p-2 border border-slate-200 font-semibold w-full rounded-lg"
            >
              I changed my mind!
            </button>
            <button
              onClick={handleOkDelete}
              disabled={cancelLoad}
              className="p-2 border border-slate-200 font-semibold w-full rounded-lg bg-red-600 text-white flex flex-row justify-center space-x-3 items-center"
            >
              <p>Cancel subscription</p>{" "}
              {cancelLoad && <LuLoader className="animate-spin" />}
            </button>
          </div>
        </div>
      </Modal>

      {/* Link Modal */}
      <Modal
        maskClosable={!linkLoad}
        centered
        className="top-14 transition-none"
        footer={null}
        title={
          <p className="font-semibold bg-transparent text-start font-sans text-lg leading-6">
            Link Shop
          </p>
        }
        open={isModalVisibleLink}
        onCancel={handleLinkCancel}
      >
        <div className="flex flex-col">
          <p className="mt-1 text-sm text-slate-600">
            Search shop to link with this plan.
          </p>
          <div className="mt-5">
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Shops..."
              prefix={<SearchOutlined />}
              className="h-10 placeholder-gray-900"
            />
          </div>
          <div className="mt-2">
            {filteredShops.map((i: any) => (
              <div
                key={i.shop_id}
                onClick={() => setSelectedShop(i?.shop_id)}
                className={`flex flex-row justify-between items-center py-2 cursor-pointer ${
                  selectedShop === i.shop_id
                    ? "border-2 border-blue-700 my-2"
                    : "my-1 border border-gray-300"
                } rounded-lg px-3 `}
              >
                <div className="flex flex-row justify-start items-center gap-x-3">
                  {/* <FaRegCircle
                      className={`${
                        selectedShop === i.shop_id
                          ? "text-blue-700"
                          : "text-gray-300"
                      }`}
                    /> */}
                  {selectedShop === i.shop_id ? (
                    <FaCircleCheck className="text-blue-700" />
                  ) : (
                    <FaRegCircle className="text-gray-300" />
                  )}
                  <p>{i?.shop_name}</p>
                  {auth?.meInfo?.role == "admin" && (
                    <>
                      <p>{i?.shop_id}</p>
                      <p>Status: {i?.status}</p>
                    </>
                  )}
                </div>
                <div className="text-gray-500">Region: {i?.shop_region}</div>
              </div>
            ))}
          </div>
          <div className="flex flex-row justify-between mt-3 gap-x-3">
            <button
              onClick={handleLinkCancel}
              className="p-2 border border-slate-200 font-semibold w-full rounded-lg"
            >
              I changed my mind!
            </button>
            <button
              disabled={!selectedShop || linkLoad}
              onClick={handleOkLink}
              className={`p-2 border border-slate-200 font-semibold w-full rounded-lg flex flex-row items-center justify-center space-x-3 ${
                selectedShop ? "opacity-100 " : "opacity-60 cursor-not-allowed"
              } ${linkLoad && "cursor-not-allowed"}`}
            >
              <p>Link to Subscription</p>{" "}
              {linkLoad && <LuLoader className="animate-spin" />}
            </button>
          </div>
        </div>
      </Modal>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={
          type === "all"
            ? subscriptions
            : subscriptions?.filter(
                (i: any) => i?.plan?.toLowerCase() === type?.toLowerCase()
              )
        }
        pagination={{ pageSize: 5 }}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <p className="font-bold text-xl align-baseline">
                    Active Subscriptions
                  </p>
                  <Tag className="ml-2 bg-white">{`${subscriptions.length} Subscriptions`}</Tag>
                </div>

                <div className="flex flex-row">
                  <div
                    onClick={() => setType("all")}
                    className={`cursor-pointer select-none ${
                      type === "all" && "bg-gray-50"
                    } text-black font-semibold text-xs cursor-pointer leading-none rounded-l-md border border-gray-300 py-2 px-3  grid place-items-center`}
                  >
                    View all
                  </div>
                  <div
                    onClick={() => setType("basic")}
                    className={`cursor-pointer select-none ${
                      type === "basic" && "bg-gray-50"
                    } text-black font-semibold text-xs  border-t border-b border-gray-300 py-2 px-3  grid place-items-center`}
                  >
                    Basic
                  </div>
                  <div
                    onClick={() => setType("pro")}
                    className={`cursor-pointer select-none ${
                      type === "pro" && "bg-gray-50"
                    } text-black font-semibold text-xs cursor-pointer leading-none rounded-r-md border border-gray-300 py-2 px-3  grid place-items-center`}
                  >
                    Pro
                  </div>
                </div>
              </div>
            </>
          );
        }}
      />
      {/* <div className="flex items-center bg-white p-3 rounded-b-lg justify-between">
        <p>{`Page ${currentPage} of ${
          Math.floor(subscriptions.length / 10) + 1
        } `}</p>
        <div className="flex items-center gap-2">
          <Button className="font-medium" onClick={prevPage}>
            Previous
          </Button>
          <Button className="font-medium" onClick={nextPage}>
            Next
          </Button>
        </div>
      </div> */}
    </div>
  );
};
export default SubscriptionTable;
