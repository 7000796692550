import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FirstModal from "./modals/FirstModal";
import { PiCurrencyCircleDollar } from "react-icons/pi";
import SubscriptionTable from "./components/SubscriptionTable";
import InvoicesTable from "./components/InvoicesTable";
import { getInvoicesData, getSubscriptionsData } from "../store/subscriptions";
import Skeletons from "../components/skeletons/Skeleton";
import { useAppDispatch, useAppSelector } from "../hooks";

enum TabType {
  Subscriptions = "subscriptions",
  Invoices = "invoices",
}

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { subscriptions_data, invoices_data, loadCompleted } = useAppSelector(
    (state) => state.subscriptions
  );

  const { customer_id, stripe_customer_id, role } = useAppSelector(
    (state) => state.auth.meInfo
  );

  const isInvoicesPath = (pathname:string) => {
    const invoicesRegex = /^\/?subscriptions\/invoices(\/|\/.*|\?.*)?$/;
    return invoicesRegex.test(pathname)
  }

  // Fetch data based on the active route
  useEffect(() => {
    console.log('isInvoice > ',isInvoicesPath(location.pathname));
    if (isInvoicesPath(location.pathname)) {
      console.log('Records length >> ',invoices_data.length);
      if (stripe_customer_id && customer_id) {
        console.log('PATH CHANGED >> ', location.pathname);

        if (role === 'admin'){
          dispatch(getInvoicesData({stripe_customer_id, customer_id: typeof customer_id == 'number' ? customer_id : parseInt(customer_id) }));
        }else {
          dispatch(getInvoicesData());
        }
      }
    } else {
      console.log('Records length >> ',subscriptions_data.length);
      if (stripe_customer_id && customer_id) {
        console.log('PATH CHANGED >> ', location.pathname);

        if(role === "admin"){
          dispatch(getSubscriptionsData({stripe_customer_id, customer_id: typeof customer_id == 'number' ? customer_id : parseInt(customer_id)}));
        }else {
          dispatch(getSubscriptionsData());
        }
      }
    }
  }, [location.pathname, dispatch, stripe_customer_id, customer_id]);

  const handleTabClick = (tab: TabType) => {
    navigate(
      tab === TabType.Subscriptions
        ? "/subscriptions"
        : "/subscriptions/invoices",
      { replace: true }
    );
  };

  return (
    <div>
      <div className="flex flex-row min-w-full">
        <h1 className="text-2xl text-blue-500 font-bold flex-1">
          Subscriptions
        </h1>
        <FirstModal />
      </div>
      <p className="text-sm text-slate-600">
        Manage your active subscriptions and download invoices.
      </p>
      <div className="p-1 bg-gray-200 w-min mt-6 rounded-lg">
        <div className="flex flex-row gap-1">
          <button
            onClick={() => handleTabClick(TabType.Subscriptions)}
            className={`${
              !isInvoicesPath(location.pathname)
                ? "bg-white text-blue-600 font-medium"
                : ""
            } p-2 text-base rounded-md`}
          >
            Subscriptions
          </button>
          <button
            onClick={() => handleTabClick(TabType.Invoices)}
            className={`${
              isInvoicesPath(location.pathname)
                ? "bg-white text-blue-600 font-medium"
                : ""
            } p-2 text-base rounded-md`}
          >
            Invoices
          </button>
        </div>
      </div>

      {!loadCompleted ? (
        <div className="mt-5">
          <Skeletons />
        </div>
      ) : !isInvoicesPath(location.pathname) ? (
        subscriptions_data.length > 0 ? (
          <SubscriptionTable subscriptions={subscriptions_data} />
        ) : (
          <NoDataView
            message="No active Subscriptions"
            subMessage="To make most out of Reacher upgrade your plan"
          />
        )
      ) : invoices_data.length > 0 ? (
        <InvoicesTable invoices={invoices_data} />
      ) : (
        <NoDataView
          message="No invoices found"
          subMessage="You currently have no invoices to show."
        />
      )}
    </div>
  );
};


const NoDataView = ({
  message,
  subMessage,
}: {
  message: string;
  subMessage: string;
}) => (
  <div className="flex flex-col gap-y-1 justify-center items-center h-[70vh]">
    <div className="rounded-3xl bg-gradient-to-b from-indigo-100 border-none p-2 mb-3">
      <div className="bg-blue-600 text-white rounded-3xl border border-blue-200">
        <div className="p-2">
          <PiCurrencyCircleDollar className="text-3xl " />
        </div>
      </div>
    </div>
    <h1 className="text-center text-sm leading-4 font-semibold mb-2">
      {message}
    </h1>
    <h2 className="text-center text-xs leading-4 font-normal mb-5">
      {subMessage}
    </h2>
    <FirstModal />
  </div>
);

export default Index;
