import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AutomationList from "./components/AutomationList";
import { getAutomations } from "../store/automation";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getShopsList } from "../store/shops";
import Layout from "./layout";

const Automations = (props: any) => {
  const navigate = useNavigate();
  const automation = useAppSelector((state) => state.automation);
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();

  const singleCustomerAutomations =
    automation.data?.[shops.selectedStoreId] || [];

  useEffect(() => {
    if (auth?.meInfo?.customer_id && shops.selectedStoreId) {
      dispatch(
        getAutomations({
          shop_id: shops.selectedStoreId,
        })
      );
    }
  }, [auth, shops.selectedStoreId]);

  const handleRowClick = (automation) => {
    navigate(`/automations/${automation.automation_id}`);
  };

  function getShopObject(shopId) {
    const data = shops.shops
    for (const customerId in data) {
      const shops = data[customerId];
      const shop = shops.find((shop) => shop.shop_id === shopId);
      if (shop) {
        return shop;
      }
    }
    return null; // Return null if the shop_id is not found
  }

  const shop = getShopObject(shops.selectedStoreId)

  // const refreshShops = async () => {
  //   await dispatch(getShopsList({ customer_id: auth.meInfo.customer_id }));
  // }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (shop?.onboarding_status !== 'added_to_affiliate_portal') {
  //       refreshShops();
  //     }
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);  

  return (
    <Layout>
      <div>
        <div className="flex items-center justify-between pb-6 mb-6 border-b border-stroke border-gray-300">
          <h1 className="text-2xl text-blue-500 font-bold">Active Automations</h1>
          <button
            className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out mr-4"
            onClick={() => {
              navigate("/automations/create");
            }}
          >
            Create New Automation
          </button>
        </div>

        <AutomationList
          automations={singleCustomerAutomations}
          isAutomationsLoading={automation.loading}
          onRowClick={handleRowClick}
          onboarding_status={shop?.onboarding_status}
          shop={shop}
          // refreshShops={() => {}}
        />
      </div>
    </Layout>
  );
};

export default Automations;
