import React, { useState } from "react";
import { Modal, Input, DatePicker, Divider } from "antd";

const DuplicateAutomationModal = ({
  visible,
  onClose,
  automationData,
  onDuplicate,
}) => {
  const [automationName, setAutomationName] = useState("");
  const [validUntilDate, setValidUntilDate] = useState(null);
  console.log("automationData1231231", automationData);

  const handleDuplicate = () => {
    if (
      (automationData.automation_type || "").toLowerCase().includes("target")
    ) {
      if (automationName && validUntilDate) {
        onDuplicate({
          ...automationData,
          automation_name: automationName,
          valid_until: validUntilDate,
        });
      } else {
        alert("Please provide all required fields.");
      }
    } else {
      if (automationName) {
        onDuplicate({
          ...automationData,
          automation_name: automationName,
        });
        
      } else {
        alert("Please provide all required fields.");
      }
    }
    onClose();
  };
  console.log("automationData1321321", automationData);
  return (
    <Modal
      title="Duplicate Automation"
      visible={visible}
      onCancel={onClose}
      onOk={handleDuplicate}
      okText="Duplicate"
    >
      <div>
        <h3 className="text-lg font-semibold text-gray-800">
          Automation Details
        </h3>
        <p>
          <strong>Automation Name:</strong> {automationData.automation_name}
        </p>
        <p>
          <strong>Automation Type:</strong> {automationData.automation_type}
        </p>
        <p>
          <strong>Status:</strong> {automationData.status}
        </p>
        <p>
          <strong>Created At:</strong> {automationData.created_at}
        </p>
        {automationData.config?.SCHEDULE && (
          <>
            <p>
              <strong>Schedule:</strong>
            </p>
            {Object.entries(automationData.config.SCHEDULE).map(
              ([day, { maxCreators }]) => (
                <p key={day}>
                  {day}: {maxCreators} creators
                </p>
              )
            )}
          </>
        )}
        <Divider />
        <h3 className="text-lg font-semibold text-gray-800">New Automation</h3>
        <Input
          placeholder="Enter New Automation Name"
          value={automationName}
          onChange={(e) => setAutomationName(e.target.value)}
          className="mb-3"
        />
        {(automationData.automation_type || "")
          .toLowerCase()
          .includes("target") && (
          <DatePicker
            placeholder="Select Valid Until Date"
            style={{ width: "100%" }}
            onChange={(date) => setValidUntilDate(date)}
          />
        )}
      </div>
    </Modal>
  );
};

export default DuplicateAutomationModal;
