import React from "react";
import { Badge, Button, Divider, Table } from "antd";
import { ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import CustomModal from "../../common/modal";

const AffiliateManagerModal = ({
  disabled,
  visible,
  onCancel,
  onConfirm,
  account_email,
  onboarding_status,
}: {
  disabled: boolean;
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  account_email: string;
  onboarding_status: string;
}) => {
  const badgeColor =
    onboarding_status === "complete"
      ? "green"
      : onboarding_status === "progress"
        ? "gold"
        : "red";

  const content = (
    <div className="space-y-6">
      {/* Status, Email, and How to do Section */}
      <table className="w-full text-sm">
        <tbody>
          {/* Status Row */}
          <tr>
            <td className="font-semibold w-32 align-top pb-3">Status:</td>
            <td className="pb-3">
              <Badge color={badgeColor} text={
                onboarding_status === "complete" ? "Verified" :
                onboarding_status === "progress" ? "Verifying..." :
                "Not verified"
              } />
            </td>
          </tr>

          {/* AM Email Row */}

          {["complete", "progress"].includes(onboarding_status) ? (
            <tr>
              <td className="font-semibold w-32 align-top pb-3">Help?</td>
              <td className="pb-3">
                If your account is not verified within 1-2 minutes, please
                contact support at <strong>contact@reacherai.com</strong> or
                click the button below.
              </td>
            </tr>
          ) : (
            <>
              <tr>
                <td className="font-semibold w-32 align-top pb-3">AM email:</td>
                <td className="pb-3">
                  Please add <strong>{account_email}</strong> to your shop as an
                  Affiliate Manager. This enables Reacher to send and monitor
                  outreach messages on your behalf.
                </td>
              </tr>

              {/* How to do Row */}
              <tr>
                <td className="font-semibold w-32 align-top">How to do:</td>
                <td>
                  You can do this in the TikTok Shop Seller Center by going to{" "}
                  <strong>
                    My Account ➔ Account Settings ➔ User Management ➔ Add User
                  </strong>
                  . Make sure to choose "Affiliate Manager" as the role.
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>

      {/* Video Walkthrough Placeholder */}
      {!["complete", "progress"].includes(onboarding_status) && (
        <>
          <div className="bg-gray-100 h-32 rounded-md flex items-center justify-center border border-gray-300">
            <p className="text-gray-500">Video Walkthrough</p>
          </div>
          <table className="w-full text-sm">
            <tbody>
              <tr>
                <td className="font-semibold w-32 align-top">Note:</td>
                <td>
                  If you see the error message below after adding the user,{" "}
                  <strong>
                    don't worry - it's just a TikTok bug! The invite went
                    through successfully.
                  </strong>{" "}
                  Just click the blue button above and let Reacher handle the
                  rest.
                </td>
              </tr>
              {onboarding_status !== "complete" && (
                <tr>
                  <td></td>
                  <td className="border border-gray-200 rounded-md p-3 flex items-center bg-white">
                    <ExclamationCircleOutlined className="text-red-500 text-lg mr-3" />
                    <p className="text-gray-700 text-xs">
                      Linking invitation is sent too frequently, please try
                      again later.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}

      {/* Note Section */}
      {!["complete", "progress"].includes(onboarding_status) ? (
        <>
          <Divider className="border-t border-b-0 border-secondary m-0" />
          <p className="text-gray-700 text-xs">
            Once you're done, please click here:{" "}
          </p>
          <Button className="flex w-full" type={"primary"} onClick={onConfirm}>
            I've added the email above as an Affiliate Manager
          </Button>
        </>
      ) : (
        <Button className="flex w-full" type={"primary"} onClick={() => {
          const mailtoURL = "https://reacherapp.com/contact";
          window.location.href = mailtoURL;
        }}>
          Contact Reacher
        </Button>
      )}
    </div>
  );

  return (
    <CustomModal
      visible={visible}
      title={
        <div className="flex flex-col">
          <div className="border border-gray-200 p-2 rounded-lg flex w-[35px] mb-2">
            <UserOutlined className="text-lg" />
          </div>

          <h2 className="text-lg font-medium">Set up affiliate manager</h2>
        </div>
      }
      content={content}
      onCancel={onCancel}
      // onConfirm={onConfirm}
      confirmText="I've added the email above as an Affiliate Manager"
      cancelText="Close"
      confirmButtonType="primary"
      showModalFooter={false}
      width={550}
      disabled={disabled}
    />
  );
};

export default AffiliateManagerModal;
