import React from "react";
import { Field, ErrorMessage } from "formik";
import AddToList from "../forms/AddToList";
import TextError from "../forms/TextError";

interface AddToListWithFormikProps {
  name: string;
  label?: string;
  placeHolder?: string;
  listItems?: any[];
  maxListLenght?: number;
  disabled?: boolean;
  onViewAll?: () => void; // Callback for "View All" button
}

const AddToListWithFormik: React.FC<AddToListWithFormikProps> = ({
  name,
  label,
  placeHolder,
  listItems = [],
  maxListLenght,
  disabled = false,
  onViewAll,
}) => {
  return (
    <div className="mb-6 w-full">
      {/* Label */}
      {label && (
        <label className="block text-sm font-semibold text-gray-700 mb-1">
          {label} <span className="text-blue-500">*</span>
        </label>
      )}
      <p className="text-sm text-gray-600">
        Specify product IDs from your TikTok Shop to be sent as product cards
        and set commission for each product.
      </p>

      {/* Add to List Input */}
      <Field name={name}>
        {({ field, form, values }: any) => (
          <AddToList
            name={name}
            form={form}
            placeHolder={placeHolder}
            onChange={(value) => form.setFieldValue(name, value)} // Sync list with Formik
            listItems={field.value || []}
            maxListLenght={maxListLenght}
            disabled={disabled}
            values={values}
          />
        )}
      </Field>
      <ErrorMessage name={name} component={TextError} />

    </div>
  );
};

export default AddToListWithFormik;
