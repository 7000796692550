import { MdCheck } from 'react-icons/md'

const Feature = ({ text }: { text: string }) => {
    return (
        <div className='flex flex-row space-x-4 items-center'>
            <div className='rounded-3xl bg-violet-100 p-1'>
                <MdCheck className='text-blue-500 text-sm' />
            </div>
            <p className='font-medium text-xs' >{text}</p>
        </div>
    )
}

export default Feature