// AutomationForm.tsx
import React, { useEffect, useState } from "react";
// @ts-ignore
import { Form, ErrorMessage, FormikValues } from "formik";

import { useAppDispatch, useAppSelector } from "../../../pages/hooks";
import CustomFilters from "./CustomFilters";

import { Spin } from "antd";

// import SingleGroupPreview from "~/pages/automations/components/ShowGroupPreview";  // Example for the plus icon

interface AutomationFormFilterProps {
  values: FormikValues;
  setFieldValue: any;
  validateField: any;
  shops: any;
  onPrev: any;
  isSubmitting: boolean;
  // onReturn: () => void;
}

const AutomationFilterForm: React.FC<AutomationFormFilterProps> = ({
  values,
  setFieldValue,
  validateField,
  shops,
  onPrev,
  isSubmitting,
  // onReturn,
}) => {
  console.log('values12312321', values)
  const automation = useAppSelector((state) => state.automation);

  const filters = automation.filters?.[shops.selectedStoreRegion] || [];
  const loading = automation.loading;

  const creatorsFilters = filters.filter((filter: any) =>
    ["category", "followers", "products"].includes(filter.name)
  );
  const followersFilters = filters.filter((filter: any) =>
    ["age", "gender"].includes(filter.name)
  );
  const performanceFilters = filters.filter((filter: any) =>
    [
      "gmv",
      "engagement",
      "units",
      "views",
      "fulfillment_rate",
      "gpm",
      "video_views",
      "quickly_response",
      "videos",
      "video_gpm",
      "live_gpm",
      "high_sample_dispatch_rate",
    ].includes(filter.name)
  );

  return (
    <Form>
      {loading ? (
        <div className="flex justify-center">
          <Spin />
        </div>
      ) : (
        <>
          {/* Left Section */}
          <div className="pl-2 pb-2 pr-5">
            <div>
              <p className="block text-md font-bold text-gray-800 mb-2">
                Creators Filters
              </p>

              <div className="">
                <CustomFilters title="" filters={[...creatorsFilters,...followersFilters, ...performanceFilters]} />
                {/* <CustomFilters title="Followers" filters={followersFilters} />
                <CustomFilters
                  title="Performance"
                  filters={performanceFilters}
                /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default AutomationFilterForm;
