import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getAllCustomers, setAdmin } from "../store/admin";

const withAdmin = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const Wrapper = (props: P) => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.auth);
    const admin = useAppSelector((state) => state.admin);

    // Memoized values to reduce re-computation
    const isAdmin = useMemo(
      () => auth.isLogin && auth.meInfo.role === "admin",
      [auth.isLogin, auth.meInfo.role]
    );

    const needsFetch = useMemo(
      () => isAdmin && auth.meInfo.customer_id && admin.customersList.length === 0,
      [isAdmin, auth.meInfo.customer_id, admin.customersList.length]
    );

    useEffect(() => {
      if (needsFetch) {
        console.log("Fetching customers list");
        dispatch(setAdmin(true));
        dispatch(getAllCustomers());
      }
    }, [needsFetch, dispatch]);

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};

export default withAdmin;
