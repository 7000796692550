export function transformConfigData(configData: any) {
  const createCheckboxOptions = (labels: any) =>
    labels.map((label: any) => ({
      label,
      value: label,
      selected: false,
    }));

  const createDropdownOptions = (products: any) =>
    products.map((product: any) => ({
      label: `${product.product_name}-${product.product_id}`,
      value: product.product_id,
    }));

  const transformedConfig = Object.values(configData).length
    ? {
        clickThroughRate: {
          label: "Click Through Rate",
          type: "checkbox",
          options: createCheckboxOptions(configData.clickThroughRate),
        },
        unitsSold: {
          label: "Units Sold",
          type: "checkbox",
          options: createCheckboxOptions(configData.unitsSold),
        },
        skuOrders: {
          label: "SKU Orders",
          type: "checkbox",
          options: createCheckboxOptions(configData.skuOrders),
        },
        views: {
          label: "Views",
          type: "checkbox",
          options: createCheckboxOptions(configData.views),
        },
        gmvAmount: {
          label: "GMV Amount",
          type: "checkbox",
          options: createCheckboxOptions(configData.gmvAmount),
        },
        products: {
          label: "Products",
          type: "dropdown_multiselect",
          options: createDropdownOptions(configData.products),
          extra: configData.products,
          fetchKeyName: "product_id",
          KeyName: "product_name",
        },
        usernames: {
          label: "Creators",
          type: "dropdown_multiselect",
          options: createCheckboxOptions(configData.usernames),
        },
      }
    : {};

  return transformedConfig;
}