import { Field } from "formik";
import React from "react";

const FormikRadioButtonGroup = ({ name, options, consistentLabel }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <div className="mb-5">
          {consistentLabel && (
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {consistentLabel}
              <span className="text-blue-500"> *</span>
            </label>
          )}
          <div role="group" aria-labelledby={name}>
            {options.map((option) => (
              <div className="flex pt-3">
              <label
                key={option.value}
                className="flex items-center mb-2 text-gray-600 font-medium text-sm cursor-pointer"
                onClick={() => form.setFieldValue(name, option.value)} // Update Formik's state
              >
                <div
                  className={`w-5 h-5  flex items-center justify-center rounded-full border-2 ${
                    field.value === option.value
                      ? "border-blue-500 bg-blue-500"
                      : "border-gray-400 bg-white"
                  }`}
                >
                  {field.value === option.value && (
                    <div className="outer-circle">
                      <div className="inner-circle-radio" />
                    </div>
                  )}
                </div>

              </label>
              <span className="ml-3 text-sm font-medium text-gray-600 ">
                {typeof option.label === "string" ? (
                  option.label
                  ) : (
                  option.label // Render JSX label if provided
                  )}

                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </Field>
  );
};

export default FormikRadioButtonGroup;
